import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { SiteManagementService } from '../_services/site-management.service';
import { SiteDetails } from '../_models/overview/site-details.type';
import { AlarmSnapshot } from '../_models/overview/alarm-snapshot.type';
import { AssetAge } from '../_models/overview/asset-age.type';
import { AssetHealth } from '../_models/overview/asset-health.type';
import { AssetWarranty } from '../_models/overview/asset-warranty.type';
import { ContractStatus } from '../_models/overview/contract-status.type';
import { Sample1 } from '../_models/overview/sample1.type';
import { SiteMaintenance } from '../_models/overview/site-maintenance.type';
import { SiteObsolenscence } from '../_models/overview/site-obsolenscence.type';
import { SiteSuggestion } from '../_models/overview/site-suggestion.type';
import { UpcomingMaintenance } from '../_models/overview/upcoming-maintenance.type';
import { VoltageDistribution } from '../_models/overview/voltage-distribution.type';
import { WorkPermitSnapshot } from '../_models/overview/work-permit-snapshot.type';
import { AssetSnapshot } from '../_models/overview/asset-snapshot.type';
import { SharedValueService } from '../_services/shared-value.service';
import { EnvironmentData, General, ObsolenscenceProfileList, SiteMaintenanceList, SuggestionsAssetList } from '../_models/overview/site-maintenance-list.type';
import { HttpErrorResponse } from '@angular/common/http';
import { RbacService } from '../_helpers/rbac.service';
import { SharedService } from '../_services/shared.service';
import { SubAssetRisk } from '../_models/health.type';
import { AssetService } from '../_services/assets.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  up: Boolean = true;
  siteId: string = '';
  optionId: string = '30';
  siteDetails!: SiteDetails;
  alarmSnapshot!: AlarmSnapshot;
  assetSnapshot!: AssetSnapshot;
  assetAge!: AssetAge;
  assetAgeTemp!: { [key: string]: any };
  assetHealth!: AssetHealth;
  assetWarranty!: AssetWarranty;
  contractStatus!: any;
  sample1!: Sample1;
  siteMaintenance!: SiteMaintenance;
  siteObsolenscence!: SiteObsolenscence;
  siteSuggestion!: SiteSuggestion;
  upcomingMaintenance!: UpcomingMaintenance[];
  voltageDistribution!: { [key: string]: any };
  workPermitSnapshot!: WorkPermitSnapshot;
  alarmMenu: any;
  maintenanceMenu: any;
  sites: any;
  maintenanceData: any;
  obsolenscenceData: any;
  selectedAlarmMenuOption: any;
  remainingHeight: any = '900px';
  maintenanceSummaryList!: SiteMaintenanceList[];
  obsolenscenceProfileList!: ObsolenscenceProfileList[];
  suggestionsAssetList!: SuggestionsAssetList[];
  environmentData!: EnvironmentData;
  popupData!: string;
  general!: General;
  innerHeight: any;
  @Input() site: string = '';
  prevSiteId: string[] = [];
  isError: boolean = false;
  ErrorMessage: string = '';
  CustomerId: any;
  licenseCheck: boolean = true;
  isLoading: boolean = true;
  module: any;
  subAssetRiskMatrix!: SubAssetRisk[];
  isDarkMode = false;
  siteName: string;
  assetHealthCard: any;

  constructor(
    private siteManagementService: SiteManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedValueService: SharedValueService,
    private sharedService: SharedService,
    private rbacService: RbacService,
    private assetService: AssetService
  ) {
    this.route.parent?.params.subscribe(param => {
      if (this.siteId != param['id']) {
        this.siteId = param['id'];
        this.sitelicenseCheck();
      }
    })
  }

  ngOnInit(): void {
    this.isDarkMode = this.rbacService?.isDarkTheme?.value;
    this.siteId = this.sharedValueService.getSiteId();
    //this.prevSiteId.push('0');
    this.getSites();
    this.siteValidation();
    this.innerHeight = window.innerHeight;
  }

  siteValidation(): void {
    if (this.sharedService.user.id) {
      let value = this.sharedService.user;
      this.router.navigate(['/layout/sites/site/' + this.siteId]);
      this.CustomerId = value?.customerId ?? 0;
      this.module = this.sharedService.modules;
    } else {
      this.rbacService.getUserInformation().subscribe((value: any) => {
        this.router.navigate(['/layout/sites/site/' + this.siteId]);
        this.CustomerId = value?.customerId ?? 0;
        this.module = this.sharedService.modules;
      })
    }
    this.sitelicenseCheck();
  }

  setSiteName(siteId){
    const site = this.sites?.find(item => item.siteId == siteId);
    this.siteName = site?.siteName;
  }

  sitelicenseCheck(){
    this.siteManagementService.userCounter(Number(this.siteId)).subscribe((data: any) => {
      if (data instanceof HttpErrorResponse) {
        this.isError = true;
        this.ErrorMessage = "Peak Users Limit for site Exceeded"
      } else {
        this.licenseCheck = false;
        this.onSiteSelection();
      }
    });
  }

  onSiteSelection(): void {
    this.getSiteById(this.siteId);
    this.getAssetSnapshotForSite(this.siteId);
    this.getAlarmSnapshotForSite(this.siteId);
    this.getWorkPermitSite(this.siteId);
    this.getUpcomingMaintenanceForSite(this.siteId, this.optionId);
    this.getAssetWarrantySite(this.siteId);
    this.getContractStatusSite(this.siteId);
    this.getAssetAgeSite(this.siteId);
    this.getAssetHealthSite(this.siteId);
    this.getSuggestionsSite(this.siteId);
    this.getVoltageDistributionSite(this.siteId);
    this.getSample1Site(this.siteId);
    this.getMaintenanceSummaryList(this.siteId);
    this.getObsolenscenceProfileList(this.siteId);
    this.getOverviewSuggestionsList(this.siteId);
    this.getOverviewSiteList(this.siteId);
    this.getSubassetMatrix();
  }

  getSiteById(id: string) {
    this.isLoading = true;
    //TODO: change the response type
    this.siteManagementService.getSiteById(id)
      .subscribe((data: SiteDetails) => {
        if (data instanceof HttpErrorResponse) {
          this.siteDetails = {} as SiteDetails;
        } else {
          this.siteDetails = data;
          this.isLoading = false;
        }
      });
  }

  getAssetSnapshotForSite(id: string) {
    this.siteManagementService.getAssetSnapshotForSite(id)
      .subscribe((data: AssetSnapshot) => {
        if (data instanceof HttpErrorResponse) {
          this.assetSnapshot = {} as AssetSnapshot;
        } else {
          this.assetSnapshot = data
        }
      });
  }

  getAlarmSnapshotForSite(id: string) {
    this.siteManagementService.getAlarmSnapshotForSite(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.alarmSnapshot = {} as AlarmSnapshot;
          this.alarmMenu = {};
        } else {
          this.alarmSnapshot = data.results;
          this.alarmMenu = data.accessOptions;
        }
      });
  }
  getWorkPermitSite(id: string) {
    this.siteManagementService.getWorkPermitSite(id)
      .subscribe((data: WorkPermitSnapshot) => {
        if (data instanceof HttpErrorResponse) {
          this.workPermitSnapshot = {} as WorkPermitSnapshot;
        } else {
          this.workPermitSnapshot = data
        }
      });
  }

  getUpcomingMaintenanceForSite(id: string, option: string) {
    this.selectedAlarmMenuOption = option;
    this.siteManagementService.getUpcomingMaintenanceForSite(id, option)
      .subscribe((data: any) => {
        this.upcomingMaintenance = data.maintenanceDetails;
        this.upcomingMaintenance.forEach((value: any) => {
          value.ptwIdNumber = parseInt(value.ptwId.split('PW')[1]);
        })
        this.maintenanceMenu = data.accessOptions;
      });
  }

  getAssetWarrantySite(id: string) {
    this.siteManagementService.getAssetWarrantySite(id)
      .subscribe((data: AssetWarranty) => {
        if (data instanceof HttpErrorResponse) {
          this.assetWarranty = {} as AssetWarranty;
        } else {
          this.assetWarranty = data;
        }
      });
  }

  getContractStatusSite(id: string) {
    this.siteManagementService.getContractStatusSite(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.contractStatus = {} as ContractStatus;
        } else {
          this.contractStatus = data;
        }
      });
  }

  getAssetAgeSite(id: string) {
    this.siteManagementService.getAssetAgeSite(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.assetAgeTemp = {} as AssetAge;
        } else {
          this.assetAgeTemp = data
        }
      });
  }

  getAssetHealthSite(id: string) {
    this.siteManagementService.getAssetHealthSite(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
            this.assetHealthCard = {};
        } else {
            this.assetHealthCard = data;
        }
      });
  }

//   getOverviewCards(id: string){
//     this.siteManagementService.getOverviewCards(id)
//       .subscribe((data: any) => {
//         this.overviewCards = data?.Cards;
//       });
//   }

  getSuggestionsSite(id: string) {
    this.siteManagementService.getSuggestionsSite(id)
      .subscribe((data: SiteSuggestion) => {
        if (data instanceof HttpErrorResponse) {
          this.siteSuggestion = {} as SiteSuggestion;
        } else {
          this.siteSuggestion = data
        }
      });
  }

  getVoltageDistributionSite(id: string) {
    this.siteManagementService.getVoltageDistributionSite(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.voltageDistribution = {} as VoltageDistribution;
        } else {
          this.voltageDistribution = data;
        }
      });
  }

  getSample1Site(id: string) {
    this.siteManagementService.getSample1Site(id)
      .subscribe((data: Sample1) => this.sample1 = data);
  }

  getSites() {
    this.isLoading = true;
    this.siteManagementService.getSitesDropdown()
      .subscribe((data: any) => {
        this.isLoading = false;
        this.sites = [];
        if (data && data.length) {
          data.forEach((item: { siteId: any; siteName: any; }) => {
            this.sites.push({ "siteId": item.siteId, "siteName": item.siteName });
          });
        }
        if(this.siteId) this.setSiteName(this.siteId);
      });
  }

  getMaintenanceSummaryList(id: string) {
    this.siteManagementService.getSiteMaintenancesummaryList(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.maintenanceSummaryList = [];
        } else {
          this.maintenanceSummaryList = data;
        }

      });
  }

  getObsolenscenceProfileList(id: string) {
    this.siteManagementService.getObsolenscenceProfileList(id)
      .subscribe((data: any) => {
        if (data instanceof HttpErrorResponse) {
          this.obsolenscenceProfileList = [];
        } else {
          this.obsolenscenceProfileList = data;
        }
      });
  }

  getOverviewSuggestionsList(id: string) {
    this.siteManagementService.getOverviewSuggestionsList(id)
      .subscribe((data: any) => {
        this.suggestionsAssetList = data;
      });
  }

  getOverviewSiteList(id: string) {
    this.siteManagementService.getOverviewSiteList(id)
      .subscribe((data: any) => {
        this.general = data.general;
        this.environmentData = data.environmentData;
      });
  }

  getSubassetMatrix(): void {
      this.assetService.getSubAssetRiskMatrixForSite(this.siteId).subscribe((data: SubAssetRisk[]) => {
        this.subAssetRiskMatrix = data;
      })
  }

  noClose(event: any) {
    event.stopPropagation();
  }
}


