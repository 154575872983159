<div class="container-fluid p-4 h-100"  style="overflow-y:auto;">
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
    <div class="col app-card" *ngFor="let app of appList;let i=index">
      <div class="card">
        <div class="card-body" style="min-height: 176px; max-height: 200px;">
          <h5 class="card-title col-sm-8 mb-3"><img [src]="app?.applogourl" [alt]="app?.apptitle" loading="lazy"
            class="img-responsive img-fullwidth" /></h5>
          <h6 class="card-subtitle mb-2">{{ app?.apptitle }}</h6>
          <p class="card-text mb-8">{{ app?.appdescription }}</p>
        </div>
        <div class="card-footer">
          <a [href]="app?.appendpoint" target="_blank" class="btn btn-primary" id="ionecloud-{{i}}" [ngClass]="!(app?.apptitle == 'Predictive & Prescriptive Maintenance' && isActive)?'disable-btn':''">Go
            <i class="fas fa-angle-right ms-5"></i>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="footer">
  <p>The content of this web portal is copyrighted. All rights reserved</p>
</div>
<button id="terms" type="button" class="btn btn-primary" data-mdb-toggle="modal" data-mdb-target="#exampleModal"
style="z-index:10000;position:relative;display:none">
Launch demo modal
</button>
<app-terms-conditions [termsandConditions]="termsandConditions" (updateTermsAndConditions)="updateUserAccount($event)"></app-terms-conditions>

<div id="mydiv" *ngIf="showStatus" class="alertMsg no-subscription" [ngClass]="showError? 'critical': 'healthy'">{{ showMsg }}</div>
