import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Index, MeasuredCondition, Observation, ObservedCondition, PresentFuture, Recommendation } from '../_models/health.type';
import { AssetService } from '../_services/assets.service';

@Component({
  selector: 'app-health-calculate',
  templateUrl: './health-calculate.component.html',
  styleUrls: ['./health-calculate.component.scss']
})
export class HealthCalculateComponent implements OnInit {
  @Input() data: any;
  @Output() closeWindowEvent = new EventEmitter<string>();
  recommendations!: Recommendation[];
  observedConditionList!: ObservedCondition[];
  observedCriteriaList!: ObservedCondition[];
  measuredConditionList!: MeasuredCondition[];
  measuredCriteriaList!: MeasuredCondition[];

  selectedObservedCondition!: ObservedCondition;
  selectedObservedCriteria!: ObservedCondition;
  selectedMeasuredCondition!: MeasuredCondition;
  selectedMeasuredCriteria!: MeasuredCondition;

  observationList: any = [];
  measuredList: any = [];
  maintenanceDate!: string;

  form: FormGroup;

  chartData!: PresentFuture;

  target: string = 'simulation';
  currentDate = new Date().toISOString().split("T")[0];
  calculated: boolean = false;
  isLoading = false;

  constructor(private assetService: AssetService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      actions: new FormArray([])
    });
  }

  ngOnInit(): void {
    (this.selectedObservedCondition as any) = {};
    (this.selectedObservedCriteria as any) = {};
    (this.selectedMeasuredCondition as any) = {};
    (this.selectedMeasuredCriteria as any) = {};
    this.getHealthRecommendation();
    this.getObservationList();
    this.getMeasuredList();
  }

  getHealthRecommendation() {
    this.assetService.getHealthRecommendation(this.data?.assetId, this.data?.feeder).subscribe((data: Recommendation[]) => {
      this.recommendations = data;
      this.addCheckboxes();
    })
  }

  private addCheckboxes() {
    this.recommendations.forEach(() => this.actionsFormArray.push(new FormControl(false)));
  }

  getObservationList() {
    this.assetService.getObservationList(this.data?.feeder).subscribe((data: ObservedCondition[]) => {
      this.observedConditionList = data;
    })
  }

  getCriteriaList(id: number) {
    this.assetService.getCriteriaList(id).subscribe((data: ObservedCondition[]) => {
      this.observedCriteriaList = data;
    })
  }

  getMeasuredList() {
    this.assetService.getMeasuredList(this.data?.feeder).subscribe((data: MeasuredCondition[]) => {
      this.measuredConditionList = data;
    })
  }

  getMeasuredCriteriaList(id: number) {
    this.assetService.getMeasuredCriteriaList(id).subscribe((data: MeasuredCondition[]) => {
      this.measuredCriteriaList = data;
    })
  }

  onObservedConditionChange(e: any) {
    this.getCriteriaList(this.selectedObservedCondition.id);
  }

  onMeasuredConditionChange(e: any) {
    this.getMeasuredCriteriaList(this.selectedMeasuredCondition.id);
  }

  addToObservationList() {
    let pos = this.observationList.map((e: any) => e.conditionId).indexOf(this.selectedObservedCondition.id);
    if (pos == -1) {
      this.observationList.push({
        conditionId: this.selectedObservedCondition.id,
        condition: this.selectedObservedCondition.observedCondition,
        criteria: this.selectedObservedCriteria.observedCondition,
        criteriaId: this.selectedObservedCriteria.id
      });
    } else {
      this.observationList[pos] = {
        conditionId: this.selectedObservedCondition.id,
        condition: this.selectedObservedCondition.observedCondition,
        criteria: this.selectedObservedCriteria.observedCondition,
        criteriaId: this.selectedObservedCriteria.id
      }
    }

    (this.selectedObservedCondition as any) = {};
    (this.selectedObservedCriteria as any) = {};
  }

  addToMeasuredList() {
    let pos = this.measuredList.map((e: any) => e.conditionId).indexOf(this.selectedMeasuredCondition.id);
    if (pos == -1) {
      this.measuredList.push({
        conditionId: this.selectedMeasuredCondition.id,
        condition: this.selectedMeasuredCondition.measuredCondition,
        criteria: this.selectedMeasuredCriteria.measuredCondition,
        criteriaId: this.selectedMeasuredCriteria.id,
        criteriaValue: this.selectedMeasuredCriteria.criteriaValue
      });
    } else {
      this.measuredList[pos] = {
        conditionId: this.selectedMeasuredCondition.id,
        condition: this.selectedMeasuredCondition.measuredCondition,
        criteria: this.selectedMeasuredCriteria.measuredCondition,
        criteriaId: this.selectedMeasuredCriteria.id,
        criteriaValue: this.selectedMeasuredCriteria.criteriaValue
      }
    }

    (this.selectedMeasuredCondition as any) = {};
    (this.selectedMeasuredCriteria as any) = {};
  }

  get actionsFormArray() {
    return this.form.controls['actions'] as FormArray;
  }

  calculate() {
    this.isLoading = true;
    this.calculated = false;
    const selectedActions = this.form.value.actions
      .map((checked: any, i: number) => checked ? this.recommendations[i] : null)
      .filter((v: null) => v !== null);

    //temp code-- to be removed post integration  
    // console.log(selectedActions);
    // console.log(this.maintenanceDate);
    // console.log(this.measuredList);
    // console.log(this.observationList);
    let payload = {
      "assetId": this.data?.assetId,
      "feederNumber": this.data?.feeder,
      "maintenanceDate": this.maintenanceDate,
      "observedConditionMasterIds": this.observationList.map((item: any) => item.criteriaId),
      "measuredConditionMasterIds": this.measuredList.map((item: any) => item.criteriaId),
      "actionFilterId": selectedActions.map((item: any) => item.id)
    }
    this.assetService.getHealthKpiCalculatePresent(payload).
      subscribe({
        next: (data: PresentFuture) => {
          this.chartData = data;
          this.calculated = true;
            this.isLoading = false;
        },
        error: (e: Error) => {
          this.calculated = false;
        this.isLoading = false;
        }
      });

  }

  reset() {
    this.actionsFormArray.value.forEach((item: any, index: number) => {
      this.actionsFormArray.at(index).setValue(false);
    });
    this.maintenanceDate = '';
    this.measuredList = [];
    this.observationList = [];
  }

  downloadCalculated(){
    const selectedActions = this.form.value.actions
    .map((checked: any, i: number) => checked ? this.recommendations[i] : null)
    .filter((v: null) => v !== null);
    let payload = {
      "assetId":this.data?.assetId,
      "feedernumber": this.data?.feeder,
      "observed_BreakerExternalCondition": this.observationList.find((x:any) => x.condition == 'Breaker External Condition')?.criteriaId,
      "observed_SwitchgearExternalCondition": this.observationList.find((x:any) => x.condition == 'Switchgear External Condition')?.criteriaId,
      "observed_CompoundLeaks": this.observationList.find((x:any) => x.condition == 'Compound Leaks')?.criteriaId,
      "observed_SwitchgearInternalConditionOperation": this.observationList.find((x:any) => x.condition == 'Switchgear Internal Condition Operation')?.criteriaId,
      "observed_InsulationCondition": this.observationList.find((x:any) => x.condition == 'Insulation Condition')?.criteriaId,
      "observed_SignsOfHeating": this.observationList.find((x:any) => x.condition == 'Signs Of Heating')?.criteriaId,
      "measured_BreakerOperationalAdequacy": this.measuredList.find((x:any) => x.condition == 'Breaker Operational Adequacy')?.criteriaId,
      "measured_SwitchgearOperationalAdequacy":this.measuredList.find((x:any) => x.condition == 'Switchgear Operational Adequacy')?.criteriaId,
      "present_HealthIndex": parseInt(this.chartData.assetPastSimulation?.healthIndex.indexValue.split('HI')[1]),
      "present_RiskIndex": parseInt(this.chartData.assetPastSimulation?.riskIndex.indexValue.split('RI')[1]),
      "future_HealthIndex": parseInt(this.chartData.assetFutureSimulation?.healthIndex.indexValue.split('HI')[1]),
      "future_RiskIndex":parseInt(this.chartData.assetFutureSimulation?.riskIndex.indexValue.split('RI')[1]),
      "maintenanceDate": this.maintenanceDate,
      "actionIds": selectedActions.map((item: any) => item.id)
    }
    this.assetService.downloadCalculated(payload).
      subscribe((data:any)=>{
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, '_blank');
      });
  }

  setMeasuredCriteria(e: any) {
    this.selectedMeasuredCriteria.id = 0;
    this.selectedMeasuredCriteria.measuredCondition = this.selectedMeasuredCondition?.measuredCondition;
    this.selectedMeasuredCriteria.conditionId = 0;
    this.selectedMeasuredCriteria.criteriaValue = e?.target?.value;
    this.selectedMeasuredCriteria.disabled = e?.target?.value;
  }

  closeWindow() {
    this.closeWindowEvent.emit();
  }
}
