import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { } from 'googlemaps';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FilterPipe } from '../_pipes/filter.pipe';
import { DashboardService } from '../_services/dashboard.service';
import { SiteDetails, WorkPermit, Alarm, HealthProfile, RecentAlarm, UpcomingMaintenance } from '../_models/dashboard.type';
import { Subscription } from 'rxjs';
import { SharedValueService } from '../_services/shared-value.service';
import { SharedService } from '../_services/shared.service';
import { IPredictService } from '../_services/i-predict.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { RbacService } from '../_helpers/rbac.service';
import { AssetService } from '../_services/assets.service';
import { SubAssetRisk } from '../_models/health.type';
import { graphData } from '../_models/dlb/reportsDocument.type';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('mapElement') mapElement: any;

    // map: any;
    // mapView: Boolean = true;
    searchText: any;
    filteredSites: { [key: string]: any }[] = [];
    up: Boolean = true;
    siteDetails: SiteDetails = {} as SiteDetails;
    workPermit!: WorkPermit
    alarm!: Alarm;
    healthProfile!: any;
    recentAlarmOrEvents!: Array<RecentAlarm>;
    allRecentAlarms: boolean = false;
    upcomingMaintenance!: UpcomingMaintenance;
    sites: any;
    allAlarm: number = 0;
    offsetDate: string = '';
    isDesktopDevice: boolean = true;
    isMobile: boolean = false;
    isLoading: boolean = false;
    isMapLoading: Boolean = false;

    maintenanceProfile: any;
    obsolesceneceProfile: any;

    upComingMaintenanceValue: string = '';

    baseUrl = environment.apiURL;
    private RecentAlarmSubscription: Subscription = new Subscription();
    private subscription: Subscription = new Subscription();
    innerHeight: any;
    module: any;
    subAssetRiskMatrix!: SubAssetRisk[];
    isDarkMode = false;
    siteList: any;
    dashboardKPICards: any;

    constructor(
        private _router: Router,
        private dashboardService: DashboardService,
        private sharedValueService: SharedValueService,
        public sharedService: SharedService,
        private iPredictService: IPredictService,
        private deviceService: DeviceDetectorService,
        private rbacService: RbacService,
        private assetService: AssetService
    ) { }

    // updateMap(): void {
    //     var myLatlng = new google.maps.LatLng(this.sites[0]['location'][0], this.sites[0]['location'][1]);
    //     var mapOptions = {
    //         zoom: 6,
    //         center: myLatlng
    //     };
    //     var map1 = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    //     this.setMarkers(map1);
    // }

    filterData(e: any): void {
        const pipe = new FilterPipe();
        this.filteredSites = pipe.transform(this.sites, this.searchText, ['siteName']);
        // this.updateMap();
    }

    ngOnInit(): void {
        this.epicFunction();
        this.getSites();
        this.isDarkMode = this.rbacService?.isDarkTheme?.value;
        this.innerHeight = window.innerHeight;
        if (this.sharedService.user?.id) {
            let userData = this.sharedService.user;
            this.module = this.sharedService.modules;
            this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
            let isOandM = userData?.role?.name == 'EA.OandM' ? true : false;
            // if(isOandM) {
            //   this._router.navigate(['/layout/DMM/workPermit/task']);
            // }
            this.fetchDataWithRbac();
        } else {
            this.rbacService.getUserInformation().subscribe(userData => {
                this.module = this.sharedService.modules;
                this.fetchDataWithRbac();
                this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
                let isOandM = userData?.role?.name == 'EA.OandM' ? true : false;
                // if(isOandM) {
                //   this._router.navigate(['/layout/DMM/workPermit/task']);
                // }
            })
        }
        this.filteredSites = this.sites;
        // this.getDashboardKPICards();
    }

    epicFunction() {
        this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    fetchDataWithRbac() {
        if (this.module?.AssetAndSubAsset?.canRead) {
            this.getSiteDetails();
            this.getSitelocations();
            this.getHealthProfile();
            this.getSubassetMatrix();
            this.getMaintenanceProfile();
            this.getObsolesceneceProfile();
        }
        if (this.module?.DigitalMaintenanceManager?.canRead) {
            this.getWorkPermit();
            this.getUpcomingMaintenance(30, 'Next 30 days');
        }
        if (this.module?.AlarmOrEvents?.canRead) {
            this.getAlarmOrEvents(this.allAlarm);
            this.getRecentAlarams(this.allRecentAlarms);
        }
    }

    // toggleMapView(view: string): void {
    //     if (view === 'map') {
    //         this.mapView = true;
    //     } else {
    //         this.mapView = false;
    //     }
    // }

    // setMarkers(map: google.maps.Map) {
    //     let lat_min = 0, lat_max = 0, lng_min = 0, lng_max = 0;
    //     var bounds = new google.maps.LatLngBounds();
    //     for (let i = 0; i < this.sites.length; i++) {
    //         let site = this.sites[i];
    //         let marker: google.maps.MVCObject = new google.maps.Marker({
    //             position: { lat: site['location']['latitude'], lng: site['location']['longitude'] },
    //             map,
    //             title: site['siteName']
    //         });
    //         let url = this.baseUrl + "/layout/sites/site/" + site['siteId'] + "/overview";
    //         let contentString =
    //             '<div id="content" class="row" style="width:17rem;font-weight:bold;font-size:12px">' +
    //             '<div id="bodyContent" style="float:right">' +
    //             "</div>" +
    //             '<a id="firstHeading"  style="color:#024D87" class="mt-1" target="_blank"  style="font-size:smaller" href=' + url + '>' + site['siteName'] + '</a>' +
    //             '<div id="firstHeading">' + site['address'] + '</div>' +
    //             // '<div id="firstHeading" class="mt-3">Overall Health <span class="healthy ms-2">' +  site['overallHealth'] + '%</span></div>' +
    //             // '<div id="firstHeading"> Health Assessment' + site['health'] + '</div>' +
    //             "</div>";

    //         let infowindow = new google.maps.InfoWindow({
    //             content: contentString,
    //         });

    //         marker.addListener('click', ((marker, map, infowindow) => {
    //             return () => {
    //                 infowindow.open(map, marker);
    //             }
    //         })(marker, map, infowindow));

    //         google.maps.event.addListener(map, "click", function (event) {
    //             infowindow.close();
    //         });
    //         if (lat_max < site['location']['latitude']) lat_max = site['location']['latitude'];
    //         if (lng_max < site['location']['longitude']) lng_max = site['location']['longitude'];
    //         if (lat_min > site['location']['latitude']) lat_min = site['location']['latitude'];
    //         if (lng_min > site['location']['longitude']) lng_min = site['location']['longitude'];

    //         bounds.extend((marker as any).position)

    //     }
    //     map.fitBounds(bounds);
    //     if (this.sites?.length && this.sites?.length === 1) {
    //         setTimeout(() => {
    //             map.setZoom(12);
    //         }, 1000);
    //     }


    //     this.isMapLoading = false;
    //     // map.fitBounds(new google.maps.LatLngBounds(
    //     //   //bottom left
    //     //   new google.maps.LatLng(lat_min, lng_min),
    //     //   //top right
    //     //   new google.maps.LatLng(lat_max, lng_max)
    //     // ));
    // }

    goToSite(id: string) {
        this._router.navigateByUrl(`/layout/sites/site/${id}`);
    }

    getSiteDetails(): void {
        this.subscription.add(
            this.dashboardService.getSiteDetails().subscribe(data => {
                this.siteDetails = data;
            }));
    }

    getWorkPermit() {
        this.subscription.add(
            this.dashboardService.getWorkPermit().subscribe(data => {
                if (data instanceof HttpErrorResponse) {
                    this.workPermit = {} as WorkPermit;
                } else {
                    this.workPermit = data;
                }
            }));
    }

    getSites() {
        this.subscription.add(
            this.dashboardService.getSitesDropdown().subscribe(res => {
                this.siteList = res;
            })
        )
    }

    getSitelocations(): void {
        this.isMapLoading = true;
        this.subscription.add(
            this.dashboardService.getSitelocations().subscribe(data => {
                data.forEach(element => {
                    const siteName = this.siteList?.find(item => item.siteId === element.siteId)?.siteName;
                    element.siteName = siteName ? siteName : element.siteName;
                });
                this.sites = data;
                this.isMapLoading = false;
                this.sharedValueService.setSiteId(this.sites[0].siteId);
                if (this.hasRoute('sites')) {
                    this._router.navigateByUrl(`/layout/sites/site/${this.sites[0].siteId}`);
                }
                // var myLatlng = new google.maps.LatLng(this.sites[0]['location']['latitude'], this.sites[0]['location']['longitude']);
                // var mapOptions = {
                //     zoom: 6,
                //     center: myLatlng
                // };
                // var map1 = new google.maps.Map(this.mapElement?.nativeElement, mapOptions);
                // this.setMarkers(map1);
            })
        );
    }

    getAlarmOrEvents(all: number): void {
        this.allAlarm = all;
        this.subscription.add(
            this.dashboardService.getAlarmOrEvents(all).subscribe(data => {
                this.alarm = data;
            }));
    }

    getRecentAlarams(all: boolean): void {
        this.RecentAlarmSubscription.unsubscribe();
        this.isLoading = true;
        this.RecentAlarmSubscription =
            this.dashboardService.getRecentAlarams(all).subscribe(data => {
                this.isLoading = false;
                this.recentAlarmOrEvents = data;
                this.allRecentAlarms = !this.allRecentAlarms;
            });
    }

    getUpcomingMaintenance(days: number, value: string): void {
        this.upComingMaintenanceValue = value;
        this.subscription.add(
            this.dashboardService.getUpcomingMaintenance(days).subscribe(data => {
                this.upcomingMaintenance = data;
                this.upcomingMaintenance.maintenanceDetails.forEach((value: any) => {
                    value.ptwIdNumber = parseInt(value.ptwId.split('PW')[1]);
                    const siteName = this.siteList?.find(item => item.siteId === value.siteId)?.siteName;
                    value.siteName = siteName ? siteName : value.siteName;
                })
            }));
    }

    getHealthProfile(): void {
        this.subscription.add(
            this.dashboardService.getHealthProfile().subscribe(data => {
                this.healthProfile = data.CardData;
            }));
    }

    getSubassetMatrix(): void {
        this.subscription.add(
            this.assetService.getSubAssetRiskMatrixForUser().subscribe((data: any) => {
                this.subAssetRiskMatrix = data?.graphData;
            })
        );
    }

    getMaintenanceProfile(): void {
        this.subscription.add(
            this.dashboardService.getMaintenanceProfile().subscribe(data => {
                this.maintenanceProfile = data.CardData;
            }));
    }

    getObsolesceneceProfile(): void {
        this.subscription.add(
            this.dashboardService.getObsolesceneceProfile().subscribe(data => {
                this.obsolesceneceProfile = data.CardData;
            }));
    }

    hasRoute(route: string) {
        return this._router.url.includes(route);
    }

    // getDashboardKPICards() {
    //     this.subscription.add(
    //         this.dashboardService.getDashboardKPICards().subscribe(data => {
    //             this.dashboardKPICards = Object.values(data);
    //         })
    //     );
    // }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.RecentAlarmSubscription.unsubscribe();
    }
}
