import { Component, Inject, OnInit, OnDestroy ,ViewChild } from '@angular/core';
import { SharedValueService } from '../_services/shared-value.service';
import { SiteManagementService } from '../_services/site-management.service';
import { AssetService } from '../_services/assets.service';
import { distinct, Subject, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetFilterPipe } from '../_pipes/asset-filter.pipe';
import { MatTableDataSource} from '@angular/material/table';
import { SelectionModel} from '@angular/cdk/collections';
import { MaintenancePlan } from 'src/app/_models/maintenance.type';
import { AssetFilter, AssetFilterKeys } from 'src/app/_models/asset';
import {FormControl} from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { cloneDeep, find } from 'lodash';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../_services/shared.service';
import { RbacService } from '../_helpers/rbac.service';
import { AdminPanelService } from '../modules/admin-panel/services/admin-panel.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit, OnDestroy {
  siteList: any;
  selectedSite: number = 0;
  selectedSubStation: number = 0;
  assets: any;
  statusFilterText: string = '';
  statusFilterCategory: string = '';
  totalAsset: number = 0;
  totalSubAsset: number = 0;
  totalHealty: number = 0;
  totalAtRisk: number = 0;
  totalCritical: number = 0;
  card: any = [
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false},
    {'mobileAccordian': false}
  ];
  activeClassIndex:any;
  formGroup:any;
  selectedLink:any;
  isAsset = true;
  private filterKey!: string;
  private filerValue!: string;
  searchKeyword!: string;
  selectedFilterList:string [] = [];
  checked = false;
  indeterminate = false;
  DistinctArray:any;
  maintenanceDetails!: MaintenancePlan[];
  dataSource = new MatTableDataSource(this.maintenanceDetails);
  selection = new SelectionModel<MaintenancePlan>(true, []);
  private subscription: Subscription = new Subscription();
  statusFilter = new FormControl();
  checkedStatusList:any;
  selectedOptions:any;
  selectedOption: any;
  subStations: any;
  selectedFilterLabel: Array<string> = [];
  showNoDataFilter: boolean = false;
  newAssets!: any;
  filterControl = new FormControl();
  assetFilterData: AssetFilter = {
    assetStatus: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    subAssetStatus: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    assetCategory: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    subAssetCategory: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    assetMake: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    designType: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    systemVoltage: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    },
    balanceLife: {
      list: [],
      countLimit: 3,
      hidden: true,
      filteredList: []
    }
  }
  isLoading: boolean = true;
  selectedSubstations: string[] = [];
  innerHeight:any;
  isDesktopDevice: boolean = true;
  isMobile: boolean = false;
  details:boolean = false;
  selectedAsset:any;
  module: any;
  customerId: any;
  @ViewChild('assetFilters', { static: false }) assetFilters!: MatSelect;
  @ViewChild('assetFiltersMobile', { static: false }) assetFiltersMobile!: MatSelect;
  pageSize = 12;
  pageNumber = 1;
  totalRecords: number;
  isScrollLoading = false;
  filterSelected: any = {
    "underWarranty": false,
    "outOfWarranty": false,
    "underContract": false,
    "notInContract": false,
    "contractExpire": false,
    "maintenanceDone": false,
    "maintenanceDue": false,
    "maintenanceOverDue": false,
    "subAssetUnderWarranty": false,
    "subAssetOutOfWarranty": false,
    "underActiveLifeCycle": false,
    "underNotice": false,
    "obsoleted": false,
    "assetCategory": [],
    "subAssetCategory": [],
    "componentMake": [],
    "designType": [],
    "systemVoltage": [],
    "balanceLife": [],
    "healthy": false,
    "risk": false,
    "critical": false,
    "veryGood": false,
    "good": false,
    "fair": false,
    "poor": false,
    "veryPoor": false,
    "unknown": false,
    "substationIds": []
  };
  isClearAll = false;
  gotAllAssets = false;
  public getAssets = new Subject<any>();

  veryGood = 0;
  good = 0;
  fair = 0;
  poor = 0;
  veryPoor = 0;
  unknown = 0;
  selectedSubAssetStatus: any;

   constructor(
    private _router: Router,
    private sharedValueService: SharedValueService,
    private siteManagementService: SiteManagementService,
    private assetService: AssetService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public sharedService: SharedService,
    private assetFilterPipe: AssetFilterPipe,
    private adminPanelService: AdminPanelService,
    private rbacService: RbacService) {
      this.route.queryParams.subscribe(qParams => {
        this.filterKey = qParams['filterKey'];
        this.filerValue = qParams['filterValue'];
        // this.filterControl.setValue([this.filerValue]);
        if (qParams['siteId']) {
          this.selectedSite = parseInt(qParams['siteId']);
        }
      });
  
      this.route.parent?.params.subscribe(routeParams => {
        this.selectedSite = parseInt(routeParams['id']);
      });

   }

  ngOnInit(): void {
    if (this.sharedService.user.id) {
      this.module = this.sharedService.modules;
      this.customerId = this.sharedService.user.customerId;
    } else {
      this.rbacService.getUserInformation().subscribe((value: any) => {
        this.module = this.sharedService.modules;
        this.customerId = this.sharedService.user.customerId;
      })
    }
    this.epicFunction();
    this.sharedService.toggleSitesSubMenu('assets');
    this.innerHeight = window.innerHeight;
    this.subscription.add(
      this.siteManagementService.getSitesDropdown().subscribe(siteList => {
        this.siteList = siteList;
        if (this.selectedSite) {
          this.getSubstationList();
        } else {
          if(this.siteList && this.siteList.length){
            this.selectedSite = this.siteList[0].siteId;
            this.getSubstationList();
            if (this.subStations && this.subStations.length) {
              this.selectedSubStation = this.subStations[0].id;
            }
          }
          if(this.siteList[0].subStations && this.siteList[0].subStations.length){
            this.selectedSubStation = this.siteList[0].subStations[0].id;
          }
        }
        // this.getAssetHeaderDetailsForSite();
        // this.getAssetDetailsforSite();
        this.getAssetDetails();
        this.getAssets.next(true);
        this.setFilterValues();
      })
    );
  }

  epicFunction() {
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

 /*  filterToggle(isOpened: boolean) {
    this.statusFilterText = '';
    this.searchKeyword = '';
  } */

  getSubstationList(){    
    this.subscription.add(
      this.adminPanelService.getSiteSubStationsForSite(this.selectedSite).subscribe(data => {
        this.subStations = data;
      })
    );
  }

  toggleFilterList(key: string, toggle: boolean) {
    if (toggle) {
      (this.assetFilterData as any)[key].countLimit = (this.assetFilterData as any)[key].list.length;
    } else {
      (this.assetFilterData as any)[key].countLimit = 3;
    }
    (this.assetFilterData as any)[key].hidden = !toggle;
  }

  filterSelection(event: any, key?: string) {
    if(!event.isUserInput){
      return;
    }
    // On clearing all the filters
    if(this.isClearAll) return;
    let filtervalue: string = event.source.value;
    if(key === 'assetStatus'){
      switch (filtervalue) {
        case 'Under Warranty':
          this.filterSelected.underWarranty = event.source.selected;
          break;
        case 'Out Of Warranty':
          this.filterSelected.outOfWarranty = event.source.selected;
          break;
        case 'Under Contract':
          this.filterSelected.underContract = event.source.selected;
          break;
        case 'Not in Contract':
          this.filterSelected.notInContract = event.source.selected;
          break;
        case 'Contract Expire':
          this.filterSelected.contractExpire = event.source.selected;
          break;
        case 'Maintenance Done':
          this.filterSelected.maintenanceDone = event.source.selected;
          break;
        case 'Maintenance Due':
          this.filterSelected.maintenanceDue = event.source.selected;
          break;
        case 'Maintenance Overdue':
          this.filterSelected.maintenanceOverDue = event.source.selected;
          break;
      }
    }else if(key === 'subAssetStatus'){
      switch (filtervalue) {
        case 'Under Warranty':
          this.filterSelected.subAssetUnderWarranty = event.source.selected;
          break;
        case 'Out Of Warranty':
          this.filterSelected.subAssetOutOfWarranty = event.source.selected;
          break;
        case 'Under Active Life Cycle':
          this.filterSelected.underActiveLifeCycle = event.source.selected;
          break;
        case 'Under Notice':
          this.filterSelected.underNotice = event.source.selected;
          break;
        case 'Obsoleted':
          this.filterSelected.obsoleted = event.source.selected;
          break;
      }
    }
    else{
      if(event.source.selected){
        this.filterSelected[key].push(filtervalue);
      }else{
        const filterLabelIndex = this.filterSelected[key].indexOf(filtervalue);
        this.filterSelected[key].splice(filterLabelIndex, 1);
      }
    }
    // else{
    //   if(event.source.selected){
    //     this.filterSelected[key].push(filtervalue);
    //     this.filterControl.setValue([...this.filterControl.value,filtervalue]);
    //   }else{
    //     const filterLabelIndex = this.filterSelected[key].indexOf(filtervalue);
    //     this.filterSelected[key].splice(filterLabelIndex, 1);
    //     const filteredValues = this.filterControl.value;
    //     const filterIndex = filteredValues.indexOf(filtervalue);
    //     filteredValues.splice(filterIndex, 1); 
    //     this.filterControl.setValue(filteredValues);
    //   }
    // }
    // this.getAssetHeaderDetailsForSite();
    // this.getAssetDetailsforSite()
    this.getAssets.next(true);
  }

  clearAllFilters() {
    this.isClearAll = true;
    if (this.assetFilters) {
      this.assetFilters.options.forEach((item: MatOption) => item.deselect());
    }
    if (this.assetFiltersMobile) {
      this.assetFiltersMobile.options.forEach((item: MatOption) => item.deselect());
    }
    this.selectedFilterLabel = [];
     this.filterSelected.underWarranty = false;
     this.filterSelected.outOfWarranty = false;
     this.filterSelected.underContract = false;
     this.filterSelected.notInContract = false;
     this.filterSelected.contractExpire = false;
     this.filterSelected.maintenanceDone = false;
     this.filterSelected.maintenanceDue = false;
     this.filterSelected.maintenanceOverDue = false;
     this.filterSelected.subAssetUnderWarranty = false;
     this.filterSelected.subAssetOutOfWarranty = false;
     this.filterSelected.underActiveLifeCycle = false;
     this.filterSelected.underNotice = false;
     this.filterSelected.obsoleted = false;
     this.filterSelected.assetCategory = [];
     this.filterSelected.subAssetCategory = [];
     this.filterSelected.componentMake = [];
     this.filterSelected.designType = [];
     this.filterSelected.systemVoltage = [];
     this.filterSelected.balanceLife = [];
    // this.getAssetHeaderDetailsForSite();
    // this.getAssetDetailsforSite()
    this.getAssets.next(true);
    // this.applyFilter();
  }

  setFilterValues()
  {
    this.assetService.getAssetStatus().subscribe((data: any) => {
      this.assetFilterData.assetStatus.list = data;
    });
    this.assetService.getSubAssetStatus().subscribe((data: any) => {
      this.assetFilterData.subAssetStatus.list = data;
    });
    this.assetService.getAssetCategories(this.selectedSite).subscribe((data: any) => {
      this.assetFilterData.assetCategory.list = data;
    });
    this.assetService.getSubAssetCategories(this.selectedSite).subscribe((data: any) => {
      this.assetFilterData.subAssetCategory.list = data;
    });
    this.assetService.getAssetmakeDetails(this.selectedSite).subscribe((data: any) => {
      this.assetFilterData.assetMake.list = data;
    });
    this.assetService.getDesignTypes(this.selectedSite).subscribe((data: any) => {
      this.assetFilterData.designType.list = data;
    });
    this.assetService.getVoltageLevel(this.selectedSite).subscribe((data: any) => {
      this.assetFilterData.systemVoltage.list = data;
    });
    this.assetService.getBalanceLife().subscribe((data: any) => {
      const keys = Object.keys(data);

        this.assetFilterData.balanceLife.list =keys;
    });
  }

  getFilters(){
    if(this.filterKey && this.filerValue){
      switch (this.filerValue) {
        case 'Under Warranty':
          this.filterSelected.underWarranty = true;
          break;
        case 'Out Of Warranty':
          this.filterSelected.outOfWarranty = true;
          break;
        case 'Under Contract':
          this.filterSelected.underContract = true;
          break;
        case 'Not in Contract':
          this.filterSelected.notInContract = true;
          break;
        case 'Contract Expire':
          this.filterSelected.contractExpire = true;
          break;
        case 'Maintenance Done':
          this.filterSelected.maintenanceDone = true;
          break;
        case 'Maintenance Due':
          this.filterSelected.maintenanceDue = true;
          break;
        case 'Maintenance Overdue':
          this.filterSelected.maintenanceOverDue = true;
          break;
      }
      this.filterKey = this.filerValue = null;
    }
    let filtersToApply = cloneDeep(this.filterSelected);
    filtersToApply.substationIds = this.selectedSubstations && this.selectedSubstations.length ? this.selectedSubstations : [];

    let filterKeys = Object.keys(filtersToApply);

    filterKeys.forEach((key) => {
      if((filtersToApply[key] instanceof Array && filtersToApply[key].length === 0) || filtersToApply[key] === false) delete filtersToApply[key];
    });

    if((filtersToApply && Object.keys(filtersToApply).length == 0) || !filtersToApply){
      filtersToApply = null  
    }

    return filtersToApply;
  }

  getAssetDetails(){
    this.getAssets.pipe(
      switchMap((isHeaderUpdate) => {
        const filtersToApply = this.getFilters();
        this.isLoading = true;

        const headerFilters = Object.assign({}, filtersToApply);
        if(isHeaderUpdate){
          this.totalAsset = 0;
          this.totalSubAsset = 0;
          this.totalHealty = 0;
          this.totalAtRisk = 0;
          this.totalCritical = 0;
          this.veryGood = 0;
          this.good = 0;
          this.fair = 0;
          this.poor = 0;
          this.veryPoor = 0;
          this.unknown = 0;
          if(headerFilters?.hasOwnProperty('healthy')) delete headerFilters.healthy;
          if(headerFilters?.hasOwnProperty('risk')) delete headerFilters.risk;
          if(headerFilters?.hasOwnProperty('critical')) delete headerFilters.critical;
        }
       
        if(this.pageNumber <= 1){
          this.assets = [];
          this.newAssets = [];
        }
        
        return this.siteManagementService.getAssetDetails(this.selectedSite, this.pageNumber, this.pageSize, filtersToApply, headerFilters, this.searchKeyword, isHeaderUpdate)
      })
    ).subscribe((response) => {
      if(response?.headerDetails){
        this.totalAsset = response?.headerDetails?.totalAssets;
        this.totalSubAsset = response?.headerDetails?.totalSubAssets;
        this.totalHealty = response?.headerDetails?.healthy;
        this.totalAtRisk = response?.headerDetails?.risk;
        this.totalCritical = response?.headerDetails?.critical;
        this.veryGood = response?.headerDetails?.veryGood;
        this.good = response?.headerDetails?.good;
        this.fair = response?.headerDetails?.fair;
        this.poor = response?.headerDetails?.poor;
        this.veryPoor = response?.headerDetails?.veryPoor;
        this.unknown = response?.headerDetails?.unknown;
      }

      this.isClearAll = false;
      this.assets = this.assets && this.assets?.length && this.pageNumber > 1 ? this.assets?.concat(response?.assetDetails?.assetOverviewList) : response?.assetDetails?.assetOverviewList;
      this.newAssets = this.assets;
      this.totalRecords = response?.assetDetails?.totalRecords;
      this.isLoading = false;
    });
  }

  // getAssetHeaderDetailsForSite(){
  //   this.isLoading = true;

  //   const filtersToApply = this.getFilters();
      
  //   this.totalSubAsset = 0;
  //   this.totalHealty = 0;
  //   this.totalAtRisk = 0;
  //   this.totalCritical = 0;

  //   if(filtersToApply?.hasOwnProperty('healthy')) delete filtersToApply.healthy;
  //   if(filtersToApply?.hasOwnProperty('risk')) delete filtersToApply.risk;
  //   if(filtersToApply?.hasOwnProperty('critical')) delete filtersToApply.critical;

  //   this.subscription.add(
  //     this.siteManagementService.getAssetsHeadersForSite(this.selectedSite, this.pageNumber, this.pageSize, filtersToApply, this.searchKeyword).subscribe(response => { 
  //       this.isLoading = false;
  //       this.totalAsset = response?.totalAssets;
  //       this.totalSubAsset = response?.totalSubAssets;
  //       this.totalHealty = response?.healthy;
  //       this.totalAtRisk = response?.risk;
  //       this.totalCritical = response?.critical;
  //     })
  //   );
  // }

  // getAssetDetailsforSite() {
  //   // let substations = this.selectedSubstations.join('&subStationIds=');
  //     this.isLoading = true;

  //     const filtersToApply = this.getFilters();

  //     if(this.pageNumber <= 1){
  //       this.assets = [];
  //       this.newAssets = [];
  //     }

  //     this.subscription.add(
  //       this.siteManagementService.getAssetsForSite(this.selectedSite, this.pageNumber, this.pageSize, filtersToApply, this.searchKeyword).subscribe(response => { 
  //         // console.log(assets);
  //         this.isLoading = false;
  //         // this.searchByKeyword();
  //         // this.totalSubAsset = 0;
  //         // this.totalHealty = 0;
  //         // this.totalAtRisk = 0;
  //         // this.totalCritical = 0;
  //         this.isClearAll = false;
  //         // if (this.filterKey && this.filerValue) {
  //         //   const filteredAssets = this.assetFilterPipe.transform(assets, this.filerValue, this.filterKey);
  //         //   this.assets = this.assets && this.assets.length ? this.assets.concat(filteredAssets) : filteredAssets;
  //         //   this.newAssets = this.assets;
  //         // }
  //         // else
  //         // {
  //         this.assets = this.assets && this.assets?.length && this.pageNumber > 1 ? this.assets?.concat(response?.assetOverviewList) : response?.assetOverviewList;
  //         this.newAssets = this.assets;
  //         this.totalRecords = response?.totalRecords;

  //         // }
  //         // this.totalAsset = response?.assetHeaderDetails?.totalAssets;
  //         // this.totalSubAsset = response?.assetHeaderDetails?.totalSubAssets;
  //         // this.totalHealty = response?.assetHeaderDetails?.healthy;
  //         // this.totalAtRisk = response?.assetHeaderDetails?.risk;
  //         // this.totalCritical = response?.assetHeaderDetails?.critical;
  //       })
  //     );
  // }

  onScroll(){
    if(this.totalRecords > (this.pageNumber * this.pageSize)){
      this.pageNumber++;
      // this.getAssetDetailsforSite()
      this.getAssets.next(false);
    }
  }

  customAccordian(event: any) {
    event.mobileAccordian = !event.mobileAccordian;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  changeSite(siteId: any) {
    if(this.assetFilters){
      this.assetFilters.options.forEach((item: MatOption) => item.deselect());
    }
    if(this.assetFiltersMobile){
      this.assetFiltersMobile.options.forEach((item: MatOption) => item.deselect());
    }
    this.selectedSubstations=[];
    this.statusFilterText = '';
    // this.selectedSite = site.value;
    this.getSubstationList();
    if(this.subStations){
      this.selectedSubStation = this.subStations[0] ? this.subStations[0].id : 0;
    }
     this.totalSubAsset = 0;
    this.totalAtRisk = 0;
    this.totalCritical = 0;
    this.totalHealty = 0;
    this.pageNumber = 1;
    // this.getAssetHeaderDetailsForSite();
    // this.getAssetDetailsforSite();
    this.getAssets.next(true);
    this._router.navigate(['/layout/sites/site/' + this.selectedSite + '/assets']);
  }

  changeSubstation(event) {
    if(event.target){ // cancelling input change event bubbling inside ng-select
      return;
    }
    this.filterSelected.substationIds = this.selectedSubstations;
    this.assets = [];
    this.pageNumber = 1;
    // this.getAssetHeaderDetailsForSite();
    // this.getAssetDetailsforSite(); 
    this.getAssets.next(true);
  }

  applyStatusFilter(status: string) {
    if (this.statusFilterText === status) {
      this.statusFilterText = '';
      this.statusFilterCategory = 'HealthStatus';
      // this.searchKeyword = '';
    } else {
      this.statusFilterText = status;
      this.statusFilterCategory = 'HealthStatus'
      // this.searchKeyword = '';
    }

    this.filterSelected.healthy = status === 'Healthy' && this.statusFilterText ? true : false;
    this.filterSelected.risk = status === 'AtRisk' && this.statusFilterText ? true : false;
    this.filterSelected.critical = status === 'Critical' && this.statusFilterText ? true : false;

    this.filterSelected.veryGood = status === 'veryGood' && this.statusFilterText ? true : false;
    this.filterSelected.good = status === 'good' && this.statusFilterText ? true : false;
    this.filterSelected.fair = status === 'fair' && this.statusFilterText ? true : false;
    this.filterSelected.poor = status === 'poor' && this.statusFilterText ? true : false;
    this.filterSelected.veryPoor = status === 'veryPoor' && this.statusFilterText ? true : false;
    this.filterSelected.unknown = status === 'unknown' && this.statusFilterText ? true : false;

    // console.log(this.filterSelected);
    this.pageNumber = 1;
    // this.getAssetDetailsforSite()
    this.getAssets.next(false);
  }

  /* searchByKeyword(input?: any) {
    this.statusFilterText = input && input.value? input.value:this.searchKeyword ;
    this.statusFilterCategory = 'searchByKeyword'
  } */

  goToAlarmsPage(assetId:string) {
    let url = `/layout/alarms?siteId=${this.selectedSite}&assetId=${assetId}`
    this._router.navigate([url]);
  }

  goToAssetDetailsMobile(assetId:string) {
    let url = `/layout/sites/site/${this.selectedSite}/asset-details/${assetId}`
    this._router.navigate([url]);
  }

  subAssetSelection(event){
    this.isAsset = false;
    this.selectedSubAssetStatus = event;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    let backdrop = document.getElementsByClassName('modal-backdrop') as HTMLCollectionOf<Element>;
    if(backdrop && backdrop[0] && backdrop[0].classList) {
      backdrop[0].classList.remove('show');
      backdrop[0].classList.remove('modal-backdrop');
    }
  }
}
