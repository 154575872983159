<div class="container-fluid pb-2" *ngIf="module">
    <div class="row pt-3 ps-3 pe-3">
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/site_details.svg)'
                            : 'url(../../assets/images/dashboard_watermark/site_details.svg)',
                        'background-size': isDarkMode ? '60px' : ''
                    }"
                >
                    <h5 class="dashboard-card-header col-sm-8">Site Details</h5>
                    <div class="row" *ngIf="module?.AssetAndSubAsset?.canRead">
                        <div class="col-4 mt-4 p-0 text-center">
                            <div class="value-text-lg-blue">
                                <span
                                    *ngIf="
                                        siteDetails && siteDetails.totalSites
                                    "
                                    matTooltip="{{
                                        siteDetails.totalSites.count
                                    }}"
                                >
                                    {{
                                        siteDetails.totalSites.count > 999
                                            ? "999+"
                                            : siteDetails.totalSites.count
                                    }}</span
                                >
                                <!-- <span>
                                  <i class="fa ms-2" [ngClass]="siteDetails?.totalSites?.delta ? (siteDetails.totalSites.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                </span> -->
                            </div>
                            <div class="value-text-grey">Total Sites</div>
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-6">
                                    <div class="title-text-md-blue">
                                        <span
                                            *ngIf="
                                                siteDetails &&
                                                siteDetails.totalAssets
                                            "
                                            matTooltip="{{
                                                siteDetails?.totalAssets?.count
                                            }}"
                                        >
                                            {{
                                                siteDetails.totalAssets.count >
                                                99999
                                                    ? "99999+"
                                                    : siteDetails.totalAssets
                                                          .count
                                            }}</span
                                        >
                                        <!-- <span>
                                          <i class="fa ms-2" [ngClass]="siteDetails?.totalAssets?.delta ? (siteDetails.totalAssets.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                        </span> -->
                                    </div>
                                    <div class="title-text-grey">Assets</div>
                                </div>
                                <div class="col-6">
                                    <div class="title-text-md-blue">
                                        <span
                                            *ngIf="
                                                siteDetails &&
                                                siteDetails.totalSubAssets
                                            "
                                            matTooltip="{{
                                                siteDetails?.totalSubAssets
                                                    ?.count
                                            }}"
                                            >{{
                                                siteDetails.totalSubAssets
                                                    .count > 99999
                                                    ? "99999+"
                                                    : siteDetails.totalSubAssets
                                                          .count
                                            }}</span
                                        >
                                        <!-- <span>
                                          <i class="fa ms-2" [ngClass]="siteDetails?.totalSubAssets?.delta ? (siteDetails.totalSubAssets.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                        </span> -->
                                    </div>
                                    <div class="title-text-grey">SubAssets</div>
                                </div>
                            </div>
                            <hr style="margin: 0.5rem -1rem" />
                            <div class="row">
                                <div class="col-6 p-0">
                                    <div class="title-text-md-blue">
                                        <span
                                            *ngIf="
                                                siteDetails &&
                                                siteDetails.connectedDevices
                                            "
                                            matTooltip="{{
                                                siteDetails?.connectedDevices
                                                    ?.count
                                            }}"
                                            ><i
                                                class="fa fa-wifi healthy me-1"
                                                style="font-size: 16px"
                                            ></i
                                            >{{
                                                siteDetails.connectedDevices
                                                    .count > 99999
                                                    ? "99999+"
                                                    : siteDetails
                                                          .connectedDevices
                                                          .count
                                            }}</span
                                        >
                                        <!-- <span><i class="fa ms-1" [ngClass]="siteDetails?.connectedDevices?.delta ? (siteDetails.connectedDevices.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="value-text-grey">Connected</div>
                                </div>
                                <div class="col-6 p-0">
                                    <div class="title-text-md-blue">
                                        <span>
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="wifi-slash"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 640 512"
                                                class="svg-inline--fa fa-wifi-slash fa-w-20 critical"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M5.09 154.87c-6.66 6.16-6.79 16.59-.35 22.97l34.24 33.96c6.14 6.09 16.02 6.23 22.4.38 6.99-6.4 14.31-12.22 21.65-18.01l-64.96-50.21c-4.3 3.71-8.79 7.04-12.98 10.91zm471.75 181.9l45.42-45.21c6.52-6.46 6.29-17.06-.57-23.17-64.94-57.74-148.91-82.66-230.34-74.98l-83.16-64.27c125.94-38.36 267.75-11.01 370.43 83.05 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.96c6.44-6.39 6.3-16.82-.35-22.97C496.46 26.82 298.08-.76 133.42 71.35L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.03-2.81-22.45L476.84 336.77zm-358.53-68.38c-6.86 6.1-7.08 16.7-.57 23.17l34.28 34.01c5.97 5.93 15.59 6.32 21.94.8 13.35-11.6 28.01-20.66 43.15-28.55l-68.36-52.83c-10.48 7.15-20.74 14.78-30.44 23.4zM256 416c0 35.35 28.65 64 64 64 31.91 0 58.15-23.42 62.99-53.98l-88.7-68.56C271.77 367.37 256 389.82 256 416z"
                                                    class=""
                                                ></path>
                                            </svg>
                                            <span
                                                *ngIf="
                                                    siteDetails &&
                                                    siteDetails.notConnectedDevices
                                                "
                                                matTooltip="{{
                                                    siteDetails
                                                        ?.notConnectedDevices
                                                        ?.count
                                                }}"
                                                >{{
                                                    siteDetails
                                                        .notConnectedDevices
                                                        .count > 99999
                                                        ? "99999+"
                                                        : siteDetails
                                                              .notConnectedDevices
                                                              .count
                                                }}</span
                                            >
                                        </span>
                                        <!-- <span><i class="fa ms-1" [ngClass]="siteDetails?.notConnectedDevices?.delta ? (siteDetails.notConnectedDevices.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="value-text-grey">
                                        NotConnected
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row access-error"
                        *ngIf="!module?.AssetAndSubAsset?.canRead"
                    >
                        <div class="col text-center mt-3">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/workpermit.svg)'
                            : 'url(../../assets/images/dashboard_watermark/workpermit.svg)',
                        'background-size': isDarkMode ? '60px' : '',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header col-sm-8">Work Permit</h5>
                    <div
                        class="row"
                        *ngIf="module?.DigitalMaintenanceManager?.canRead"
                    >
                        <div
                            *ngIf="(workPermit | json) == '{}'"
                            class="critical text-center"
                        >
                            No data
                        </div>
                        <ng-container *ngIf="workPermit?.requested">
                            <div class="col-4 mt-4 p-0 text-center">
                                <div class="value-text-lg-blue">
                                    <span
                                        *ngIf="
                                            workPermit && workPermit.requested
                                        "
                                        matTooltip="{{
                                            workPermit?.requested?.count
                                        }}"
                                        >{{
                                            workPermit.requested.count > 999
                                                ? "999+"
                                                : workPermit.requested.count
                                        }}</span
                                    >
                                    <!-- <span><i class="fa ms-2" [ngClass]="workPermit?.requested?.delta ? (workPermit.requested.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                </div>
                                <div class="value-text-grey">Requested</div>
                            </div>
                            <div class="col-8" style="overflow: hidden">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="title-text-md-blue">
                                            <span
                                                *ngIf="
                                                    workPermit &&
                                                    workPermit.open
                                                "
                                                matTooltip="{{
                                                    workPermit?.open?.count
                                                }}"
                                                >{{
                                                    workPermit.open.count > 999
                                                        ? "999+"
                                                        : workPermit.open.count
                                                }}</span
                                            >
                                            <!-- <span><i class="fa ms-3" [ngClass]="workPermit?.open?.delta ? (workPermit.open.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                        </div>
                                        <div class="title-text-grey">Open</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="title-text-md-blue">
                                            <span
                                                *ngIf="
                                                    workPermit &&
                                                    workPermit.closed
                                                "
                                                matTooltip="{{
                                                    workPermit?.closed?.count
                                                }}"
                                                >{{
                                                    workPermit.closed.count >
                                                    999
                                                        ? "999+"
                                                        : workPermit.closed
                                                              .count
                                                }}</span
                                            >
                                            <!-- <span><i class="fa ms-3" [ngClass]="workPermit?.closed?.delta ? (workPermit.closed.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                        </div>
                                        <div class="title-text-grey">
                                            Closed
                                        </div>
                                    </div>
                                </div>
                                <hr style="margin: 0.5rem -1rem" />
                                <hr class="vertical-divider" />
                                <div class="row">
                                    <div class="col-6">
                                        <div class="title-text-md-blue">
                                            <!-- <span>{{ workPermit?.approved?.count }}</span> -->
                                            <span
                                                *ngIf="
                                                    workPermit &&
                                                    workPermit.approved
                                                "
                                                matTooltip="{{
                                                    workPermit?.approved?.count
                                                }}"
                                                >{{
                                                    workPermit.approved.count >
                                                    999
                                                        ? "999+"
                                                        : workPermit.approved
                                                              .count
                                                }}</span
                                            >
                                            <!-- <span><i class="fa ms-3" [ngClass]="workPermit?.approved?.delta ? (workPermit.approved.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                        </div>
                                        <div class="value-text-grey">
                                            Approved
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="title-text-md-blue">
                                            <span
                                                *ngIf="
                                                    workPermit &&
                                                    workPermit.cancelled
                                                "
                                                matTooltip="{{
                                                    workPermit?.cancelled?.count
                                                }}"
                                                >{{
                                                    workPermit.cancelled.count >
                                                    999
                                                        ? "999+"
                                                        : workPermit.cancelled
                                                              .count
                                                }}</span
                                            >
                                            <!-- <span><i class="fa ms-3" [ngClass]="workPermit?.cancelled?.delta ? (workPermit.cancelled.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                        </div>
                                        <div class="value-text-grey">
                                            Cancelled
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div
                        class="row access-error"
                        *ngIf="!module?.DigitalMaintenanceManager?.canRead"
                    >
                        <div class="col text-center mt-3">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AlarmOrEvents?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/alarm_events.svg)'
                            : 'url(../../assets/images/dashboard_watermark/alarm_events.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <div class="card-row">
                        <h5 class="dashboard-card-header col-sm-8">
                            Alarm/Events
                        </h5>
                        <!-- <div class="card-menu">
                                <a class="">
                                    <span class="value-text-md-grey" style="font-size:12px">Today</span>
                                    <span><i class="fa fa-angle-down ms-1"></i></span>
                                </a>
                            </div> -->
                        <div class="dropdown-maintenance">
                            <a
                                class="value-text-grey me-1"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                                style="font-size: 12px"
                            >
                                {{ allAlarm == 0 ? "All" : "Today" }}
                                <i class="arrow down"></i>
                            </a>
                            <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                            >
                                <li *ngFor="let option of [0, 1]">
                                    <a
                                        class="dropdown-item"
                                        (click)="getAlarmOrEvents(option)"
                                    >
                                        {{ option == 0 ? "All" : "Today" }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 mt-4 p-0 text-center">
                            <div class="value-text-lg-blue">
                                <span
                                    ><i
                                        class="fa fa-exclamation-triangle critical me-1"
                                        style="font-size: 18px"
                                    ></i>
                                    <span
                                        *ngIf="
                                            alarm &&
                                            alarm.results &&
                                            alarm.results.Critical
                                        "
                                        matTooltip="{{
                                            alarm?.results?.Critical?.count
                                        }}"
                                        >{{
                                            alarm.results.Critical.count > 999
                                                ? "999+"
                                                : alarm.results.Critical.count
                                        }}</span
                                    >
                                </span>
                                <!-- <span><i class="fa ms-1"
                                        [ngClass]="alarm?.results?.Critical?.delta ? (alarm.results.Critical.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                            </div>
                            <div class="value-text-grey">Critical Alarm</div>
                        </div>
                        <div class="col-8" style="overflow: hidden">
                            <div class="row">
                                <div class="col-6">
                                    <div class="title-text-md-blue">
                                        <span
                                            ><i
                                                class="fa fa-exclamation-triangle at-risk px-1"
                                                style="font-size: 13px"
                                            ></i>
                                            <span
                                                *ngIf="
                                                    alarm &&
                                                    alarm.results &&
                                                    alarm.results.High
                                                "
                                                matTooltip="{{
                                                    alarm?.results?.High?.count
                                                }}"
                                                >{{
                                                    alarm.results.High.count >
                                                    999
                                                        ? "999+"
                                                        : alarm.results.High
                                                              .count
                                                }}</span
                                            >
                                        </span>
                                        <!-- <span><i class="fa ms-1"
                                                [ngClass]="alarm?.results?.High?.delta ? (alarm.results.High.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="title-text-grey">
                                        High Alarm
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="title-text-md-blue">
                                        <span
                                            ><i
                                                class="fa fa-exclamation-triangle px-1"
                                                style="
                                                    font-size: 13px;
                                                    color: #ffff16;
                                                "
                                            ></i>
                                            <span
                                                *ngIf="
                                                    alarm &&
                                                    alarm.results &&
                                                    alarm.results.Low
                                                "
                                                matTooltip="{{
                                                    alarm?.results?.Low?.count
                                                }}"
                                                >{{
                                                    alarm.results.Low.count >
                                                    999
                                                        ? "999+"
                                                        : alarm.results.Low
                                                              .count
                                                }}</span
                                            >
                                        </span>
                                        <!-- <span><i class="fa ms-1"
                                                [ngClass]="alarm?.results?.Low?.delta ? (alarm.results.Low.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="title-text-grey">Low Alarm</div>
                                </div>
                            </div>
                            <hr style="margin: 0.5rem -1rem" />
                            <hr class="vertical-divider" />
                            <div class="row">
                                <div class="col-6">
                                    <div class="title-text-md-blue">
                                        <span
                                            ><i
                                                class="fa fa-info-circle critical px-1"
                                                style="font-size: 13px"
                                            ></i>
                                            <span
                                                *ngIf="
                                                    alarm &&
                                                    alarm.results &&
                                                    alarm.results.Warnings
                                                "
                                                matTooltip="{{
                                                    alarm?.results?.Warnings
                                                        ?.count
                                                }}"
                                                >{{
                                                    alarm.results.Warnings
                                                        .count > 999
                                                        ? "999+"
                                                        : alarm.results.Warnings
                                                              .count
                                                }}</span
                                            >
                                        </span>
                                        <!-- <span><i class="fa ms-1"
                                                [ngClass]="alarm?.results?.Warnings?.delta ? (alarm.results.Warnings.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="value-text-grey">Warnings</div>
                                </div>
                                <div class="col-6 pe-0">
                                    <div class="title-text-md-blue">
                                        <span
                                            ><i
                                                class="fa fa-info-circle ipredict-blue px-1"
                                                style="font-size: 13px"
                                            ></i>
                                            <span
                                                *ngIf="
                                                    alarm &&
                                                    alarm.results &&
                                                    alarm.results.Notification
                                                "
                                                matTooltip="{{
                                                    alarm?.results?.Notification
                                                        ?.count
                                                }}"
                                                >{{
                                                    alarm.results.Notification
                                                        .count > 999
                                                        ? "999+"
                                                        : alarm.results
                                                              .Notification
                                                              .count
                                                }}</span
                                            >
                                        </span>
                                        <!-- <span><i class="fa ms-1"
                                                [ngClass]="alarm?.results?.Notification?.delta ? (alarm.results.Notification.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                                    </div>
                                    <div class="value-text-grey">
                                        Notifications
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AlarmOrEvents?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/alarm_events.svg)'
                            : 'url(../../assets/images/dashboard_watermark/alarm_events.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Alarm/Events</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.DigitalMaintenanceManager?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/upcoming_maintenance.svg)'
                            : 'url(../../assets/images/dashboard_watermark/upcoming_maintenance.svg)'
                    }"
                >
                    <div class="dropdown-section">
                        <h5 class="dashboard-card-header col-sm-8 col-8">
                            Upcoming Maintenance
                        </h5>
                        <div class="dropdown-maintenance">
                            <a
                                class="value-text-grey me-1"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                                style="font-size: 12px"
                            >
                                {{ upComingMaintenanceValue }}
                                <i class="arrow down"></i>
                            </a>
                            <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                            >
                                <li
                                    *ngFor="
                                        let option of upcomingMaintenance?.accessOptions
                                    "
                                >
                                    <a
                                        class="dropdown-item"
                                        (click)="
                                            getUpcomingMaintenance(
                                                option,
                                                option == 0
                                                    ? 'Today'
                                                    : 'Next ' + option + ' days'
                                            )
                                        "
                                    >
                                        {{
                                            option == 0
                                                ? "Today"
                                                : "Next " + option + " days"
                                        }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        *ngIf="upcomingMaintenance?.maintenanceDetails"
                        class="mt-3 mb-2 pe-2"
                        style="height: 105px; overflow-y: scroll; color: #000"
                    >
                        <table
                            *ngIf="
                                upcomingMaintenance?.maintenanceDetails
                                    ?.length !== 0
                            "
                            class="table table-small upcoming-maintenance"
                        >
                            <tbody>
                                <tr
                                    *ngFor="
                                        let sites of upcomingMaintenance?.maintenanceDetails
                                    "
                                >
                                    <td>
                                        <a>{{
                                            sites.siteName | slice : 0 : 25
                                        }}</a>
                                    </td>
                                    <td>{{ sites.assetName }}</td>
                                    <td>
                                        <a
                                            class="ptwLink"
                                            [routerLink]="[
                                                '/layout/DMM/workPermit/read'
                                            ]"
                                            [queryParams]="{
                                                customerId: sites.customerId,
                                                siteId: sites.siteId,
                                                ptwId: sites.ptwIdNumber
                                            }"
                                            >{{ sites.ptwId }}</a
                                        >
                                    </td>
                                    <td>
                                        {{
                                            sites.maintenanceDate + "Z"
                                                | date
                                                    : "dd/MM/yyyy h:mm:ss a"
                                                    : offsetDate
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            *ngIf="
                                upcomingMaintenance?.maintenanceDetails
                                    ?.length === 0
                            "
                            class="critical text-center"
                        >
                            There is no upcoming maintenance
                        </div>
                    </div>
                    <!-- <ul class="list-group list-group-flush mt-3"
                        style="height:90px;overflow-y:scroll;">
                        <li class="list-group-item data-columns" style="line-height: 12px;" *ngFor="let sites of upcomingMaintenance?.maintenanceDetails">
                          <span>{{ sites.siteName }}</span>
                          <span>{{ sites.assetName }}</span>
                          <span>{{ sites.maintenanceDate | date:'dd/MM/yyyy h:mm:ss a' }}</span>
                        </li>
                    </ul> -->
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.DigitalMaintenanceManager?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/upcoming_maintenance.svg)'
                            : 'url(../../assets/images/dashboard_watermark/upcoming_maintenance.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header">Upcoming Maintenance</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row px-3">
        <!-- <div class="col-lg-6 col-md-6 col-xl-3 p-2" *ngFor="let graphCard of dashboardKPICards?.Graphs">
            <mat-card class="dashboard-tile healthy-bg">
                <mat-card-title class="card-title mb-0">
                    {{graphCard.graphName}}
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <div class="col py-2">
                            <app-subasset-matrix [subAssetRiskMatrix]="graphCard.graphData"></app-subasset-matrix>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3 p-2" *ngFor="let card of dashboardKPICards?.Cards">
            <mat-card class="healthy-bg">
                <mat-card-title class="card-title">
                    {{card.CardName}}
                </mat-card-title>
                <mat-card-content>
                    <div class="row content-center-align">
                        <div class="col-4 py-2" *ngFor="let data of card?.CardData">
                            <div class="count" [ngClass]="data.Name">{{data.Count}}</div>
                            <div class="label">{{data.Name}}</div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div> -->
        <!-- </div> -->

        <!-- <div class="row ps-3 pe-3"> -->
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.HealthKpi?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Asset Risk Profile</h5>
                    <div class="row">
                        <div class="col-12">
                            <app-subasset-matrix
                                [subAssetRiskMatrix]="subAssetRiskMatrix"
                            ></app-subasset-matrix>
                        </div>
                    </div>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.HealthKpi?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Asset Risk Profile</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Asset Health Profile</h5>
                    <div class="row mt-2">
                        <div
                            class="col-4 text-center mt-1"
                            *ngFor="let statusItem of healthProfile"
                        >
                            <div class="value-text-lg-blue-2">
                                <span
                                    matTooltip="{{ statusItem.Count }}"
                                    [ngClass]="statusItem.Name"
                                >
                                    {{
                                        statusItem.Count > 999
                                            ? "999+"
                                            : statusItem.Count
                                    }}</span
                                >
                                <!-- <span><i class="fa ms-1"
                                        [ngClass]="healthProfile?.healthy?.delta ? (healthProfile.healthy.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                            </div>
                            <div class="value-text-grey">
                                {{ statusItem.Name }}
                            </div>
                        </div>
                        <!-- <div class="col-4 text-center">
                            <div class="value-text-lg-blue-2">
                                <span *ngIf="healthProfile && healthProfile.risk"
                                    matTooltip="{{healthProfile.risk.count}}" class="at-risk">
                                    {{ (healthProfile.risk.count > 99)? '99+': healthProfile?.risk?.count }}</span>
                                <span><i class="fa ms-1"
                                        [ngClass]="healthProfile?.risk?.delta ? (healthProfile.risk.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span>
                            </div>
                            <div class="value-text-grey">At Risk</div>
                        </div>
                        <div class="col-4 text-center">
                            <div class="value-text-lg-blue-2">
                                <span *ngIf="healthProfile && healthProfile.critical"
                                    matTooltip="{{healthProfile.critical.count}}" class="critical">
                                    {{ (healthProfile.critical.count > 99)? '99+':healthProfile?.critical?.count
                                    }}</span>
                                <span><i class="fa ms-1"
                                        [ngClass]="healthProfile?.critical?.delta ? (healthProfile.critical.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span>
                            </div>
                            <div class="value-text-grey">Critical</div>
                        </div> -->
                    </div>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Asset Health Profile</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Maintenance Profile</h5>
                    <div class="row mt-5">
                        <div
                            class="col-4 text-center"
                            *ngFor="let statusItem of maintenanceProfile"
                        >
                            <div class="value-text-lg-blue-2">
                                <span
                                    matTooltip="{{ statusItem.Count }}"
                                    [ngClass]="statusItem.Name"
                                >
                                    {{
                                        statusItem.Count > 999
                                            ? "999+"
                                            : statusItem.Count
                                    }}</span
                                >
                                <!-- <span><i class="fa ms-1"
                                        [ngClass]="healthProfile?.healthy?.delta ? (healthProfile.healthy.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                            </div>
                            <div class="value-text-grey">
                                {{ statusItem.Name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Maintenance Profile</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Obsolescence Profile</h5>
                    <div class="row mt-5">
                        <div
                            class="col-4 text-center"
                            *ngFor="let statusItem of obsolesceneceProfile"
                        >
                            <div class="value-text-lg-blue-2">
                                <span
                                    matTooltip="{{ statusItem.Count }}"
                                    [ngClass]="statusItem.Name"
                                >
                                    {{
                                        statusItem.Count > 999
                                            ? "999+"
                                            : statusItem.Count
                                    }}</span
                                >
                                <!-- <span><i class="fa ms-1"
                                        [ngClass]="healthProfile?.healthy?.delta ? (healthProfile.healthy.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i></span> -->
                            </div>
                            <div class="value-text-grey">
                                {{ statusItem.Name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                        'background-position': 'right 3px top 30px;'
                    }"
                >
                    <h5 class="dashboard-card-header">Obsolescence Profile</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-xl-6 card-padding">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AlarmOrEvents?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/recent_alarm.svg)'
                            : 'url(../../assets/images/dashboard_watermark/recent_alarm.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header col-sm-8 col-7">
                        Recent Alarm / Events
                    </h5>
                    <span class="card-menu">
                        <!-- <span class="value-text-grey me-1" style="font-size:12px">Today</span>
                        <span><i class="fa fa-angle-down me-2"></i></span> -->
                        <span
                            (click)="getRecentAlarams(allRecentAlarms)"
                            class="alarm-btn"
                            role="button"
                            ><a>View {{ allRecentAlarms ? "All" : "Less" }}</a>
                        </span>
                    </span>
                    <ng-container *ngIf="isLoading">
                        <div class="container text-center mt-5">
                            <i
                                class="fas fa-spinner fa-pulse fa-3x healthy"
                            ></i>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <ul
                            *ngIf="recentAlarmOrEvents"
                            class="list-group list-group-flush mt-3"
                            style="
                                height: 125px;
                                overflow-y: scroll;
                                padding-right: 8px;
                            "
                        >
                            <li
                                class="list-group-item data-columns card-column-recentAlarm px-0 py-1"
                                *ngFor="let alarm of recentAlarmOrEvents"
                            >
                                <span class="card-column col-1 me-1">
                                    <span class="card-column-head">&nbsp;</span>
                                    <span class="card-column-text mt-1">
                                        <i
                                            class="fa fa-exclamation-triangle ms-2"
                                            *ngIf="alarm.alarmClass"
                                            [ngClass]="
                                                alarm.alarmClass | lowercase
                                            "
                                        ></i>
                                        <i
                                            class="fa fa-info-circle ipredict-blue ms-2"
                                            *ngIf="
                                                alarm.eventType ==
                                                'Notification'
                                            "
                                        ></i>
                                        <i
                                            class="fa fa-info-circle critical ms-2"
                                            *ngIf="alarm.eventType == 'Warning'"
                                        ></i>
                                    </span>
                                </span>
                                <span class="card-column col-2">
                                    <span class="card-column-head"
                                        >ASSET ID</span
                                    >
                                    <!--commented till link clickable-->
                                    <!-- <span class="mt-1" [ngClass]="alarm.assetId == 'NA' ? 'card-no-link': 'card-column-link'">{{ alarm.assetId }}</span> -->
                                    <span class="card-column-text mt-1">{{
                                        alarm.assetId
                                    }}</span>
                                </span>
                                <span class="card-column col-5 pe-1 ps-2">
                                    <span class="card-column-head"
                                        >DETAILS</span
                                    >
                                    <span
                                        class="card-column-text mt-1"
                                        style="line-height: 1.2"
                                        >{{ alarm.details }}</span
                                    >
                                </span>
                                <span class="card-column col-4">
                                    <span class="card-column-head"
                                        >RECEIVED AT</span
                                    >
                                    <span class="card-column-text mt-1">{{
                                        alarm.receivedAt + "Z"
                                            | date
                                                : "dd/MM/yyyy
                                        h:mm:ss a"
                                                : offsetDate
                                    }}</span>
                                </span>
                            </li>
                        </ul>
                        <div
                            *ngIf="!recentAlarmOrEvents"
                            class="critical text-center"
                        >
                            There is no recent alarms / events
                        </div>
                    </ng-container>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AlarmOrEvents?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/recent_alarm.svg)'
                            : 'url(../../assets/images/dashboard_watermark/recent_alarm.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header">Recent Alarm / Events</h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div>
    <div class="row ps-3 pe-3"> -->
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card dashboard-tile">
                        <div
                            class="card-body p-0"
                            *ngIf="module?.AssetAndSubAsset?.canRead"
                        >
                            <div
                                *ngIf="isMapLoading"
                                class="w-100 h-100 text-center pt-5"
                            >
                                <i class="fas fa-spinner fa-pulse healthy"></i>
                            </div>
                            <!-- <div #mapElement id="map"></div> -->
                            <app-azure-maps
                                class="map-container"
                                [height]="'170px'"
                                [width]="'100%'"
                                [sites]="sites"
                            ></app-azure-maps>
                        </div>
                        <div
                            class="card-body"
                            *ngIf="!module?.AssetAndSubAsset?.canRead"
                        >
                            <div class="row access-error mt-5 pt-5">
                                <div class="col text-center">
                                    You don't have enough privileges to view
                                    this information
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <app-horizontal-chart
                        chartTitle="Assets Category wise Breakdown"
                        apiEndPoint="categories"
                        type="assetmanagement"
                    ></app-horizontal-chart>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header">
                        Assets Category wise Breakdown
                    </h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <app-horizontal-chart
                        chartTitle="Assets with Max Breakdown"
                        apiEndPoint="maxbreakdown"
                        type="assetmanagement"
                    ></app-horizontal-chart>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header">
                        Assets with Max Breakdown
                    </h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xl-3">
            <div class="card dashboard-tile">
                <div
                    class="card-body"
                    *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <app-horizontal-chart
                        chartTitle="Top Cause of Breakdown"
                        apiEndPoint="alarmcause"
                        type="alarm"
                    ></app-horizontal-chart>
                </div>
                <div
                    class="card-body"
                    *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode
                            ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)'
                            : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                    }"
                >
                    <h5 class="dashboard-card-header">
                        Top Cause of Breakdown
                    </h5>
                    <div class="row access-error mt-3">
                        <div class="col text-center">
                            You don't have enough privileges to view this
                            information
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
