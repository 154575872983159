import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IPredictService } from 'src/app/_services/i-predict.service';
import { RbacService } from 'src/app/_helpers/rbac.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FeederDetails } from 'src/app/_models/feeder.type';
import { AdminPanelService } from 'src/app/modules/admin-panel/services/admin-panel.service';

@Component({
    selector: 'app-sub-asset-details-admin',
    templateUrl: './subasset-details.component.html',
    styleUrls: ['./subasset-details.component.scss']
})
export class SubAssetDetailsComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    @Input('selectedAssetId') selectedAssetId: any;
    @Input('subAssetsList') subAssetsList: any;
    @Input('isCustomerUser') isCustomerUser: any;
    @Input() siteId: number = 0;
    @Input() health: any;
    @Input() isEditableForUser!: boolean;
    @Input() editMode!: boolean;
    @Input() pendingRequest!: boolean;
    @Input() isAdminOrEAUser: boolean;
    @Output() dataUpdate = new EventEmitter<string>();

    feederDetails!: FeederDetails | any;
    assetId: string = '';
    selectedSet = 0;
    recordsPerPage = 10;
    start: any;
    end: any;
    currArray: any;
    healthData: any;
    showList: boolean = false;
    isMobile: boolean = false;
    cardView: boolean = true;
    selectedHealthfilter: string = '';
    healthStatus: string = '';
    healthKpiTab: boolean = false;
    ibDataTab: boolean = true;
    conditionTab: boolean = false;
    module: any;
    subAssetVerticals: any;
    feederList: any;
    selectedFeeder: any;
    selectedVertical: any;
    showMsg!: string;
    message:string = '';
    showError: boolean = false;
    createStatus!: boolean;
    isVisible = false;

    constructor(private iPredictService: IPredictService,
        private deviceService: DeviceDetectorService,
        public sharedService: SharedService,
        private rbacService: RbacService,
        private adminPanelService: AdminPanelService,) { }

    ngOnInit(): void {
        if (this.sharedService.user.id) {
            this.module = this.sharedService.modules;
        } else {
            this.rbacService.getUserInformation().subscribe((value: any) => {
                this.module = this.sharedService.modules;
                if(!this.module.IBData.canUpdate) {
                    this.isEditableForUser = false;
                }
            })
        }
        this.epicFunction();
        this.getSubAssetDetails();
    }

    epicFunction() {
        this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        if (this.isMobile) {
            this.recordsPerPage = 3;
        } else this.recordsPerPage = 10;
    }

    getSubAssetDetails(): void {
        this.iPredictService.getSubAssetsForAssetIdIBABS(this.selectedAssetId).subscribe((data: any) => {
            this.subAssetVerticals = data;
            if (this.subAssetVerticals.length) {
                this.selectedVertical = this.subAssetVerticals[0]
                if(this.subAssetVerticals?.length > this.recordsPerPage)
                    this.currArray = this.subAssetVerticals.slice(0, this.recordsPerPage);
                else
                    this.getSubAssetFeeders(this.selectedVertical);
            }
        });
    }

    getSubAssetFeeders(subAssetVertical){
        this.iPredictService.getAllSubAssetsForFeeder(this.selectedAssetId,subAssetVertical).subscribe((data: any) => {
            this.isVisible = data?.isVisible;
            this.feederDetails = data?.feederSubAssetDetails;
            this.healthData = data?.healthStatus;
            this.feederList = Object.keys(this.feederDetails);
            this.selectedFeeder = this.feederList[0];
        });
    }

    setSelectedVertical(vertical: string) {
        this.selectedVertical = vertical;
        this.getSubAssetFeeders(vertical);
    }
    
    setSelectedFeeder(feeder: string){
        this.selectedFeeder = undefined;
        this.selectedFeeder = feeder;
    }

    displayActiveSection(activeSection: any) {
        this.start = activeSection.start;
        this.end = activeSection.end;
        this.currArray = this.subAssetVerticals.slice(this.start, this.end);
        this.setSelectedVertical(this.currArray[0]);
    }

    filterByHealth(health: string) {
        if (this.healthStatus == health) {
            this.healthStatus = '';
        } else {
            this.healthStatus = health;
        }
    }

    tabActivation(tabValue: string) {
        if (tabValue == 'ibDataTab') {
            this.ibDataTab = true;
            this.healthKpiTab = false;
            this.conditionTab = false;
        }
        else if (tabValue == 'conditionTab') {
            this.ibDataTab = false;
            this.conditionTab = true;
            this.healthKpiTab = false;
        }
        else if (tabValue == 'healthKpiTab') {
            this.ibDataTab = false;
            this.conditionTab = false;
            this.healthKpiTab = true;
        }
    }

    addToPayload(e: any) {
        this.dataUpdate.emit(e as any);
    }

    toggleVisibility(event: any){
        const selectedFeederDetails = this.feederDetails[this.selectedFeeder];
        const componentIds = selectedFeederDetails?.map(item => item?.Id?.value);
        this.subscription.add(
          this.adminPanelService.postSubAssetVisibility(componentIds,event?.target?.checked ? event?.target?.checked : false).subscribe((result:any) => {
            this.showError = false;
            this.showUIMsg('SubAsset visibility updated Successfully');
          })
        )
    }

    showUIMsg(msg: string) {
        this.createStatus = true;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.showMsg = msg;
        setTimeout(() => { this.createStatus = false; }, 6000);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}