<ng-container *ngIf="isLoading">
    <div class="container text-center loading">
        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
</ng-container>
<div class="row pt-3 desktop-only">
    <div class="col-6">
        <div class="search col-5">
            <select name="sites" id="sites" class="form-control select-input" [(ngModel)]="siteId"
                (change)="siteValidation()">
                <ng-container *ngIf="sites">
                    <option [value]="site.siteId" *ngFor="let site of sites" [title]="site.siteName">
                        {{ (site.siteName.length>20)? (site.siteName | slice:0:20)+'..':(site.siteName)}}
                    </option>
                </ng-container>
            </select>
            <i class="fa fa-angle-down"></i>
        </div>
    </div>
    <div class="col-6">
        <div class="row" style="display: none;">
            <div class="search col-5">
                <input type="text" class="form-control select-input" placeholder="Search">
                <i class="fa fa-search"></i>
            </div>
            <div class="search col-5">
                <select name="cars" id="cars" class="form-control select-input">
                    <option value="select">Filter</option>
                </select>
                <i class="fa fa-angle-down"></i>
            </div>
            <div class="col-1"><i class="fa fa-print"></i></div>
            <div class="col-1"><i class="fa fa-download"></i></div>
        </div>
    </div>
</div>
<div class="row mt-2 non-desktop-only">
        <div class="search col-12">
            <select name="sites" id="sites" class="form-control select-input" [(ngModel)]="siteId" (change)="siteValidation()">
                <option [value]="site.siteId" *ngFor="let site of sites" [title]="site.siteName">
                    {{ (site.siteName.length>15)? (site.siteName | slice:0:30)+'..':(site.siteName)}}
                </option>
            </select>
            <i class="fa fa-angle-down"></i>
        </div>
</div>
<div class="row mt-2">
    <div class="overview-data">
        <div class="row mt-1">
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile">
                    <div class="card-body"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/site_details.svg)' : 'url(../../assets/images/dashboard_watermark/site_details.svg)',
                        'background-size': isDarkMode ?'60px':'',
                        'background-position': 'right 5px top 25px'
                      }">
                        <div class="dashboard-card-header col-sm-11">{{this.siteName| slice: 0:20}}</div>
                        <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                            <a class="card-menu" id="dropdownMenuLink1" role="button" data-mdb-toggle="modal"
                                data-mdb-target="#exampleModal1">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <!-- Modal -->
                            <app-popup-modal *ngIf="general && environmentData" [general]="general" [environmentData]='environmentData'
                                popupData="Site Name & Details"></app-popup-modal>
                        </div>
                        <div class=" mt-2" *ngIf="siteDetails">
                            <div class="row">
                                <div class="col-6">
                                    <div class="title-text-blue">Customer Name</div>
                                    <div class="value-text-grey">{{siteDetails.customerName}}</div>
                                </div>
                                <div class="col-6 ps-0">
                                    <div class="title-text-blue">GPS Location</div>
                                    <div class="value-text-grey">{{siteDetails.addressLine1}}</div>
                                    <div class="value-text-grey">{{siteDetails.addressLine2}}</div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-6">
                                    <div class="title-text-blue">City & Country</div>
                                    <div class="value-text-grey">{{siteDetails.city}}</div>
                                    <div class="value-text-grey">{{siteDetails.country}}</div>
                                </div>
                                <div class="col-6 ps-0">
                                    <div class="title-text-blue">Contact Details</div>
                                    <div class="value-text-grey">{{siteDetails.contactDetails}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile">
                    <div class="card-body" *ngIf="module?.AssetAndSubAsset?.canRead">
                        <h5 class="dashboard-card-header col-sm-10">Asset Snapshot</h5>
                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="value-text-md-blue">
                                    <span  *ngIf="assetSnapshot && assetSnapshot.totalAssets" matTooltip="{{assetSnapshot.totalAssets.count}}">
                                        {{ (assetSnapshot.totalAssets.count > 999) ? '999+': assetSnapshot.totalAssets.count }}</span>
                                </div>
                                <div class="title-text-grey">Total Assets</div>
                            </div>
                            <div class="col-6">
                                <div class="value-text-md-blue">
                                    <span  *ngIf="assetSnapshot && assetSnapshot.totalSubAssets" matTooltip="{{assetSnapshot.totalSubAssets.count}}">
                                        {{ (assetSnapshot.totalSubAssets.count > 999) ? '999+': assetSnapshot.totalSubAssets.count }}</span>
                                </div>
                                <div class="title-text-grey">Total Sub Assets</div>
                            </div>
                        </div>
                        <hr style="margin:0.5rem -0.7rem">
                        <div class="row mt-1">
                            <div class="col-6">
                                <div class="dashboard-card-header p-1">
                                    <span><i class="fa fa-wifi healthy me-1" style="font-size: 16px;"></i>
                                        <span *ngIf="assetSnapshot && assetSnapshot.connectedDevices"
                                            matTooltip="{{assetSnapshot.connectedDevices.count}}">
                                            {{ (assetSnapshot.connectedDevices.count > 999) ? '999+': assetSnapshot.connectedDevices.count }}</span>
                                    </span>
                                </div>
                                <div class="value-text-grey">Connected Sub Assets</div>
                            </div>
                            <div class="col-6 pe-0">
                                <div class="dashboard-card-header p-1">
                                    <span>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                            data-icon="wifi-slash" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 640 512" class="svg-inline--fa fa-wifi-slash fa-w-20 critical">
                                            <path fill="currentColor"
                                                d="M5.09 154.87c-6.66 6.16-6.79 16.59-.35 22.97l34.24 33.96c6.14 6.09 16.02 6.23 22.4.38 6.99-6.4 14.31-12.22 21.65-18.01l-64.96-50.21c-4.3 3.71-8.79 7.04-12.98 10.91zm471.75 181.9l45.42-45.21c6.52-6.46 6.29-17.06-.57-23.17-64.94-57.74-148.91-82.66-230.34-74.98l-83.16-64.27c125.94-38.36 267.75-11.01 370.43 83.05 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.96c6.44-6.39 6.3-16.82-.35-22.97C496.46 26.82 298.08-.76 133.42 71.35L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.03-2.81-22.45L476.84 336.77zm-358.53-68.38c-6.86 6.1-7.08 16.7-.57 23.17l34.28 34.01c5.97 5.93 15.59 6.32 21.94.8 13.35-11.6 28.01-20.66 43.15-28.55l-68.36-52.83c-10.48 7.15-20.74 14.78-30.44 23.4zM256 416c0 35.35 28.65 64 64 64 31.91 0 58.15-23.42 62.99-53.98l-88.7-68.56C271.77 367.37 256 389.82 256 416z"
                                                class=""></path>
                                        </svg>
                                        <span class="ms-2">
                                            <span *ngIf="assetSnapshot && assetSnapshot.notConnectedDevices"
                                            matTooltip="{{assetSnapshot.notConnectedDevices.count}}">
                                            {{ (assetSnapshot.notConnectedDevices.count > 999) ? '999+': assetSnapshot.notConnectedDevices.count }}</span>

                                        </span>
                                    </span>
                                </div>
                                <div class="value-text-grey">Non Connected Sub Assets</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead" >
                        <h5 class="dashboard-card-header col-sm-10">Asset Snapshot</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile">
                    <div class="card-body" *ngIf="module?.AlarmOrEvents?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/alarm_events.svg)' : 'url(../../assets/images/dashboard_watermark/alarm_events.svg)',
                        'background-size': isDarkMode ?'60px':'',
                        'background-position': 'right 5px top 16px;'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Alarm/Event Snapshot</h5>
                        <div class="row">
                            <div class="col-6  mt-4 text-center" style="width:40%">
                                <div class="value-text-lg-blue">
                                    <span><i class="fa fa-exclamation-triangle critical me-1" style="font-size: 20px;"></i>
                                        <span *ngIf="alarmSnapshot && alarmSnapshot.Critical" matTooltip="{{alarmSnapshot.Critical.count}}">
                                            {{ (alarmSnapshot.Critical.count > 999) ? '999+': alarmSnapshot.Critical.count }}</span>
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1" style="font-size:16px"
                                            [ngClass]="alarmSnapshot?.Critical?.delta ? (alarmSnapshot.Critical.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Critical Alarm</div>
                            </div>
                            <div class="col-6 mt-1 pe-0 ps-0" style="width:60%;overflow: hidden;">
                                <div class="row">
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue">
                                            <span><i class="fa fa-exclamation-triangle at-risk me-1" style="font-size: 12px;"></i>
                                                <span *ngIf="alarmSnapshot && alarmSnapshot.High" matTooltip="{{alarmSnapshot.High.count}}">
                                                    {{ (alarmSnapshot.High.count > 999) ? '999+': alarmSnapshot.High.count }}</span>
                                            </span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="alarmSnapshot?.High?.delta ? (alarmSnapshot.High.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="title-text-grey">High Alarm</div>
                                    </div>
                                    <div class="col-6 p-0  text-center">
                                        <div class="value-text-md-blue">
                                            <span><i class="fa fa-exclamation-triangle me-1"
                                                    style="font-size: 12px;color:#FFFF16"></i>
                                                    <span *ngIf="alarmSnapshot && alarmSnapshot.Low" matTooltip="{{alarmSnapshot.Low.count}}">
                                                        {{ (alarmSnapshot.Low.count > 999) ? '999+': alarmSnapshot.Low.count }}</span>
                                            </span>
                                            <!-- <span>
                                                <i class="fa"
                                                    [ngClass]="alarmSnapshot?.Low?.delta ? (alarmSnapshot.Low.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="title-text-grey ">Low Alarm</div>
                                    </div>
                                </div>
                                <hr style="margin:0.5rem 0rem">
                                <hr class="vertical-hr"style="margin:0 1rem; transform:rotate(90deg); width:72%;">
                                <div class="row">
                                    <div class="col-6 p-0  text-center">
                                        <div class="value-text-md-blue">
                                            <span><i class="fa fa-info-circle critical me-1" style="font-size: 12px;"></i>
                                                <span *ngIf="alarmSnapshot && alarmSnapshot.Warnings" matTooltip="{{alarmSnapshot.Warnings.count}}">
                                                    {{ (alarmSnapshot.Warnings.count > 999) ? '999+': alarmSnapshot.Warnings.count }}</span>
                                            </span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="alarmSnapshot?.Warnings?.delta ? (alarmSnapshot.Warnings.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">Warnings</div>
                                    </div>
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue me-2">
                                            <span><i class="fa fa-info-circle ipredict-blue pe-1"
                                                    style="font-size: 12px;"></i>
                                                    <span *ngIf="alarmSnapshot && alarmSnapshot.Notification" matTooltip="{{alarmSnapshot.Notification.count}}">
                                                        {{ (alarmSnapshot.Notification.count > 999) ? '999+': alarmSnapshot.Notification.count }}</span>
                                            </span>
                                            <!-- <span>
                                                <i class="fa "
                                                    [ngClass]="alarmSnapshot?.Notification?.delta ? (alarmSnapshot.Notification.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">Notification</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AlarmOrEvents?.canRead" [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/alarm_events.svg)' : 'url(../../assets/images/dashboard_watermark/alarm_events.svg)',
                        'background-position': 'right 5px top 16px;'
                  }">
                        <h5 class="dashboard-card-header col-sm-10">Alarm/Event Snapshot</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile">
                    <div class="card-body"  *ngIf="module?.DigitalMaintenanceManager?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/workpermit.svg)' : 'url(../../assets/images/dashboard_watermark/workpermit.svg)',
                        'background-size': isDarkMode ?'60px':'',
                        'background-position': 'right 3px top 30px;'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Work Permit Snapshot</h5>
                        <div class="row">
                            <div class="col-4 text-center" style="width:40%; margin-top:40px">
                                <div class="value-text-lg-blue">
                                    <span *ngIf="workPermitSnapshot && workPermitSnapshot.requested" matTooltip="{{workPermitSnapshot.requested.count}}">
                                        {{ (workPermitSnapshot.requested.count > 999) ? '999+': workPermitSnapshot.requested.count }}</span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="workPermitSnapshot?.requested?.delta ? (workPermitSnapshot.requested.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Requested</div>
                            </div>
                            <div class="col-8  mt-2 pe-0 ps-0" style="overflow:hidden;width:60%">
                                <div class="row">
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue">
                                            <span *ngIf="workPermitSnapshot && workPermitSnapshot.open" matTooltip="{{workPermitSnapshot.open.count}}">
                                                {{ (workPermitSnapshot.open.count > 999) ? '999+': workPermitSnapshot.open.count }}</span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="workPermitSnapshot?.open?.delta ? (workPermitSnapshot.open.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="title-text-grey">Open</div>
                                    </div>
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue">
                                            <span *ngIf="workPermitSnapshot && workPermitSnapshot.closed" matTooltip="{{workPermitSnapshot.closed.count}}">
                                                {{ (workPermitSnapshot.closed.count > 999) ? '999+': workPermitSnapshot.closed.count }}</span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="workPermitSnapshot?.closed?.delta ? (workPermitSnapshot.closed.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="title-text-grey">Closed</div>
                                    </div>
                                </div>
                                <hr style="margin:0.5rem 0rem">
                                <hr style="margin:0.2rem 0rem; transform:rotate(90deg); ">
                                <div class="row">
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue">
                                            <span *ngIf="workPermitSnapshot && workPermitSnapshot.approved" matTooltip="{{workPermitSnapshot.approved.count}}">
                                                {{ (workPermitSnapshot.approved.count > 999) ? '999+': workPermitSnapshot.approved.count }}</span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="workPermitSnapshot?.approved?.delta ? (workPermitSnapshot.approved.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">Approved</div>
                                    </div>
                                    <div class="col-6 p-0 text-center">
                                        <div class="value-text-md-blue">
                                            <span *ngIf="workPermitSnapshot && workPermitSnapshot.cancelled" matTooltip="{{workPermitSnapshot.cancelled.count}}">
                                                {{ (workPermitSnapshot.cancelled.count > 999) ? '999+': workPermitSnapshot.cancelled.count }}</span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="workPermitSnapshot?.cancelled?.delta ? (workPermitSnapshot.cancelled.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">Cancelled</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.DigitalMaintenanceManager?.canRead" [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/workpermit.svg)' : 'url(../../assets/images/dashboard_watermark/workpermit.svg)',
                        'background-size': isDarkMode ?'60px':'',
                        'background-position': 'right 3px top 30px;'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Work Permit Snapshot</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-xl-6">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card dashboard-chart-tile">
                            <div class="card-body" *ngIf="module?.DigitalMaintenanceManager?.canRead"
                                [ngStyle]="{
                    'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)' : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                  }">
                                <!-- <app-stackedVertical-chart chartTitle="Maintenance Summary" apiEndPoint="summary"
                                    [maintenanceSummaryList]='maintenanceSummaryList' [siteId]="siteId" [licenseCheck]="licenseCheck">
                                </app-stackedVertical-chart> -->
                                <app-stacked-column-chart chartTitle="Maintenance Summary" apiEndPoint="summary" [maintenanceSummaryList]='maintenanceSummaryList' [siteId]="siteId" [licenseCheck]="licenseCheck"></app-stacked-column-chart>
                            </div>
                            <div class="card-body" *ngIf="!module?.DigitalMaintenanceManager?.canRead" [ngStyle]="{
                    'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)' : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                  }">
                                <h5 class="dashboard-card-header col-sm-10">Maintenance Summary</h5>
                                <div class="row access-error mt-5">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card dashboard-chart-tile">
                            <div class="card-body" *ngIf="module?.DigitalMaintenanceManager?.canRead"
                                [ngStyle]="{
                    'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)' : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                  }">
                                <app-stacked-column-chart chartTitle="Obsolescence Profile"
                                apiEndPoint="obsolenscenceprofile"
                                [obsolenscenceProfileList]='obsolenscenceProfileList' [siteId]="siteId" [licenseCheck]="licenseCheck"></app-stacked-column-chart>
                                <!-- <app-stackedVertical-chart chartTitle="Obsolescence Profile"
                                    apiEndPoint="obsolenscenceprofile"
                                    [obsolenscenceProfileList]='obsolenscenceProfileList' [siteId]="siteId" [licenseCheck]="licenseCheck">
                                </app-stackedVertical-chart> -->
                            </div>
                            <div class="card-body" *ngIf="!module?.DigitalMaintenanceManager?.canRead" [ngStyle]="{
                    'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_breakdown.svg)' : 'url(../../assets/images/dashboard_watermark/asset_breakdown.svg)'
                  }">
                                <h5 class="dashboard-card-header col-sm-10">Obsolescence Profile</h5>
                                <div class="row access-error mt-5">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-xl-6">
                <div class="row">
                    <!-- <div class="col-sm-6">
                        <div class="card dashboard-tile">
                            <div class="card-body" *ngIf="module?.AssetAndSubAsset?.canRead"
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)' : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                                'background-position': 'right 25px top 25px;'
                              }">
                                <h5 class="dashboard-card-header col-sm-10">Asset Health Profile</h5>
                                <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                                    <a class="card-menu" id="dropdownMenuLink7" role="button" data-mdb-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </a>
                                    <ul class="dropdown-menu pop-up-style" aria-labelledby="dropdownMenuLink7">
                                        <div class="list-card-header">Details</div>
                                        <li class="liststyle"><a target="_blank" [routerLink]="['../assets']" [queryParams]="{siteId: siteId}" class="dropdown-item">List View</a></li>
                                        <li class="liststyle"><a class="dropdown-item">Download Report</a></li>
                                    </ul>
                                </div>
                                <div class="row mt-4 pt-3">
                                    <div class="col-4 mt-1 text-center px-0">
                                        <div class="value-text-lg-blue">
                                            <span class="healthy" *ngIf="assetHealth && assetHealth.healthy" matTooltip="{{assetHealth.healthy.count}}">
                                                {{ (assetHealth.healthy.count > 99) ? '99+': assetHealth.healthy.count }}</span>
                                            <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="assetHealth?.healthy?.delta ? (assetHealth.healthy.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span>
                                        </div>
                                        <div class="value-text-grey">Healthy</div>
                                    </div>
                                    <div class="col-4 mt-1 text-center px-0">
                                        <div class="value-text-lg-blue">
                                            <span class="at-risk" *ngIf="assetHealth && assetHealth.risk" matTooltip="{{assetHealth.risk.count}}">
                                                {{ (assetHealth.risk.count > 99) ? '99+': assetHealth.risk.count }}
                                            </span>
                                            <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="assetHealth?.risk?.delta ? (assetHealth.risk.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span>
                                        </div>
                                        <div class="value-text-grey">At Risk</div>
                                    </div>
                                    <div class="col-4 mt-1 text-center">
                                        <div class="value-text-lg-blue">
                                            <span class="critical" *ngIf="assetHealth && assetHealth.critical" matTooltip="{{assetHealth.critical.count}}">
                                                {{ (assetHealth.critical.count > 99) ? '99+': assetHealth.critical.count }}
                                            </span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="assetHealth?.critical?.delta ? (assetHealth.critical.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span>
                                        </div>
                                        <div class="value-text-grey">Critical</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead" 
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_health.svg)' : 'url(../../assets/images/dashboard_watermark/asset_health.svg)',
                                'background-position': 'right 25px top 25px;'
                              }">
                                <h5 class="dashboard-card-header col-sm-10">Asset Health Profile</h5>
                                <div class="row access-error mt-3">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-sm-6">
                        <mat-card class="healthy-bg">
                            <mat-card-title class="card-title">
                                {{assetHealthCard?.CardName}}
                            </mat-card-title>
                            <mat-card-content>
                                <div class="row content-center-align">
                                    <div class="col-4 py-2" *ngFor="let data of assetHealthCard?.CardData">
                                        <div class="count" [ngClass]="data?.Name" matTooltip="{{data?.Count}}">{{data?.Count > 999 ? '999+' : data?.Count}}</div>
                                        <div class="label">{{data?.Name}}</div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-sm-6">
                        <div class="card dashboard-tile">
                            <div class="card-body"  *ngIf="module?.DigitalMaintenanceManager?.canRead"
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/upcoming_maintenance.svg)' : 'url(../../assets/images/dashboard_watermark/upcoming_maintenance.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-8">Upcoming Maintenance</h5>
                                <div class="parent dropstart" style="position: absolute;top: 5px;right: 9px;">
                                    <span class="" id="dropdownMenuLink8" data-mdb-toggle="dropdown"><span
                                            class="value-text-grey"
                                            style="top: 0px;right: 4px;position: relative;font-size:14px">
                                            {{ selectedAlarmMenuOption == 0 ? 'Today': ( 'Next ' +
                                            selectedAlarmMenuOption + '
                                            days')}}
                                        </span> <i class="fa fa-angle-down"></i></span>
                                    <ul class="dropdown-menu pop-up-style" aria-labelledby="dropdownMenuLink8">
                                        <li *ngFor="let option of maintenanceMenu">
                                            <a class="dropdown-item"
                                                (click)="getUpcomingMaintenanceForSite(siteId, option)">{{option == 0 ?
                                                'Today':( 'Next ' + option + ' days') }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="upcomingMaintenance" class="mt-3 mb-2 pe-2" style="height:90px;overflow-y:scroll;color:#000">
                                    <table class="table table-small upcoming-maintenance">
                                        <tbody *ngIf="upcomingMaintenance.length > 0">
                                            <tr *ngFor="let item of upcomingMaintenance; let i =index">
                                                <td>{{item.assetName | slice:0:15 }}</td>
                                                <td><a class="ptwLink" [routerLink]="['/layout/DMM/workPermit', item.ptwIdNumber, 'read']">{{ item.ptwId }}</a></td>
                                                <td>{{item.maintenanceDate| date: 'd/M/yyyy'}}</td>
                                                <td>{{item.maintenanceDate| date: 'mediumTime'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <div *ngIf="upcomingMaintenance.length  === 0" class="critical text-center">There is no upcoming maintenance </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="!module?.DigitalMaintenanceManager?.canRead" 
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/upcoming_maintenance.svg)' : 'url(../../assets/images/dashboard_watermark/upcoming_maintenance.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-10">Upcoming Maintenance</h5>
                                <div class="row access-error mt-3">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="height:50%">
                    <div class="col-sm-6">
                        <div class="card dashboard-tile">
                            <div class="card-body"  *ngIf="module?.HealthKpi?.canRead"
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_warranty.svg)' : 'url(../../assets/images/dashboard_watermark/asset_warranty.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-10">Asset Risk Profile</h5>
                                <app-subasset-matrix [subAssetRiskMatrix]="subAssetRiskMatrix"></app-subasset-matrix>
                            </div>
                            <div class="card-body" *ngIf="!module?.HealthKpi?.canRead" 
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_warranty.svg)' : 'url(../../assets/images/dashboard_watermark/asset_warranty.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-10">Asset Risk Profile</h5>
                                <div class="row access-error mt-3">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card dashboard-tile">
                            <div class="card-body"  *ngIf="module?.AssetAndSubAsset?.canRead"
                            [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_warranty.svg)' : 'url(../../assets/images/dashboard_watermark/asset_warranty.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-10">Asset Warranty</h5>
                                <div *ngIf="(assetWarranty | json) == '{}'" class="critical text-center">No data </div>
                                <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                                    <a class="card-menu" id="dropdownMenuLink8" role="button" data-mdb-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </a>
                                    <ul class="dropdown-menu pop-up-style" aria-labelledby="dropdownMenuLink8">
                                        <div class="list-card-header">Details</div>
                                        <li class="liststyle"><a target="_blank" [routerLink]="['../assets']"
                                                [queryParams]="{filterKey: 'WarrantyStatus', filterValue: 'Out Of Warranty', siteId: siteId}"
                                                class="dropdown-item">List View</a></li>
                                        <li class="liststyle"><a class="dropdown-item">Download Report</a></li>
                                    </ul>
                                </div>
                                <div *ngIf="(assetWarranty | json) !== '{}'"class="row mt-4 ">
                                    <div class="col-5 p-2 text-center " style="font-weight:bold">
                                        <div class="value-text-lg-blue">
                                            <span *ngIf="assetWarranty && assetWarranty.inWarranty" matTooltip="{{assetWarranty.inWarranty.count}}">
                                                {{ (assetWarranty.inWarranty.count > 999) ? '999+': assetWarranty.inWarranty.count }}
                                            </span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="assetWarranty?.inWarranty?.delta ? (assetWarranty.inWarranty.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">In Warranty</div>
                                    </div>
                                    <div class="col-1 ">

                                    </div>
                                    <div class="col-5 p-2 text-center">
                                        <div class="value-text-lg-blue">
                                            <span *ngIf="assetWarranty && assetWarranty.outOfWarranty" matTooltip="{{assetWarranty.outOfWarranty.count}}">
                                                {{ (assetWarranty.outOfWarranty.count > 999) ? '999+': assetWarranty.outOfWarranty.count }}
                                            </span>
                                            <!-- <span>
                                                <i class="fa ms-1"
                                                    [ngClass]="assetWarranty?.outOfWarranty?.delta ? (assetWarranty.outOfWarranty.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                            </span> -->
                                        </div>
                                        <div class="value-text-grey">Out of Warranty</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead" 
                             [ngStyle]="{
                                'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/asset_warranty.svg)' : 'url(../../assets/images/dashboard_watermark/asset_warranty.svg)','background-position': 'right 15px top 39px'}">
                                <h5 class="dashboard-card-header col-sm-10">Asset Warranty</h5>
                                <div class="row access-error mt-3">
                                    <div class="col text-center">You don't have enough privileges to view this information</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile ">
                    <div class="card-body"  *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/suggestions.svg)' : 'url(../../assets/images/dashboard_watermark/suggestions.svg)','background-position': 'right 15px top 39px'}">
                        <h5 class="dashboard-card-header col-sm-10">Suggestions</h5>
                        <div *ngIf="(siteSuggestion | json) == '{}'" class="critical text-center">No data </div>
                        <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                            <a class="card-menu" id="dropdownMenuLink10" role="button" data-mdb-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <ul class="dropdown-menu pop-up-style" aria-labelledby="dropdownMenuLink10"><div
                                    class="list-card-header">Details</div>
                                <li class="liststyle">
                                    <a class="dropdown-item" data-mdb-toggle="modal" data-mdb-target="#exampleModal">
                                        List View
                                    </a>
                                </li>
                                <li class="liststyle"><a class="dropdown-item">Download Report</a></li>
                            </ul>
                            <!-- Modal -->
                            <app-popup-modal [suggestionsAssetList]='suggestionsAssetList' popupData="Suggestions">
                            </app-popup-modal>
                        </div>
                        <div *ngIf="(siteSuggestion | json) !== '{}'" class="row mt-1 align-items-center dashboard-tile-data">
                            <div class="col-4 text-center">
                                <div class="value-text-lg-blue">
                                    <span *ngIf="siteSuggestion && siteSuggestion.modernizeOfAssets" matTooltip="{{siteSuggestion.modernizeOfAssets.count}}">
                                        {{ (siteSuggestion.modernizeOfAssets.count > 999) ? '999+': siteSuggestion.modernizeOfAssets.count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="siteSuggestion?.modernizeOfAssets?.delta ? (siteSuggestion.modernizeOfAssets.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Modernize of Assets</div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="value-text-lg-blue">
                                    <span *ngIf="siteSuggestion && siteSuggestion.renewalOfAmc" matTooltip="{{siteSuggestion.renewalOfAmc.count}}">
                                        {{ (siteSuggestion.renewalOfAmc.count > 999) ? '999+': siteSuggestion.renewalOfAmc.count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="siteSuggestion?.renewalOfAmc?.delta ? (siteSuggestion.renewalOfAmc.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Renewal</div> 
                                <div class="value-text-grey">of AMC</div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="value-text-lg-blue">
                                    <span *ngIf="siteSuggestion && siteSuggestion.requestForNewAmc" matTooltip="{{siteSuggestion.requestForNewAmc.count}}">
                                        {{ (siteSuggestion.requestForNewAmc.count > 999) ? '999+': siteSuggestion.requestForNewAmc.count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="siteSuggestion?.requestForNewAmc?.delta ? (siteSuggestion.requestForNewAmc.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Request for New AMC</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/suggestions.svg)' : 'url(../../assets/images/dashboard_watermark/suggestions.svg)','background-position': 'right 15px top 39px'}">
                        <h5 class="dashboard-card-header col-sm-10">Suggestions</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile ">
                    <div class="card-body" *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/balance_life.svg)' : 'url(../../assets/images/dashboard_watermark/balance_life.svg)'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Balance Life</h5>
                        <div *ngIf="(assetAgeTemp | json) == '{}'" class="critical text-center">No data </div>
                        <div *ngIf="assetAgeTemp && (assetAgeTemp | json) !== '{}'" class="row mt-1 dashboard-tile-data align-items-center">
                            <div class="col-4 text-center"  *ngFor="let age of assetAgeTemp | keyvalue">
                                <div class="value-text-lg-blue" *ngIf="age.value">
                                    <span  matTooltip="{{age.value.count}}">{{age.value.count | json}}</span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="age.value.delta ? (age.value.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">{{ age.key }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead"
                    style="background-image: url('../../assets/images/dashboard_watermark/balance_life.svg');">
                        <h5 class="dashboard-card-header col-sm-10">Balance Life</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile ">
                    <div class="card-body" *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/voltage_distribution.svg)' : 'url(../../assets/images/dashboard_watermark/voltage_distribution.svg)'
                      }">
                        <h5 class="dashboard-card-header col-sm-11">Voltage Wise Distribution</h5>
                        <div *ngIf="(voltageDistribution | json) == '{}'" class="critical text-center">No data </div>
                        <div *ngIf="(voltageDistribution | json) !== '{}'"class="row mt-1 align-items-center dashboard-tile-data">
                            <div class="col-4 text-center mt-2" *ngFor="let voltage of voltageDistribution | keyvalue"
                                style="text-align:center;">
                                <div class="value-text-lg-blue-2">
                                    <span class=""  matTooltip="{{voltage.value.count}}">{{voltage.value.count | json}}</span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="voltage.value.delta ? (voltage.value.delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey" style="text-align:center;">{{ voltage.key }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/voltage_distribution.svg)' : 'url(../../assets/images/dashboard_watermark/voltage_distribution.svg)'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Voltage Wise Distribution</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xl-3">
                <div class="card dashboard-tile">
                    <div class="card-body" *ngIf="module?.AssetAndSubAsset?.canRead"
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/contract_status.svg)' : 'url(../../assets/images/dashboard_watermark/contract_status.svg)'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Contract Status</h5>
                        <div *ngIf="(contractStatus | json) == '{}'" class="critical text-center">No data </div>
                        <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                            <a class="card-menu" id="dropdownMenuLink9" role="button" data-mdb-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <ul class="dropdown-menu pop-up-style" aria-labelledby="dropdownMenuLink9">
                                <div class="list-card-header">Details</div>
                                <li class="liststyle"><a target="_blank" [routerLink]="['../assets']"
                                        [queryParams]="{filterKey: 'ContractStatus', filterValue: 'Contract Expire', siteId: siteId}"
                                        class="dropdown-item">List View</a></li>
                                <li class="liststyle"><a class="dropdown-item">Download Report</a></li>
                            </ul>
                        </div>
                        <div class="row mt-4" *ngIf="contractStatus && (contractStatus | json) !== '{}'">
                            <div class="col-4 ps-0 pe-0 text-center">
                                <div class="value-text-lg-blue-2">
                                    <span *ngIf="contractStatus && contractStatus['Under Contract']" matTooltip="{{contractStatus['Under Contract'].count}}">
                                        {{ (contractStatus['Under Contract'].count > 999) ? '999+': contractStatus['Under Contract'].count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="contractStatus['Under Contract'].delta ? (contractStatus['Under Contract'].delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Under </div>
                                <div class="value-text-grey"> Contract</div>
                            </div>
                            <div class="col-4 pe-0 ps-0 text-center">
                                <div class="value-text-lg-blue-2">
                                    <span *ngIf="contractStatus && contractStatus['Contract Expired']" matTooltip="{{contractStatus['Contract Expired'].count}}">
                                        {{ (contractStatus['Contract Expired'].count > 999) ? '999+': contractStatus['Contract Expired'].count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="contractStatus['Contract Expired'].delta ? (contractStatus['Contract Expired'].delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Contract </div>
                                <div class="value-text-grey"> Expired</div>
                            </div>
                            <div class="col-4 ps-0 pe-0 text-center">
                                <div class="value-text-lg-blue-2 ">
                                    <span *ngIf="contractStatus && contractStatus['Not in Contract']" matTooltip="{{contractStatus['Not in Contract'].count}}">
                                        {{ (contractStatus['Not in Contract'].count > 999) ? '999+': contractStatus['Not in Contract'].count }}
                                    </span>
                                    <!-- <span>
                                        <i class="fa ms-1"
                                            [ngClass]="contractStatus['Not in Contract'].delta ? (contractStatus['Not in Contract'].delta > 0 ? 'fa-arrow-up arrow-up' : 'fa-arrow-down arrow-down') : ''"></i>
                                    </span> -->
                                </div>
                                <div class="value-text-grey">Not in</div>
                                <div class="value-text-grey">contract</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="!module?.AssetAndSubAsset?.canRead" 
                    [ngStyle]="{
                        'background-image': isDarkMode ? 'url(../../assets/images/dark-theme-icons/dashboard_watermark/contract_status.svg)' : 'url(../../assets/images/dashboard_watermark/contract_status.svg)'
                      }">
                        <h5 class="dashboard-card-header col-sm-10">Contract Status</h5>
                        <div class="row access-error mt-3">
                            <div class="col text-center">You don't have enough privileges to view this information</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
