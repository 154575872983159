<!-- Modal -->
<div class="modal fade" data-mdb-backdrop="static" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content h-100">
            <div class="modal-header text-white blue-bg">
                <h5 class="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
            </div>
            <div class="modal-body">
                    <iframe id="termsUse" src="https://ipredictstoragedev.blob.core.windows.net/termsandcondition/TermsAndConditions.pdf#navpanes=0" style="width:100%;height:100%"></iframe>
            </div>
            <div class="modal-footer">
                <div class="float-left">
                    <div class="terms form-check d-flex float-start mb-4">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="agree"  id="form1Example3" />
                        <label class="form-check-label" for="form1Example3"> I have read and agree to SmartComm
                            <a (click)="openTermsOfUse('privacyPolicy')" role="button">Privacy Policy</a>,
                            <a (click)="openTermsOfUse('privacyNotice')" role="button">Privacy Notice</a> and
                            Terms & Conditions </label>
                    </div>
                </div>
                <div class="float-right" *ngIf="agree">
                    <button type="button" class="btn bg-black text-white" (click)="updateValue(agree)" data-mdb-dismiss="modal">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <h2 mat-dialog-title>Terms & Conditions</h2>
<mat-dialog-content class="mat-typography">
  <iframe src="https://ipredictstoragedev.blob.core.windows.net/termsandcondition/TermsAndConditions.pdf" style="width:100%;height:100%"></iframe>
</mat-dialog-content> -->
