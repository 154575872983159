<div class="calculate-card" [ngClass]="isLoading ? 'disable' : ''">
    <div class="card-body p-1">
        <div class="card-title trend-header row">
            <div class="col-lg-10 col-9 title">What-If Simulation</div>
            <div class="col-lg-2 col-3 flex-right">
                <span class="ms-3"><i class="fa fa-times" role="button" style="font-size: 18px;color:gray"
                        (click)="closeWindow()"></i></span>
            </div>
        </div>
        <div class="card-text px-3 outer-container">
            <div class="row">
                <div class="col-lg-2 col-12 mt-2">
                    <div class="content-header">Deferred Maintenance Date</div>
                    <div class="content-header">
                        <input type="date" class="calculate-card-input" [(ngModel)]="maintenanceDate" [min]="currentDate">
                    </div>
                </div>
                <!-- <div class="col-lg-8 col-12 mt-2">
                    <div class="content-header">Action</div>
                    <div class="card action-card">
                        <div class="card-body px-3 py-2">
                            <div class="card-text">
                                <form [formGroup]="form">
                                    <label class="form-check-label w-100" formArrayName="actions"
                                        *ngFor="let action of actionsFormArray.controls; let i = index">
                                        <input type="checkbox" class=" form-check-input" [formControlName]="i">
                                        {{recommendations[i]?.actionDescription}}
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> -->
                <div class="col-lg-5 col-12 mt-3">
                    <div class="card condition-card">
                        <div class="card-body p-0 w-100">
                            <div class="card-text w-100 pb-1">
                                <div class="row p-3">
                                    <div class="col-lg-5 col-12">
                                        <div class="condition-card-header">Observed Condition</div>
                                        <div class="w-100">
                                            <select name="conditions" id="conditionso" class="calculate-card-input"
                                                (change)="onObservedConditionChange($event)" role="button"
                                                [(ngModel)]="selectedObservedCondition">
                                                <option [value]='{}' style="color:gray" selected disabled>Select
                                                    Condition</option>
                                                <option [ngValue]="condition"
                                                    *ngFor="let condition of observedConditionList"
                                                    [title]="condition.observedCondition">
                                                    {{ (condition.observedCondition.length>20)?
                                                    (condition.observedCondition |
                                                    slice:0:20)+'..':(condition.observedCondition)}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="condition-card-header">Criteria</div>
                                        <div class="w-100 d-flex">
                                            <select name="criteria" id="criteria" class="calculate-card-input"
                                                role="button" [(ngModel)]="selectedObservedCriteria">
                                                <option [value]='{}' style="color:gray" selected disabled>Select
                                                    Criteria</option>
                                                <option [ngValue]="item" *ngFor="let item of observedCriteriaList"
                                                    [title]="item.observedCondition">
                                                    {{ (item.observedCondition.length>20)? (item.observedCondition |
                                                    slice:0:20)+'..':(item.observedCondition)}}
                                                </option>
                                            </select>
                                            <button class="mx-2 condition-card-header" (click)="addToObservationList()"
                                                [disabled]="!(this.selectedObservedCondition?.id && selectedObservedCriteria?.id)">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="list-group list-group-light list-group-small">
                                            <li class="list-group-item mx-2" *ngFor="let item of observationList">
                                                <div class="col-lg-6 col-5">{{item.condition}}</div>
                                                <div class="col-lg-6 col-6 offset-1 offset-lg-0">{{item.criteria}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-12 mt-3">
                    <div class="card condition-card">
                        <div class="card-body p-0 w-100">
                            <div class="card-text w-100 pb-1">
                                <div class="row p-3">
                                    <div class="col-lg-5 col-12">
                                        <div class="condition-card-header">Measured Condition</div>
                                        <div class="w-100">
                                            <select name="conditions" id="conditions" class="calculate-card-input"
                                                [(ngModel)]="selectedMeasuredCondition" role="button"
                                                (change)="onMeasuredConditionChange($event)">
                                                <option [value]='{}' style="color:gray" selected disabled>Select
                                                    Condition</option>
                                                <option [ngValue]="condition"
                                                    *ngFor="let condition of measuredConditionList"
                                                    [title]="condition.measuredCondition">
                                                    {{ (condition.measuredCondition.length>20)?
                                                    (condition.measuredCondition |
                                                    slice:0:20)+'..':(condition.measuredCondition)}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="condition-card-header">Criteria</div>
                                        <div class="w-100 d-flex">
                                            <select name="criteria" id="criteria" class="calculate-card-input"
                                                role="button" [(ngModel)]="selectedMeasuredCriteria" *ngIf="!(selectedMeasuredCondition?.measuredCondition?.includes('Operations'))">
                                                <option [value]='{}' style="color:gray" selected disabled>Select
                                                    Criteria</option>
                                                <option [ngValue]="item" *ngFor="let item of measuredCriteriaList"
                                                    [title]="item.measuredCondition">
                                                    {{ (item.measuredCondition.length>20)? (item.measuredCondition |
                                                    slice:0:20)+'..':(item.measuredCondition)}}
                                                </option>
                                            </select>
                                            <input type="number" max="99999" id="" class="w-100" *ngIf="selectedMeasuredCondition?.measuredCondition?.includes('Operations')" (change)="setMeasuredCriteria($event)">
                                            <button class="mx-2 condition-card-header" (click)="addToMeasuredList()"
                                                [disabled]="!((this.selectedMeasuredCondition?.id && selectedMeasuredCriteria?.id) || selectedMeasuredCriteria?.criteriaValue)">Add</button>
                                        </div>


                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="list-group list-group-light list-group-small">
                                            <li class="list-group-item mx-2" *ngFor="let item of measuredList">
                                                <div class="col-lg-6 col-5">{{item.condition}}</div>
                                                <div class="col-lg-6 col-6 offset-1 offset-lg-0">{{item.criteriaValue || item.criteria}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="float-end">
                        <span class="me-1" role="button" (click)="reset()">Reset</span>
                        <span class="ms-2">
                            <button class="btn-primary" (click)="calculate()"
                                [disabled]="!(maintenanceDate?.length)">Calculate</button>
                        </span>
                        <span class="ms-2">
                            <button class="download-btn" (click)="downloadCalculated()" *ngIf="(maintenanceDate?.length && measuredList?.length && observationList?.length) && calculated">
                                <img src="../../assets/images/download.svg" alt="" height="21"
                                    class="me-1">Download</button>
                        </span>
                    </div>
                </div>
            </div>
            <hr style="height: 1.5px;">
            <div class="row" *ngIf="chartData">
                <div class="col-12 px-3 mt-3 calculated-index px-lg-5"><h5>Present</h5></div>
                <div class="col-lg-6 col-12 px-3 calculated-index px-lg-5">
                    <div class="card-title main-title">Sub Asset</div>
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body px-2 px-lg-3">
                            <div class="card-title health-kpi-header">Health Index</div>
                            <div class="card-text" *ngIf="chartData.feederPastSimulation.healthIndex">
                                <app-index-chart [target]="target" [chartName]="'present-subasset-health'"
                                [chartData]="chartData.feederPastSimulation.healthIndex"></app-index-chart>
                            </div>
                            <div class="card-title health-kpi-header">Risk Index</div>
                            <div class="card-text" *ngIf="chartData.feederPastSimulation.riskIndex">
                                <app-index-chart [target]="target" [chartName]="'present-subasset-risk'"
                                    [chartData]="chartData.feederPastSimulation.riskIndex"></app-index-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 px-3 calculated-index px-lg-5">
                    <div class="card-title main-title">Asset</div>
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body px-2 px-lg-3">
                            <div class="card-title health-kpi-header">Health Index</div>
                            <div class="card-text" *ngIf="chartData.assetPastSimulation.healthIndex">
                                <app-index-chart [target]="target" [chartName]="'present-asset-health'"
                                [chartData]="chartData.assetPastSimulation.healthIndex"></app-index-chart>
                            </div>
                            <div class="card-title health-kpi-header">Risk Index</div>
                            <div class="card-text" *ngIf="chartData.assetPastSimulation.riskIndex">
                                <app-index-chart [target]="target" [chartName]="'present-asset-risk'"
                                    [chartData]="chartData.assetPastSimulation.riskIndex"></app-index-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="chartData">
                <div class="col-12 px-3 mt-3 calculated-index px-lg-5"><h5>Future</h5></div>
                <div class="col-lg-6 col-12 px-3 calculated-index px-lg-5">
                    <div class="card-title main-title">Sub Asset</div>
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body px-2 px-lg-3">
                            <div class="card-title health-kpi-header">Health Index</div>
                            <div class="card-text" *ngIf="chartData.feederFutureSimulation.healthIndex">
                                <app-index-chart [target]="target" [chartName]="'future-subasset-health'"
                                [chartData]="chartData.feederFutureSimulation.healthIndex"></app-index-chart>
                            </div>
                            <div class="card-title health-kpi-header">Risk Index</div>
                            <div class="card-text" *ngIf="chartData.feederFutureSimulation.riskIndex">
                                <app-index-chart [target]="target" [chartName]="'future-subasset-risk'"
                                    [chartData]="chartData.feederFutureSimulation.riskIndex"></app-index-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 px-3 calculated-index px-lg-5">
                    <div class="card-title main-title">Asset</div>
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body px-2 px-lg-3 px-lg-3">
                            <div class="card-title health-kpi-header">Health Index</div>
                            <div class="card-text" *ngIf="chartData.assetFutureSimulation.healthIndex">
                                <app-index-chart [target]="target" [chartName]="'future-asset-health'"
                                [chartData]="chartData.assetFutureSimulation.healthIndex"></app-index-chart>
                            </div>
                            <div class="card-title health-kpi-header">Risk Index</div>
                            <div class="card-text" *ngIf="chartData.assetFutureSimulation.riskIndex">
                                <app-index-chart [target]="target" [chartName]="'future-asset-risk'"
                                    [chartData]="chartData.assetFutureSimulation.riskIndex"></app-index-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isLoading">
    <div class="container text-center mt-5 loader">
        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
</ng-container>