import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Chart } from '../_models/register.type';
import { map } from 'rxjs/operators';
import { General } from '../_models/overview/site-maintenance-list.type';

@Injectable({
    providedIn: 'root'
})
export class SiteManagementService {
    baseUrl = environment.apiURL;

    constructor(private http: HttpClient) { }
    getSites(): any {
        return this.http.get<General>(`${this.baseUrl}/sitemanagement/Site`);
    }

    getSitesDropdown(includeAll = false): Observable<any> {
        let url = `${this.baseUrl}/sitemanagement/Site/siteDropdown`;
        if (includeAll) {
            url += '?includeAll=true'
        }
        return this.http.get<General>(url);
    }

    uploadTemplateData(assetId: number, file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file[0]);
        const url = `${this.baseUrl}/assetmanagement/Asset/upsert/subassetIb/sheet/${assetId}`;
        return this.http.post<any>(url, formData);
    }

    getSitesForCustomerId(customerId: number): any {
        return this.http.get<General>(`${this.baseUrl}/sitemanagement/Site/${customerId}`).pipe(catchError(error => of(error)));
    }

    getSitesByCustomers(selectedCustomerIds: any) {
        const url = `${this.baseUrl}/sitemanagement/Site/sitesByCustomers`;
        return this.http.post<any>(url, selectedCustomerIds).pipe(catchError(error => of(error)));
    }

    getSiteLocations(id: string): any {
        return this.http.get(`${this.baseUrl}/sitemanagement/sitelocations`);
    }

    getSiteOverviewPanelHeader(): any {
        return this.http.get(`${this.baseUrl}/sitemanagement/Dashboard`);
    }

    getSiteOverviewPanelData(): Observable<any> {
        return this.http.get(`${this.baseUrl}/sitemanagement/Overview/menu`);
    }

    getSiteOverviewData(pageNumber: number, pageSize: number, searchText = null): Observable<any> {
        let url = `${this.baseUrl}/sitemanagement/Overview/menuDetails/${pageNumber}/${pageSize}`;
        if (searchText) {
            const searchString = encodeURIComponent(searchText);
            url += `?searchString=${searchString}`;
        }
        return this.http.get(url);
    }

    getSiteOverviewFilteredPanelData(siteId: number, substationIds: any): Observable<any> {
        const url = `${this.baseUrl}/sitemanagement/Overview/menu/site?siteId=${siteId}`;
        return this.http.post<any>(url, substationIds).pipe(catchError(error => of(error)));
    }

    getSiteById(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/sitemanagement/Overview/` + id).pipe(catchError(error => of(error)));
    }

    getAssetSnapshotForSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/assetmanagement/Overview/snapshot/` + id).pipe(catchError(error => of(error)));
    }

    getAlarmSnapshotForSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/alarm/Overview/${id}`).pipe(catchError(error => of(error)));
    }

    getWorkPermitSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/workpermit/` + id).pipe(catchError(error => of(error)));
    }

    getUpcomingMaintenanceForSite(id: string, option: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/upcoming/${id}/${option}`);
    }

    getAssetWarrantySite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/assetswarranty/${id}`).pipe(catchError(error => of(error)))
    }

    getContractStatusSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/contractstatus/${id}`).pipe(catchError(error => of(error)))
    }

    getAssetAgeSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/assetage/${id}`).pipe(catchError(error => of(error)))
    }

    getOverviewCards(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/${id}`).pipe(catchError(error => of(error)))
    }

    getAssetHealthSite(id: string): Observable<any> {
        // return this.http.get(`${this.baseUrl}/alarm/Overview/healthprofile/${id}`).pipe(catchError(error => of(error)))
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/assetHealthProfile/${id}`).pipe(catchError(error => of(error)))
    }

    getSuggestionsSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/suggestions/${id}`).pipe(catchError(error => of(error)))
    }

    getVoltageDistributionSite(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/distributions/${id}`).pipe(catchError(error => of(error)))
    }

    getSample1Site(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/sample1/${id}`);
    }

    getSiteObsolenscence(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/obsolenscenceprofile/${id}`);
    }

    getSiteMaintenance(id: string, option: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/summary/${id}/${option}`);
    }

    getStackedVerticalCharts(siteId: string, category: string): Observable<any> {
        return this.http.get<Chart>(`${this.baseUrl}/maintenanceworkflow/Overview/${category}/${siteId}`).pipe(
            map((chartData: any) => {
                return chartData;
            }),
            catchError((error: any) => {
                return of(error);
            })
        )
    }

    //   getAssetDetailsForSite(siteId: number, subStation: number): Observable<any> {
    //     const url = `${this.baseUrl}/assetmanagement/Overview/assetDetails/${siteId}/${subStation}`
    //     return this.http.get(url);
    //   }

    //   getAssetsForSubstations(siteId: number, subStations: string): Observable<any> {
    //     let url =  `${this.baseUrl}/assetmanagement/Overview/assetDetailsByMultipleSites?siteId=${siteId}`
    //     if(subStations && subStations.length) {
    //       url = url + '&subStationIds='
    //       url = url + subStations;
    //     } else {
    //       url = `${this.baseUrl}/assetmanagement/Overview/assetDetails/${siteId}/0`
    //     }
    //     return this.http.get(url);
    //   }

    getAssetDetails(siteId: number, pageNumber: number, pageSize: number, payload: any = null, headerPayload: any = null, searchText = null, isHeaderUpdate = false): Observable<any> {
        let headerUrl = `${this.baseUrl}/assetmanagement/Overview/asset/headerDetails/${siteId}/${pageNumber}/${pageSize}`;
        let url = `${this.baseUrl}/assetmanagement/Overview/assetDetails/${siteId}/${pageNumber}/${pageSize}`
        if (searchText) {
            const searchString = encodeURIComponent(searchText);
            headerUrl += `?searchString=${searchString}`;
        }
        let headerDetails = this.http.post<any>(headerUrl, headerPayload).pipe(catchError(error => of(error)));
        let assetDetails = this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
        if (isHeaderUpdate) {
            return forkJoin({
                headerDetails,
                assetDetails
            });
        } else {
            return forkJoin({
                assetDetails
            });
        }

    }

    getAssetsHeadersForSite(siteId: number, pageNumber: number, pageSize: number, payload: any = null, searchText = null): Observable<any> {
        let headerUrl = `${this.baseUrl}/assetmanagement/Overview/asset/headerDetails/${siteId}/${pageNumber}/${pageSize}`
        if (searchText) {
            const searchString = encodeURIComponent(searchText);
            headerUrl += `?searchString=${searchString}`;
        }
        return this.http.post<any>(headerUrl, payload).pipe(catchError(error => of(error)));
    }

    getAssetsForSite(siteId: number, pageNumber: number, pageSize: number, payload: any = null, searchText = null): Observable<any> {
        let url = `${this.baseUrl}/assetmanagement/Overview/assetDetails/${siteId}/${pageNumber}/${pageSize}`
        if (searchText) {
            const searchString = encodeURIComponent(searchText);
            url += `?searchString=${searchString}`;
        }
        return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
    }

    getSiteMaintenancesummaryList(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/summarylistview/${id}`).pipe(catchError(error => of(error)));
    }

    getObsolenscenceProfileList(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/ObsolensceneDetails/${id}`).pipe(catchError(error => of(error)));
    }

    getOverviewSuggestionsList(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/asset/suggestions/${id}`);
    }

    getOverviewSiteList(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/maintenanceworkflow/Overview/siteOverview/${id}`);
    }

    getCustomerById(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/sitemanagement/Customer/${id}`);
    }

    userCounter(currentSiteId: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/sitemanagement/Site/licenseCheck/${currentSiteId}`);
    }

    downloadSummaryData(id: string, filters: any) {
        let query = '';
        if (filters.status && filters.search && filters.search.length) {
            query = `?filter=${filters.status}&searchString=${filters.search}`;
        } else if (filters.status) {
            query = `?filter=${filters.status}`;
        } else {
            query = `?searchString=${filters.search || ''}`;
        }
        const url = `${this.baseUrl}/maintenanceworkflow/Overview/generate/summaryListView/${id}${query}`;
        return this.http.get<any>(url, { responseType: 'blob' as 'json' });
    }

    downloadObsolensceneDetails(id: string, filters: any) {
        let query = '';
        if (filters.status && filters.search && filters.search.length) {
            query = `?filter=${filters.status}&searchString=${filters.search}`;
        } else if (filters.status) {
            query = `?filter=${filters.status}`;
        } else {
            query = `?searchString=${filters.search || ''}`;
        }
        const url = `${this.baseUrl}/maintenanceworkflow/ObsolensceneDetails/generate/obsolenscenceprofile/${id}${query}`;
        return this.http.get<any>(url, { responseType: 'blob' as 'json' });
    }

    searchSiteOverview(key: string): Observable<any> {
        // Encode key with encodeURIComponent in order to pass special charactrers within search string
        const searchString = encodeURIComponent(key);
        return this.http.get(`${this.baseUrl}/sitemanagement/Overview/menu/details?searchString=${searchString}`);
    }

}
