<table class="table table-sm" style="height: fit-content;">
      <tr *ngIf="data" class="headStyle">           
        <td *ngFor="let head of data[0] | keys" [width]="tableCellWidth + '%'">{{head | camelToSpace | titlecase}}</td>
      </tr>
      <tr *ngFor="let item of data">           
        <td *ngFor="let list of item | keys" [width]="tableCellWidth + '%'">
          {{isDate(item[list]) ? (item[list] | date: 'dd/MM/yyyy') : (item[list])}}
        </td>
      </tr>
  </table>
