<div class="container-fluid h-100" style="padding:0">
    <div class="row h-100 m-0">
        <div class="col-sm-12 sites-page h-100" >
            <div class="row h-100">
                <div class="dashboard col no-padding main-page p-0">
                    <div class="container-fluid nav-container">
                        <nav class="navbar navbar-expand-lg non-desktop-only site-navbar">
                            <div class="container-fluid">
                                <div class="row" style="width: 100%;">
                                    <div class="col-4">
                                        <a class="nav-link  dashboardnav" routerLink="overview" routerLinkActive="active-link">
                                            <span class="">Overview</span>
                                        </a>
                                    </div>
                                    <div class="col-4">
                                        <a class="nav-link dashboardnav" (click)="closeSubMenu('assets')" routerLink="assets"  routerLinkActive="active-link">
                                            <span class="">Assets</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <nav class="navbar navbar-expand-lg desktop-only" style=" background: var(--main-header-bg);">
                            <div class="container-fluid">
                                <div class="row" style="width: 100%;">
                                    <ul class="navbar-nav me-auto">
                                        <li class="nav-item">
                                            <a class="nav-link  dashboardnav" routerLink="overview" routerLinkActive="active-link">
                                                <img src="../../assets/images/site_overview/overview.svg" alt="" height="13">
                                                <img src="../../assets/images/site_overview/overview_active.svg" alt="" height="13">
                                                <span class="ms-2">Overview</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a (click)="closeSubMenu('assets')" class="nav-link dashboardnav" routerLink="assets" routerLinkActive="active-link">
                                                <img src="../../assets/images/site_overview/assets.svg" alt="" height="13">
                                                <img src="../../assets/images/site_overview/assets_active.svg" alt="" height="13">
                                                <span class="ms-2">Assets</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="container-fluid" [ngClass]="{'p-0': hasRoute('assets')}">
                        <router-outlet></router-outlet>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
