import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubAssetRisk } from 'src/app/_models/health.type';

@Component({
  selector: 'app-subasset-matrix',
  templateUrl: './subasset-matrix.component.html',
  styleUrls: ['./subasset-matrix.component.scss']
})
export class SubassetMatrixComponent implements OnInit {
  colors: any = {
    '11': '#08aa17',
    '21': '#79b96e',
    '31': '#9dd594',
    '41': '#f3e52c',
    '51': '#f3c42c',
    '52': '#f89843',
    '53': '#fa7600',
    '54': '#ea2f40',
    '12': '#79b96e',
    '22': '#9dd594',
    '32': '#f3e52c',
    '42': '#f3c42c',
    '13': '#9dd594',
    '23': '#f3e52c',
    '33': '#f3c42c',
    '43': '#f89843',
    '14': '#f3e52c',
    '24': '#f3c42c',
    '34': '#f89843',
    '44': '#fa7600'

  }

  healthIndex: any = [
    { 'label': 'HI1', 'description': 'Very Good', 'code': 'veryGood'},
    { 'label': 'HI2', 'description': 'Good', 'code': 'good'},
    { 'label': 'HI3', 'description': 'Fair', 'code': 'fair'},
    { 'label': 'HI4', 'description': 'Poor', 'code': 'poor'},
    { 'label': 'HI5', 'description': 'Very Poor', 'code': 'veryPoor'}
  ]

  criticalIndex: any = [
    { 'label': 'CI1', 'description': 'Insignificant', 'code': 'insignificant'},
    { 'label': 'CI2', 'description': 'Minor', 'code': 'minor'},
    { 'label': 'CI3', 'description': 'Major', 'code': 'major'},
    { 'label': 'CI4', 'description': 'Critical', 'code': 'critical'}
  ]
  @Input() subAssetRiskMatrix!: SubAssetRisk[];
  @Input() showY:boolean = true;
  @Output() subAssetSelection = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  getData(i: number, j: number) {
    let arr = this.subAssetRiskMatrix.filter((item: any) => item.moduleHealthIndex == i && item.moduleCriticalIndex == j);
    return arr[0]?.subAssetCounts;
  }

}
