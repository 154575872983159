<div class="row" *ngIf="!target?.includes('report')">
    <div class="col-12 health-kpi-header fw-bold">{{params?.name | camelToSpace | titlecase}}</div>
</div>
<div class="row mt-2 mb-5 forecastStyle" *ngIf="!target?.includes('report')">
    <div class="col-12 d-flex justify-content-between" *ngIf="!isMobile">
        <div>
            <span class="fw-bold">
                Historical Data
            </span>
            <span class="ms-3">
                <select name="conditions" id="durations" class="calculate-card-input" (change)="onDurationChange($event)"
                    role="button" [(ngModel)]="selectedDuration">
                    <option [value]='' style="color:gray">Select Condition</option>
                    <option [value]="duration.dropdownValue" *ngFor="let duration of durations"
                        [title]="duration.dropdownDisplayText">
                        {{ duration.dropdownDisplayText}}
                    </option>
                </select>
            </span>
        </div>
        <div class="vr mx-2"></div>
        <ng-container *ngIf="params.name=='healthIndex' && forecastData">
            <!-- <div class="col-12 col-md-6 col-xl-2">Forecast Period: 
                <span>{{forecastData.forecastPeriod}}
                    {{forecastData.forecastPeriodType}}</span></div> -->
            <div class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="staticModel"
                        [style.background]="forecastColors['static']" (change)="changeSelection('static', $event)" [checked]="true"/>
                    <label class="form-check-label" for="staticModel">Static Model</label>
            </div>
            <div class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="dynamicModel"
                        [style.background]="forecastColors['dynamic']" (change)="changeSelection('dynamic', $event)"/>
                    <label class="form-check-label" for="dynamicModel">Dynamic Model</label>
            </div>
            <div class="vr mx-2"></div>
            <div class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="healthScore"
                        [style.background]="forecastColors['healthScore']" (change)="changeSelection('healthScore', $event)"/>
                    <label class="form-check-label" for="healthScore">Health Score</label>
            </div>
            <div class="vr mx-2"></div>
            <div class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="staticForecast"
                        [style.background]="forecastColors['staticForecast']" (change)="changeSelection('staticForecast', $event)"/>
                    <label class="form-check-label" for="staticForecast">Static Forecast</label>
                    <div class="caption-duration text-center">{{forecastData.forecastPeriodType}}</div>
            </div> 
            <div class="vr mx-2"></div>
            <!-- <div *ngFor="let item of forecastTypes;let i = index" class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="{{'forcastItem-'+i}}"
                        [style.background]='forecastColors[item.name]' (change)="changeSeriesSelection(item.name, $event)"/>
                    <label class="form-check-label" for="forcastItem">{{item.description}}</label>
                    <div class="caption-duration text-center">{{item.duration}}</div>
            </div> -->
            <div *ngFor="let item of predictionTypes;let i = index" class="px-4 text-center">
                <input class="form-check-input" type="checkbox" value="" id="{{'forcastItem-'+i}}"
                        [style.background]='item.color' (change)="changeSeriesSelection(item.name, $event)" [(ngModel)]="item.value"/>
                    <label class="form-check-label" for="forcastItem">{{item.name}}</label>
            </div>
        </ng-container>
    </div>
    <div class="row" *ngIf="isMobile" >
        <div class="col-12 mb-2">
            <span class="fw-bold">
                Historical Data
            </span>
            <span class="ms-3">
                <select name="conditions" id="durations" class="calculate-card-input" (change)="onDurationChange($event)"
                    role="button" [(ngModel)]="selectedDuration">
                    <option [value]='' style="color:gray">Select Condition</option>
                    <option [value]="duration.dropdownValue" *ngFor="let duration of durations"
                        [title]="duration.dropdownDisplayText">
                        {{ duration.dropdownDisplayText}}
                    </option>
                </select>
            </span>
        </div>
        <ng-container *ngIf="params.name=='healthIndex' && forecastData">
            <div class="col-12 d-flex mb-2">
                <div>
                    <input class="form-check-input" type="checkbox" value="" id="staticModel"
                        [style.background]="forecastColors['static']" (change)="changeSelection('static', $event)" [checked]="true"/>
                    <label class="form-check-label" for="staticModel">Static Model</label>
                </div>
                <div class="ms-2">
                    <input class="form-check-input" type="checkbox" value="" id="dynamicModel"
                            [style.background]="forecastColors['dynamic']" (change)="changeSelection('dynamic', $event)"/>
                        <label class="form-check-label" for="dynamicModel">Dynamic Model</label>
                </div>
            </div>
            <div class="col-12 d-flex mb-2">
                <div>
                    <input class="form-check-input" type="checkbox" value="" id="healthScore"
                            [style.background]="forecastColors['healthScore']" (change)="changeSelection('healthScore', $event)"/>
                        <label class="form-check-label" for="healthScore">Health Score</label>
                </div>
                <div class="ms-2">
                    <input class="form-check-input" type="checkbox" value="" id="staticForecast"
                            [style.background]="forecastColors['staticForecast']" (change)="changeSelection('staticForecast', $event)"/>
                        <label class="form-check-label" for="staticForecast">Static Forecast</label>
                        <span class="caption-duration ms-2">{{forecastData.forecastPeriodType}}</span>
                </div> 
            </div>
            <div class="col-12 d-flex justify-content-between">
                <div *ngFor="let item of forecastTypes;let i = index" class="">
                    <input class="form-check-input" type="checkbox" value="" id="{{'forcastItem-'+i}}"
                            [style.background]='forecastColors[item.name]' (change)="changeSeriesSelection(item.name, $event)"/>
                        <label class="form-check-label" for="forcastItem">{{item.description}}</label>
                        <div class="caption-duration text-center">{{item.duration}}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div  class="position-absolute text-center pt-5 mt-5 w-100 h-100 mb-5" *ngIf="isloading"><i class="fas fa-spinner fa-pulse fa-3x healthy"></i></div>
<div id="{{target}}" *ngIf="data"></div>