import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedValueService } from '../_services/shared-value.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  siteId: string = '';
  constructor(private _router: Router, private sharedValueService:SharedValueService, public sharedService: SharedService ) {
    
  }

  ngOnInit(): void {
  }
  
  closeSubMenu() {
    this.sharedService.toggleSitesSubMenu();
  }

  checkIfAssets() {
    if(window.location.href.includes('asset'))
      return false;
     return true; 
  }

  ngOnDestroy(): void {
    this.sharedService.sites = false;
    this.sharedService.toggleSitesSubMenu();
  }
}
