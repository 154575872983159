import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    const isDesigner = true;
    this.breadcrumbService.set('@Layout', '');
    this.breadcrumbService.set('@Site', 'Site');
    this.breadcrumbService.set('@Dashboard', 'Dashboard');
    this.breadcrumbService.set('@Overview', 'Overview');
    this.breadcrumbService.set('@Assets', 'Assets');
    this.breadcrumbService.set('@Settings', 'Settings');
    this.breadcrumbService.set('@DMM', 'Digital Maintenance Manager');
    this.breadcrumbService.set('@DMM', {
      routeInterceptor: (routeLink, breadcrumb) =>
      '/layout/DMM/plans'
    });
    this.breadcrumbService.set('@create', 'Permit to Work');
    this.breadcrumbService.set('@MaintenancePlans', 'Maintenance Plans');
    this.breadcrumbService.set('@PastMaintenanceRecords', 'Past Maintenance Records');
    this.breadcrumbService.set('@Task', 'Task');
    this.breadcrumbService.set('@Alarms', 'Alarms / Events');
    this.breadcrumbService.set('@DLB', 'Digital Log Book');
    this.breadcrumbService.set('@customer', 'Customer');
    this.breadcrumbService.set('@sites', 'Sites');
    this.breadcrumbService.set('@assets', 'Assets');
    this.breadcrumbService.set('@mapping', 'Mapping');
    this.breadcrumbService.set('@assetmanagement', 'Asset Management');
    this.breadcrumbService.set('@adminpanel', 'Admin');
    this.breadcrumbService.set('@usermanagement', 'User Management');
    this.breadcrumbService.set('@templatemanagement', 'Template');
    this.breadcrumbService.set('@gatewaymanagement', 'Gateway');
    this.breadcrumbService.set('@Documents', 'View Documents');
    this.breadcrumbService.set('@uploads', 'Upload Documents');
    this.breadcrumbService.set('@qr', 'Manage QR Code');
    this.breadcrumbService.set('@trash', 'Trash');
    this.breadcrumbService.set('@General', 'General');
    this.breadcrumbService.set('@conditionMonitoring', 'Condition Monitoring');
    this.breadcrumbService.set('@profile', 'Profile');
    this.breadcrumbService.set('@subscription', 'Subscription');
    this.breadcrumbService.set('@addsubscription', 'Add Subscription');
    this.breadcrumbService.set('@generate', 'Generate Report'); 
    this.breadcrumbService.set('@automated', 'Automated Rules');
    this.breadcrumbService.set('@insights', 'Insights');
    this.breadcrumbService.set('@contact', 'Contact Us');
    this.breadcrumbService.set('@whatsnew', 'What\'s New');
    this.breadcrumbService.set('@help', 'Help');
  }
}
