<div class="header mb-5">
    <div class="btns float-end me-3">
        <button class="cancel-btn" (click)="Cancel()">Cancel</button>
        <button class="edit-btn" (click)="Edit()" *ngIf="!isUpdate">Edit</button>
        <button class="edit-btn" (click)="validate()" *ngIf="isUpdate" [disabled]="isDisabled">Update</button>
        <button class="edit-btn ms-3" (click)="exit()">Exit</button>

    </div>

    <div class="profilepic-upload">
        <div class="profilepic-edit">
            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
        </div>
        <div class="profilepic-preview">
            <ng-container *ngIf="isLoading">
                <div class="container text-center mt-5">
                    <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
                </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                </div>
            </ng-container>
        </div>
        <!-- Button trigger modal -->
        <img class="btn-img-icon" data-mdb-toggle="modal" data-mdb-target="#exampleModal"
            src="./../../../../assets/images/profile_image.png" />
    </div>
    <!-- <div class="profilepic"><input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
        (change)="uploadFile($event)"/><img class="btn-img-icon" src="./../../../../assets/images/profile_image.png" (click)="fileInput.click()"/></div> -->
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit profile picture</h5>
                <button type="button" id="closeBtnClick" class="btn-close" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="content-text h-100">
                    <div class="text-flex">
                        <div (click)="fileInput.click()">
                            <i class="fa fa-upload"></i>
                            <button class="buttonclick">Upload picture</button>
                        </div>
                        <div (click)="deleteProfilePicture()">
                            <i class="fa fa-trash-can"></i>
                            <button [disabled]="imageUrl == defaultUrl || (!profilePic && imageUrl !==defaultUrl)"
                                class="buttonclick">Remove picture</button>
                        </div>
                        <div class="critical mt-1" *ngIf="errorMessage">{{showMsg}}</div>
                    </div>
                    <div class="profilepic-upload">
                        <div class="profilepic-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                (change)="uploadFile($event)" />
                        </div>
                        <div class="profilepic-preview">
                            <div [style.backgroundImage]="'url('+ imageUrl +')'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="removeUploadedFile()" class="btn-cancel"
                    data-mdb-dismiss="modal">Cancel</button>
                <button type="button" [disabled]="picUpdate==false" (click)="updateUserProfilePicture()"
                    class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>

<!--form-->
<ng-container *ngIf="isLoading">
    <div class="container text-center loading">
        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
</ng-container>
<div class="user justify-content-left m-2">
    <form [formGroup]="reactiveForm" #form="ngForm" autocomplete="off">
        <div class="row m-auto">
            <div class="col-lg-6">
                <div class="row ">
                    <div class="col-lg-4 mb-2 col-12">
                        <label for="firstname" class="form-label">First Name*</label>
                    </div>
                    <div class="col-lg-6 mb-2 col-12">
                        <input type="text" id="firstname" name="firstname" formControlName="firstName"
                            class="form-control form-control-sm"
                            [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)" min="1">
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                            class="invalid-feedback">
                            Enter valid First Name
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 mb-2 col-12">
                        <label for="lastname" class="form-label">Last Name*</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="lastname" name="lastname" formControlName="lastName"
                            class="form-control form-control-sm"
                            [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)" min="1">
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="invalid-feedback">
                            Enter valid Last Name
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 mb-2 col-12">
                        <label for="role" class="form-label">Role</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="role" name="role" formControlName="role"
                            class="form-control form-control-sm" readonly>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="mobNum" class="form-label">Mobile Number*</label>
                    </div>
                    <div class="col-lg-6 col-12"
                        [ngClass]="mobNum.invalid && (mobNum.dirty || mobNum.touched)?'mb-5':' mb-2'">
                        <div class="input-group">
                            <input type="text" id="mobnum" name="mobnum" formControlName="mobNum"
                                class="form-control form-control-sm mob-input"
                                [class.is-invalid]="mobNum.invalid && (mobNum.dirty || mobNum.touched)" maxlength="10">
                            <button *ngIf="!verifiedMobileOTP &&isUpdate && !showOtptext" [disabled]="mobNum.invalid || !(mobNum.dirty || mobNum.touched)"
                                class="form-control form-control-sm otp-button" type="button" id="button-addon2"
                                (click)="generateMobileOTP()"> Validate </button>
                            <input *ngIf="!verifiedMobileOTP &&isUpdate && showOtptext" id="mobileNumOTP"
                                name="mobileNumOTP" formControlName="mobileNumOTP"
                                class="form-control form-control-sm otp-txt" type="text" maxlength="6" id="otpBtn"
                                placeholder="Enter OTP" (keydown.enter)="verfiyMobileOTP()" />
                            <button *ngIf="verifiedMobileOTP &&isUpdate" class="form-control form-control-sm otp-button"
                                type="button" id="mobileVerfiyBtn">Verified <i class="fas fa-check ps-1"></i></button>
                            <div *ngIf="mobNum.invalid && (mobNum.dirty || mobNum.touched)" style="margin-bottom: 10px;"
                                class="invalid-feedback">
                                Enter valid Mobile Number
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
            <div class="col-2 mb-2">
                <label for="mobNum" class="form-label">Mobile Number*</label>
            </div>
            <div class="col-3 mb-2">
                <div class="input-group mb-2">
                    <input type="text" id="mobnum" name="mobnum" formControlName="mobNum"
                        class="form-control form-control-sm mob-input"
                        [class.is-invalid]="mobNum.invalid && (mobNum.dirty || mobNum.touched)" maxlength="10">
                    <button *ngIf="!verifiedMobileOTP &&isUpdate && !showOtptext"
                        class="form-control form-control-sm otp-button" type="button" id="button-addon2"
                        (click)="generateMobileOTP()"> Validate </button>
                    <input *ngIf="!verifiedMobileOTP &&isUpdate && showOtptext" id="mobileNumOTP" name="mobileNumOTP"
                        formControlName="mobileNumOTP" class="form-control form-control-sm otp-txt" type="text"
                        maxlength="6" id="otpBtn" placeholder="Enter OTP" (keydown.enter)="verfiyMobileOTP()" />
                    <button *ngIf="verifiedMobileOTP &&isUpdate" class="form-control form-control-sm otp-button"
                        type="button" id="mobileVerfiyBtn">Verified <i class="fas fa-check ps-1"></i></button>
                </div>
                <div *ngIf="mobNum.invalid && (mobNum.dirty || mobNum.touched)" class="invalid-feedback">
                    Enter valid Mobile Number
                </div>
            </div>
            
        </div> -->
                <!-- <div class="row">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-lg bg-white otp-field mobile-number-verified" formControlName="mobnum" placeholder="Mobile Number*"
                id="mobnum" maxlength="10" name="mobnum"
                [ngClass]="{'is-invalid': (reactiveFormControls['mobnum'].touched && reactiveFormControls['mobnum'].errors || (errorGenerateOTP))}" />
                <label class="form-label" for="mobnum">Mobile Number*</label>
                <button *ngIf="reactiveFormControls['mobnum'].errors === null && !verifiedMobileOTP" class="btn bg-white otp-button"
                type="button" id="button-addon2" (click)="generateMobileOTP()"> Get OTP </button>
                <button *ngIf="reactiveFormControls['mobileNumberOTP'].errors === null && verifiedMobileOTP" class="btn bg-white otp-button"
                type="button" id="mobileVerfiyBtn">Verified <i class="fas fa-check ps-1"></i></button>
              </div>
              <div class="error-text ps-2" *ngIf="reactiveFormControls['mobnum'].touched && reactiveFormControls['mobnum'].errors">
                <p *ngIf="reactiveFormControls['mobnum']['errors']['required']">Mobile Number is required</p>
                <p *ngIf="reactiveFormControls['mobnum'].hasError('pattern')">enter an valid mobile number</p>
              </div>
              <div class="error-text ps-2" *ngIf="errorGenerateOTP">
                <p>{{ errorGenerateOTP }}</p>
              </div>
        </div> -->
                <!-- <div class="row" *ngIf="showMobileOTP">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-lg bg-white otp-field" formControlName="mobileNumberOTP" placeholder="Mobile Number - OTP*"
                id="mobilenumberOTP"  maxlength="6" name="mobileNumberOTP"
                [ngClass]="{'is-invalid': (reactiveFormControls['mobileNumberOTP'].touched && reactiveFormControls['mobileNumberOTP'].errors || (errorVerifyOTP))}"/>
                <label class="form-label" for="mobilenumberOTP">Mobile Number - OTP*</label>
                <button *ngIf="reactiveFormControls['mobileNumberOTP'].errors === null" class="btn bg-white otp-button"
                type="button" id="mobileVerfiyBtn" (click)="verfiyMobileOTP()">Verify
                </button>
              </div>
              <div class="error-text ps-2" *ngIf="reactiveFormControls['mobileNumberOTP'].touched && reactiveFormControls['mobileNumberOTP'].errors">
                <p *ngIf="reactiveFormControls['mobileNumberOTP']['errors']['required']">Mobile Number - OTP is required</p>
                <p *ngIf="reactiveFormControls['mobileNumberOTP'].hasError('pattern')">enter an valid OTP number</p>
              </div>
              <div class="error-text ps-2" *ngIf="errorVerifyOTP">
                <p>{{ errorVerifyOTP }}</p>
              </div>
        </div> -->
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="emailId" class="form-label">Email ID*</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="email" name="email" formControlName="emailId"
                            class="form-control form-control-sm"
                            [class.is-invalid]="emailId.invalid && (emailId.dirty || emailId.touched)">
                        <div *ngIf="emailId.invalid && (emailId.dirty || emailId.touched)" class="invalid-feedback">
                            Enter Email ID
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="country" class="form-label">Country*</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <ng-select [items]="countryList" class="" bindValue="id" (change)="changeCountry($event)"
                            [class.is-invalid]="country.invalid && (country.dirty || country.touched)"
                            formControlName="country" [dropdownPosition]="'bottom'" bindLabel="name" id="country">
                        </ng-select>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="critical">
                            Please Enter Country
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="companyName" class="form-label">Company Name</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="companyName" name="companyName" formControlName="companyName"
                            class="form-control form-control-sm">
                        <!-- <div *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)" class="invalid-feedback">
                Enter Company Name
              </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="streetAddr" class="form-label">Street Address</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="streetAddr" name="streetAddr" formControlName="streetAddr"
                            class="form-control form-control-sm">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="city" class="form-label">City</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="city" name="city" formControlName="city"
                            class="form-control form-control-sm"
                            [class.is-invalid]="city.invalid && (city.dirty || city.touched)">
                        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
                            Enter valid city name
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="state" class="form-label">State</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-2">
                        <input type="text" id="state" name="state" formControlName="state"
                            class="form-control form-control-sm"
                            [class.is-invalid]="state.invalid && (state.dirty || state.touched)">
                        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="invalid-feedback">
                            Enter valid state name
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-2">
                        <label for="zipCode" class="form-label">Zip Code</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-3">
                        <input type="text" id="zipCode" name="zipCode" formControlName="zipCode"
                            class="form-control form-control-sm" maxlength="12">
                        <!-- <div *ngIf="logbookdata.invalid && (logbookdata.dirty || logbookdata.touched)" class="invalid-feedback">
                Enter Valid ZipCode
              </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-12 mb-3">
                        <label for="businessType" class="form-label">Business Type</label>
                    </div>
                    <div class="col-lg-6 col-12 mb-3">
                        <ng-select [items]="businessTypeList" class="" bindValue="id"
                            (change)="changeBusinessType($event)"
                            [class.is-invalid]="businessType.invalid && (businessType.dirty || businessType.touched)"
                            formControlName="businessType" [dropdownPosition]="'bottom'" bindLabel="" id="businessType">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div id="mydiv" *ngIf="createStatus" class="alertMsg" [ngClass]="showError? 'critical': 'healthy'">{{ showMsg }}
    </div>
</div>