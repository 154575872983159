<ng-container *ngIf="isLoading">
  <div class="container text-center mt-5">
    <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
  </div>
</ng-container>
<ng-container *ngIf="currentView == 'cards' && !isLoading">
  <!-- <div class="d-flex justify-content-center text-last-update" *ngIf="conditionMonitoring">
    <u>Last Update: {{(lastUpdate + 'Z' | date: 'dd/MM/yyyy h:mm:ssa': offsetDate)}}</u></div> -->
  <div class="card-columns">
    <div *ngIf="(conditionMonitoring && conditionMonitoring.length == 0) || errorMessage || !conditionMonitoring">No data</div>
    <div *ngFor="let item of conditionMonitoring" class="card conditionmonitoring-tile"
      [ngClass]="item.values.length > 5 ? 'width-modifier' : ''">

      <!-- status -->
      <div *ngIf="item.group.toLowerCase()=='status'" class="card-column">
        <div class="row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/status.svg);">
          <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
            <div *ngFor="let value of item.values; let i = index">
              <div class="column">
                <ng-container
                  *ngIf="value.tag.toLowerCase()  == ('open/close') || value.tag.toLowerCase()  == ('status') || value.tag.toLowerCase()  == 'position(service/test)' ">
                  <div class="title-style status" *ngIf="item.group.toLowerCase()=='status'">Status</div>
                  <div class="row pt-4">
                    <div class="col-12 pt-3 value-style {{value.value | lowercase}}">{{(value.value)? value.value+'
                      '+value.unit : '-'}}</div>
                  </div>
                </ng-container>

                <ng-container *ngIf="!(value.tag.toLowerCase()  == ('open/close') || value.tag.toLowerCase()  == ('status') || value.tag.toLowerCase()  == 'position(service/test)') ">
                  <div class="title-style">{{value.tag}}</div>
                  <div class="row" [ngClass]="i==0?'':'border-left'">
                    <div *ngIf="(value.value).toLowerCase() !='reset'" class="col-12 pt-3 value-style {{value.value | lowercase}}">{{(value.value && (value.value).toLowerCase()
                      !=='reset')? value.value+'
                      '+value.unit : '-'}}</div>
                    <div *ngIf="(value.value).toLowerCase() =='reset'" class="col-12 pt-3 value-style {{value.value | lowercase}}">{{
                      'Healthy' +'
                      '+value.unit}}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- status end-->

      <!-- no of operations -->
      <div *ngIf="item.group.toLowerCase() =='counter'" class="card-column">
        <div class=" row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/hour.svg);">
          <div class="col-10 scroll-column">
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index">
                <div class="column">
                  <div class="title-style">{{value.tag}}</div>
                  <div class="row" [ngClass]="i==0?'':'border-left'">
                    <div class="col-12 data-style blue"
                      [ngClass]="(  value.staticReference && getInput(value.value) > value.staticReference) ? 'disconnected': ''">
                      {{(value.value)? value.value+' ': '-'}} <span
                        *ngIf="value.tag.toLowerCase() !=='run hours'">{{value.unit}}</span></div>
                    <div class="col-12 lightgrey-style" *ngIf="value.tag.toLowerCase()=='run hours'"> Cumulative</div>
                    <div *ngIf="value.dynamicReference || value.staticReference" class="col-12 lightgrey-style">
                      Reference
                      <ng-container *ngIf="value.dynamicReference">
                        <div><span class="refvalue-style"> {{value.dynamicReference+' '+value.unit}} <span
                              *ngIf="value.tag=='No. Of Operation'">(Mechanical)</span></span>
                          <span *ngIf="value.error" style="margin:5px">{{value.error + '%'}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="value.dynamicReference === null">
                        <div class="refvalue-style">{{value.staticReference+' '+value.unit}} <span
                            *ngIf="value.tag=='No. Of Operation'">(Mechanical)</span></div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group, isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- no of operations end-->

      <!-- opening time closing time -->
      <div *ngIf="item.group.toLowerCase()=='time'" class="card-column">
        <div class="row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/closing_time.svg);">
          <div class="col-10 scroll-column">
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index">
                <div class="column">
                  <div class="title-style">{{value.tag}}</div>
                  <div class="row" [ngClass]="i==0?'':'border-left'">
                    <div class="col-12 data-style blue"
                      [ngClass]="(  value.staticReference && getInput(value.value) > value.staticReference) ? 'disconnected': ''">
                      {{(value.value)?
                      value.value+' '+value.unit : '-'}}</div>
                    <div *ngIf="value.dynamicReference || value.staticReference" class="col-12 lightgrey-style">
                      Reference
                      <ng-container *ngIf="value.dynamicReference">
                        <div><span class="refvalue-style"> {{value.dynamicReference+' '+value.unit}}</span>
                          <span *ngIf="value.error" style="margin:5px">{{value.error + '%'}}</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="value.dynamicReference === null">
                        <div class="refvalue-style">{{value.staticReference+' '+value.unit}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group, isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- opening time closing time ends-->

      <!-- current -->
      <div *ngIf="item.group.toLowerCase() == 'curr'|| item.group == '-'" class="card-column">
        <div class=" row card-body pb0"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/current.svg);">
          <div class="col-10 scroll-column">
            <div class="title-style">{{item.group == '-' ? '-' : 'Current'}}</div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div class="column">
                  <div class="center"> <span class="data-style pe-1 {{value.tag.split(' ')[1] | lowercase}}"
                      [ngClass]="( value.percentage && value.percentage > 100 ) ? 'blink': ''">{{(value.value)?
                      (value.value | number)+'
                      '+value.unit : '-'}}</span>
                    <span *ngIf="value.percentage" class="percentage-font">
                      {{value.percentage + '%'}}</span>
                  </div>
                  <div class="refvalue-style"> {{value.tag.substr(value.tag.indexOf(' ') + 1)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group, isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>

        <span *ngIf="item.group.toLowerCase()=='curr'" class="text-italic"> % of full load</span>
      </div>
      <!-- current ends-->

      <!-- power -->
      <div *ngIf="item.group.toLowerCase()=='power'" class="card-column">
        <div class=" row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/power.svg);">
          <div class="col-10 scroll-column">
            <div class="title-style">Power</div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div class="column">
                  <div class="flex-around"> <span class="data-style blue {{value.tag.split(' ')[0] | lowercase}}"
                      [ngClass]="( value.percentage && value.percentage > 100 ) ? 'blink': ''">{{(value.value)?
                      (value.value | number)+' '+value.unit : '-'}}</span>
                  </div>
                  <div class="refvalue-style"> {{value.tag}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group, isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms}}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- power ends -->

      <!-- energy -->
      <div *ngIf="item.group.toLowerCase()=='energy'" class="card-column">
        <div class="row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/voltage.svg);">
          <div class="col-11">
            <div class="title-style">Energy</div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div class="column">

                  <div> <span class="data-style blue {{value.tag.split(' ')[0] | lowercase}}"
                      [ngClass]="( value.percentage && value.percentage > 100 ) ? 'blink': ''">{{(value.value)?
                      (value.value | number)+' '+value.unit : '-'}}</span></div>
                  <div class="refvalue-style"> {{value.tag}}</div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group, isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- energy ends -->

      <!-- Temperature Bus bar -->
      <div
        *ngIf="item.group.toLowerCase()=='bb'|| item.group.toLowerCase()=='top'|| item.group.toLowerCase()=='bot' || (item.group.toLowerCase()=='BBFP'|| item.group.startsWith('BBFP'))"
        class="card-column">
        <div class=" row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/temperature.svg);">
          <div class="col-10 scroll-column">
            <div class="title-style">
              <span *ngIf="item.group.toLowerCase()=='bb'">Temperature-Busbar</span>
              <span *ngIf="item.group.toLowerCase()=='top'">Temperature-Top Terminal</span>
              <span *ngIf="item.group.toLowerCase()=='bot'">Temperature-Bottom Terminal</span>
              <span *ngIf="item.group.toLowerCase()=='bbfp'|| item.group.startsWith('BBFP')">Temperature-Busbar-Fish
                plate {{item.group.split(' ')[1]}}</span>
            </div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div *ngIf="value.tag !=='Ambient Temperature' && value.tag !=='Average Ambient Temperature'"
                  class="column">
                  <div>
                    <div class="temperature-font blue {{value.tag.split(' ')[1] | lowercase}}"
                      [ngClass]="( value.staticReference && getInput(value.value) > value.staticReference ) ? 'blink': ''">
                      {{(value.value)? (value.value | number)+'
                      '+value.unit : '-'}}
                    </div>
                    <div class="refvalue-style">{{value.tag.substr(value.tag.indexOf(' ') + 1)}}</div>
                  </div>
                  <div *ngIf="value.dynamicReference || value.staticReference" class="col-12 lightgrey-style">Reference
                    <ng-container *ngIf="value.dynamicReference">
                      <div><span class="refvalue-style"> {{value.dynamicReference+' '+value.unit}}</span>
                        <span *ngIf="value.error" style="margin:5px">{{value.error + '%'}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="value.dynamicReference === null">
                      <div class="refvalue-style">{{value.staticReference+' '+value.unit}}</div>
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="value.tag =='Ambient Temperature' || value.tag =='Average Ambient Temperature'"
                  class="ms-2 column">
                  <div class="data-style blue"> {{value.value ? (value.value | number) : '-'}} <span
                      *ngIf="value.value">{{' '+ value.unit }}</span></div>
                  <div class="refvalue-style"> {{ value.tag =='Ambient Temperature'? value.tag : 'Avg. Ambient'}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group.split(' ')[0], isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{item.alarms}}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Temperature Bus bar ends -->

      <!-- environment data-->
      <div *ngIf="item.group.startsWith('env') || item.group.split(' ')[0].toLowerCase() == 'env'" class="card-column">
        <div class="row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/environment.svg);">
          <div class="col-10 scroll-column">
            <div class="title-style">Environment Data {{item.group.split(' ')[1]}}</div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div class="column">
                  <div class="row">
                    <div class="col-12 data-style index{{i}}"
                      [ngClass]="( value.staticReference && getInput(value.value) > value.staticReference) ? 'blink': ''">
                      {{(value.value)? (value.value | number) +' '+value.unit :'-'}}
                    </div>
                    <div class="refvalue-style"> {{value.tag}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group.split(' ')[0], isCommon:item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- environment data ends-->

      <!-- voltage data-->
      <div *ngIf="item.group.startsWith('volt') || item.group.split(' ')[0].toLowerCase() == 'volt'"
        class="card-column">
        <div class="row card-body"
          style="background-image: url(../../assets/images/conditionmonitoring-watermark/voltage.svg);">
          <div class="col-10 scroll-column">
            <div class="title-style">Voltage {{item.group.split(' ')[1]}}</div>
            <div [ngClass]="item.values.length > 4 ? 'columns' : 'columns-nowrap'">
              <div *ngFor="let value of item.values; let i = index" class="border-left">
                <div class="column">
                  <div class="row">
                    <div class="col-12 data-style blue {{value.tag.split(' ')[1] | lowercase}}"
                      [ngClass]="( value.percentage && value.percentage > 100 ) ? 'blink': ''">
                      {{(value.value)?(value.value | number)+' '+value.unit : '-'}}</div>
                  </div>
                  <div class="refvalue-style">{{value.tag.substr(value.tag.indexOf(' ') + 1)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 marginTop">
            <a>
              <img src="../../assets/images/graph-cards.svg" *ngIf="isMobile"
                (click)="currentView = 'trends';getSelectedGroupData(item.group)">
              <span type="button" class="dashboard-card-header desktop-only" (click)="getSelectedGroupData(item.group)">
                <img src="../../assets/images/graph-cards.svg" *ngIf="!isMobile">
              </span>
            </a>
          </div>
          <div class="col-1 card-notification">
            <a *ngIf="item.alarms !== 0" target="_blank" [routerLink]="['/layout/alarms']"
              [queryParams]="{siteId: siteId, assetId:assetId, feederNo:item.feederNumber, group:item.group.split(' ')[0], isCommon: item.isCommon}">
              <i class="fas fa-bell fa-md"></i>
              <span class="badge rounded-pill badge-notification bell-style">
                {{ item.alarms }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- voltage data ends-->

      <u *ngIf="item.lastUpdated" class="text-end p-2 text-last-update">Last Update: {{(item.lastUpdated + 'Z' | date: 'dd/MM/yyyy h:mm:ssa': offsetDate)}}</u>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentView == 'trends' && isMobile && selectedGroupData">
  <div class="container-fluid p-0 m-0" style="position: absolute;
  top: 129px;
  left: 1px;">
    <app-highchart-poc [group]="selectedGroup" [data]="selectedGroupData[0]" (close)="currentView='cards'"
      [params]="params" [isCommon]="isCommon">
    </app-highchart-poc>
  </div>
</ng-container>


<ng-container *ngIf="currentView == 'trends' && !isMobile && selectedGroupData">
  <div class="trends-modal-backdrop">
    <div class="trends-modal">
      <app-highchart-poc [group]="selectedGroup" [data]="selectedGroupData[0]" (close)="currentView='cards'"
        [params]="params" [isCommon]="isCommon">
      </app-highchart-poc>
    </div>
  </div>
</ng-container>
