<div class="row h-100">
    <div class="overlay p-0">
        <div class="card h-100">
            <div class="card-body">
                <div class="row sites-header" style="height: unset!important;">
                    <div class="col-sm-12">
                        <p class="card-title mt-3" style="font-size: larger;">
                            Sites Overview</p>
                    </div>
                </div>

                <div class="" *ngIf="siteOverviewHeader">
                    <span class="me-3 totalSites">Total Sites({{siteOverviewHeader.totalSites.count}})</span>
                    <span class="me-3 totalSites">Assets({{siteOverviewHeader.totalAssets.count}})</span>
                    <span class="totalSites">Sub Assets({{siteOverviewHeader.totalSubAssets.count}})</span>
                </div>
                <ng-container *ngIf="isLoading">
                    <div class="container text-center loading">
                        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isLoading">

                <div class="row mt-2 sites-search" style="height: unset!important;">
                    <div class="col-sm-12">
                        <div class="search">
                            <input type="text" class="form-control" placeholder="Search Site / Asset"
                                [(ngModel)]="searchText" (keyup.enter)="search()" (keyup)="searchText?.length > 2 || searchText?.length === 0 ?  search() : ''">
                            <i class="fa fa-search" (click)="search()" role="button"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 ms-2">
                    <span class="me-4 sitesHealth"><i class="fa fa-square me-1 healthy"></i>Healthy</span>
                    <span class="me-4 sitesHealth"><i class="fa fa-square me-1 at-risk"></i>At Risk</span>
                    <span class="me-4 sitesHealth"><i class="fa fa-square me-1 critical"></i>Critical</span>
                </div>
                    <div class="row sites-list mt-2" infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false" [style.height]="(innerHeight-230)+'px'">
                        <div class="col-sm-12" style="padding:0">
                            <div class="text-center m-4 critical" *ngIf="siteOverviewData?.length == 0">
                                No Site found </div>
                            <div class="accordion accordion-flush" id="accordionExample"
                                >
                                <div class="accordion-item mb-3 ps-1 pe-1"
                                    *ngFor="let site of siteOverviewData;let i=index">
                                    <h2 class="accordion-header sitename-style" id="headingOne">
                                        <div class="row">
                                            <div class="col-8 ps-4 mt-2">
                                                <div class="site-name">
                                                    <a (click)="goToSiteDashboard(site.siteId)" role="button"
                                                        [title]="site.siteName" class="desktop-only">
                                                        {{ (site.siteName.length>21)? (site.siteName | uppercase |
                                                        slice:0:17)+'..':(site.siteName | uppercase)}}
                                                    </a>
                                                    <a (click)="goToSiteDashboardMobile(site.siteId)" class="non-desktop-only">{{ (site.siteName.length>21)? (site.siteName | uppercase |
                                                        slice:0:17)+'..':(site.siteName | uppercase)}}
                                                    </a>
                                                </div>
                                                <div class="overall-health">Overall Health
                                                    <span class="overall-health-value">{{site.overallHealth}}<i
                                                            class="fa fa-info-circle"></i></span>
                                                </div>
                                                <div class="assets-count">Total Assets - {{site.totalAssets}}</div>
                                            </div>
                                            <div class="col-4 mt-3">
                                                <div class="accordion-button blue_active" type="button"
                                                    data-mdb-toggle="collapse" attr.data-mdb-target="#site{{i}}"
                                                    [ngClass]="i ? 'collapsed': ''" aria-controls="collapseOne"
                                                    style="border-radius:6px">

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="accordion-button" type="button" data-mdb-toggle="collapse" attr.data-mdb-target="#site{{i}}" [ngClass]="i ? 'collapsed': ''" aria-controls="collapseOne" style="border-radius:6px">
                                    <div class="row" style="width:100%">
                                        <div class="col-sm-12" style="padding:unset">
                                            <div class="site-name desktop-only" >
                                                <a (click)="goToSiteDashboard($event,'12')">{{site.name}}</a>
                                            </div>
                                            <div class="site-name non-desktop-only">
                                                <a (click)="goToSiteDashboardMobile('12')">{{site.name}}</a>
                                            </div>
                                            <div class="overall-health">Overall Health
                                                <span class="overall-health-value"><i class="fa fa-info-circle"></i></span>
                                            </div>
                                            <div class="assets-count">Total Assets - {{site.total}}</div>
                                        </div>
                                    </div>
                                </div> -->
                                    </h2>
                                    <div id="site{{i}}" class="accordion-collapse collapse" [ngClass]="i ? '': 'show'"
                                        aria-labelledby="headingOne" data-mdb-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="accordion" id="accordionExample2" style="padding:0">
                                                <div class="container-fluid" style="padding:0 12px">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="substations-filter row">
                                                                <div class="substations mt-1">SubStations</div>
                                                                <div class="col substaion-list flex-style"
                                                                    *ngFor="let substation of site.subStations">
                                                                    <input type="checkbox" [id]="substation.id" value=""
                                                                        (change)="onChange(substation.id, substation.siteId, $event)">
                                                                    <label class=" ms-1 totalSites"
                                                                        for="flexCheckDefault">{{substation.subStationName}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pb-2">
                                                    <app-site-overview-assets [assets]="site.subMenu">
                                                    </app-site-overview-assets>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="isScrollLoading">
                            <div class="container text-center scroll-loader">
                                <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div id="mydiv" *ngIf="createStatus" class="alertMsg" [ngClass]="showError? 'critical': 'healthy'">{{ showMsg }}</div>
