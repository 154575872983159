import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-work-performed-modal',
  templateUrl: './work-performed-modal.component.html',
  styleUrls: ['./work-performed-modal.component.css']
})
export class WorkPerformedModalComponent implements OnInit {

  subAsset: any;
  subAssetFeederNo: any;
  parentFormGroup: FormGroup;
  maintenanceTaskData: any;
  disableWorkPerformedForm: boolean;

  start: any;
  end: any;
  currArray: any;
  selectedLevel: string = '';
  allSubAssetsForSelectedLevel: any;
  subAssets: any;
  selectedSubAsset: any = 0;
  feederDetails!: { [x: string]: any; };
  groupedSubAssets: any;
  recordsPerPage = 10;

  constructor(
    public dialogRef: MatDialogRef<WorkPerformedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.maintenanceTaskData = this.modalData.template;
    this.subAsset = this.modalData.template[0].submittedResponse;
    this.subAssetFeederNo = this.modalData.template[0].feederNo;
    this.parentFormGroup = this.modalData.parentForm;
    this.disableWorkPerformedForm = this.modalData.disableWorkPerformedForm
    this.getSubAssetDetails();
  }

  group(arr: any) {
    const map = arr.reduce((acc: any, val: any) => {
      let char = val.split('-')[0];
      acc[char] = [].concat((acc[char] || []), val);
      return acc;
    }, {});
    const res = Object.keys(map).map(el => ({
      level: el,
      feeders: map[el]
    }));
    return res;
  }

  setSelectedLevel(level: string) {
    this.selectedLevel = level;
    this.getAllSubassetsForLevel(this.selectedLevel);
  }

  getAllSubassetsForLevel(level: string) {
    this.allSubAssetsForSelectedLevel = this.subAssets.filter((item: any) => {

      return item.split('-')[0] == level
    });
    if (this.allSubAssetsForSelectedLevel.length)
      this.selectedSubAsset = this.allSubAssetsForSelectedLevel[0];
  }

  setSelectedSubAsset(subasset: string) {
    this.selectedSubAsset = subasset;
  }

  getSubAssetDetails(): void {
      this.feederDetails = this.getFeeders();
      this.subAssets = Object.keys(this.feederDetails);
      this.groupedSubAssets = this.group(this.subAssets);
      if (this.groupedSubAssets.length) {
        this.setSelectedLevel(this.groupedSubAssets[0].level);
        this.currArray = this.groupedSubAssets.slice(0, this.recordsPerPage);
      }
  }

  getFeeders() {
    let feederDetails: { [x: string]: any; } = {};
    this.maintenanceTaskData?.forEach((item: any)=> {
      let key = item.feederNo;
      let value = item;
      if (feederDetails[key] && feederDetails[key].length > 0) {
        feederDetails[key].push(item);
      } else {
        feederDetails[key] = [item];
      }
    })
    return feederDetails;
  }


  displayActiveSection(activeSection: any) {
    this.start = activeSection.start;
    this.end = activeSection.end;
    this.currArray = this.groupedSubAssets.slice(this.start, this.end);
    this.setSelectedLevel(this.currArray[0].level);
  }

  closeDialog() {
    this.dialogRef.close({event: 'update', data: this.parentFormGroup});
  }

}
