import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../_models/user';

import { AuthenticationService } from '../_services/authentication.service';
import { SharedService } from '../_services/shared.service';
import { RbacService } from './rbac.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    aliases: any = {
        "/layout/alarms": "AlarmOrEvents",
        "/layout/adminpanel/assetmanagement/customer": "AssetManagement",
        "/layout/adminpanel/assetmanagement/sites": "AssetManagement",
        "/layout/adminpanel/assetmanagement/assets": "AssetManagement",
        "/layout/adminpanel/assetmanagement/requests": "AssetManagement",
        "/layout/adminpanel/assetmanagement/ingestedData": "AssetManagement",
        "/layout/settings/conditionMonitoring": "Settings",
        "/layout/DMM/pastRecords": "DigitalMaintenanceManager",
        "/layout/DMM/plans": "DigitalMaintenanceManager",
        "/layout/DMM/overview": "DigitalMaintenanceManager",
        "/layout/DLB/documents": "DigitalLogbook",
        "/layout/DLB/design": "DigitalLogbook",
        "/layout/healthkpi": "HealthKpi",
        "/layout/ibdata": "IBData",
        "/layout/sites/dashboard": "SiteOverview",
        "/layout/DLB/qrcode/qrcodeGenerate": "QrCodeManagement",
        "/layout/DLB/qrcode/qrcodeBulk": "QrCodeManagement",
        "/layout/DLB/qrcode/qrDownload": "QrCodeManagement",
        "/layout/reports": "Reports",
        "/layout/servicerequest": "ServiceRequest",
        "/layout/adminpanel/subscription": "SubscriptionManagement",
        "/layout/adminpanel/templatemanagement": "TemplateManagement",
        "/layout/adminpanel/gatewaymanagement": "ConditionMonitoring",
        "/layout/adminpanel/template": "TemplateManagement",
        "/layout/DLB/uploads": "DigitalLogbook",
        "/layout/adminpanel/usermanagement": "UserManagement",
        "/overview": "SiteOverview",
        "/assets": "AssetAndSubAsset",
        "/DMM/workPermit": "DigitalMaintenanceManager",
        "/layout/settings/dmm": "Settings",
        "/layout/settings/algorithm": "Settings",
        "/layout/settings/lifecycleManagement": "Settings",
        "/layout/settings/remedialActions": "Settings",
        "/layout/DLB/report/generate": "Reports",
        "/layout/DLB/report/automated": "Reports",
        "/layout/DLB/report/donwloadReports": "Reports",
        "/layout/whatsnew": "WhatsNew",
        "/layout/whatsnew/add": "WhatsNew",
        "/layout/insights": "Insights",
        "/layout/help": "Help",

    }
    constructor(
        private router: Router,
        private rbacService: RbacService,
        private sharedService: SharedService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let module = state.url;
        let url = state.url;
        if (module.includes('DMM/overview')) module = '/layout/DMM/overview';
        else if (module.includes('overview')) module = '/overview';
        else if (module.includes('assets')) module = '/assets';
        else if (module.includes('documents')) module = '/layout/DLB/documents';
        else if (module.includes('alarms')) module = '/layout/alarms';
        else if (module.includes('workPermit')) module = '/DMM/workPermit';
        else if (module.includes('subscription')) module = '/layout/adminpanel/subscription';
        else if (module.includes('layout/Reports')) module = '/layout/reports';
        else if (module.includes('insights')) module = '/layout/insights';
        else if (module.includes('whatsNew')) module = '/layout/whatsnew';
        else if (module.includes('help')) module = '/layout/help';
        else if (module.includes('general')) return true;
        if (this.sharedService.user.id) {
            // check if route is restricted by role
            console.log(this.sharedService.modules[this.aliases[module]]?.canRead);
            console.log(this.aliases[module]);
            console.log(this.sharedService.modules[this.aliases[module]]);
            if (this.sharedService.user?.role?.name !== 'EA.OandM' && (this.sharedService.modules[this.aliases[module]]?.canRead)) {
                return true;
            } else if (this.aliases[module] === 'WhatsNew') {
                return true;
            }
            else {
                // role not authorised so redirect to home page
                this.router.navigate(['/layout/home']);
                return false;
            }
        }
        else {
            this.rbacService.getUserInformation().subscribe(userData => {
                console.log(this.sharedService.modules[this.aliases[module]]?.canRead);
                console.log(this.aliases[module]);
                console.log(this.sharedService.modules[this.aliases[module]]);
                let user = userData;
                if (user.id && user?.role?.name !== 'EA.OandM' && (this.sharedService.modules[this.aliases[module]]?.canRead)) {
                    this.router.navigate([state.url.split('?')[0]], { queryParams: route.queryParams });

                    return true;
                } else {
                    this.router.navigate(['/layout/home']);
                    return false;
                }
            })

        }
        setTimeout(() => {
            console.log(" timeout")
        }, 6000);
        return false;
    }
}