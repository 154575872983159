import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, catchError, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetRecomendedSpares, ReminderFrequency } from '../../../_models/settings.type'
import { AdminSettings, Cause } from 'src/app/_models/settings.type';
import { Configuration } from '../../../_models/settings/condition-monitoring.type';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private baseUrl: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  getGeneralSettings(userId?: number) {
    //const moduleMaster = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/moduleMaster`).pipe(catchError(error => of(error)));
    const notificationMaster = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/notificationMaster`).pipe(catchError(error => of(error)));
    const timeZones = this.http.get(`${this.baseUrl}/maintenanceworkflow/AdminSettings/timezones`).pipe(catchError(error => of(error)));
    const adminType = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`).pipe(catchError(error => of(error)));
    const pushNotification = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/pushNotification/${userId}`).pipe(catchError(error => of(error)));

    return forkJoin({
      //moduleMaster: moduleMaster,
      notificationMaster: notificationMaster,
      timeZones: timeZones,
      adminType: adminType,
      pushNotification
    });
  }

  getSelectedGeneralSettings(customerId: number): Observable<any> {
    // const moduleData = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/module/${customerId}`).pipe(catchError(error => of(error)));
    const notificationData = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/notification/${customerId}`).pipe(catchError(error => of(error)));
    const timeZoneId = this.http.get(`${this.baseUrl}/maintenanceworkflow/AdminSettings/timezones/${customerId}`).pipe(catchError(error => of(error)));
    const measurementUnit = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/measurementUnits/${customerId}`).pipe(catchError(error => of(error)));
    //const licenseId = this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/customerLicenses/${customerId}`).pipe(catchError(error => of(error)));

    return forkJoin({
      // moduleData: moduleData,
      notificationData: notificationData,
      timeZoneId: timeZoneId,
      measurementUnit: measurementUnit,
      //licenseId: licenseId
    });
  }

  getTimeZoneFromId(timeZoneId: string): Observable<string> {
    const url = `${this.baseUrl}/maintenanceworkflow/AdminSettings/timezone/${timeZoneId}`;
    return this.http.get<string>(url);
  }

  postGeneralSettings(postData: any, customerId: number): Observable<any> {
    const url  = `${this.baseUrl}/accesscontrol/GeneralSettings/generalSettings/${customerId}`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  updateThemeSetting(isDarkMode: boolean): Observable<any> {
    const url  = `${this.baseUrl}/accesscontrol/GeneralSettings/generalSettings/darkMode/${isDarkMode}`;
    return this.http.post<any>(url,isDarkMode).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  updatePushNotification(userId: number, isEnabled: boolean): Observable<any> {
    const url  = `${this.baseUrl}/accesscontrol/GeneralSettings/pushNotificationUpdate/${userId}/${isEnabled}`;
    return this.http.patch<any>(url,{}).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  /* DMM Settings - Team Services  */

  getDMMUsers(customerId: number) {
    const newUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/false`).pipe(catchError(error => of(error)));
    const oldUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/true`).pipe(catchError(error => of(error)));
    const dmmRoles = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/dmmroles`).pipe(catchError(error => of(error)));

    return forkJoin({
      newUser: newUser,
      oldUser: oldUser,
      dmmRoles: dmmRoles
    });
  }

  getDMMexistingUser(customerId: number): Observable<Array<any>> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/true`;
    return this.http.get<Array<any>>(url);
  }

  deleteUser(customerId: number, userId: number): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/deleteTeamMember/${userId}/${customerId}`;
    return this.http.delete<any>(url).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      switchMap((successResponse: any) => {
        if(successResponse === 1) {
          const newUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/false`).pipe(catchError(error => of(error)));
          const oldUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/true`).pipe(catchError(error => of(error)));
          return forkJoin({
            newUser: newUser,
            oldUser: oldUser
          });
        } else if (successResponse === 2){
          throw new Error("Can't delete this team member as he/she is part of PTW.");
        } else {
          throw new Error("error in delete role");
        }
      }),
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateAddRole(isUpdate: boolean, payload: any, customerId: number): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/UpsertTeamMember?isUpdate=${isUpdate}`;
    return this.http.put<any>(url, payload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      switchMap((successResponse: number) => {
        if (successResponse === 1 || successResponse === 3) {
          if (isUpdate === false) {
            const newUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/false`).pipe(catchError(error => of(error)));
            const oldUser = this.http.get(`${this.baseUrl}/maintenanceworkflow/DMMSettings/getTeams/${customerId}/true`).pipe(catchError(error => of(error)));
            return forkJoin({
              newUser: newUser,
              oldUser: oldUser
            });
          } else {
            return this.getDMMexistingUser(customerId);
          }
        } else if (successResponse === 2) {
          throw new Error("Can't Update this team member details as he/she is part of PTW.");
        }
        else {
          throw new Error("error in update/delete member role");
        }
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  /* DMM Settings - Maintenance Frequency Services  */

  getComponentType(customerId:any): Observable<Array<string>> {
    const url = `${this.baseUrl}/assetmanagement/Asset/asset/componentTypes/${customerId}`;
    return this.http.get<Array<string>>(url);
  }

  getClassDescription(selectedCompType: string, id: string): Observable<Array<string>> {
    const url = `${this.baseUrl}/assetmanagement/Asset/asset/descriptions/${selectedCompType}/${id}`;
    return this.http.get<Array<string>>(url);
  }

  getCatNumber(selectedCompType: string, selectedClassDesc: string, id: string): Observable<Array<string>> {
    const url = `${this.baseUrl}/assetmanagement/Asset/asset/catNos/${selectedCompType}/${selectedClassDesc}/${id}`;
    return this.http.get<Array<string>>(url);
  }

  getMainFreqByCategory(selectedCompType: string, selectedClassDesc: string, selectedCatNum: string,id: string) {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/getMaintenaceFrequency/${id}?compType=${selectedCompType}&classDes=${selectedClassDesc}&catNo=${selectedCatNum}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getSpareItems(freqId: number): Observable<Array<GetRecomendedSpares>> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/getRecomendedSpares/${freqId}`;
    return this.http.get<Array<GetRecomendedSpares>>(url).pipe(catchError(error => of(error)));
  }

  deleteSpareItem(spareId: number): Observable<boolean> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/deleteSpares/${spareId}`;
    return this.http.delete<boolean>(url).pipe(catchError(error => of(error)));
  }

  sendMaintenanceFrequency(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/maintenanceFrequencyCreation`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getCauses(id: string): Observable<Cause[]> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/breakdownCauses/${id}`;
    return this.http.get<Cause[]>(url);
  }

  getCustomers(): Observable<AdminSettings> {
    return this.http.get<AdminSettings>(`${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`);
  }

  saveCauseList(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/breakdownCauses`;
    return this.http.put<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  deleteCauseList(id: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/breakdownCause/${id}`;
    return this.http.delete<any>(url).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getChecklists(id: string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/ptwCheckLists/${id}`;
    return this.http.get<any>(url);
  }

  deleteChecklist(id: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/ptwCheckList/${id}`;
    return this.http.delete<any>(url).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getWorkPermitPriority(id: string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/workPermitPriorityType/${id}`;
    return this.http.get<any>(url);
  }

  deleteWorkPermitPriority(id: any, category: string): Observable<any> {
    let type = '';
    let url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/`;
    switch (category) {
      case 'permit':
        type = 'workPermitType';
        break;
      case 'work':
        type = 'workType';
        break;
      case 'priority':
        type = 'ptwPriority';
        break;
    }
    url = url + type + `/${id}`;
    return this.http.delete<any>(url).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  saveCheckList(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/ptwChecklists`;
    return this.http.put<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  saveWorkPermitPriority(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/workPermitPriorityType`;
    return this.http.put<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getReminderFrequency(id: string): Observable<ReminderFrequency[]> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/getReminderFrequencies/${id}`;
    return this.http.get<ReminderFrequency[]>(url);
  }

  saveReminderFrequency(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/reminderFrequency`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  downloadTeamSheet(id:string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/DMMSettings/generate/teamSheet/${id}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadTeamSheet(files: any): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0])
    const url  = `${this.baseUrl}/maintenanceworkflow/DMMSettings/upsert/TeamSheet`;
    return this.http.post<any>(url, formData);
  }

  downloadMaintenanceFrequencySheet(id: string): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/generate/maintenanceFrequencyMaster/${id}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadMaintenanceFrequencySheet(files: any, id: string): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0]);
    const url  = `${this.baseUrl}/assetmanagement/Asset/maintenanceFrequencyMaster/${id}`;
    return this.http.post<any>(url, formData);
  }

  /* Condition Monitoring API */

  getGateWayList(siteId: number) {
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/gateways?siteId=${siteId}`;
    // const url = `/assets/mock/gateways.json`;
    return this.http.get<Array<any>>(url).pipe(catchError(error => of(error)));
  }

  getAssetListByGatewayId(gatewayId: number) {
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/assets?gatewayId=${gatewayId}`;
    //const url = `/assets/mock/assets.json`;
    return this.http.get<Array<any>>(url).pipe(catchError(error => of(error)));
  }

  getFeederByAssetId(assetId: number) {
    const url =`${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/assetByFeeder/${assetId}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getConfigurations(pageNumber:number, pageSize: number, assetId: number, feederNo?: string | null) {
    let params = new HttpParams();
    params = params.append('PageNumber', pageNumber);
    params = params.append('PageSize', pageSize);
    params = params.append('assetId', assetId); //37
    if (feederNo) {
      params = params.append('feederNo', feederNo);
    }
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/configuration`;
    //const url = '/assets/mock/configuration.json';
    return this.http.get<Array<any>>(url, {params: params}).pipe(catchError(error => of(error)));
  }

  postConfigurations(changedConfigs: any) {
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/configurations`;
    return this.http.post<Array<any>>(url, changedConfigs).pipe(catchError(error => of(error)));
  }

  vaidateDynamicReference(configId: number) {
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoringSettings/validateDynamicReference`;
    let params = new HttpParams();
    params = params.append('configId', configId);
    return this.http.get<Array<any>>(url, {params: params}).pipe(catchError(error => of(error)));
  }

  //settings algorithm Health KPI
  //Select Assets
  getAssets(customerId: number, siteId:number) {
    const url =`${this.baseUrl}/assetmanagement/HealthKpiSettings/assetBySiteId/${customerId}/${siteId}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getSelectedAssets(customerId: number, siteId:number) {
    const url =`${this.baseUrl}/assetmanagement/HealthKpiSettings/getalgorithmselectassetlist/${customerId}/${siteId}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  postSelectedAssets(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/setalgorithmselectassetlist`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }
  
  //Consequence of failure 
  downloadConsequenceofFailureSheet(customerId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/generate/consequenceOfFailure/${customerId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadConsequenceofFailureSheet(files: any, customerId:number): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0]);
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/upsert/consequenceOfFailure/${customerId}`;
    return this.http.post<any>(url, formData);
  }

  //controls
  postAlgorithmStatus(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/algorithmorchestration/Algorithm/algorithmStatus`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  postPredictAlgorithmStatus(postData: any, isEnabled: boolean): Observable<any> {
    const url  = `${this.baseUrl}/algorithmorchestration/Algorithm/algorithmStatus/prediction/${isEnabled}`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  manualRestart(customerId: any): Observable<any> {
    const url  = `${this.baseUrl}/algorithmorchestration/Algorithm/${customerId}`;
    return this.http.post<any>(url,null).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  //observed condition  
  downloadObservedConditionSheet(customerId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/generate/observedCondition/${customerId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadObservedConditionSheet(files: any, customerId:number): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0]);
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/upsert/observedCondition/${customerId}`;
    return this.http.post<any>(url, formData);
  }

  //measured condition   
  downloadMeasuredConditionSheet(customerId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/generate/measuredCondition/${customerId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadMeasuredConditionSheet(files: any, customerId:number): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0]);
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/upsert/measuredCondition/${customerId}`;
    return this.http.post<any>(url, formData);
  }

  //remedial actions  
  getCauseList(): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeList`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));;
  }

  postCauseList(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeList`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  // reference Settings
  getAlgorithmReferenceSetting(customerId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/getalgorithmreferencesetting/${customerId}`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));;
  }

  postReferenceSetting(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/setalgorithmreferencesettings`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  deleteCauseItem(causeId: number): Observable<boolean> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeList?causeId=${causeId}`;
    return this.http.delete<boolean>(url).pipe(catchError(error => of(error)));
  }

  getCauseAction(actionId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeAction?actionID=${actionId}`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  postCauseAction(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeAction`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  deleteCauseActionItem(causeActionId: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeAction?causeId=${causeActionId}`;
    return this.http.delete<boolean>(url).pipe(catchError(error => of(error)));
  }

  remedialActionUpdate(postData: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/HealthKpiSettings/remedialActionUpdate`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  getCauseListByDigitId(digitId:number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/causeListByDigitId?digitId=${digitId}`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  getRemedialActions(): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/remedialActions`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }


  /**
   * End of life 
   */
  getComponentTypeEol(customerId:any, filter: string): Observable<Array<string>> {
    let url = `${this.baseUrl}/assetmanagement/EndOfLife/asset/componentTypes`;
    if(customerId && filter) url += `?customerId=${customerId}&filter=${filter}`;
    if(customerId && !filter) url += `?customerId=${customerId}`;
    if(!customerId && filter) url += `?filter=${filter}`;
    return this.http.get<Array<string>>(url);
  }

  getClassDescriptionEol(selectedCompType:any,customerId:any, filter: string): Observable<Array<string>> {
    let url = `${this.baseUrl}/assetmanagement/EndOfLife/asset/descriptions?componentType=${selectedCompType}`;
    if(customerId) url += `&customerId=${customerId}`;
    if(filter) url += `&filter=${filter}`
    return this.http.get<Array<string>>(url);
  }

  getCatNumberEol(selectedCompType: string, selectedClassDesc: string, customerId: string,  filter: string): Observable<Array<string>> {
    let url = `${this.baseUrl}/assetmanagement/EndOfLife/asset/catNos?componentType=${selectedCompType}&description=${selectedClassDesc}`;
    if(customerId) url += `&customerId=${customerId}`;
    if(filter) url += `&filter=${filter}`
    return this.http.get<Array<string>>(url);
  }

  getEol(selectedCompType: string, selectedClassDesc: string, selectedCartNum: string, customerId: string): Observable<string> {
    let url = `${this.baseUrl}/assetmanagement/EndOfLife/asset/EOL?componentType=${selectedCompType}&description=${selectedClassDesc}&catNos=${selectedCartNum}`;
    if(customerId) url += `&customerId=${customerId}`;
    return this.http.get<string>(url);
  }

  saveEol(payload: any): Observable<any> {
    const url  = `${this.baseUrl}/assetmanagement/EndOfLife/asset/EOLUpdate`;
    return this.http.post<any>(url, payload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => of(error))
    )
  }

  downloadEolSheet(customerId: string, filter: string): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/EndOfLife/generate/endofLife`;
    if(customerId && filter) url += `?customerId=${customerId}&filter=${filter}`;
    if(customerId && !filter) url += `?customerId=${customerId}`;
    if(!customerId && filter) url += `?filter=${filter}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }

  uploadEolSheet(files: any, customerId: string): Observable<any> {
    const formData = new FormData();
    formData.append('file',files[0]);
    let url  = `${this.baseUrl}/assetmanagement/EndOfLife/endOfLifeUpdate`;
    if(customerId) url += `?customerId=${customerId}`;
    return this.http.post<any>(url, formData);
  }
}
