import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modalFilter'
})
export class ModalFilterPipe implements PipeTransform {
  transform(value: any, searchText?: any): any {
    if (!value) return null;
    if (!searchText) return value;

    searchText = searchText.toLowerCase();
    return value.filter(function (data: any) {
      return JSON.stringify(data).toLowerCase().includes(searchText);
    });
  }

}
