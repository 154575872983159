<div>
  <div class="close-container" (click)="closeDialog()">
    <span class="close-icon"><img class="Modal-close" src="/assets/images/dmm_icons/presentation_mode.svg" alt="close"/></span>
  </div>
  <div mat-dialog-content style="max-height: none;">
    <!-- Tabs navs -->
    <ul class="nav nav-tabs" id="ex1" role="tablist">
      <ng-container *ngIf="groupedSubAssets?.length <= 10">
        <li class="nav-item" role="presentation" *ngFor="let item of groupedSubAssets; let i = index">
          <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
            aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedLevel == item.level? 'active':''"
            (click)="setSelectedLevel(item.level);">{{item.level}}</a>
        </li>
      </ng-container>
      <ng-container *ngIf="groupedSubAssets?.length > 10">
        <li class="nav-item" role="presentation" *ngFor="let item of currArray; let i = index">
          <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
            aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedLevel == item.level? 'active':''"
            (click)="setSelectedLevel(item.level);">{{item.level}}</a>
        </li>
      </ng-container>
      <li class="nav-item">
        <app-pagination [totalRecords]="groupedSubAssets?.length" [recordsPerPage]="recordsPerPage"
          (onPageChange)="displayActiveSection($event)" *ngIf="groupedSubAssets?.length > 10"></app-pagination>
      </li>
    </ul>

    <!-- Tabs navs -->

    <!-- Tabs content -->

    <div class="tab-content" id="ex1-content">
      <ng-container *ngIf="groupedSubAssets?.length <= 10">
        <div class="tab-pane fade" [ngClass]="selectedLevel == item.level? 'active show':''" id="ex1-tabs-{{i}}"
          role="tabpanel" attr.aria-labelledby="ex1-tabs-{{i}}" *ngFor="let item of groupedSubAssets; let i = index">
          <!-- first tab content starts-->
          <div class="row">
            <div class="col-12 col-lg-1 feeders-list">
              <div class="content-value py-2 pe-2" *ngFor="let subAsset of allSubAssetsForSelectedLevel" role="button"
                (click)="setSelectedSubAsset(subAsset)" [ngClass]="subAsset == selectedSubAsset ? 'selected-feeder':''">
                {{subAsset}}</div>
            </div>
            <div class="col-12 col-lg-11" style="height: 70vh;overflow-y: scroll;">
              <div class="content-value py-2" *ngFor="let subAsset of allSubAssetsForSelectedLevel, let j =index" role="button"
              (click)="setSelectedSubAsset(subAsset)">
              <form [formGroup]="parentFormGroup" *ngFor="let feederForm  of feederDetails[subAsset]; let formIndex = index;">
                <div class="assetClass"
                  *ngIf="(feederForm['submittedResponse'] | json) !== '{}' && selectedSubAsset == subAsset">{{
                  feederForm.subAssetType + ' - ' + feederForm.subAssetClass }}</div>
                <app-work-performed *ngIf="feederForm['submittedResponse'] && selectedSubAsset == subAsset"
                  [template]="feederForm['submittedResponse']" [isPreview]="false" [feederNo]="subAsset"
                  [formId]="feederForm.id" [disableWorkPerformedForm]="disableWorkPerformedForm"></app-work-performed>
              </form>
            </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="groupedSubAssets?.length > 10">
        <div class="tab-pane fade" [ngClass]="selectedLevel == item.level? 'active show':''" id="ex1-tabs-{{i}}"
          role="tabpanel" attr.aria-labelledby="ex1-tabs-{{i}}" *ngFor="let item of currArray; let i = index">
          <!-- first tab content starts-->
          <div class="row">
            <div class="col-12 col-lg-1 feeders-list">
              <div class="content-value py-2 pb-0 pe-2" *ngFor="let subAsset of allSubAssetsForSelectedLevel"
                (click)="setSelectedSubAsset(subAsset)" type="button"
                [ngClass]="subAsset == selectedSubAsset ? 'selected-feeder':''">
                <span>{{subAsset}}</span>
              </div>
            </div>
            <div class="col-12 col-lg-11" style="height: 70vh;overflow-y: scroll;">

                <div class="content-value py-2" *ngFor="let subAsset of allSubAssetsForSelectedLevel, let j =index" role="button"
                (click)="setSelectedSubAsset(subAsset)">
                <form [formGroup]="parentFormGroup" *ngFor="let feederForm  of feederDetails[subAsset]; let formIndex = index;">
                  <div class="assetClass"
                    *ngIf="(feederForm['submittedResponse'] | json) !== '{}' && selectedSubAsset == subAsset">{{
                    feederForm.subAssetType + ' - ' + feederForm.subAssetClass }}</div>
                  <app-work-performed *ngIf="feederForm['submittedResponse'] && selectedSubAsset == subAsset"
                    [template]="feederForm['submittedResponse']" [isPreview]="false" [feederNo]="subAsset"
                    [formId]="feederForm.id" [disableWorkPerformedForm]="disableWorkPerformedForm"></app-work-performed>
                </form>
              </div> 
            </div>
          </div>
        </div>
      </ng-container>

      <!-- first tab content ends -->
    </div>
    <!-- Tabs content -->


    <!-- adding horizontal and vertical menu here -->

    <!-- <form [formGroup]="parentFormGroup">
      <app-work-performed [template]="subAsset" [isPreview]="false" [feederNo]="subAssetFeederNo"></app-work-performed>
    </form> -->

  </div>
</div>
