import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { SitesComponent } from './sites/sites.component';
import { OverviewComponent } from './overview/overview.component';
import { AlarmComponent } from './alarm/alarm.component';
import { MaintenanceManagerComponent } from './maintenance-manager/maintenance-manager.component';
import { CompareAssetsComponent } from './compare-assets/compare-assets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SitesDashboardComponent } from './sites-dashboard/sites-dashboard.component';
import { AssetsComponent } from './assets/assets.component';
import { RegisterComponent } from './register/register.component';
import { LandingComponent } from './landing/landing.component';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { InsightsComponent } from './modules/insights/insights.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
    { path: '', redirectTo: "layout", pathMatch: 'full' },
    { path: 'register', component: RegisterComponent },
    {
        path: 'layout', component: LayoutComponent,
        data: { breadcrumb: { skip: true } },
        children: [
            {
                path: '',
                redirectTo: "home",
                pathMatch: 'full'
            },
            {
                path: 'sites', component: SitesComponent,
                data: { breadcrumb: { alias: 'Site' } },
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: "dashboard", pathMatch: 'full' },
                    {
                        path: 'dashboard',
                        component: DashboardComponent,
                        data: { breadcrumb: { alias: 'Dashboard' } }
                    },
                    {
                        path: 'site/:id',
                        component: SitesDashboardComponent,
                        data: { breadcrumb: { skip: true } },
                        children: [
                            { path: '', redirectTo: "overview", pathMatch: 'full' },
                            { path: 'overview', component: OverviewComponent, data: { breadcrumb: { alias: 'Overview' } } },
                            { path: 'assets', component: AssetsComponent, data: { breadcrumb: { alias: 'Assets' } } },
                            { path: 'monitoring', component: MaintenanceManagerComponent },
                            //{ path: 'health', component:  },
                            { path: 'compare', component: CompareAssetsComponent, data: { breadcrumb: { alias: 'Compare' } } }
                        ]
                    }
                ]
            },
            { path: 'home', component: LandingComponent, data: { breadcrumb: { skip: true } } },
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: { breadcrumb: { alias: 'Dashboard' } }
            },
            {
                path: 'settings',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
            },
            {
              path: 'DMM',
              canActivate: [AuthGuard],
              loadChildren: () => import('./modules/DMM/DMM.module').then(m => m.DMMModule)
            },
            {
                path: 'alarms', component: AlarmComponent, data: { breadcrumb: { alias: 'Alarms' } },  canActivate: [AuthGuard]

            },
            {
                path: 'adminpanel',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/admin-panel/admin-panel.module').then(m => m.AdminPanelModule)
            },
            {
                path: 'DLB',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/DLB/DLB.module').then(m => m.DLBModule)
            },
            {
                path: 'profile', component: UserProfileComponent, data: { breadcrumb: { alias: 'profile' } } 
            },
            {
                path: 'Reports',
                loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'insights',
                //path: 'insights', component: InsightsMainComponent, data: { breadcrumb: { alias: 'Insights' } }
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/insights/insights.module').then(m => m.InsightsModule)
            },
            {
                path: 'whatsnew',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/whats-new/whats-new.module').then(m => m.WhatsNewModule)
            },
            { 
                path: 'contact', 
                component: ContactUsComponent,
                data: { breadcrumb: { alias: 'contact' } }
            },
            {
                path: 'help',
                loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
            },
        ],
        // canActivate: [AuthGuard]
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
