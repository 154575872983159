<div class="w-100" style="overflow-y:auto">
    <div class="card w-100 mt-3" *ngFor="let element of feedersData; let i = index">
        <div class="alarm-data collapsedView" id="element{{i+tabno}}">
            <div class="row ps-2 pe-2 mx-1">
                <div class="col-12 list-value" style="position: relative;">
                    <span style="position: absolute; top:23px;right: 17px;" role="button">
                        <i class="fa fa-angle-up hide" (click)="toggleHeight(i+tabno, 'collapse')"
                            id="collapse{{i+tabno}}"></i>
                        <i class="fa fa-angle-down show" (click)="toggleHeight(i+tabno, 'expand')"
                            id="expand{{i+tabno}}"></i>
                    </span>
                </div>
                <ng-container *ngFor="let item of getKeys(element)">
                    <ng-container *ngIf="(isCustomerUser && item !== 'WarrantyStartDate' && item !== 'WarrantyEndDate' && item !== 'Tag') || !isCustomerUser">
                    <div class="col-lg-3 col-6 px-3" *ngIf="!item.includes('Id')">
                        <div class="field-header mt-2">{{element[item]?.displayName | camelToSpace |
                            titlecase}}</div>
                        <div [id]="item" class="field-value col" *ngIf="!(assetEdit && selectedField == item && selectedSubAssetId == element['Id'].value)">
                            <ng-container
                                *ngIf="element[item].dataType !== 'DateTime' && element[item].dataType !== 'Boolean' && item !== 'DeletionFlag'" >
                                <span>{{(element[item].value)?
                                    element[item].value :
                                    '-'}} </span>
                            </ng-container>
                            <ng-container *ngIf="element[item].dataType == 'DateTime' && item !== 'DeletionFlag'">
                                <span>{{element[item].value | date: 'd/M/yyyy'}} </span>
                            </ng-container>
                            <ng-container *ngIf="element[item].dataType == 'Boolean' && item !== 'DeletionFlag'">
                                <span *ngIf="item =='IsDigital'">{{element[item].value ?
                                    'Digital' : 'Non Digital'}} </span>
                                <span
                                    *ngIf="item !=='IsDigital' && element[item].value.length">{{element[item].value.length
                                    ?
                                    'Yes' : 'No'}} </span>
                                <span *ngIf="item !=='IsDigital' && !element[item].value.length">-</span>
                            </ng-container>
                            <ng-container *ngIf="item === 'DeletionFlag'">
                                <span *ngIf="item =='DeletionFlag'"> <input type="checkbox" *ngIf="element[item].value" [checked]="element[item].value" 
                                    readonly></span>
                            </ng-container>
                            <button class="edit-icon" [disabled]="pendingRequest" *ngIf="isEditableForUser && element[item].isWritable"  (click)="setEditProperties(element, item)">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </div>
                        <ng-container
                            *ngIf="element[item].dataType !== 'DateTime' && element[item].dataType !== 'Boolean' && item !== 'DeletionFlag'">
                            <input id="input-{{item}}" type="text" [ngModel]="element[item].value" (change)="updateTempValue(element,item, $event)"
                                *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value"
                                (keydown)="$event.stopPropagation()" class="edit-asset col">
                        </ng-container>
                        <ng-container *ngIf="element[item].dataType == 'DateTime' && item !== 'DeletionFlag'">
                            <input id="input-{{item}}" type="date" value="{{element[item].value | date: 'yyyy-MM-dd'}}" (change)="updateTempValue(element,item, $event)"
                                *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value"
                                (keydown)="$event.stopPropagation()" class="edit-asset col">
                        </ng-container>
                        <ng-container *ngIf="element[item].dataType == 'Boolean' && item !== 'DeletionFlag'">
                            <ng-container *ngIf="item =='IsDigital'">
                                <select name="isdigital" id="isdigital" class="edit-asset col"
                                    [ngModel]="element[item].value" (change)="updateTempValue(element,item, $event)"
                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value"
                                    (keydown)="$event.stopPropagation()">
                                    <option value="true">Digital</option>
                                    <option value="false">Non Digital</option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="item !=='IsDigital'">
                                <select name="notdigital" id="notdigital" class="edit-asset col"
                                    [ngModel]="element[item].value" (change)="updateTempValue(element,item, $event)"
                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value"
                                    (keydown)="$event.stopPropagation()">
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item ==='DeletionFlag' && isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value">
                            <input type="checkbox" value="" id="input-{{item}}" [checked]="element[item].value" (click)="updateTempValue(element, item, $event)" #check>
                        </ng-container>
                        <div class="col" (click)="selectedField = item"
                            *ngIf="(isEditableForUser && assetEdit && selectedField == item && element[item].isWritable && selectedSubAssetId == element['Id'].value)">
                            <i class="fa fa-check healthy p-1" title="Save" (click)="sendValues(element,item)"></i>
                            <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                (click)="assetEdit = !assetEdit;element[item].value=oldVal"></i>
                        </div>
                    </div>
                </ng-container>
                </ng-container>

            </div>
        </div>
    </div>
</div>