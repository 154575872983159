<div class="chart-container">
<h5 class="chart-title col-11">{{ chartTitle }}</h5>
<div class=" col-1">
    <a class="card-menu" id="dropdownMenuLink5" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-ellipsis-v"></i>
    </a>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink5"
        style="box-shadow: 0px 0px 15px #00000066;border-radius: 5px;">
        <div class="list-card-header">Details</div>
        <li class="liststyle">
            <ng-container *ngIf="chartTitle=='Obsolescence Profile'">
                <a class="dropdown-item" data-mdb-toggle="modal" data-mdb-target="#exampleModal3">
                    List View
                </a>
            </ng-container>
            <ng-container *ngIf="chartTitle=='Maintenance Summary'">
                <a class="dropdown-item" data-mdb-toggle="modal" data-mdb-target="#exampleModal2">
                    List View
                </a>
            </ng-container>
        </li>
        <li class="liststyle"><a class="dropdown-item">Download Reports</a></li>
    </ul>
    <ng-container *ngIf="chartTitle=='Obsolescence Profile' && obsolenscenceProfileList">
        <app-popup-modal [obsolenscenceProfileList]='obsolenscenceProfileList' popupData="Obsolescence Profile"
            [siteId]="siteId"></app-popup-modal>
    </ng-container>
    <ng-container *ngIf="chartTitle=='Maintenance Summary' && maintenanceSummaryList">
        <app-popup-modal [maintenanceSummaryList]='maintenanceSummaryList' popupData="Maintenance Summary"
            [siteId]="siteId"></app-popup-modal>
    </ng-container>

</div>
</div>
<div id="{{apiEndPoint}}" [ngClass]="noDataForChart == ''?'chart-element':''"></div>
<div class="chart-btn-group" *ngIf="noDataForChart === ''">
    <button type="button" (click)="customLegendFilter(legend)" class="btn btn-light btn-rounded"
      [class.selected]="legend.chartBtnSelected" [ngClass]="'chart-btn'+[i+1]"
      *ngFor="let legend of customLegend?.Count; let i = index">{{ legend.value }}</button>
  </div>
<div *ngIf="noDataForChart !== ''" class="text-center mt-5 critical">{{ noDataForChart }}</div>
<div class="chart-legend mt-1">
  <ul class="custom-legend">
    <li class="custom-legend-label" *ngIf="false">
      <span class="variant-text"></span>
    </li>
    <li class="custom-legend-label" *ngFor="let legend of customLegend?.Count; let i = index">
      <span [ngStyle]="{'background-color': colors[i]}" class="variant-indicator"></span>
      <span class="variant-text">{{ legend.name }}</span>
    </li>
  </ul>
</div>
