import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  files: any;
  @Input() status: string = 'none';
  @Output() addFileEvent = new EventEmitter<string>();
  @Output() uploadFileEvent = new EventEmitter<string>();
  @Output() cancelEvent = new EventEmitter<string>();
  @Input() message!: string;
  myFiles: any;
  @Input() changing!: Subject<boolean>;

  @Input() allowedFormats!: string[];
  
  constructor() { }

  ngOnInit(): void {
    this.changing?.subscribe(v => { 
     this.cancel();
    });
  }

  //check allowed file formats
  checkFile(fileName: string) {
    let arr = fileName.split('.');
    let len = arr.length;
    let ext = arr[len-1].toLocaleLowerCase();
    if(this.allowedFormats.indexOf(ext) !==-1)
      return false;
    return true;  
  }

  addFile(e: any) {
    let files = e.target ? e.target.files : e;
    let currentSelectedFiles = [];
    // console.log(this.files);
    if(files.length === 0) {
      if(this.files.length === 0) {
        console.log("no file found");
        return;
      }

    } else {
      if(this.checkFile(files[0].name)) {
        this.status = 'error';
        this.message = 'Invalid File selected';
        this.showErrorMessage();
        return;
      }
      this.files = files;
    //   console.log(this.files);
      this.addFileEvent.emit(this.files);
    }
  }

  showErrorMessage() {
    setTimeout(() => {
      this.status = 'none';
      this.message = '';
    }, 4000);
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDropSuccess(event: any) {
    event.preventDefault();
    this.addFile(event.dataTransfer.files);
  }

  uploadFile() {
    this.uploadFileEvent.emit();
  }

  cancel() {
    this.files = null;
    this.cancelEvent.emit();
    this.myFiles = null;
  }


}
