import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';

export type FilterOption = {
    name: string, value: string
}

export type FilterGroup = {
    name: string; // to be displayed in UI
    fieldName: string; // key to be passed on API
    type: string; // daterange, checkbox
    list: FilterOption[]; // filter options
    countLimit: number,
    hidden: boolean,
    value: {}
}

@Component({
    selector: 'app-group-filter',
    templateUrl: './group-filter.component.html',
    styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnInit, OnChanges {

    @Input() filterGroups: FilterGroup[] = [];
    @Input() clearAll: boolean = false;
    @Output() filterSelected =  new EventEmitter<FilterGroup[]>();
    filterGroupsCopy: FilterGroup[] = [];
    filterControl = new FormControl();
    filterFromDate: string;
    filterToDate: string;
    rangeOption = '';
    isNaN = isNaN;
    selectTriggerValue: string[] = [];

    constructor() { }

    ngOnInit(): void {
        this.filterGroupsCopy = cloneDeep(this.filterGroups);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['clearAll'] && changes['clearAll'].currentValue) {
            this.filterGroups = cloneDeep(this.filterGroupsCopy);
            this.filterControl.reset();
            this.rangeOption = this.filterFromDate = this.filterToDate = '';
            this.selectTriggerValue = [];
        }
    }

    filterSelection(event, index: number, item: string){
        this.filterGroups[index].value[item] = event.source.selected;
        let selectedValues = [];
        for (const key of Object.keys(this.filterGroups[index].value)) {
            if(this.filterGroups[index].value[key]){
                const itemName = this.filterGroups[index].list.find(item => item.value === key)?.name
                selectedValues.push(itemName);
            }
        }
        if(event.isUserInput) this.filterSelected.emit(this.filterGroups);
        const selectIndex = this.selectTriggerValue?.findIndex(str => str.includes(this.filterGroups[index].name));
        const selctedValueWithGroup = this.filterGroups[index].name + ' - ' + selectedValues?.join(',');
        if(selectIndex >=0 && selectedValues.length) {
            this.selectTriggerValue[selectIndex] = selctedValueWithGroup;
            return;
        }
        if(selectedValues.length) {
            this.selectTriggerValue.push(selctedValueWithGroup);
            return;
        }
        if(selectIndex >=0 && !selectedValues.length){
            this.selectTriggerValue.splice(selectIndex,1);
            return;
        }
    }

    setRangeOption(event, index: number){
        const range = event.value;
        for (const key of Object.keys(this.filterGroups[index].value)) {
            if(key === 'from' || key === 'to')
                this.filterGroups[index].value[key]= "";
            else
                this.filterGroups[index].value[key]= false;
        }
        this.filterGroups[index].value[range]= true;
        if(range === 'custom') return;
        this.filterSelected.emit(this.filterGroups);
        this.filterFromDate = this.filterToDate = '';
        const itemName = this.filterGroups[index].list.find(item => item.value === range)?.name
        const selectIndex = this.selectTriggerValue?.findIndex(str => str.includes(this.filterGroups[index].name));
        const selctedValueWithGroup = this.filterGroups[index].name + ' - ' + itemName;
        if(selectIndex >= 0) {
            this.selectTriggerValue[selectIndex] = selctedValueWithGroup;
            return
        };
        this.selectTriggerValue.push(selctedValueWithGroup);
    }

    setCustomRange(event, index: number, key: string){
        this.filterGroups[index].value[key] = new Date(event).toISOString();
        const selectIndex = this.selectTriggerValue?.findIndex(str => str.includes(this.filterGroups[index].name));
        const selctedValueWithGroup = this.filterGroups[index].name + ' - ' + this.filterFromDate + ' - ' + this.filterToDate;
        if(selectIndex < 0) {
            this.selectTriggerValue.push(selctedValueWithGroup);
            return
        };
        this.selectTriggerValue[selectIndex] = selctedValueWithGroup;
        if(key === 'to') this.filterSelected.emit(this.filterGroups);
    }

    clearAllFilters(){
        this.filterGroups = cloneDeep(this.filterGroupsCopy);
        this.filterControl.reset();
        this.rangeOption = this.filterFromDate = this.filterToDate = '';
        this.selectTriggerValue = [];
        this.filterSelected.emit(this.filterGroups);
    }

}
