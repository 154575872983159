<ng-container>
    <div class="table-widget" [style.height.px]="tableHeight">
    <div class="tableScroll" [style.height.px]="tableHeight - 60">
        <table mat-table [dataSource]="dataSource" matSort [ngClass]="{'link-item': isLinkItem}">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null; getSelected($event)"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation();"
                                (change)="$event ? selection.toggle(row) : null; getSelected($event)"
                                [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
    
            <ng-container matColumnDef="{{column.field}}" *ngFor="let column of header">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="{{'Sort by ' + column.title}}"> {{column.title}} </th>
                <td mat-cell *matCellDef="let element;" (click)="isLinkItem ? '' : columnClick(element)"> 
                    <span [class]="column.field" (click)="linkColumn === column.field ? columnClick(element) : ''" [title]="column.field === 'assets' || column.field === 'publishTo' ? element[column.field] : ''" [ngClass]="element.IsVisited ? '' : 'not-visited-item'">{{element[column.field]}}</span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Download </th>
                <td mat-cell *matCellDef="let element" class="text-center"> 
                    <a class="pointer" (click)="fileDownload(element)"><i class="fa fa-cloud-download-alt"></i></a> 
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="status"> Status</th>
                <td mat-cell *matCellDef="let element">
                    <span title="{{element?.status}}"
                        class="fa fa-circle {{element?.status?.toLowerCase()}} me-1"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="text-center"> 
                    <a *ngIf="actions.delete" title="Delete" class="pointer" (click)="actionCall(element.id,'delete')"><i class="fa fa-solid fa-trash-can"></i></a> 
                    <a *ngIf="actions.edit" title="Edit" class="pointer" (click)="actionCall(element.id,'edit')"><i class="fa fa-solid fa-pencil ms-3"></i></a> 
                    <a *ngIf="actions.replace" title="Replace" class="pointer" (click)="actionCall(element.id,'replace')"><i class="fa fa-solid fa-arrow-right-arrow-left ms-3"></i></a> 
                    <span  *ngIf="actions.download && element.action !== 'Success'" [ngClass]="element.action === 'Error' ? 'status-error' : 'status-progress'">{{element.action}}</span>
                    <button *ngIf="actions.download && element.action === 'Success'" type="button" class="btn btn-primary px-2" (click)="fileDownload(element)">
                        <span>Download &nbsp;<i class="fa fa-cloud-download-alt ms-1"></i></span>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="loader">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <div class="container text-center my-5">
                        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="noData">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                  No Data
                </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['loader']" class="loader-tr" [hidden]="!loading"></tr>
            <tr mat-footer-row *matFooterRowDef="['noData']" class="nodata-tr" [hidden]="loading || dataSource.data.length > 0"></tr>

        </table>         
    </div>
    <mat-paginator #paginator [length]="totalRecords" [pageSize]="10" (page)="onPageChange($event)" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
</div>
</ng-container>