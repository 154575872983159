import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FeederDetails } from '../_models/feeder.type';
import { IPredictService } from '../_services/i-predict.service';
import { RbacService } from '../_helpers/rbac.service';
import { SharedService } from '../_services/shared.service';
import { AssetService } from '../_services/assets.service';

@Component({
    selector: 'app-sub-asset-details',
    templateUrl: './subasset-details.component.html',
    styleUrls: ['./subasset-details.component.scss']
})
export class SubAssetDetailsComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    @Input('selectedAssetId') selectedAssetId: any;
    @Input('subAssetsList') subAssetsList: any;
    @Input() customerId: any = 0;
    @Input() siteId: number = 0;
    @Input() health: any;
    @Input() isEditableForUser!: boolean;
    @Input() selectedFilter: any;

    feederDetails!: FeederDetails | any;
    assetId: string = '';
    selectedSet = 0;
    recordsPerPage = 10;
    start: any;
    end: any;
    currArray: any;
    healthData: any;
    showList: boolean = false;
    isMobile: boolean = false;
    cardView: boolean = true;
    commonFeederNo: string = '';
    selectedHealthfilter: string = '';
    healthStatus: string = '';
    criticalStatus: string = '';
    healthKpiTab: boolean = false;
    ibDataTab: boolean = true;
    conditionTab: boolean = false;
    module: any;
    calculatePage: Boolean = false;
    subAssetVerticals: any;
    feederList: any;
    selectedFeeder: any;
    selectedVertical: any;
    analyticsData: any;
    offsetDate: string = '';

    statusPayload = {
        "healthFilter": {
          "veryGood": false,
          "good": false,
          "fair": false,
          "poor": false,
          "veryPoor": false,
          "unknown": false
        },
        "criticalityFilter": {
          "insignitficant": false,
          "minor": false,
          "major": false,
          "critcal": false
        }
    }

    constructor(private iPredictService: IPredictService,
        private deviceService: DeviceDetectorService,
        public sharedService: SharedService,
        private rbacService: RbacService,
        private assetService: AssetService) { }

    ngOnInit(): void {
        if (this.sharedService.user.id) {
            this.module = this.sharedService.modules;
        } else {
            this.rbacService.getUserInformation().subscribe((value: any) => {
                this.module = this.sharedService.modules;
                if(!this.module.IBData.canUpdate) {
                    this.isEditableForUser = false;
                }
            })
        }
        this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
        this.epicFunction();
        this.getSubAssetDetails();
    }

    resetFilterStatus(){
        this.statusPayload = {
            "healthFilter": {
              "veryGood": false,
              "good": false,
              "fair": false,
              "poor": false,
              "veryPoor": false,
              "unknown": false
            },
            "criticalityFilter": {
              "insignitficant": false,
              "minor": false,
              "major": false,
              "critcal": false
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedFilter) {
            this.resetFilterStatus();
            this.healthStatus = this.selectedFilter[0];
            this.criticalStatus = this.selectedFilter[1];
            this.statusPayload.healthFilter[this.healthStatus] = true;
            this.statusPayload.criticalityFilter[this.criticalStatus] = true;
            this.getSubAssetDetails();
        }
    }

    epicFunction() {
        this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        if (this.isMobile) {
            this.recordsPerPage = 3;
        } else this.recordsPerPage = 10;
    }

    getSubAssetDetails(): void {
        this.iPredictService.getSubAssetsForAssetIdABS(this.selectedAssetId, this.statusPayload).subscribe((data: any) => {
            this.subAssetVerticals = data;
            if (this.subAssetVerticals.length) {
                this.selectedVertical = this.subAssetVerticals[0]
                // if(this.subAssetVerticals?.length <= this.recordsPerPage)
                    this.currArray = this.subAssetVerticals.slice(0, this.recordsPerPage);
                // else
                    this.getSubAssetFeeders(this.selectedVertical);
            }
        });
    }

    getSubAssetFeeders(subAssetVertical){
        this.iPredictService.getAllSubAssetsForFeederABS(this.selectedAssetId,subAssetVertical, this.statusPayload).subscribe((data: any) => {
            this.feederDetails = data?.feederSubAssetDetails;
            this.healthData = data?.healthStatus;
            this.feederList = Object.keys(this.feederDetails);
            if(this.feederList?.length){
                this.feederList.push('Common')
                this.commonFeederNo = subAssetVertical;
                this.setSelectedFeeder(this.feederList[0])
            }
        });
    }

    setSelectedVertical(vertical: string) {
        this.selectedVertical = vertical;
        this.getSubAssetFeeders(vertical);
    }

    setHealthStatuspayload(status: string){
        if (this.healthStatus == status) {
            this.healthStatus = '';
        } else {
            this.healthStatus = status;
        }
        this.statusPayload.healthFilter.veryGood = status === 'veryGood' && this.healthStatus ? true : false;
        this.statusPayload.healthFilter.good = status === 'good' && this.healthStatus ? true : false;
        this.statusPayload.healthFilter.fair = status === 'fair' && this.healthStatus ? true : false;
        this.statusPayload.healthFilter.poor = status === 'poor' && this.healthStatus ? true : false;
        this.statusPayload.healthFilter.veryPoor = status === 'veryPoor' && this.healthStatus ? true : false;
        this.statusPayload.healthFilter.unknown = status === 'unknown' && this.healthStatus ? true : false;

        this.getSubAssetDetails();
    }

    setCriticalStatuspayload(status: string){
        if (this.criticalStatus == status) {
            this.criticalStatus = '';
        } else {
            this.criticalStatus = status;
        }
        this.statusPayload.criticalityFilter.insignitficant = status === 'insignitficant' && this.criticalStatus ? true : false;
        this.statusPayload.criticalityFilter.minor = status === 'minor' && this.criticalStatus ? true : false;
        this.statusPayload.criticalityFilter.major = status === 'major' && this.criticalStatus ? true : false;
        this.statusPayload.criticalityFilter.critcal = status === 'critcal' && this.criticalStatus ? true : false;

        this.getSubAssetDetails();
    }
    
    setSelectedFeeder(feeder: string){
        this.selectedFeeder = undefined;
        this.selectedFeeder = feeder;
        this.getAlgoStatus(feeder);
        if (this.selectedFeeder == 'Common') {
            if(this.ibDataTab) this.tabActivation('conditionTab');
        }
    }

    displayActiveSection(activeSection: any) {
        this.start = activeSection.start;
        this.end = activeSection.end;
        this.currArray = this.subAssetVerticals.slice(this.start, this.end);
        this.setSelectedVertical(this.currArray[0]);
    }

    filterByHealth(health: string) {
        if (this.healthStatus == health) {
            this.healthStatus = '';
        } else {
            this.healthStatus = health;
        }
    }

    tabActivation(tabValue: string) {
        this.calculatePage = false;
        if (tabValue == 'ibDataTab') {
            this.ibDataTab = true;
            this.healthKpiTab = false;
            this.conditionTab = false;
        }
        else if (tabValue == 'conditionTab') {
            this.ibDataTab = false;
            this.conditionTab = true;
            this.healthKpiTab = false;
        }
        else if (tabValue == 'healthKpiTab') {
            this.ibDataTab = false;
            this.conditionTab = false;
            this.healthKpiTab = true;
        }
    }

    closeWindow() {
        this.calculatePage = false;
    }

    getAlgoStatus(feeder: string) {
        this.assetService.getAlgoStatus(this.customerId ? this.customerId : 0, this.siteId, this.selectedAssetId).subscribe((data:any) => {
          this.analyticsData = data;
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}