import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-horizontal-chart',
  templateUrl: './horizontal-chart.component.html',
  styleUrls: ['./horizontal-chart.component.css']
})
export class HorizontalChartComponent implements OnInit, OnDestroy {

  single!: any[];
  view: [any, any] = [260, 130];
  categoryView: [number, number] = [260, 115];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Population';
  xAxisTicks: number[] = [];
  maxYAxisTickLength: number = 30;
  noChartData: string = '';
  xScaleMin: number = 0; 
  xScaleMax!: number;

  /* colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  }; */

  colorScheme: Color = {
    name: 'site-chart',
    selectable: false,
    group: ScaleType.Ordinal,
    domain: ['#1DA6DB']
  };

  accessOptions!: Array<number>;
  accessOptionValue: string = 'Top 5';
  private subscription: Subscription = new Subscription();

  @Input('chartTitle') chartTitle!: string;
  @Input('apiEndPoint') apiEndPointName!: string;
  @Input('type') type!: string;

  constructor(
    private dashboardService: DashboardService,
    private router: Router
  ) {
   }

  ngOnInit(): void {
    this.subscription.add(
      this.dashboardService.getHorizontalCharts(this.type, this.apiEndPointName, 5).subscribe(chartData => {
        this.accessOptions = chartData.accessOptions
        this.renderData(chartData);
      })
    )
  }

  getMoreData(option: number, value: string) {
    this.accessOptionValue = value
    this.subscription.add(
      this.dashboardService.getHorizontalCharts(this.type, this.apiEndPointName, option).subscribe(chartData => {
        this.accessOptions = chartData.accessOptions;
        this.renderData(chartData);
      })
    )
  }

  renderData(chartData: any) {
    if (chartData.results.length > 0) {
      this.noChartData = '';
      const maxValue = Math.max(...chartData.results.map((element: any) => element.value));
      this.single =  chartData.results;
      this.xAxisTicks = this.getTicks(maxValue);
      if (this.single.length < 6) {
        this.view = [260, 120]
        this.categoryView = [260, 120];
        for (let i = this.single.length; i < 5; i++) {
          this.single.unshift({ name: '0' + i, value: 0 });
        }
      } else {
        for (let i = this.single.length; i < 10; i++) {
          this.single.unshift({ name: '0' + i, value: 0 });
        }
        this.view = [260, 200]
        this.categoryView = [260, 200];
      }
    } else {
      this.noChartData = '0 Assets'
    }

  }

  xAxisTickFormatting(value: string) {
    return parseInt(value);
  }

  yAxisTickFormatting(name: string) {
    return name[0]=='0' ? '' : name;
  }

  getTicks(max: number) {
    let arr=[];
    let interval = Number(((max)/5).toFixed(0));
    if(interval) {
      for(let i=0;i*interval<=max;i++){
        arr.push(i*interval);
      }
    } else {
      for(let i=0;i<=5;i++){
        arr.push(i);
      }
    }

    return arr;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
