<div class="container-fluid pb-2 main-page">
    <div class="row pt-3 ps-3 pe-3 desktop-alarm" *ngIf="isDesktopDevice">
        <div class="col-2">
            <div class="search col-12 mt-4" *ngIf="sites">
                <select name="sites" id="sites" class="form-control select-input" [(ngModel)]="siteId"
                    (change)="onSiteChange($event)" role="button">
                    <option [value]="site.siteId" *ngFor="let site of sites" [title]="site.siteName">
                        {{ (site.siteName.length>20)? (site.siteName | slice:0:20)+'..':(site.siteName)}}
                    </option>
                </select>
                <i class="fa fa-angle-down"></i>
            </div>
        </div>
        <div class="col-1 p-0">
            <div class="refetch-data"><i class="fa fa-sync" (click)="refetchData();" role="button"></i></div>
        </div>
        <div class="col-1 p-3" style="width:2%">
            <div class="line h-100 w-100" style="border-right:1px solid gray"></div>
        </div>
        <div class="col-xl-6 col-lg-8 col-12 col-sm-12">
            <div class="row">
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === '' ? 'selected all-border' : ''"
                        (click)="applyStatusFilter('', '')"><span class="app-value">{{alarmStatistics?.all}}</span>
                    </div>
                    <div class="app-caption">All</div>
                </div>
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === 'Critical' ? 'selected critical-border' : ''"
                        (click)="applyStatusFilter('Critical', 'alarmClass');"><span
                            class="app-value critical">{{alarmStatistics?.criticalAlarm}}</span></div>
                    <div class="app-caption">Critical Alarm</div>
                </div>
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === 'High' ? 'selected high-border' : ''"
                        (click)="applyStatusFilter('High', 'alarmClass')"><span
                            class="app-value high">{{alarmStatistics?.highAlarm}}</span></div>
                    <div class="app-caption">High Alarm</div>
                </div>
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === 'Low' ? 'selected low-border' : ''"
                        (click)="applyStatusFilter('Low', 'alarmClass')"><span
                            class="app-value low">{{alarmStatistics?.lowAlarm}}</span></div>
                    <div class="app-caption">Low Alarm</div>
                </div>
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === 'Warning' ? 'selected critical-border' : ''"
                        (click)="applyStatusFilter('Warning', 'eventType')"><span
                            class="app-value critical">{{alarmStatistics?.warnings}}</span></div>
                    <div class="app-caption">Warnings</div>
                </div>
                <div class="col text-center">
                    <div class="app-circle btn btn-floating btn-lg"
                        [ngClass]="statusFilterText === 'Notification' ? 'selected notification-border' : ''"
                        (click)="applyStatusFilter('Notification', 'eventType')"><span
                            class="app-value notification">{{alarmStatistics?.notifications}}</span></div>
                    <div class="app-caption">Notifications</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3 ps-3 pe-3" *ngIf="isDesktopDevice">
        <div class="alarm-list">
            <div class="alram-header">
                <div class="row align">
                    <div class="col-6" style="position: relative;">
                        <ng-container
                            *ngIf="!((newAlarms | filter: searchKey:['details', 'assetId']  | fieldBasedFilter: statusFilterText:statusFilterType)?.length <= 0)">
                            <div class="ps-3 selectAll flex align" *ngIf="alarms?.length">
                                <input type="checkbox" value="" id="selectAll"
                                    (click)="(check.checked ? cat.selectAll(): cat.deselectAll()); onGroupsChange(cat.selectedOptions.selected)"
                                    #check [checked]="selectedOptions?.length == alarms?.length">
                                <label class="ms-1 list-value" for="flexCheckDefault">{{!check.checked ? 'Select All':
                                    'Deselect All'}}</label>
                                <div *ngIf="routeParams" class="responseStyle">{{responseMessage + '.'}} <a
                                        class="anchor" (click)="refetchData()">show all</a></div>
                            </div>
                        </ng-container>

                    </div>
                    <div class="col-6" #menu>
                        <div class="row d-flex justify-content-end">
                            <div class="search col-4">
                                <input type="text" class="form-control select-input" placeholder="Search"
                                    [(ngModel)]="searchKey" (keyup.enter)="getAlarmsWithPagination(true,'search')"
                                    (keyup)="searchKey?.length > 2 || searchKey?.length === 0 ? getAlarmsWithPagination(true,'search') : ''">
                                <i class="fa fa-search" (click)="getAlarmsWithPagination(true,'search')"></i>
                            </div>
                            <div class="search col-3">
                                <mat-select multiple name="filters" id="filters" [formControl]="filterControl"
                                    placeholder="Filters" class="form-control select-input alarmFilter"
                                    disableOptionCentering #alarmFilters>
                                    <mat-select-trigger>
                                        {{ filterControl.value?.[0] }}
                                        <span *ngIf="filterControl.value?.length > 1">(+ {{ filterControl.value?.length
                                            -1 }})</span>
                                    </mat-select-trigger>
                                    <div class="group" style="position:relative">
                                        <a class="filterShowMore" *ngIf="alarmFilterData.alarmClass.list.length > 3"
                                            (click)="toggleFilterList('alarmClass', alarmFilterData.alarmClass.hidden)">{{
                                            alarmFilterData.alarmClass.hidden ? 'See all':'See less' }}</a>
                                        <mat-optgroup label="Alarm Class">
                                            <mat-option
                                                *ngFor="let alarmClass of alarmFilterData.alarmClass.list | slice:0:alarmFilterData.alarmClass.countLimit"
                                                [value]="alarmClass"
                                                (onSelectionChange)="filterSelection($event, 'alarmClass')">
                                                {{alarmClass}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </div>
                                    <div class="group" style="position:relative;">
                                        <a class="filterShowMore" *ngIf="alarmFilterData.eventType.list.length > 3"
                                            (click)="toggleFilterList('eventType', alarmFilterData.eventType.hidden)">{{
                                            alarmFilterData.eventType.hidden ?
                                            'See all':'See less' }}</a>
                                        <mat-optgroup label="Event Class">
                                            <mat-option
                                                *ngFor="let eventType of alarmFilterData.eventType.list | slice:0:alarmFilterData.eventType.countLimit"
                                                [value]="eventType"
                                                (onSelectionChange)="filterSelection($event, 'eventClass')">
                                                {{eventType}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </div>
                                    <div class="group" style="position:relative;">
                                        <a class="filterShowMore" *ngIf="alarmFilterData.status.list.length > 3"
                                            (click)="toggleFilterList('status', alarmFilterData.status.hidden)">{{
                                            alarmFilterData.status.hidden ? 'See all':'See less' }}</a>
                                        <mat-optgroup label="Status">
                                            <mat-option
                                                *ngFor="let status of alarmFilterData.status.list | slice:0:alarmFilterData.status.countLimit"
                                                [value]="status"
                                                (onSelectionChange)="filterSelection($event, 'status')">
                                                {{status}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </div>
                                    <div class="group" style="position:relative;">
                                        <a class="filterShowMore"
                                            *ngIf="alarmFilterData.assetCategoryName.list.length > 3"
                                            (click)="toggleFilterList('assetCategoryName', alarmFilterData.assetCategoryName.hidden)">{{
                                            alarmFilterData.assetCategoryName.hidden ? 'See all' : 'See less' }}</a>
                                        <mat-optgroup label="Asset Category">
                                            <mat-option
                                                *ngFor="let assetCategoryName of alarmFilterData.assetCategoryName.list | slice:0:alarmFilterData.assetCategoryName.countLimit"
                                                [value]="assetCategoryName"
                                                (onSelectionChange)="filterSelection($event, 'assetCategories')">
                                                {{assetCategoryName}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </div>
                                    <div class="group" style="position:relative;">
                                        <a style="margin-left:20px" class="filterShowMore"
                                            *ngIf="alarmFilterData.Make.list.length > 3"
                                            (click)="toggleFilterList('Make', alarmFilterData.Make.hidden)">{{
                                            alarmFilterData.Make.hidden ? 'See all' : 'See less' }}</a>
                                        <mat-optgroup label="Component Make">
                                            <mat-option
                                                *ngFor="let Make of alarmFilterData.Make.list | slice:0:alarmFilterData.Make.countLimit"
                                                [value]="Make"
                                                (onSelectionChange)="filterSelection($event, 'assetMake')">
                                                {{Make}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </div>
                                    <mat-optgroup label="Date Range">
                                    </mat-optgroup>

                                    <div class="label-container">
                                        <label style="margin-top: 20px;">From </label>
                                        <input matInput type="date" id="fromDate" name="fromDate"
                                            placeholder="00/00/0000" style="margin-left:12px"
                                            [(ngModel)]="filterFromDate" (ngModelChange)="filterSelection($event)" [max]="currentDate">

                                        <label style="margin-top: 20px;margin-bottom:20px"> To </label>
                                        <input matInput [min]="filterFromDate" type="date" id="toDate" name="toDate"
                                            placeholder="00/00/0000" style="margin-left:12px" [(ngModel)]="filterToDate"
                                            (ngModelChange)="filterSelection($event)" [max]="currentDate">
                                    </div>
                                    <mat-optgroup (click)="clearAllFilters()" class="clearAll" role="button">
                                        <span>Clear All</span>
                                    </mat-optgroup>
                                </mat-select>
                            </div>
                            <div class="search col-3">
                                <div class="parent dropstart">
                                    <div class="input-group mb-3" *ngIf="module?.AlarmOrEvents?.canUpdate">
                                        <!-- <button class="btn btn-sm alarm-btn p-0" type="button"
                                            data-mdb-ripple-color="dark" [disabled]="!selectedOptions?.length"
                                            [ngClass]="selectedOptions?.length ? 'enabled':''">
                                            <a (click)="sendMail()"
                                                href="mailto:abc@example.com?subject={{Feedback}}&body={{Message}}"
                                                style="color: unset;" class="py-2 px-3"><i
                                                    class="fa fa-envelope" matTooltip='send via email'></i></a>
                                        </button> -->
                                        <a (click)="sendMail()" [ngClass]="!selectedOptions?.length ? 'disabled' : ''" href="mailto:abc@example.com?subject={{Feedback}}&body={{Message}}" style="color: unset;" class="send-mail-link">
                                            <app-icon class="me-1" title="Send via email" iconFor="envelope" action="group" [disabled]="!selectedOptions?.length"></app-icon>
                                        </a>
                                        <!-- <button id="dropdownMenuLink1" role="button" data-mdb-toggle="dropdown"
                                            aria-expanded="false" class="btn btn-sm alarm-btn p-0" type="button" data-mdb-ripple-color="dark" matTooltip='actions'
                                            [disabled]="!selectedOptions?.length"
                                            [ngClass]="selectedOptions?.length ? 'enabled': ''">
                                            <i class="fa-solid fa-code-compare py-2 px-3"></i>
                                        </button> -->
                                        <app-icon id="dropdownMenuLink1" class="me-1" title="Actions" iconFor="code-compare" action="group" [disabled]="!selectedOptions?.length"
                                        data-mdb-toggle="dropdown" aria-expanded="false" ></app-icon>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink1"
                                            onclick="event.stopPropagation()"
                                            style="box-shadow: 0px 0px 15px #00000066;border-radius: 5px;">
                                            <ng-container *ngIf="selectedOptions?.length">
                                            <div class="form-check">
                                                Actions
                                            </div>
                                            <!-- <div *ngIf="!isWorkProgress">
                                            <div class="form-check" >
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                                value="Open" [(ngModel)]='selectedStatus' />
                                                <label for="inlineRadio1" class="ms-2">Open</label>
                                            </div>
                                            </div> -->

                                            <div class="form-check" *ngIf="!isNotification && !isClosedAlarm">
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                                    value="WorkInProgress" [(ngModel)]='selectedStatus'
                                                    [disabled]="isWorkProgress" />
                                                <label for="inlineRadio2" class="ms-2">Work In Progress</label>
                                            </div>
                                            <div class="form-check" *ngIf="selectedStatus == 'WorkInProgress'">
                                                <input type="text" id="wipno" class="ms-2 form-control "
                                                    style="width: 100px;" required [(ngModel)]="wipno">
                                            </div>
                                            <div class="form-check" *ngIf="!isNotification && !isClosedAlarm">
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                                    value="Closed" [(ngModel)]='selectedStatus' />
                                                <label for="inlineRadio3" class="ms-2">Close</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio4"
                                                    value="Delete" [(ngModel)]='selectedStatus' />
                                                <label for="inlineRadio4" class="ms-2">Delete</label>
                                            </div>
                                            <div class="form-check" *ngIf="selectedOptions?.length === 1 && !isNotification && !isClosedAlarm">
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio5"
                                                    value="CreatePTW" [(ngModel)]='selectedStatus' />
                                                <label for="inlineRadio5" class="ms-2">Create PTW</label>
                                            </div>
                                            <div class="form-check" *ngIf="selectedOptions?.length === 1 && !isNotification && !isClosedAlarm">
                                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio6"
                                                    value="ERP" [(ngModel)]='selectedStatus' />
                                                <label for="inlineRadio6" class="ms-2">Notification to ERP</label>
                                            </div>
                                            <button class="btn w-100 mx-0 px-0 py-2 enabled"
                                                [id]="selectedStatus + wipno"
                                                [disabled]="!(selectedStatus && (selectedStatus == 'WorkInProgress'?  wipno.length : true))"
                                                (click)="changeStatus();menu.click()">Apply</button>
                                                </ng-container>
                                        </div>

                                        <!-- <button class="btn btn-sm alarm-btn p-0" type="button"
                                            data-mdb-ripple-color="dark"
                                            [disabled]="!selectedOptions?.length || statusFilterText ==='Notification'|| (selectedOptions?.length == 1 && selectedOptions[0].status == 'Closed' )"
                                            [ngClass]="selectedOptions?.length ? (((selectedOptions.length == 1 && selectedOptions[0].status == 'Closed') || statusFilterText ==='Notification') ? '':'enabled'):''"
                                            (click)="acknowledge()" matTooltip='acknowledge'>
                                            <i class="fa fa-check py-2 px-3"></i>
                                        </button> -->
                                        <app-icon class="me-2" title="Acknowledge" iconFor="check" action="group" [disabled]="!selectedOptions?.length || statusFilterText ==='Notification'|| (selectedOptions?.length == 1 && selectedOptions[0].status == 'Closed')" (clickEvent)="acknowledge()"></app-icon>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-2">
                                <app-icon class="me-2" title="Print" iconFor="print" action="layout"></app-icon>
                                <app-icon title="Download" iconFor="download" action="layout"></app-icon>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isLoading">
                <div class="container text-center mt-5">
                    <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
                </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <div class="text-center m-4 critical" *ngIf="newAlarms.length == 0">No Data found for the Applied Filter
                </div>
            </ng-container>
            <div class="">
                <!-- <div class="card w-100 mt-3"> -->
                <mat-selection-list [disableRipple]="true" #cat
                    (selectionChange)="onGroupsChange(cat.selectedOptions.selected)" class="alarm-data">
                    <mat-list-option
                        *ngFor="let alarm of newAlarms  | fieldBasedFilter: statusFilterText:statusFilterType"
                        [value]="alarm" [disableRipple]="true" [checkboxPosition]="'before'"
                        class="card w-100 my-3 alarm-list-option">
                        <div class="alarm-data collapsedView" id="alarm{{alarm.Id}}" (click)="prevent($event)">
                            <div class="row ps-2 pe-2">
                                <div class="col-1 list-header" style="width:5%"></div>
                                <div class="col-1 list-header">ASSET ID</div>
                                <div class="col-3 list-header" style="width:28%">DETAILS</div>
                                <div class="col-2 list-header" style="width:13.667%">RECEIVED AT</div>
                                <div class="col-4 list-header" style="width:32.33%">ACTION PERFORMED BY</div>
                                <div class="col-1 list-header" style="width:10%">STATUS</div>
                                <div class="col-1 list-header" style="width:2%">
                                    <!-- <i class="fa fa-ellipsis-v fa-2x"
                                            style="color:#908282"></i> -->
                                </div>
                            </div>
                            <div class="row ps-2 pe-2 alarm-data">
                                <div class="col-1 list-value" style="width:5%;margin-top: -12px;">
                                    <!-- <input type="checkbox"
                                            value="" class="me-2 ms-2">  -->
                                    <i class="fa fa-exclamation-triangle ms-2" *ngIf="alarm.alarmClass"
                                        [ngClass]="(alarm.alarmClass)|lowercase" style="font-size: 1.3em;"></i>
                                    <i class="fa fa-info-circle ipredict-blue ms-2"
                                        *ngIf="alarm.eventType == 'Notification'" style="font-size: 1.3em;"></i>
                                    <i class="fa fa-info-circle critical ms-2" *ngIf="alarm.eventType == 'Warning'"
                                        style="font-size: 1.3em;"></i>
                                </div>
                                <div class="col-1 list-value"><a>{{alarm.assetId}}</a></div>
                                <div class="col-3 list-value" style="width:28%">{{alarm.details}}</div>
                                <div class="col-2 list-value" style="width:13.667%">{{alarm.receivedAt + 'Z' | date:
                                    'dd/MM/yyy h:mm a': offsetDate}}</div>
                                <div class="col-4 list-value" style="width:32.33%">

                                    <div class="row step completed">
                                        <ng-container *ngFor="let item of alarm.information;let i =index">
                                            <div class="v-stepper">
                                                <div class="circle"></div>
                                                <div class="line" *ngIf="i !== alarm.information.length-1"></div>
                                            </div>

                                            <div class="content">
                                                <div class="col">{{(item.performedAt + 'Z' | date: 'dd/MM/yyyy
                                                    h:mm:ssa': offsetDate) + ' '
                                                    + (item.performedAction=='WorkInProgress'?
                                                    'WIP':item.performedAction) + ' by ' +
                                                    (item.performedBy.split(' ')[0]|titlecase) + ' ' +
                                                    (item.performedAction=='WorkInProgress'?
                                                    item.workInProgress||'':'')}}</div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                                <div class="col-1 list-value" style="width:10%">{{alarm.status|camelToSpace|titlecase}}
                                </div>
                                <div class="col-1 list-value hide" id="collapse{{alarm.Id}}" style="width:2%"
                                    (click)="toggleHeight(alarm.Id, 'collapse', $event)">
                                    <i class="fa fa-angle-up "></i>
                                </div>
                                <div class="col-1 list-value show" id="expand{{alarm.Id}}" style="width:2%"
                                    (click)="toggleHeight(alarm.Id, 'expand', $event)">
                                    <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                            <div class="row ps-2 pe-2" *ngIf="module?.AlarmOrEvents?.canUpdate">
                                <div class="col-2" style="width:12.33%"></div>
                                <div class="col-5" (click)="prevent($event)">
                                    <label class="list-header" for="commentsBox">COMMENTS</label>
                                    <div class="input-group ms-1 mb-2" id="commentsBox"
                                        *ngIf="alarm.status !== 'Closed'">
                                        <input type="text" class="form-control" aria-label="Comment"
                                            (keydown)="$event.stopPropagation()" #comment />
                                        <button class="input-group-text"
                                            (click)="addComment(comment.value,alarm.Id, alarm);comment.value =''"
                                            [disabled]="!(comment.value && comment.value.trim().length)">Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row ps-2 pe-2">
                                <div class="col-2" style="width:12.33%"></div>
                                <div class="col-7" (click)="prevent($event)">
                                    <div class="row" style="max-height: 105px;overflow-y: scroll;margin: 15px;">
                                        <div *ngFor="let comment of alarm.comments" class="mt-2">
                                            <div style="font-size: x-small;color: #9d9b9a;" class="ps-2">
                                                {{(comment.performedby|titlecase) + ' | '+
                                                (comment.performedOn + 'Z'| date: 'dd/MM/yyyy h:mm:ss a': offsetDate)}}
                                            </div>
                                            <div class="row ps-2">
                                                <div style="font-size: 15px;" [id]="alarm.Id+'edit'+comment.id"
                                                    *ngIf="!(commentEdit && selectedComment == comment.id)"
                                                    class="col-8 comment-text">
                                                    <span style="visibility: visible!important;">{{comment.comment}}
                                                    </span>
                                                    <span class="edit-icon"><i class="fa fa-pencil"
                                                            (click)="setCommentId(comment.id)"
                                                            *ngIf="!(commentEdit && selectedComment == comment.id)"></i></span>
                                                </div>
                                                <input [id]="comment.id" type="text" [(ngModel)]="comment.comment"
                                                    *ngIf="commentEdit && selectedComment == comment.id"
                                                    (keydown)="$event.stopPropagation()" class="col-8">
                                                <!-- <div class="col-2" (click)="setCommentId(comment.id) " [id]="alarm.Id+'edit'+comment.id"
                                                    *ngIf="!(commentEdit && selectedComment == comment.id)"><i
                                                        class="fa fa-pencil"></i></div> -->
                                                <div class="col-2" (click)="setCommentId(comment.id) "
                                                    *ngIf="(commentEdit && selectedComment == comment.id)">
                                                    <i class="fa fa-check healthy p-1" title="Save"
                                                        (click)="updateComment(alarm.Id,comment.id, comment.comment, alarm, comment.CommentId)"></i>
                                                    <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                        (click)="commentEdit = !commentEdit"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3" style="position:relative">
                                    <div style="position:absolute;bottom:1px;margin: 15px;" *ngIf="alarm.eventType !== 'Notification'">
                                        <span class="list-header">ALARM ACTIVATED FOR </span>
                                        <span class="list-value"
                                            style="font-size: 12px;">{{(alarm.activatedFor?.split(':')[0].includes('.')
                                            == true ?
                                            alarm.activatedFor?.split(':')[0].split('.')[0] +
                                            (alarm.activatedFor?.split(':')[0].split('.')[0] == '1' ? 'day ':
                                            'days ') + alarm.activatedFor?.split(':')[0].split('.')[1]
                                            +(alarm.activatedFor?.split(':')[0].split('.')[1] == '01' ? 'hr ':
                                            'hrs '): (alarm.activatedFor?.split(':')[0]|number) +
                                            (alarm.activatedFor?.split(':')[0] == '01' ? 'hr ': 'hrs ')+
                                            (alarm.activatedFor?.split(':')[1]|number)+ ' min' ) }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
                <!-- </div> -->
                <mat-paginator #paginator [length]="totalRecords" [pageSize]="pageSize" (page)="getData($event)"
                    [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page" [disabled]="!newAlarms?.length">
                </mat-paginator>
            </div>

        </div>
    </div>
    <div *ngIf="isMobile">
        <div class="row">
            <div class="search col-7 mt-4" *ngIf="sites">
                <select name="sites" id="sites" class="form-control select-input" [(ngModel)]="siteId"
                    (change)="onSiteChange($event)" role="button">
                    <option [value]="site.siteId" *ngFor="let site of sites" [title]="site.siteName">
                        {{ (site.siteName.length>20)? (site.siteName | slice:0:20)+'..':(site.siteName)}}
                    </option>
                </select>
                <i class="fa fa-angle-down"></i>
            </div>
            <div class="col-1">
                <div class="mt-4"><i class="fa fa-sync" (click)="refetchData();" role="button"></i></div>
            </div>
            <div class="col-1">
                <div class="mt-4"><i class="fa fa-search" (click)="searchOpen=true;" role="button"></i></div>
            </div>
            <div class="filter-search p-2" *ngIf="searchOpen">
                <div class="row">
                    <div class="col-6">Search</div>
                    <div class="col-6"><i class="fa fa-times float-end" (click)="searchOpen=false"></i></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input type="text" class="form-control filter-input" placeholder="Search"
                            [(ngModel)]="searchKey" (keyup.enter)="getAlarmsWithPagination(true,'search')"
                            (blur)="getAlarmsWithPagination(true,'search')" (keyup)="searchKey?.length > 2 || searchKey?.length === 0 ? getAlarmsWithPagination(true,'search') : ''">
                    </div>
                </div>
            </div>
            <div class="col-1 mobile-div p-0" style="position: relative;">

                <mat-select multiple name="filters" id="filters" [formControl]="filterControl"
                    class="form-control select-input alarmFilter p-0" disableOptionCentering #alarmFilters style="height: 67px;">

                    <div class="group" style="position:relative">
                        <a class="filterShowMore" *ngIf="alarmFilterData.alarmClass.list.length > 3"
                            (click)="toggleFilterList('alarmClass', alarmFilterData.alarmClass.hidden)">{{
                            alarmFilterData.alarmClass.hidden ? 'See all':'See less' }}</a>
                        <mat-optgroup label="Alarm Class">
                            <mat-option
                                *ngFor="let alarmClass of alarmFilterData.alarmClass.list | slice:0:alarmFilterData.alarmClass.countLimit"
                                [value]="alarmClass" (onSelectionChange)="filterSelection($event, 'alarmClass')">
                                {{alarmClass}}
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    <div class="group" style="position:relative;">
                        <a class="filterShowMore" *ngIf="alarmFilterData.eventType.list.length > 3"
                            (click)="toggleFilterList('eventType', alarmFilterData.eventType.hidden)">{{
                            alarmFilterData.eventType.hidden ?
                            'See all':'See less' }}</a>
                        <mat-optgroup label="Event Class">
                            <mat-option
                                *ngFor="let eventType of alarmFilterData.eventType.list | slice:0:alarmFilterData.eventType.countLimit"
                                [value]="eventType" (onSelectionChange)="filterSelection($event, 'eventClass')">
                                {{eventType}}
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    <div class="group" style="position:relative;">
                        <a class="filterShowMore" *ngIf="alarmFilterData.status.list.length > 3"
                            (click)="toggleFilterList('status', alarmFilterData.status.hidden)">{{
                            alarmFilterData.status.hidden ? 'See all':'See less' }}</a>
                        <mat-optgroup label="Status">
                            <mat-option
                                *ngFor="let status of alarmFilterData.status.list | slice:0:alarmFilterData.status.countLimit"
                                [value]="status" (onSelectionChange)="filterSelection($event, 'status')">
                                {{status}}
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    <div class="group" style="position:relative;">
                        <a class="filterShowMore" *ngIf="alarmFilterData.assetCategoryName.list.length > 3"
                            (click)="toggleFilterList('assetCategoryName', alarmFilterData.assetCategoryName.hidden)">{{
                            alarmFilterData.assetCategoryName.hidden ? 'See all' : 'See less' }}</a>
                        <mat-optgroup label="Asset Category">
                            <mat-option
                                *ngFor="let assetCategoryName of alarmFilterData.assetCategoryName.list | slice:0:alarmFilterData.assetCategoryName.countLimit"
                                [value]="assetCategoryName"
                                (onSelectionChange)="filterSelection($event, 'assetCategories')">
                                {{assetCategoryName}}
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    <div class="group" style="position:relative;">
                        <a style="margin-left:20px" class="filterShowMore" *ngIf="alarmFilterData.Make.list.length > 3"
                            (click)="toggleFilterList('Make', alarmFilterData.Make.hidden)">{{
                            alarmFilterData.Make.hidden ? 'See all' : 'See less' }}</a>
                        <mat-optgroup label="Component Make">
                            <mat-option
                                *ngFor="let Make of alarmFilterData.Make.list | slice:0:alarmFilterData.Make.countLimit"
                                [value]="Make" (onSelectionChange)="filterSelection($event, 'assetMake')">
                                {{Make}}
                            </mat-option>
                        </mat-optgroup>
                    </div>
                    <mat-optgroup label="Date Range">
                    </mat-optgroup>

                    <div class="label-container">
                        <label style="margin-top: 19px;">From </label>
                        <input matInput type="date" id="fromDate" name="fromDate" placeholder="00/00/0000"
                            style="margin-left:5px" [(ngModel)]="filterFromDate"
                            (ngModelChange)="filterSelection($event)">

                        <label style="margin-top: 19px;margin-bottom:11px"> To </label>
                        <input matInput [min]="filterFromDate" type="date" id="toDate" name="toDate"
                            placeholder="00/00/0000" style="margin-left:5px" [(ngModel)]="filterToDate"
                            (ngModelChange)="filterSelection($event)">
                    </div>
                    <mat-optgroup (click)="clearAllFilters()" class="clearAll" role="button">
                        <span>Clear All</span>
                    </mat-optgroup>
                </mat-select>
                <mat-select-trigger>
                    <mat-icon matSuffix>filter_list</mat-icon>
                </mat-select-trigger>
            </div>
        </div>
        <div class="row pt-3" style="background: #fff;">
            <div class="col-12">
                <div class="row circles">
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === '' ? 'selected all-border' : ''"
                            (click)="applyStatusFilter('', '')"><span class="app-value">{{alarmStatistics?.all}}</span>
                        </div>
                        <div class="app-caption">All</div>
                    </div>
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === 'Critical' ? 'selected critical-border' : ''"
                            (click)="applyStatusFilter('Critical', 'alarmClass')"><span
                                class="app-value critical">{{alarmStatistics?.criticalAlarm}}</span></div>
                        <div class="app-caption">Critical</div>
                    </div>
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === 'High' ? 'selected high-border' : ''"
                            (click)="applyStatusFilter('High', 'alarmClass')"><span
                                class="app-value high">{{alarmStatistics?.highAlarm}}</span></div>
                        <div class="app-caption">High</div>
                    </div>
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === 'Low' ? 'selected low-border' : ''"
                            (click)="applyStatusFilter('Low', 'alarmClass')"><span
                                class="app-value low">{{alarmStatistics?.lowAlarm}}</span></div>
                        <div class="app-caption">Low</div>
                    </div>
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === 'Warning' ? 'selected critical-border' : ''"
                            (click)="applyStatusFilter('Warning', 'eventType')"><span
                                class="app-value critical">{{alarmStatistics?.warnings}}</span></div>
                        <div class="app-caption">Warnings</div>
                    </div>
                    <div class="col text-center">
                        <div class="app-circle btn btn-floating btn-lg"
                            [ngClass]="statusFilterText === 'Notification' ? 'selected notification-border' : ''"
                            (click)="applyStatusFilter('Notification', 'eventType')"><span
                                class="app-value notification">{{alarmStatistics?.notifications}}</span></div>
                        <div class="app-caption">Notifications</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alram-header mb-3" style="background: #fff;">
            <div class="row ">
                <div class="col-6" style="position: relative; top: -9px;">
                    <div class="ps-2 selectAll" *ngIf="alarms?.length">
                        <input type="checkbox" value="" id="selectAll"
                            (click)="(check.checked ? cat.selectAll(): cat.deselectAll()); onGroupsChange(cat.selectedOptions.selected)"
                            #check [checked]="selectedOptions?.length == alarms?.length">
                        <label class="ms-1" for="flexCheckDefault">{{!check.checked ? 'Select All': 'Deselect All'}}</label>
                    </div>
                </div>
                <div class="search col-6" #menu>
                    <!-- <div class="parent dropstart"> -->
                    <div class="input-group" *ngIf="module?.AlarmOrEvents?.canUpdate">
                        <!-- <button class="btn btn-sm" type="button" data-mdb-ripple-color="dark"
                            [disabled]="!selectedOptions?.length" [ngClass]="selectedOptions?.length ? 'enabled':''">
                            <a (click)="sendMail()" href="mailto:abc@example.com?subject={{Feedback}}&body={{Message}}"
                                style="color: unset;"><i class="fa fa-envelope"></i></a>
                        </button> -->
                        <a (click)="sendMail()" [ngClass]="!selectedOptions?.length ? 'disabled' : ''" href="mailto:abc@example.com?subject={{Feedback}}&body={{Message}}" style="color: unset;" class="send-mail-link">
                            <app-icon class="me-1" title="Send via email" iconFor="envelope" action="group" [disabled]="!selectedOptions?.length"></app-icon>
                        </a>
                        <!-- <button id="dropdownMenuLink1" role="button" data-mdb-toggle="dropdown" aria-expanded="false"
                            class="btn btn-sm p-2" type="button" data-mdb-ripple-color="dark" 
                            [disabled]="!selectedOptions?.length"
                            [ngClass]="selectedOptions?.length ? 'enabled': ''">
                            <i class="fa-solid fa-code-compare"></i>
                        </button> -->
                        <app-icon id="dropdownMenuLink1" class="me-1" title="Actions" iconFor="code-compare" action="group" [disabled]="!selectedOptions?.length"
                                        data-mdb-toggle="dropdown" aria-expanded="false" ></app-icon>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink1" onclick="event.stopPropagation()"
                            style="box-shadow: 0px 0px 15px #00000066;border-radius: 5px;">
                            <ng-container *ngIf="selectedOptions?.length">
                            <div class="form-check">
                                Actions
                            </div>

                            <div class="form-check"
                            *ngIf="!isNotification && !isClosedAlarm">
                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                    value="WorkInProgress" [(ngModel)]='selectedStatus' [disabled]="isWorkProgress" />
                                <label for="inlineRadio2" class="ms-2">Work In Progress</label>
                            </div>
                            <div class="form-check" *ngIf="selectedStatus == 'WorkInProgress'">
                                <input type="text" id="wipno" class="ms-2 form-control " style="width: 100px;" required
                                    [(ngModel)]="wipno">
                            </div>
                            <div class="form-check" *ngIf="!isNotification && !isClosedAlarm">
                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="Closed"
                                    [(ngModel)]='selectedStatus' />
                                <label for="inlineRadio3" class="ms-2">Close</label>
                            </div>
                            <div class="form-check">
                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio4"
                                    value="Delete" [(ngModel)]='selectedStatus' />
                                <label for="inlineRadio4" class="ms-2">Delete</label>
                            </div>
                            <div class="form-check" *ngIf="selectedOptions?.length === 1 && !isNotification && !isClosedAlarm">
                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio5"
                                    value="CreatePTW" [(ngModel)]='selectedStatus' />
                                <label for="inlineRadio5" class="ms-2">Create PTW</label>
                            </div>
                            <div class="form-check" *ngIf="selectedOptions?.length === 1 && !isNotification && !isClosedAlarm">
                                <input class="" type="radio" name="inlineRadioOptions" id="inlineRadio6"
                                    value="ERP" [(ngModel)]='selectedStatus' />
                                <label for="inlineRadio6" class="ms-2">Notification to ERP</label>
                            </div>
                            <button class="btn w-100 mx-0 px-0 py-2 enabled" [id]="selectedStatus + wipno"
                                [disabled]="!(selectedStatus && (selectedStatus == 'WorkInProgress'?  wipno.length : true))"
                                (click)="changeStatus();menu.click()">Apply</button>
                            </ng-container>
                        </div>
                        <app-icon class="me-2" title="Acknowledge" iconFor="check" action="group" [disabled]="!selectedOptions?.length || statusFilterText ==='Notification'|| (selectedOptions?.length == 1 && selectedOptions[0].status == 'Closed')" (clickEvent)="acknowledge()"></app-icon>
                        <!-- <button class="btn btn-sm p-2" type="button" data-mdb-ripple-color="dark"
                            [disabled]="!selectedOptions?.length || statusFilterText ==='Notification'|| (selectedOptions?.length == 1 && selectedOptions[0].status == 'Closed' )"
                            [ngClass]="selectedOptions?.length ? (((selectedOptions.length == 1 && selectedOptions[0].status == 'Closed')||statusFilterText ==='Notification') ? '':'enabled'):''"
                            (click)="acknowledge()">
                            <i class="fa fa-check"></i>
                        </button> -->
                    </div>
                    <!-- </div> -->
                </div>
                <!-- <div class="col-1 mt-2"><i class="fa fa-print"></i></div>
                <div class="col-1 mt-2"><i class="fa fa-download"></i></div> -->
            </div>
        </div>
        <div *ngIf="routeParams && !isLoading" class="responseStyle">{{responseMessage + '.'}} <a class="anchor"
                (click)="refetchData()">show all</a></div>
        <ng-container *ngIf="isLoading">
            <div class="container text-center mt-5">
                <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <div class="text-center m-4 critical" *ngIf="newAlarms.length == 0">No Data found for the Applied Filter
            </div>
        </ng-container>
        <div class="row" style="background: #EBEBEB; overflow-y: scroll;" [style.height]="(innerHeight-287)+'px'">
            <div class="alarm-list px-1">
                <div class="h-100">
                    <mat-selection-list [disableRipple]="true" #cat
                        (selectionChange)="onGroupsChange(cat.selectedOptions.selected)" class="alarm-data">
                        <mat-list-option
                            *ngFor="let alarm of newAlarms  | fieldBasedFilter: statusFilterText:statusFilterType"
                            [value]="alarm" [disableRipple]="true" [checkboxPosition]="'before'"
                            class="card w-100 my-3">
                            <div (click)="prevent($event)" class="alarm-data collapsedView pb-1" id="alarm{{alarm.Id}}"
                                style="position: relative;">
                                <div class="row ps-2 pe-0 pt-3 alarm-data">
                                    <div class="col-1 list-header"></div>
                                    <div class="col-3 list-header">ASSET ID</div>
                                    <div class="col-5 col-lg-6 list-header">RECEIVED AT</div>
                                    <div class="col-2 list-header">STATUS</div>
                                    <div class="col-1 list-value text-center mt-1 status"
                                        style="position: absolute;top:6px;right:-5px">
                                        <i class="fa fa-angle-up hide" (click)="toggleHeight(alarm.Id, 'collapse')"
                                            id="collapse{{alarm.Id}}"></i>
                                        <i class="fa fa-angle-down show" (click)="toggleHeight(alarm.Id, 'expand')"
                                            id="expand{{alarm.Id}}"></i>
                                    </div>
                                </div>
                                <div class="row ps-2 pe-0">
                                    <div class="col-1 list-value">
                                        <!-- <input type="checkbox"
                                                value="" class="me-2 ms-2">  -->
                                        <i class="fa fa-exclamation-triangle ms-2" *ngIf="alarm.alarmClass"
                                            [ngClass]="(alarm.alarmClass)|lowercase" style="font-size: 1.3em;"></i>
                                        <i class="fa fa-info-circle ipredict-blue ms-2"
                                            *ngIf="alarm.eventType == 'Notification'" style="font-size: 1.3em;"></i>
                                        <i class="fa fa-info-circle critical ms-2" *ngIf="alarm.eventType == 'Warning'"
                                            style="font-size: 1.3em;"></i>
                                    </div>
                                    <div class="col-3 list-value">{{alarm.assetId}}</div>
                                    <div class="col-5 col-lg-6 list-value">{{alarm.receivedAt + 'Z' | date: 'dd/MM/yyy
                                        h:mm a':
                                        offsetDate}}</div>
                                    <div class="col-2 list-value">{{alarm.status}}</div>
                                    <!-- <div class="col-1"></div> -->
                                </div>
                                <div class="row ps-2 pe-0 pt-2 alarm-data">
                                    <div class="col-11 list-header offset-1">DETAILS</div>
                                    <div class="col-11 list-value offset-1">{{alarm.details}}</div>
                                </div>
                                <div class="row ps-2 pe-0 pt-2 alarm-data">
                                    <div class="col-11 list-header offset-1">ACTION PERFORMED BY</div>
                                    <div class="col-11 list-value px-0 offset-1">
                                        <div class="row step completed pt-2">
                                            <ng-container *ngFor="let item of alarm.information;let i =index">
                                                <div class="v-stepper">
                                                    <div class="circle"></div>
                                                    <div class="line" *ngIf="i !== alarm.information.length-1"></div>
                                                </div>

                                                <div class="content">
                                                    <div class="col">{{(item.performedAt + 'Z' | date: 'dd/MM/yyyy
                                                        h:mm:ssa': offsetDate) + ' '
                                                        + (item.performedAction=='WorkInProgress'?
                                                        'WIP':item.performedAction) + ' by ' +
                                                        (item.performedBy.split(' ')[0]|titlecase) + ' ' +
                                                        (item.performedAction=='WorkInProgress'?
                                                        item.workInProgress||'':'')}}</div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="module?.AlarmOrEvents?.canUpdate">
                                    <div class="col-11 offset-1 pe-4" (click)="prevent($event)">
                                        <label class="list-header" for="commentsBox">COMMENTS</label>
                                        <div class="input-group ms-1 mb-2" id="commentsBox">
                                            <input type="text" class="form-control" aria-label="Comment"
                                                (keydown)="$event.stopPropagation()" #comment />
                                            <span class="input-group-text"
                                                (click)="addComment(comment.value,alarm.Id, alarm);comment.value =''">Submit</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 offset-1 comments-list"
                                        style="max-height: 110px;overflow-y: scroll;">
                                        <div *ngFor="let comment of alarm.comments" class="mt-2">
                                            <div style="font-size: x-small;color: #9d9b9a;" class="ps-2">
                                                {{(comment.performedby|titlecase) + ' | '+
                                                (comment.performedOn + 'Z' | date: 'dd/MM/yyyy h:mm:ss a': offsetDate)}}
                                            </div>
                                            <div class="row ps-2">
                                                <div style="font-size: 12px;" [id]="alarm.Id+'edit'+comment.id"
                                                    *ngIf="!(commentEdit && selectedComment == comment.id)"
                                                    class="col-8 comment-text">
                                                    <span style="visibility: visible!important;">{{comment.comment}}
                                                    </span>
                                                    <span class="edit-icon"><i class="fa fa-pencil"
                                                            (click)="setCommentId(comment.id)"
                                                            *ngIf="!(commentEdit && selectedComment == comment.id)"></i></span>
                                                </div>
                                                <input [id]="comment.id" type="text" [(ngModel)]="comment.comment"
                                                    *ngIf="commentEdit && selectedComment == comment.id"
                                                    (keydown)="$event.stopPropagation()" class="col-8">
                                                <div class="col-2" (click)="setCommentId(comment.id) "
                                                    *ngIf="(commentEdit && selectedComment == comment.id)">
                                                    <i class="fa fa-check healthy p-1" title="Save"
                                                        (click)="updateComment(alarm.Id,comment.id, comment.comment, alarm, comment.CommentId)"></i>
                                                    <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                        (click)="commentEdit = !commentEdit"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="position:relative">
                                    <div class="col-12">
                                        <div style="position:absolute;bottom:1px;right: 1px;">
                                            <span class="list-header">ALARM ACTIVATED FOR Mobile</span>
                                            <span class="list-value"
                                            style="font-size: 12px;">{{(alarm.activatedFor?.split(':')[0].includes('.')
                                            == true ?
                                            alarm.activatedFor?.split(':')[0].split('.')[0] +
                                            (alarm.activatedFor?.split(':')[0].split('.')[0] == '1' ? 'day ':
                                            'days ') + alarm.activatedFor?.split(':')[0].split('.')[1]
                                            +(alarm.activatedFor?.split(':')[0].split('.')[1] == '01' ? 'hr ':
                                            'hrs '): (alarm.activatedFor?.split(':')[0]|number) +
                                            (alarm.activatedFor?.split(':')[0] == '01' ? 'hr ': 'hrs ')+
                                            (alarm.activatedFor?.split(':')[1]|number)+ ' min' ) }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                    <mat-paginator #paginator class="p-0" [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50, 100]"
                        (page)="getData($event)"
                        aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
            <div class="text-center m-4 critical" *ngIf="showNoDataFilter && !isLoading">No Data found for the Applied
                Filter</div>
        </div>
    </div>
    <div id="mydiv" *ngIf="createStatus" class="alertMsg" [ngClass]="showError? 'critical': 'healthy'">{{ showMsg }}</div>