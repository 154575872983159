<div class="subasset-health-filters d-flex align-items-center">
    <!-- <div class="p-1 health-text Healthy" (click)="filterByHealth('Healthy')" [ngClass]="healthStatus == 'Healthy'? 'selected-health-filter':''">Healthy {{health[0]}}</div>
    <div class="p-1 health-text AtRisk" (click)="filterByHealth('AtRisk')" [ngClass]="healthStatus == 'AtRisk'? 'selected-atrisk-filter':''">At Risk {{health[1]}}</div>
    <div class="p-1 health-text Critical" (click)="filterByHealth('Critical')" [ngClass]="healthStatus == 'Critical'? 'selected-critical-filter':''">Critical {{health[2]}}</div> -->
    <div class="me-1">Health</div>
    <div class="p-1 health-text VeryGood" (click)="setHealthStatuspayload('veryGood')" [ngClass]="healthStatus == 'veryGood'? 'selected':''">VeryGood</div>
    <div class="p-1 health-text Good" (click)="setHealthStatuspayload('good')" [ngClass]="healthStatus == 'good'? 'selected':''">Good</div>
    <div class="p-1 health-text Fair" (click)="setHealthStatuspayload('fair')" [ngClass]="healthStatus == 'fair'? 'selected':''">Fair</div>
    <div class="p-1 health-text Poor" (click)="setHealthStatuspayload('poor')" [ngClass]="healthStatus == 'poor'? 'selected':''">Poor</div>
    <div class="p-1 health-text VeryPoor" (click)="setHealthStatuspayload('veryPoor')" [ngClass]="healthStatus == 'veryPoor'? 'selected':''">VeryPoor</div>
    <div class="p-1 health-text Unknown" (click)="setHealthStatuspayload('unknown')" [ngClass]="healthStatus == 'unknown'? 'selected':''">Unknown</div>
    <div class="vr mx-2"></div>
    <div class="me-1">Criticality</div>
    <div class="p-1 health-text Insignificant" (click)="setCriticalStatuspayload('insignificant')" [ngClass]="criticalStatus == 'insignificant'? 'selected':''">Insignificant</div>
    <div class="p-1 health-text Minor" (click)="setCriticalStatuspayload('minor')" [ngClass]="criticalStatus == 'minor'? 'selected':''">Minor</div>
    <div class="p-1 health-text Major" (click)="setCriticalStatuspayload('major')" [ngClass]="criticalStatus == 'major'? 'selected':''">Major</div>
    <div class="p-1 health-text Critical" (click)="setCriticalStatuspayload('critical')" [ngClass]="criticalStatus == 'critical'? 'selected':''">Critical</div>

</div>

<ng-container *ngIf="subAssetVerticals?.length">
<!-- Tabs navs -->
<ul class="nav nav-tabs feeder-nav mt-2" id="ex1" role="tablist">
    <ng-container *ngIf="subAssetVerticals?.length <= recordsPerPage">
        <li class="nav-item" role="presentation" *ngFor="let item of subAssetVerticals; let i = index">
            <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
                aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedVertical == item? 'active':''"
                (click)="setSelectedVertical(item);">{{item}}</a>
        </li>
    </ng-container>
    <ng-container *ngIf="subAssetVerticals?.length > recordsPerPage">
        <li class="nav-item" role="presentation" *ngFor="let item of currArray; let i = index">
            <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
                aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedVertical == item? 'active':''"
                (click)="setSelectedVertical(item);">{{item}}</a>
        </li>
    </ng-container>
    <li class="nav-item col" style="position:relative ;">
        <app-pagination [totalRecords]="subAssetVerticals?.length" [recordsPerPage]="recordsPerPage"
            (onPageChange)="displayActiveSection($event)" *ngIf="subAssetVerticals?.length > recordsPerPage"
            style="position: absolute; right:0;top:0"></app-pagination>
    </li>
</ul>
<!-- Tabs navs -->

<!-- Tabs content -->
<div class="tab-content" id="ex1-content">
    <ng-container *ngIf="subAssetVerticals?.length">
        <div class="tab-pane fade main-tab" [ngClass]="selectedVertical == item? 'active show':''" id="ex1-tabs-{{i}}"
            role="tabpanel" attr.aria-labelledby="ex1-tabs-{{i}}" *ngFor="let item of currArray; let i = index">
            <!-- first tab content starts-->
            <div *ngIf="recordsPerPage < 10" class="col-8" style="position:absolute;top:16%;left:65%">
                <div class="subasset-health-filters-mobile">
                    <div class="p-1 health-text Healthy" (click)="filterByHealth('Healthy')"
                        [ngClass]="healthStatus == 'Healthy'? 'selected-health-filter':''" matTooltip="Healthy {{health[0]}}">{{health[0]}}</div>
                    <div class="p-1 health-text AtRisk" (click)="filterByHealth('AtRisk')"
                        [ngClass]="healthStatus == 'AtRisk'? 'selected-atrisk-filter':''" matTooltip="At Risk {{health[1]}}">{{health[1]}}</div>
                    <div class="p-1 health-text Critical" (click)="filterByHealth('Critical')"
                        [ngClass]="healthStatus == 'Critical'? 'selected-critical-filter':''"matTooltip="Critical {{health[2]}}/">{{health[2]}}</div>
                </div>
            </div>
            <div class="row" [ngClass]="isMobile? '':'px-2'" *ngIf="feederList?.length">
                <div class="col-1 px-0 col-sm-2 col-xl-1" *ngIf="recordsPerPage == 10" style="z-index:1000">
                    <ng-container  *ngFor="let feeder of feederList">
                        <div class="content-value py-1  ps-3"
                        (click)="setSelectedFeeder(feeder)" type="button"
                        [ngClass]="feeder == selectedFeeder ? 'selected-feeder':''">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[feeder]"></i></span>
                        <span class="feeder-name">{{feeder}}</span>
                    </div>
                    </ng-container>
                </div>
                <div *ngIf="recordsPerPage < 10" class="col-4">
                    <div class="selected-feeder-name" (click)="showList=true" role="button">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="selectedFeeder?healthData[selectedFeeder]:healthData[feederList[0]]"></i></span>
                        <span>{{selectedFeeder || feederList[0]}}</span>
                        <span  style="padding-left:10px"><i class="fa fa-angle-down"></i></span>
                    </div>
                   <div class="list-of-feeders p-2" *ngIf="showList">
                    <ng-container *ngFor="let subAsset of feederList" >
                        <div class="content-value py-1  ps-3" role="button"
                        (click)="setSelectedFeeder(subAsset);showList=false"
                        [ngClass]="subAsset == selectedFeeder ? 'selected-feeder feeder-mobile':''">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[subAsset]"></i></span>
                        <span>{{subAsset}}</span>
                        </div>
                    </ng-container>
                   </div>
                </div>
                <div class="col-12 feeder-main-content col-sm-10 col-xl-11 col-lg-11" [ngClass]="recordsPerPage==10?'feeder-background':''">
                    <div class="header-tabs">
                        <div class="row my-3" [ngClass]="isMobile? '':'mx-2'">
                            <div class="col-3" *ngIf="!isMobile">
                                <span class="feeder-no" *ngIf="feederDetails && feederDetails[selectedFeeder]">{{ feederDetails[selectedFeeder][0]['Tag']?.value ? feederDetails[selectedFeeder][0]['Tag']?.value : ''}}</span>
                            </div>
                            <div class="col-9 col-lg-7 px-lg-2 px-0">
                                <!-- Tabs navs -->
                                <ul class="nav nav-tabs mb-3 menu" id="ex1-feeder-{{i}}" role="tablist">
                                    <li class="nav-item" role="presentation" *ngIf="module?.AssetAndSubAsset?.canRead">
                                        <a class="button-bottom-triangle first" (click)="tabActivation('ibDataTab')" [class.active]="ibDataTab"
                                            [ngClass]="(selectedFeeder =='Common')? 'disableTab' : ''" id="ex1-feeder-tab-{{i}}-1"
                                            data-mdb-toggle="tab" href="#ex1-feeder-tabs-{{i}}-1" role="tab"
                                            attr.aria-controls="ex1-feeder-tabs-{{i}}-1" [attr.aria-selected]="ibDataTab">IB Data</a>
                                    </li>
                                    <li class="nav-item" role="presentation" *ngIf="module?.ConditionMonitoring?.canRead">
                                        <a class="button-bottom-triangle" (click)="tabActivation('conditionTab')" [class.active]="conditionTab"
                                            id="ex1-feeder-tab-{{i}}-2" data-mdb-toggle="tab" href="#ex1-feeder-tabs-{{i}}-2" role="tab"
                                            attr.aria-controls="ex1-feeder-tabs-{{i}}-2" [attr.aria-selected]="conditionTab">Condition Monitoring</a>
                                    </li>
                                    <li class="nav-item" role="presentation" *ngIf="module?.HealthKpi?.canRead">
                                        <a class="button-bottom-triangle last" (click)="tabActivation('healthKpiTab')" [class.active]="healthKpiTab"
                                            id="ex1-feeder-tab-{{i}}-3" data-mdb-toggle="tab" href="#ex1-feeder-tabs-{{i}}-3" role="tab"
                                            attr.aria-controls="ex1-feeder-tabs-{{i}}-3" [attr.aria-selected]="healthKpiTab">Health KPIs</a>
                                    </li>
                                </ul>
                                <!-- Tabs navs -->
                            </div>

                            <div class="col-lg-2 col-3">
                                <div class="icon-arrange me-1">
                                    <!-- <span class="ms-1 me-1" *ngIf="healthKpiTab"> -->
                                        <!-- <i class="fa fa-cube" style="font-size: 16px;" (click)="calculatePage = true" role="button"></i> -->
                                    <!-- <img src="../../assets/images/automation.svg" alt="" height="15" (click)="calculatePage = true" role="button">
                                    </span> -->
                                    <!-- <span class="ms-1"><a target="_blank" [routerLink]="['/layout/alarms']"  [queryParams]="{siteId: siteId, assetId:selectedAssetId}"><img src="../../assets/images/alarm_events.svg" alt="" height="17"></a></span>
                                    <span class="ms-1"><img src="../../assets/images/download.png" alt="" height="21"></span>
                                    <span class="ms-1 me-"><i class="fa fa-print"></i></span> -->
                                    <app-icon *ngIf="healthKpiTab && analyticsData && analyticsData?.analyticStatus" class="me-2" title="" imgFor="automation" action="layout" (clickEvent)="calculatePage = true"></app-icon>
                                    <a target="_blank" [routerLink]="['/layout/alarms']"  [queryParams]="{siteId: siteId, assetId:selectedAssetId}"><app-icon class="me-2" title="" iconFor="bell" action="layout"></app-icon></a>
                                    <app-icon class="me-2" title="print" iconFor="print" action="layout"></app-icon>
                                    <app-icon class="" title="download" iconFor="download" action="layout"></app-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-content" id="ex1-feeder-content" *ngIf="!calculatePage">
                        <div class="tab-pane fade no-border" [class.active]="ibDataTab" [class.show]="ibDataTab"
                            id="ex1-feeder-tabs-{{i}}-1" role="tabpanel" attr.aria-labelledby="ex1-feeder-tab-{{i}}-1"
                            [ngClass]="isMobile?'py-0 px-0 mobile-feeder-data':''">
                            <app-feeder-info [feedersData]="feederDetails[selectedFeeder]" [tabno]="i.toString()" [isEditableForUser]="isEditableForUser">
                            </app-feeder-info>
                        </div>
                        <div *ngIf="selectedAssetId" [class.active]="conditionTab" [class.show]="conditionTab"
                            class="tab-pane fade no-border" id="ex1-feeder-tabs-{{i}}-2" role="tabpanel"
                            attr.aria-labelledby="ex1-feeder-tab-{{i}}-2" [ngClass]="isMobile?'py-0 px-0 mobile-feeder-data':''">
                            <app-conditionmonitoring-cards [assetId]="selectedAssetId" [feederNo]="selectedFeeder" [siteId]="siteId"
                                [commonFeeder]="commonFeederNo" *ngIf="selectedFeeder && selectedVertical == item">
                            </app-conditionmonitoring-cards>
                        </div>
                        <div class="tab-pane fade no-border" [class.active]="healthKpiTab" [class.show]="healthKpiTab"
                            id="ex1-feeder-tabs-{{i}}-3" role="tabpanel" attr.aria-labelledby="ex1-feeder-tab-{{i}}-3"
                            [ngClass]="isMobile?'py-0 px-0 mobile-feeder-data':''" *ngIf="analyticsData && analyticsData?.analyticStatus">
                            <div class="row mb-3">
                                <div class="col text-center">
                                    <span>Analytics Status:&nbsp;</span><span class="fw-bold me-3">{{analyticsData?.analyticStatus}}</span>
                                    <span class="ms-2">Last Calculation:&nbsp;</span><span class="fw-bold" style="color: var(--highlight-text);">{{' '+
                                        analyticsData?.endTime ? (analyticsData?.endTime + 'Z' | date:
                                        'dd/MM/yyy h:mm a': offsetDate) : ''}}</span>
                                </div>
                            </div>
                            <app-healthkpi [target]="'subassets'" [index]="i" [data]="{assetId:selectedAssetId , feeder: (selectedFeeder== 'Common'? commonFeederNo : selectedFeeder), siteId:siteId }" *ngIf="healthKpiTab && selectedFeeder && selectedVertical == item"></app-healthkpi>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="calculatePage" id="ex1-feeder-content" style="background: white;
                    border-radius: 10px;">
                        <div class="tab-pane fade active show">
                            <app-health-calculate [data]="{assetId:selectedAssetId , feeder: selectedFeeder }" *ngIf="selectedFeeder && selectedVertical == item" (closeWindowEvent)="closeWindow()"></app-health-calculate>
                        </div>
                    </div>
                </div>
                <div class="no-data col-12" *ngIf="recordsPerPage==10">No Data for selected filter</div>
            </div>
        </div>
    </ng-container>

    <!-- first tab content ends -->
</div>
<!-- Tabs content -->
</ng-container>

<div *ngIf="!subAssetVerticals?.length" class="no-data">
    No Data for selected filter
</div>