
<ng-container *ngIf="subAssetVerticals?.length">
<!-- Tabs navs -->
<ul class="nav nav-tabs feeder-nav mt-2" id="ex1" role="tablist">
    <ng-container *ngIf="subAssetVerticals?.length <= recordsPerPage">
        <li class="nav-item" role="presentation" *ngFor="let item of subAssetVerticals; let i = index">
            <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
                aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedVertical == item? 'active':''"
                (click)="setSelectedVertical(item);">{{item}}</a>
        </li>
    </ng-container>
    <ng-container *ngIf="subAssetVerticals?.length > recordsPerPage">
        <li class="nav-item" role="presentation" *ngFor="let item of currArray; let i = index">
            <a class="nav-link" id="ex1-tab-{{i}}" data-mdb-toggle="tab" href="#ex1-tabs-{{i}}" role="tab"
                aria-controls="ex1-tabs" aria-selected="true" [ngClass]="selectedVertical == item? 'active':''"
                (click)="setSelectedVertical(item);">{{item}}</a>
        </li>
    </ng-container>
    <li class="nav-item col" style="position:relative ;">
        <app-pagination [totalRecords]="subAssetVerticals?.length" [recordsPerPage]="recordsPerPage"
            (onPageChange)="displayActiveSection($event)" *ngIf="subAssetVerticals?.length > recordsPerPage"
            style="position: absolute; right:0;top:0"></app-pagination>
    </li>
</ul>
<!-- Tabs navs -->

<!-- Tabs content -->
<div class="tab-content" id="ex1-content">
    <ng-container *ngIf="subAssetVerticals?.length <= recordsPerPage">
        <div class="tab-pane fade main-tab" [ngClass]="selectedVertical == item? 'active show':''" id="ex1-tabs-{{i}}"
            role="tabpanel" attr.aria-labelledby="ex1-tabs-{{i}}" *ngFor="let item of subAssetVerticals; let i = index">
            <!-- first tab content starts-->
            <div class="row" [ngClass]="isMobile? '':'px-2'" *ngIf="feederList?.length">
                <div class="col-1 px-0 col-sm-2 col-xl-1" *ngIf="recordsPerPage == 10" style="z-index:1000">
                    <ng-container *ngFor="let feeder of feederList">
                        <div class="content-value py-1  ps-3"  role="button"
                        (click)="setSelectedFeeder(feeder)"
                        [ngClass]="feeder == selectedFeeder ? 'selected-feeder':''" *ngIf="healthStatus.length? (healthData[feeder]==healthStatus || feeder == 'Common'): true">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[feeder]"></i></span>
                        <span>{{feeder}}</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="recordsPerPage < 10" class="col-4">
                    <div class="selected-feeder-name" (click)="showList=true" role="button">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="selectedFeeder?healthData[selectedFeeder]:healthData[feederList[0]]"></i></span>
                        <span>{{selectedFeeder || feederList[0]}}</span>
                        <span style="padding-left:10px"><i class="fa fa-angle-down"></i></span>
                    </div>
                   <div class="list-of-feeders p-2" *ngIf="showList">
                    <ng-container *ngFor="let feeder of feederList">
                        <div class="content-value py-1  ps-3" role="button"
                        (click)="setSelectedFeeder(feeder);showList=false"
                        [ngClass]="feeder == selectedFeeder ? 'selected-feeder feeder-mobile':''" *ngIf="healthStatus.length? (healthData[feeder]==healthStatus || feeder == 'Common'): true">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[feeder]"></i></span>
                        <span class="feeder-name">{{feeder}}</span>
                        </div>
                    </ng-container>
                   </div>
                </div>
                <div *ngIf="recordsPerPage < 10" class="col-8">
                    <div class="subasset-health-filters-mobile">
                        <div class="p-1 health-text Healthy" (click)="filterByHealth('Healthy')" [ngClass]="healthStatus == 'Healthy'? 'selected-health-filter':''">Healthy {{health[0]}}</div>
                        <div class="p-1 health-text AtRisk" (click)="filterByHealth('AtRisk')" [ngClass]="healthStatus == 'AtRisk'? 'selected-health-filter':''">At Risk {{health[1]}}</div>
                        <div class="p-1 health-text Critical" (click)="filterByHealth('Critical')" [ngClass]="healthStatus == 'Critical'? 'selected-health-filter':''">Critical {{health[2]}}</div>                   
                    </div>
                </div>
                <div class="col-12 feeder-main-content col-sm-10 col-xl-11 col-lg-11 content-testingggggggg" [ngClass]="recordsPerPage==10?'feeder-background':''" *ngIf="healthStatus.length? (healthData[selectedFeeder]==healthStatus || selectedFeeder == 'Common'): true">
                    <div class="header-tabs">
                        <div class="row my-3" [ngClass]="isMobile? '':'mx-2'">
                            <div class="col-3" *ngIf="!isMobile">
                                <span class="feeder-no">{{'Feeder '+ selectedVertical}}</span>
                            </div>
                            <div class="col-xl-7 col-12">
                                <!-- Tabs navs -->
                                <ul class="nav nav-tabs mb-3 menu" id="ex1-feeder-{{i}}" role="tablist">
                                    <li class="nav-item" role="presentation" *ngIf="module?.IBData?.canRead">
                                        <a class="button-bottom-triangle" (click)="tabActivation('ibDataTab')" [class.active]="ibDataTab"
                                            [ngClass]="(selectedFeeder =='Common')? 'disableTab' : ''" id="ex1-feeder-tab-{{i}}-1"
                                            data-mdb-toggle="tab" href="#ex1-feeder-tabs-{{i}}-1" role="tab"
                                            attr.aria-controls="ex1-feeder-tabs-{{i}}-1" [attr.aria-selected]="ibDataTab">IB Data</a>
                                    </li>
                                </ul>
                                <!-- Tabs navs -->
                            </div>
                            <div class="col-2" *ngIf="!isMobile">
                                <div class="icon-arrange">
                                    <span class="ms-1" *ngIf="isAdminOrEAUser">
                                        <label class="toggle">
                                            <input class="toggle-checkbox" id="serial-toggle" type="checkbox" (change)="toggleVisibility($event)" [checked]="isVisible">
                                            <div class="toggle-switch"></div>
                                        </label>
                                    </span>
                                    <!-- <span class="ms-1"><img src="../../assets/images/alarm_events.svg" alt="" height="17"></span>
                                    <span class="ms-1"><img src="../../assets/images/download.png" alt="" height="21"></span>
                                    <span class="ms-1"><i class="fa fa-print"></i></span> -->
                                    <app-icon class="me-2" title="" iconFor="bell" action="layout"></app-icon>
                                    <app-icon class="me-2" title="print" iconFor="print" action="layout"></app-icon>
                                    <app-icon class="" title="download" iconFor="download" action="layout"></app-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" id="ex1-feeder-content">
                        <div class="tab-pane fade no-border" [class.active]="ibDataTab" [class.show]="ibDataTab"
                            id="ex1-feeder-tabs-{{i}}-1" role="tabpanel" attr.aria-labelledby="ex1-feeder-tab-{{i}}-1"
                            [ngClass]="isMobile?'py-0 px-0 mobile-feeder-data':''">
                            <ng-container *ngIf="editMode">
                                <app-feeder-info-edit [isCustomerUser]="isCustomerUser" [feedersData]="feederDetails[selectedFeeder]" [tabno]="i.toString()" [isEditableForUser]="isEditableForUser" [selectedAssetId]="selectedAssetId" (dataUpdate)="addToPayload($event)" [pendingRequest]="pendingRequest">
                                </app-feeder-info-edit>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="no-data col-12" *ngIf="recordsPerPage==10">No Data for selected filter</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="subAssetVerticals?.length > recordsPerPage">
        <div class="tab-pane fade main-tab" [ngClass]="selectedVertical == item? 'active show':''" id="ex1-tabs-{{i}}"
            role="tabpanel" attr.aria-labelledby="ex1-tabs-{{i}}" *ngFor="let item of currArray; let i = index">
            <!-- first tab content starts-->
            <div class="row" [ngClass]="isMobile? '':'px-2'" *ngIf="feederList?.length">
                <div class="col-1 px-0 col-sm-2 col-xl-1" *ngIf="recordsPerPage == 10" style="z-index:1000">
                    <ng-container  *ngFor="let subAsset of feederList">
                        <div class="content-value py-1  ps-3"
                        (click)="setSelectedFeeder(subAsset)" type="button"
                        [ngClass]="subAsset == selectedFeeder ? 'selected-feeder':''" *ngIf="healthStatus.length ? (healthData[subAsset]==healthStatus || subAsset == 'Common'): true">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[subAsset]"></i></span>
                        <span class="feeder-name">{{subAsset}}</span>
                    </div>
                    </ng-container>
                </div>
                <div *ngIf="recordsPerPage < 10" class="col-4">
                    <div class="selected-feeder-name" (click)="showList=true" role="button">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="selectedFeeder?healthData[selectedFeeder]:healthData[feederList[0]]"></i></span>
                        <span>{{selectedFeeder || feederList[0]}}</span>
                        <span  style="padding-left:10px"><i class="fa fa-angle-down"></i></span>
                    </div>
                   <div class="list-of-feeders p-2" *ngIf="showList">
                    <ng-container *ngFor="let subAsset of feederList" >
                        <div class="content-value py-1  ps-3" role="button"
                        (click)="setSelectedFeeder(subAsset);showList=false"
                        [ngClass]="subAsset == selectedFeeder ? 'selected-feeder feeder-mobile':''" *ngIf="healthStatus.length? (healthData[subAsset]==healthStatus || subAsset == 'Common'): true">
                        <span style="font-size: 12px;" class="me-2"><i class="fa fa-square Common" [ngClass]="healthData[subAsset]"></i></span>
                        <span>{{subAsset}}</span>
                        </div>
                    </ng-container>
                   </div>
                </div>
                <div *ngIf="recordsPerPage < 10" class="col-8">
                    <div class="subasset-health-filters-mobile">
                        <div class="p-1 health-text Healthy" (click)="filterByHealth('Healthy')" [ngClass]="healthStatus == 'Healthy'? 'selected-health-filter':''">Healthy {{health[0]}}</div>
                        <div class="p-1 health-text AtRisk" (click)="filterByHealth('AtRisk')" [ngClass]="healthStatus == 'AtRisk'? 'selected-health-filter':''">At Risk {{health[1]}}</div>
                        <div class="p-1 health-text Critical" (click)="filterByHealth('Critical')" [ngClass]="healthStatus == 'Critical'? 'selected-health-filter':''">Critical {{health[2]}}</div>                    
                    </div>
                </div>
                <div class="col-12 feeder-main-content col-sm-10 col-xl-11 col-lg-11 new-testinggggggggggggggg" [ngClass]="recordsPerPage==10?'feeder-background':''" *ngIf="healthStatus.length? (healthData[selectedFeeder]==healthStatus || selectedFeeder == 'Common'): true">
                    <div class="header-tabs">
                        <div class="row my-3" [ngClass]="isMobile? '':'mx-2'">
                            <div class="col-3" *ngIf="!isMobile && feederDetails[selectedFeeder]">
                                <span class="feeder-no">{{ feederDetails[selectedFeeder][0]['Tag']?.value ? feederDetails[selectedFeeder][0]['Tag']?.value : ''}}</span>
                            </div>
                            <div class="col-12 col-xl-7">
                                <!-- Tabs navs -->
                                <ul class="nav nav-tabs mb-3 menu" id="ex1-feeder-{{i}}" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="button-bottom-triangle" (click)="tabActivation('ibData')" [class.active]="ibDataTab"
                                            [ngClass]="(selectedFeeder =='Common')? 'disableTab' : ''" id="ex1-feeder-tab-{{i}}-1"
                                            data-mdb-toggle="tab" href="#ex1-feeder-tabs-{{i}}-1" role="tab"
                                            attr.aria-controls="ex1-feeder-tabs-{{i}}-1" [attr.aria-selected]="ibDataTab">IB Data</a>
                                    </li>
                                </ul>
                                <!-- Tabs navs -->
                            </div>
                            <div class="col-2" *ngIf="!isMobile">
                                <div class="icon-arrange">
                                    <span class="ms-1" *ngIf="isAdminOrEAUser">
                                        <label class="toggle">
                                            <input class="toggle-checkbox" id="serial-toggle" type="checkbox" (change)="toggleVisibility($event)" [checked]="isVisible">
                                            <div class="toggle-switch"></div>
                                        </label>
                                    </span>
                                    <!-- <span class="ms-1"><a target="_blank" [routerLink]="['/layout/alarms']"  [queryParams]="{siteId: siteId, assetId:selectedAssetId}"><img src="../../assets/images/alarm_events.svg" alt="" height="17"></a></span>
                                    <span class="ms-1"><img src="../../assets/images/download.png" alt="" height="21"></span>
                                    <span class="ms-1"><i class="fa fa-print"></i></span> -->
                                    <a target="_blank" [routerLink]="['/layout/alarms']"  [queryParams]="{siteId: siteId, assetId:selectedAssetId}"><app-icon class="me-2" title="" iconFor="bell" action="layout"></app-icon></a>
                                    <app-icon class="me-2" title="print" iconFor="print" action="layout"></app-icon>
                                    <app-icon class="" title="download" iconFor="download" action="layout"></app-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-content" id="ex1-feeder-content">
                        <div class="tab-pane fade no-border" [class.active]="ibDataTab" [class.show]="ibDataTab"
                            id="ex1-feeder-tabs-{{i}}-1" role="tabpanel" attr.aria-labelledby="ex1-feeder-tab-{{i}}-1"
                            [ngClass]="isMobile?'py-0 px-0 mobile-feeder-data':''">
                            <ng-container *ngIf="editMode">
                                <app-feeder-info-edit [isCustomerUser]="isCustomerUser" [feedersData]="feederDetails[selectedFeeder]" [tabno]="i.toString()" [isEditableForUser]="isEditableForUser" [selectedAssetId]="selectedAssetId" (dataUpdate)="addToPayload($event)" [pendingRequest]="pendingRequest">
                                </app-feeder-info-edit>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="no-data col-12" *ngIf="recordsPerPage==10">No Data for selected filter</div>
            </div>
        </div>
    </ng-container>

    <!-- first tab content ends -->
</div>
<!-- Tabs content -->
</ng-container>

<div id="mydiv" *ngIf="createStatus" class="alertMsg" [ngClass]="showError? 'critical': 'healthy'">{{ showMsg }}</div>
