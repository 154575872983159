<div class="container-fluid p-0" id="theme-mode">
    <ng-container *ngIf="user">
        <header>
            <nav class="navbar navbar-expand-lg navbar-light header-navbar">
                <div class="container-fluid">
                    <div>
                        <a
                            class="navbar-brand mt-2 mt-lg-0 desktop-only"
                            href="#"
                        >
                            <img
                                *ngIf="showHome"
                                src="../assets/images/newLogo/predict_dark.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive mt-1 light-theme-img"
                                style="height: 2.4rem"
                            />
                            <img
                                *ngIf="showHome"
                                src="../assets/images/newLogo/LK_Digital_Logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive mt-1 dark-theme-img"
                                style="height: 2.4rem"
                            />
                            <img
                                *ngIf="!showHome"
                                src="../assets/images/newLogo/cloud_logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive light-theme-img"
                                style="height: 2.5rem"
                            />
                            <img
                                *ngIf="!showHome"
                                src="../assets/images/newLogo/LK_Digital_Logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive dark-theme-img"
                                style="height: 2.5rem"
                            />
                        </a>
                        <a
                            class="navbar-brand mt-lg-0 non-desktop-only"
                            href="#"
                        >
                            <img
                                *ngIf="showHome"
                                src="../assets/images/newLogo/predict_dark.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive light-theme-img"
                                style="height: 1.4rem"
                            />
                            <img
                                *ngIf="showHome"
                                src="../assets/images/newLogo/LK_Digital_Logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive dark-theme-img"
                                style="height: 1.4rem"
                            />
                            <img
                                *ngIf="!showHome"
                                src="../assets/images/newLogo/cloud_logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive light-theme-img"
                                style="height: 1.4rem"
                            />
                            <img
                                *ngIf="!showHome"
                                src="../assets/images/newLogo/LK_Digital_Logo.png"
                                alt=""
                                loading="lazy"
                                class="img-responsive dark-theme-img"
                                style="height: 1.4rem"
                            />
                        </a>
                    </div>
                    <div class="" id="navbarExample02" class="desktop-only">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 p-2">
                            <li class="nav-item">
                                <a
                                    class="navbar-brand pt-3 mt-lg-0 desktop-only"
                                    href="#"
                                >
                                    <img
                                        src="../assets/images/newLogo/Smartcomm_predict.png"
                                        alt=""
                                        loading="lazy"
                                        class="img-responsive dark-theme-img"
                                        width="130"
                                /></a>
                            </li>
                            <li
                                class="nav-item"
                                *ngIf="module?.AlarmOrEvents?.canRead"
                            >
                                <a
                                    class="nav-link me-4 mt-3"
                                    routerLink="alarms"
                                    *ngIf="showHome"
                                >
                                    <i
                                        class="fa fa-bell ms-4 text-black"
                                        style="font-size: 20px"
                                    ></i>
                                    <span
                                        class="badge rounded-pill badge-notification"
                                        matTooltip="{{ count }}"
                                        matTooltipPosition="left"
                                        style="margin-top: -5px"
                                        >{{ count > 99 ? "99+" : count }}</span
                                    >
                                </a>
                            </li>
                            <li class="nav-item dropdown">
                                <a
                                    class="nav-link dropdown-toggle text-grey ms-2 mt-2 me-3 user-info"
                                    href="#"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-mdb-toggle="dropdown"
                                    aria-expanded="false"
                                    style="font-size: 18px"
                                >
                                    <i
                                        class="fa fa-user-circle text-gray me-2 mt-2"
                                        style="font-size: 21px"
                                    ></i>
                                    <div
                                        *ngIf="user?.displayName"
                                        style="
                                            margin-top: -5px;
                                            display: inline-block;
                                        "
                                    >
                                        {{ user?.displayName }}
                                    </div>
                                </a>
                                <ul
                                    class="dropdown-menu w-100"
                                    aria-labelledby="navbarDropdownMenuLink"
                                    style="font-size: large"
                                >
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            type="button"
                                            (click)="goToProfile()"
                                            >User Profile</a
                                        >
                                        <a
                                            class="dropdown-item"
                                            type="button"
                                            (click)="onLogout()"
                                            >Logout</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="nav-item ">
                            <a class="nav-link" href="#">
                                <img src="../assets/images/lntlogo.png" style="height:2.7rem" alt="" loading="lazy"
                                    class="img-responsive" />

                            </a>
                        </li> -->
                        </ul>
                    </div>
                    <!-- <div class="non-desktop-only fill">
                    <img src="../assets/images/lntlogo.png" height="19" alt="" loading="lazy"
                        class="img-responsive mt-1" />
                </div> -->
                </div>
            </nav>
            <nav
                class="navbar navbar-expand-lg app-navbar sticky-top"
                style="height: 53px"
            >
                <div class="container-fluid">
                    <div
                        class="row non-desktop-only"
                        title="Menu"
                        style="
                            font-size: smaller;
                            width: 82%;
                            align-items: center;
                        "
                    >
                        <div
                            *ngIf="showHome"
                            class="col-2 col-sm-1"
                            style="padding: 0"
                        >
                            <button
                                (click)="toggleSlimFull()"
                                class="expand col-sm-3"
                                type="button"
                                id="main-menu-collapse"
                            >
                                <i class="fas fa-bars text-white"></i>
                            </button>
                        </div>
                        <div class="col-10">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>
                    <div
                        class="row desktop-only"
                        style="font-size: smaller; width: 70%"
                        title="Menu"
                    >
                        <div
                            *ngIf="showHome"
                            class="col-1"
                            style="padding: 0; max-width: 50px"
                        >
                            <button
                                (click)="toggleSlimFull()"
                                class="expand col-sm-3 nav-toggle-img"
                                type="button"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/Menu_expand.svg"
                                    alt=""
                                    height="20"
                                    *ngIf="slim"
                                    style="max-width: none"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/menu_close.svg"
                                    alt=""
                                    height="20"
                                    *ngIf="!slim"
                                    style="max-width: none"
                                />
                                <img
                                    src="../../assets/images/dark-theme-icons/Menu_expand.svg"
                                    alt=""
                                    height="20"
                                    *ngIf="slim"
                                    style="max-width: none"
                                />
                                <img
                                    src="../../assets/images/dark-theme-icons/menu_close.svg"
                                    alt=""
                                    height="20"
                                    *ngIf="!slim"
                                    style="max-width: none"
                                />
                                <!-- <i class="fas fa-bars text-white"></i> -->
                            </button>
                        </div>
                        <div
                            class="col-11"
                            style="
                                padding: 0.3rem 0rem 0 0.4rem;
                                font-size: larger;
                            "
                        >
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>
                    <div>
                        <a
                            class="non-desktop-only-nav non-desktop-only-navbar"
                            style="margin: 0 1rem"
                            routerLink="alarms"
                            matTooltip="{{ count }}"
                            *ngIf="showHome && module?.AlarmOrEvents?.canRead"
                        >
                            <i class="fa fa-bell ms-2"></i>
                            <span
                                class="badge rounded-pill badge-notification"
                                >{{ count > 99 ? "99+" : count }}</span
                            >
                        </a>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-mdb-toggle="collapse"
                            data-mdb-target="#navbarExample02"
                            aria-controls="navbarExample02"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            #test
                        >
                            <i class="fas fa-ellipsis-v text-white"></i>
                        </button>
                    </div>

                    <div
                        class="collapse navbar-collapse non-desktop-only-navbar"
                        id="navbarExample02"
                    >
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a
                                    class="nav-link text-white ms-2"
                                    id="navbarDropdownMenuLink"
                                >
                                    <i class="fa fa-user text-gray me-2"></i>Hi
                                    {{ user?.displayName }}
                                </a>
                            </li>
                            <li>
                                <a
                                    class="nav-link text-white ms-2"
                                    type="button"
                                    routerLink="profile"
                                    [queryParams]="{ userId: user?.id }"
                                    (click)="test.click()"
                                    >User Profile</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link text-white ms-2"
                                    (click)="onLogout()"
                                    >Logout</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav
                *ngIf="showHome"
                class="sidenav ps app-navbar desktop-only"
                (click)="closeSlimFull()"
                [ngClass]="slim ? 'sidenavslim' : 'sidenavexpand'"
                style="z-index: 2; height: calc(100vh - 128px)"
            >
                <ul
                    id="sidenav-menu"
                    class="sidenav-menu navbar-nav"
                    style="width: 100%; padding-right: 0.9px"
                >
                    <li class="sidenav-menu-item nav-item">
                        <a
                            class="sidenav-link nav-link"
                            routerLink="dashboard"
                            title="Dashboard"
                            routerLinkActive="active"
                        >
                            <img
                                src="../../assets/images/Menu_icons/Dashboard.svg"
                                alt=""
                                height="20"
                            />
                            <img
                                src="../../assets/images/Menu_icons/Dashboard_active.svg"
                                alt=""
                                height="20"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                class="ms-3"
                                >Dashboard</span
                            ></a
                        >
                    </li>

                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.SiteOverview?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="Sites"
                            id="openSitePanel"
                            (click)="navigateToSites()"
                            [ngClass]="
                                currentNavigation.includes('layout/sites')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/site.svg"
                                alt=""
                                height="20"
                            />
                            <img
                                src="../../assets/images/Menu_icons/site_active.svg"
                                alt=""
                                height="20"
                            />
                            <span
                                [ngClass]="[slim ? 'slim' : 'full']"
                                class="ms-3"
                                >Sites</span
                            ></a
                        >
                    </li>

                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.AlarmOrEvents?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            routerLink="alarms"
                            title="Alarms / Events"
                        >
                            <img
                                src="../../assets/images/Menu_icons/alarm_events.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/alarm_events_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Alarms / Events</span
                            ></a
                        >
                    </li>

                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.DigitalMaintenanceManager?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            title="Digital Maintenance Manager"
                            (click)="navigateToDMM()"
                            [ngClass]="
                                currentNavigation.includes('DMM')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/dmm.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/dmm_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Digital Maintenance Manager</span
                            ></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.DigitalLogbook?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="Digital Logbook"
                            (click)="navigateToDLB()"
                            id="dlb-documents-menu-desktop"
                            [ngClass]="
                                currentNavigation.includes('DLB')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/digital_log_book.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/digital_log_book_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Digital Logbook
                            </span></a
                        >
                    </li>
                    <li class="sidenav-menu-item nav-item">
                        <a
                            class="sidenav-link nav-link"
                            (click)="navigateToSettings()"
                            [ngClass]="
                                currentNavigation.includes('settings')
                                    ? 'active'
                                    : ''
                            "
                            routerLinkActive="active"
                            title="Settings"
                        >
                            <img
                                src="../../assets/images/Menu_icons/settings.svg"
                                alt=""
                                height="22"
                                fill="red"
                            />
                            <img
                                src="../../assets/images/Menu_icons/settings_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Settings
                            </span></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="
                            module?.AssetManagement?.canRead ||
                            module?.UserManagement?.canRead ||
                            module?.TemplateManagement?.canRead ||
                            module?.SubscriptionManagement?.canRead
                        "
                    >
                        <a
                            class="sidenav-link nav-link"
                            (click)="navigateToAdminPanel()"
                            [ngClass]="
                                currentNavigation.includes('adminpanel')
                                    ? 'active'
                                    : ''
                            "
                            routerLinkActive="active"
                            title="Admin Panel"
                        >
                            <img
                                src="../../assets/images/Menu_icons/admin-panel.png"
                                alt=""
                                height="25"
                            />
                            <img
                                src="../../assets/images/Menu_icons/admin-panel_active.png"
                                alt=""
                                height="25"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Admin Panel</span
                            ></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="!isOandM && module?.ServiceRequest?.canRead"
                        style="display: none"
                    >
                        <a
                            class="sidenav-link"
                            routerLinkActive="active"
                            title="Complaint Management"
                        >
                            <img
                                src="../../assets/images/Menu_icons/complaint_management.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Complaint Management</span
                            ></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="isAdminOrEAUser && module?.Insights?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="Insights"
                            (click)="navigateToInsights()"
                            [ngClass]="
                                currentNavigation.includes('insights')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/insights.svg"
                                alt=""
                                height="16"
                            />
                            <img
                                src="../../assets/images/Menu_icons/insights_active.svg"
                                alt=""
                                height="16"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Insights
                            </span></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.WhatsNew?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="What's New?"
                            (click)="navigateToWhatsNew()"
                            [ngClass]="
                                currentNavigation.includes('whatsnew')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/whatisnew.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/whatisnew_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >What's New?</span
                            ></a
                        >
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="isCustomerUser"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="Contact us"
                            (click)="navigateToContactUs()"
                            [ngClass]="
                                currentNavigation.includes('contact')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/contact_us.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/contact_us_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Contact Us</span
                            >
                        </a>
                    </li>
                    <li
                        class="sidenav-menu-item nav-item"
                        *ngIf="module?.Help?.canRead"
                    >
                        <a
                            class="sidenav-link nav-link"
                            routerLinkActive="active"
                            title="Help"
                            (click)="navigateToHelp()"
                            [ngClass]="
                                currentNavigation.includes('help')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <img
                                src="../../assets/images/Menu_icons/help.svg"
                                alt=""
                                height="22"
                            />
                            <img
                                src="../../assets/images/Menu_icons/help_active.svg"
                                alt=""
                                height="22"
                            />
                            <span
                                [ngClass]="slim ? 'slim' : 'full'"
                                class="ms-3"
                                >Help
                            </span></a
                        >
                    </li>
                </ul>
            </nav>
            <nav
                class="sidenav ps app-navbar non-desktop-only"
                [ngClass]="slim ? 'sidenavhide' : 'sidenavexpand'"
                style="width: 100%"
            >
                <div class="container-fluid">
                    <ul
                        id="sidenav-menu"
                        class="sidenav-menu navbar-nav"
                        (click)="toggleSlimFull()"
                    >
                        <li class="sidenav-menu-item nav-item">
                            <a
                                class="sidenav-link nav-link"
                                routerLink="dashboard"
                                routerLinkActive="active"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/Dashboard.svg"
                                    alt=""
                                    height="20"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/Dashboard_active.svg"
                                    alt=""
                                    height="20"
                                />
                                <span
                                    [ngClass]="slim ? 'slim' : 'full'"
                                    class="ms-3"
                                    >Dashboard</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.SiteOverview?.canRead"
                            (click)="navigateToSites()"
                            [ngClass]="
                                currentNavigation.includes('sites')
                                    ? 'active'
                                    : ''
                            "
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                routerLink="sites"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/site.svg"
                                    alt=""
                                    height="20"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/site_active.svg"
                                    alt=""
                                    height="20"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Sites</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.AlarmOrEvents?.canRead"
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                routerLink="alarms"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/alarm_events.svg"
                                    alt=""
                                    height="23"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/alarm_events_active.svg"
                                    alt=""
                                    height="23"
                                />
                                <span
                                    [ngClass]="slim ? 'slim' : 'full'"
                                    class="ms-3"
                                    >Alarm / Events
                                </span></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.DigitalMaintenanceManager?.canRead"
                        >
                            <a
                                class="sidenav-link"
                                (click)="navigateToDMM()"
                                [ngClass]="
                                    currentNavigation.includes('DMM')
                                        ? 'active'
                                        : ''
                                "
                            >
                                <img
                                    src="../../assets/images/Menu_icons/dmm.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Digital Maintenance Manager</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.DigitalLogbook?.canRead"
                        >
                            <a
                                class="sidenav-link"
                                routerLinkActive="active"
                                routerLink="DLB"
                                (click)="navigateToDLB()"
                                title="Digital Logbook"
                                id="dlb-documents-menu-mobile"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/digital_log_book.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Digital Logbook</span
                                ></a
                            >
                        </li>
                        <li class="sidenav-menu-item nav-item">
                            <a
                                class="sidenav-link nav-link"
                                (click)="navigateToSettings()"
                                routerLinkActive="active"
                            >
                                <img
                                    src="../../assets/images/Menu_icons/settings.svg"
                                    alt=""
                                    height="22"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/settings_active.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Settings</span
                                ></a
                            >
                        </li>
                        <!-- <li class="sidenav-menu-item nav-item" *ngIf="showSettingsAdminPanel && !isOandM"><a
                            class="sidenav-link" routerLink="adminpanel" (click)="navigateToAdminPanel()"
                            [ngClass]="currentNavigation.includes('adminpanel') ? 'active' : ''"
                            routerLinkActive="active">
                            <img src="../../assets/images/Menu_icons/admin_panel.svg" alt="" height="22">
                            <span [ngClass]=" 'full'" class="ms-3">Admin Panel</span></a>
                    </li> -->
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="!isOandM && module?.ServiceRequest?.canRead"
                            style="display: none"
                        >
                            <a class="sidenav-link" routerLinkActive="active">
                                <img
                                    src="../../assets/images/Menu_icons/complaint_management.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Complaint Management</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="!isOandM && module?.Insights?.canRead"
                            style="display: none"
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                (click)="navigateToInsights()"
                                [ngClass]="
                                    currentNavigation.includes('insights')
                                        ? 'active'
                                        : ''
                                "
                            >
                                <img
                                    src="../../assets/images/Menu_icons/insights.svg"
                                    alt=""
                                    height="22"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/insights_active.svg"
                                    alt=""
                                    height="22"
                                />

                                <span [ngClass]="'full'" class="ms-3"
                                    >Insights</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.WhatsNew?.canRead"
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                (click)="navigateToWhatsNew()"
                                [ngClass]="
                                    currentNavigation.includes('whatsnew')
                                        ? 'active'
                                        : ''
                                "
                            >
                                <img
                                    src="../../assets/images/Menu_icons/whatisnew.svg"
                                    alt=""
                                    height="22"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/whatisnew_active.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >What's New?</span
                                ></a
                            >
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="isCustomerUser"
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                (click)="navigateToContactUs()"
                                [ngClass]="
                                    currentNavigation.includes('contact')
                                        ? 'active'
                                        : ''
                                "
                            >
                                <img
                                    src="../../assets/images/Menu_icons/contact_us.svg"
                                    alt=""
                                    height="22"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/contact_us_active.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Contact Us</span
                                >
                            </a>
                        </li>
                        <li
                            class="sidenav-menu-item nav-item"
                            *ngIf="module?.Help?.canRead"
                        >
                            <a
                                class="sidenav-link nav-link"
                                routerLinkActive="active"
                                (click)="navigateToHelp()"
                                [ngClass]="
                                    currentNavigation.includes('help')
                                        ? 'active'
                                        : ''
                                "
                            >
                                <img
                                    src="../../assets/images/Menu_icons/help.svg"
                                    alt=""
                                    height="22"
                                />
                                <img
                                    src="../../assets/images/Menu_icons/help_active.svg"
                                    alt=""
                                    height="22"
                                />
                                <span [ngClass]="'full'" class="ms-3"
                                    >Help</span
                                ></a
                            >
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <main>
            <section
                class="content-area"
                [ngClass]="
                    showHome ? (slim ? 'paddingSlim' : 'paddingExpand') : ''
                "
            >
                <div class="no-right-margin no-margin-mobile main-layout">
                    <router-outlet></router-outlet>
                </div>
            </section>
        </main>
        <button
            id="terms"
            type="button"
            class="btn btn-primary"
            data-mdb-toggle="modal"
            data-mdb-target="#exampleModal"
            style="z-index: 10000; position: relative; display: none"
        >
            Launch demo modal
        </button>
        <app-terms-conditions></app-terms-conditions>
    </ng-container>
</div>

<div
    *ngIf="!user || !user?.displayName"
    style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/images/iOneCloudBG.png);
    "
>
    <div class="container text-center" style="top: 50%; position: relative">
        <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
</div>
<div
    *ngIf="sharedValueService.message && sharedValueService.message.length"
    class="othermessage"
>
    <div class="container text-center p-3 peak-user">
        <label>{{ sharedValueService.message }}</label>
        <span
            class="ms-2 clear-button"
            role="button"
            (click)="sharedValueService.clear()"
            ><i class="fa fa-times"></i
        ></span>
    </div>
</div>
