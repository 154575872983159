import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { parseInt } from 'lodash';
import { Subscription } from 'rxjs';
import { ChartParams, ConditionMonitoringCard } from '../_models/conditionmonitoring-cards.type';
import { AssetService } from '../_services/assets.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-conditionmonitoring-cards',
  templateUrl: './conditionmonitoring-cards.component.html',
  styleUrls: ['./conditionmonitoring-cards.component.scss']
})
export class ConditionmonitoringCardsComponent implements OnInit {
  conditionMonitoring!: Array<ConditionMonitoringCard>;
  @Input() assetId!: any;
  @Input() feederNo!: string;
  @Input() siteId: number = 0;
  isDesktopDevice: boolean = true;
  isMobile: boolean = false;
  currentView = 'cards';
  selectedGroup: string = '';
  selectedGroupData: any;
  params!: ChartParams;
  @Input() commonFeeder!: any;
  isCommon: boolean = false;
  isLoading: boolean = true;
  errorMessage: boolean = false;
  lastUpdate: any;
  offsetDate: string = '';

  private getconditionMonitoring: Subscription = new Subscription();
  private subscription: Subscription = new Subscription();
  constructor(
    private assetService: AssetService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.epicFunction();
    if (this.feederNo == 'Common') {
      this.isCommon = true;
      this.feederNo = this.commonFeeder;

    }
    this.params = {
      siteId: this.siteId,
      assetId: this.assetId,
      feederNumber: this.feederNo
    }
    this.getconditionMonitoringCards(parseInt(this.assetId), this.feederNo, this.isCommon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading=true;
    if (changes['feederNo'] && !changes['feederNo'].firstChange) {
      if (this.feederNo == 'Common') {
        this.isCommon = true;
        this.feederNo = this.commonFeeder;
        this.params.feederNumber = this.feederNo;
      }
      else {
        this.isCommon = false;
        this.params.feederNumber = this.feederNo;
      }
      this.getconditionMonitoring.unsubscribe();
      this.getconditionMonitoringCards(parseInt(this.assetId), this.feederNo, this.isCommon);

    }
  }

  epicFunction() {
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
  }

  getconditionMonitoringCards(assetId: number, feederNo: string, common: boolean) {
    this.isLoading = true;
    this.errorMessage = false;
    this.conditionMonitoring =[];
    this.getconditionMonitoring = 
      this.assetService.getconditionMonitoringCards(assetId, feederNo, common).subscribe((data:any) => {
        this.isLoading=false;
        if (data instanceof HttpErrorResponse) {
          this.errorMessage = true;
        }
        else{
          this.lastUpdate = data.lastUpdated;
          this.conditionMonitoring = data.conditionMonitoringCards;
        }
      }
      )  
  }

  getSelectedGroupData(selectedGroup: string) {
    this.currentView = 'trends';
    // this.isMobile = false;
    this.selectedGroup = selectedGroup;
    this.selectedGroupData = this.conditionMonitoring.filter((item: any) => item.group === selectedGroup);
    this.params.group = this.isCommon ? selectedGroup.split(' ')[0]  : selectedGroup;
  }

  getInput(input:any){
    return parseInt(input);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.getconditionMonitoring.unsubscribe();
  }

}
