export class Asset {
	"Alarm Type": string;
	"Asset ID": string;
	"Alarm Details": string;
	"Received At": string;
	"Acknowledged At": string;
	"Acknowledged by": string;
	"Status": string;
	"Assigned To": string;
  }

  export class AssetLife {
		"installation": string;
		"commissioning":string;
		"inService": string;
		"warrantyEnd": string;
		"activeContractStart": string;
		"activeContractEnd": string;
		"endOfLife": string;
  }

  export interface AssetType {
	"id": string,
    "typeName": string,
    "description": string,
    "hasTemplate": boolean,
    "templateId": string
  }

  export interface AssetCategory {
	"id": string,
	"name":	string,
	"description": string
  }

  export interface AssetFilter {
	assetStatus: AssetFilterKeys;
	subAssetStatus: AssetFilterKeys;
	assetCategory: AssetCategoryFilter;
	subAssetCategory: AssetCategoryFilter;
	assetMake:AssetFilterKeys;
	designType:AssetFilterKeys;
	systemVoltage:AssetFilterKeys;
	balanceLife:AssetFilterKeys;

  }
  export interface AssetFilterKeys {
	list: Array<string>,
	countLimit: number,
	hidden: boolean,
	filteredList: Array<string>
  }
  export interface AssetCategoryFilter {
	list: Array<AssetCategory>,
	countLimit: number,
	hidden: boolean,
	filteredList: Array<string>
  }

  export interface RecommendedSpare {
	partNo: string;
	materialDescription: string;
	qty: number;
	compType: string;
	classDesc: string;
	catNo: string;
  }
  
 
