import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { WorkPermitService } from '../../../modules/DMM/services/work-permit.service';
import { Subscription } from 'rxjs';
import { findIndex, forEach } from 'lodash';
import { TaskTemplate, Measurement } from '../../../_models/dmm.type';
import { ControlContainer, FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms'
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-work-performed',
  templateUrl: './work-performed.component.html',
  styleUrls: ['./work-performed.component.scss']
})
export class WorkPerformedComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  // assetTemplate: any;
  parentFormGroup: FormGroup;
  @Input('template') assetTemplate!: TaskTemplate;
  @Input('isPreview') isPreview!: boolean;
  @Input('feederNo') feederNo!: string;
  @Input('formId') formId!: string;
  @Input('disableWorkPerformedForm') disableWorkPerformedForm : boolean;
  isMobile: boolean = false;

  constructor(
    private workPermitService : WorkPermitService,
    private parentControl: ControlContainer,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit(): void {
    // console.log(this.assetTemplate);
    
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.parentFormGroup = this.parentControl.control?.get('workPerformed') as FormGroup;
    if (this.assetTemplate.report) {
      this.createWorkPerformedForm();
      this.disbaleGroupAtload();
      if (this.disableWorkPerformedForm) {
        this.parentFormGroup.disable();
        this.accordianState();
      }
    }
  }

  accordianState() {
    this.assetTemplate.report.header.openAccordian = false;
    this.assetTemplate.report.group.forEach((value: any) => {
      value.openAccordian = false;
    });
  }


  createWorkPerformedForm() {
    const rawJson = this.assetTemplate.report;
    let subAssetFormGroup = new FormGroup({});
    let feederId = new FormGroup({});
    let headerFormArray = new FormArray([]);
    let groupFormArray = new FormArray([]);

    if (rawJson.header) {
      rawJson.header.children.textField?.forEach((textField: any) => {
        const formField = new FormControl(textField.value);
        if (textField.isApplicable) {
          formField.addValidators(Validators.required);
          formField.updateValueAndValidity();
        }
        headerFormArray.push(formField);
      });
      subAssetFormGroup.addControl('header', headerFormArray);
    }

    if (rawJson.group) {
      rawJson.group.forEach((groupValue: any, groupIndex: number) => {
        let groupForm = new FormGroup({
          checkPointFormArray: new FormArray([]),
          measuredConditionFormArray: new FormArray([]),
          observedConditionFormArray: new FormArray([]),
          measurementFormArray: new FormArray([]),
          textFieldFormAray: new FormArray([]),
          enable: new FormControl(groupValue.isApplicable)
        });
        forEach(groupValue.children, (Fieldvalue: any, key: string) => {
          if (key === 'textField' && Fieldvalue != null) {
            Fieldvalue.forEach((field: any) => {
              const texFieldFormcontrol = new FormControl(field.value);
              const textFieldFormAray  = groupForm.get('textFieldFormAray') as FormArray;
              texFieldFormcontrol?.addValidators(Validators.required);
              texFieldFormcontrol?.updateValueAndValidity();
              textFieldFormAray.push(texFieldFormcontrol);
            });
          }
          if (key === 'checkPoint' && Fieldvalue != null) {
            Fieldvalue.forEach((field: any, index: number) => {
              const checkPointGroup = new FormGroup({
                radio: new FormControl(field.value),
                remarks: new FormControl(field.remarks)
              });
              const checkPointFormArray  = groupForm.get('checkPointFormArray') as FormArray;
              checkPointGroup.get('radio')?.addValidators(Validators.required);
              checkPointGroup.get('radio')?.updateValueAndValidity();
              // checkPointGroup.get('remarks')?.addValidators(Validators.required);
              // checkPointGroup.get('remarks')?.updateValueAndValidity();
              checkPointFormArray.push(checkPointGroup);
            });
          }
          if (key === 'measuredCondition' && Fieldvalue != null) {
            Fieldvalue.forEach((field: any, index: number) => {
              const measuredConditionGroup = new FormGroup({
                radio: new FormControl(field.value),
                remarks: new FormControl(field.remarks)
              });
              const measuredConditionFormArray  = groupForm.get('measuredConditionFormArray') as FormArray;
              measuredConditionGroup.get('radio')?.addValidators(Validators.required);
              measuredConditionGroup.get('radio')?.updateValueAndValidity();
              measuredConditionFormArray.push(measuredConditionGroup);
            });
          }
          if (key === 'observedCondition' && Fieldvalue != null) {
            Fieldvalue.forEach((field: any, index: number) => {
              const observedConditionGroup = new FormGroup({
                radio: new FormControl(field.value),
                remarks: new FormControl(field.remarks)
              });
              const observedConditionFormArray  = groupForm.get('observedConditionFormArray') as FormArray;
              observedConditionGroup.get('radio')?.addValidators(Validators.required);
              observedConditionGroup.get('radio')?.updateValueAndValidity();
              observedConditionFormArray.push(observedConditionGroup);
            });
          }
          if (key === 'measurement' && Fieldvalue !== null) {
            Fieldvalue.forEach((field: any, fieldIndex: number) => {
              const measurementGroup = new FormGroup({
                valueMeasured: new FormControl({value: field.value !== 'NA' ? field.value: '', disabled: field.value === 'NA'}),
                measurementNA: new FormControl(field.value === 'NA'),
                remarks: new FormControl({value: field.value !== 'NA' ? field.remarks : '', disabled: field.value === 'NA'}),
                min: new FormControl(field.lowerThreshold),
                max: new FormControl(field.upperThreshold)
              });
              const measurementFormArray  = groupForm.get('measurementFormArray') as FormArray;
              measurementGroup.get('valueMeasured')?.addValidators(Validators.required);
              // measurementGroup.get('valueMeasured')?.addValidators(Validators.min(field.lowerThreshold));
              // measurementGroup.get('valueMeasured')?.addValidators(Validators.max(field.upperThreshold));
              measurementGroup.get('valueMeasured')?.updateValueAndValidity();
              // measurementGroup.get('remarks')?.addValidators(Validators.required);
              // measurementGroup.get('remarks')?.updateValueAndValidity();
              measurementFormArray.push(measurementGroup);
            });
          }
        });
        groupFormArray.push(groupForm);
      });
      subAssetFormGroup.addControl('group', groupFormArray);
    }
    if (this.parentFormGroup.get(this.feederNo)) {
      const feederGroup = this.parentFormGroup.get(this.feederNo) as FormGroup;
      feederGroup.addControl(this.formId, subAssetFormGroup);
    } else {
      feederId.addControl(this.formId, subAssetFormGroup);
      this.parentFormGroup.addControl(this.feederNo, feederId);
    }

  }

  disbaleGroupAtload() {
    const rawJson = this.assetTemplate.report;
    if (rawJson.group) {
      rawJson.group.forEach((groupValue: any, groupIndex: number) => {
        if (!groupValue.isApplicable) {
          this.groupEnableDisable({target: {
            checked: false
          }}, groupIndex);
        }
      });
    }
  }

  accordian(type: string, groupIndex?: number) {
    if (type === 'header') {
      this.assetTemplate.report.header.openAccordian = !this.assetTemplate.report.header.openAccordian;
    } else  if (type === 'group') {
      this.assetTemplate.report.group[groupIndex as number].openAccordian = !this.assetTemplate.report.group[groupIndex as number].openAccordian;
    }
  }

  measurementNA(event: any, fieldIndex: any, groupIndex: number) {
    const groupFormArray = this.parentFormGroup.get(this.feederNo)?.get(this.formId.toString())?.get('group') as FormArray;
    const measurementFormArray = groupFormArray.at(groupIndex).get('measurementFormArray') as FormArray;
    if (event.target.checked) {
      measurementFormArray.at(fieldIndex).get('valueMeasured')?.disable();
      measurementFormArray.at(fieldIndex).get('remarks')?.disable();
      measurementFormArray.at(fieldIndex).reset({
        valueMeasured: 'NA',
        measurementNA: true,
        remarks: '',
        min: '',
        max: ''
      });
    } else {
      measurementFormArray.at(fieldIndex).get('valueMeasured')?.enable();
      measurementFormArray.at(fieldIndex).get('remarks')?.enable();
      measurementFormArray.at(fieldIndex).reset({
        valueMeasured: '',
        measurementNA: false,
        remarks: '',
        min: '',
        max: ''
      });
    }
  }

  /* disableMeasurementNA(groupIndex: number) {
    const groupFormArray = this.parentFormGroup.get(this.feederNo)?.get('group') as FormArray;
    const groupDisabled = groupFormArray.at(groupIndex).get('enable')?.value;
    return !groupDisabled;
  } */

  measurementValueValidator(fieldIndex: any, groupIndex: number) {
    const groupFormArray = this.parentFormGroup.get(this.feederNo)?.get(this.formId.toString())?.get('group') as FormArray;
    const measurementFormArray = groupFormArray.at(groupIndex).get('measurementFormArray') as FormArray;
    const valueMeasuredField = measurementFormArray.at(fieldIndex).get('valueMeasured') as FormControl;

    if (valueMeasuredField.errors) {
      if (valueMeasuredField.errors['min']) {
        return 'min'
      } else if (valueMeasuredField.errors['max']) {
        return 'max'
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  measurementValueChecker(fieldIndex: any, groupIndex: number) {
    const groupFormArray = this.parentFormGroup.get(this.feederNo)?.get(this.formId.toString())?.get('group') as FormArray;
    const measurementFormArray = groupFormArray.at(groupIndex).get('measurementFormArray') as FormArray;
    const valueMeasuredField = (measurementFormArray.at(fieldIndex).get('valueMeasured') as FormControl)?.value;
    const min = (measurementFormArray.at(fieldIndex).get('min') as FormControl)?.value;
    const max = (measurementFormArray.at(fieldIndex).get('max') as FormControl)?.value;
    if(valueMeasuredField && valueMeasuredField !== 'NA' && valueMeasuredField < min) return "min";
    if(valueMeasuredField && valueMeasuredField !== 'NA' && valueMeasuredField > max) return "max";
    return '';
  }

  groupEnableDisable(event: any, groupIndex: number) {
    const groupFormArray = this.parentFormGroup.get(this.feederNo)?.get(this.formId.toString())?.get('group') as FormArray;
    const measurementFormArray = groupFormArray.at(groupIndex).get('measurementFormArray') as FormArray;
    const checkPointFormArray = groupFormArray.at(groupIndex).get('checkPointFormArray') as FormArray;
    const measuredConditionFormArray = groupFormArray.at(groupIndex).get('measuredConditionFormArray') as FormArray;
    const observedConditionFormArray = groupFormArray.at(groupIndex).get('observedConditionFormArray') as FormArray;
    const textFieldFormArray = groupFormArray.at(groupIndex).get('textFieldFormAray') as FormArray;

    const rawJson = this.assetTemplate.report;
    rawJson.group[groupIndex].isApplicable = event.target.checked;
    const checkPoint = rawJson.group[groupIndex].children.checkPoint;
    const measurement = rawJson.group[groupIndex].children.measurement;
    const measuredCondition = rawJson.group[groupIndex].children.measuredCondition;
    const observedCondition = rawJson.group[groupIndex].children.observedCondition;
    const textField = rawJson.group[groupIndex].children.textField;
    if (event.target.checked) {
      if (checkPoint && checkPoint !== null) {
        checkPointFormArray.enable();
        checkPoint.forEach((fieldvalue: any, index: number) => {
          checkPointFormArray.at(index).setValue({
            radio: fieldvalue.value,
            remarks: fieldvalue.remarks,
          });
          checkPointFormArray.at(index).get('radio')?.addValidators(Validators.required);
          checkPointFormArray.at(index).get('radio')?.updateValueAndValidity();
          // checkPointFormArray.at(index).get('remarks')?.addValidators(Validators.required);
          // checkPointFormArray.at(index).get('remarks')?.updateValueAndValidity();
        });
      }
      if (measuredCondition && measuredCondition !== null) {
        measuredConditionFormArray.enable();
        measuredCondition.forEach((fieldvalue: any, index: number) => {
          measuredConditionFormArray.at(index).setValue({
            radio: fieldvalue.value,
            remarks: fieldvalue.remarks,
          });
          measuredConditionFormArray.at(index).get('radio')?.addValidators(Validators.required);
          measuredConditionFormArray.at(index).get('radio')?.updateValueAndValidity();
        });
      }
      if (observedCondition && observedCondition !== null) {
        observedConditionFormArray.enable();
        observedCondition.forEach((fieldvalue: any, index: number) => {
          observedConditionFormArray.at(index).setValue({
            radio: fieldvalue.value,
            remarks: fieldvalue.remarks,
          });
          observedConditionFormArray.at(index).get('radio')?.addValidators(Validators.required);
          observedConditionFormArray.at(index).get('radio')?.updateValueAndValidity();
        });
      }
      if (measurement && measurement !== null) {
        measurementFormArray.enable();
        measurement.forEach((fieldValue: any, index: number) => {
          measurementFormArray.at(index).setValue({
            valueMeasured: fieldValue.value !== 'NA' ? fieldValue.value: '',
            measurementNA: fieldValue.value === 'NA',
            remarks: fieldValue.remarks,
            min: fieldValue.lowerThreshold,
            max: fieldValue.upperThreshold
          });
          measurementFormArray.at(index).get('valueMeasured')?.addValidators(Validators.required);
          // measurementFormArray.at(index).get('valueMeasured')?.addValidators(Validators.min(fieldValue.lowerThreshold));
          // measurementFormArray.at(index).get('valueMeasured')?.addValidators(Validators.max(fieldValue.upperThreshold));
          measurementFormArray.at(index).get('valueMeasured')?.updateValueAndValidity();
          // measurementFormArray.at(index).get('remarks')?.addValidators(Validators.required);
          // measurementFormArray.at(index).get('remarks')?.updateValueAndValidity();
        });
      }
      if (textField && textField !== null) {
        textFieldFormArray.enable();
        textField.forEach((fieldValue: any, index: number) => {
          textFieldFormArray.at(index).setValue(fieldValue.value);
          textFieldFormArray.at(index).addValidators(Validators.required);
          textFieldFormArray.at(index).updateValueAndValidity();
        });
      }
    } else {
      if (checkPoint && checkPoint !== null) {
        checkPoint.forEach((fieldvalue: any, index: number) => {
          checkPointFormArray.at(index).reset({
            radio: {value: '', disabled: true},
            remarks: {value: '', disabled: true},
          });
          checkPointFormArray.at(index).clearValidators();
          checkPointFormArray.at(index).updateValueAndValidity();
        });
      }
      if (measuredCondition && measuredCondition !== null) {
        measuredCondition.forEach((fieldvalue: any, index: number) => {
          measuredConditionFormArray.at(index).reset({
            radio: {value: '', disabled: true},
            remarks: {value: '', disabled: true},
          });
          measuredConditionFormArray.at(index).clearValidators();
          measuredConditionFormArray.at(index).updateValueAndValidity();
        });
      }
      if (observedCondition && observedCondition !== null) {
        observedCondition.forEach((fieldvalue: any, index: number) => {
          observedConditionFormArray.at(index).reset({
            radio: {value: '', disabled: true},
            remarks: {value: '', disabled: true},
          });
          observedConditionFormArray.at(index).clearValidators();
          observedConditionFormArray.at(index).updateValueAndValidity();
        });
      }
      if (measurement && measurement !== null) {
        measurement.forEach((fieldValue: any, index: number) => {
          measurementFormArray.at(index).reset({
            valueMeasured: {value: '', disabled: true},
            measurementNA: {value: null, disabled: true},
            remarks: {value: '', disabled: true},
            min: '',
            max: ''
          });
          measurementFormArray.at(index).clearValidators();
          measurementFormArray.at(index).updateValueAndValidity();
        });
      }
      if (textField && textField !== null) {
        textField.forEach((fieldValue: any, index: number) => {
          textFieldFormArray.at(index).reset({value: '', disabled: true});
          textFieldFormArray.at(index).clearValidators();
          textFieldFormArray.at(index).updateValueAndValidity();
        });
      }

    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // this.parentFormGroup.removeControl(this.feederNo);
  }

}
