import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { IdleCheckService } from './_services/idle-check.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { IPredictService } from './_services/i-predict.service';

export enum Operation {
    None,
    StoreCode,
    ShowLoginPage,
    ExchangeCode,
    Local,
    Register
}


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'web-app';
    errorMessage = '';

    private operation = Operation.None;
    innerHeight!: string;

    public lastActiveDate$: Observable<Date>;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    private timeoutId: any;
    private logoutTimerId: any;
    inActiveDialog: any;
    sessionOutDialog: any;
    private subscription: Subscription = new Subscription();

    //   private googleMapKey: string = environment.googleMapAPIKey;
    constructor(public auth: AuthenticationService, private router: Router,
        private idleCheckService: IdleCheckService, public dialog: MatDialog,
        private iPredictService: IPredictService,
    ) {
        this.lastActiveDate$ = this.idleCheckService.lastActive$;
        const lastActiveDate = this.idleCheckService.getLastActiveDate();
    }

    ngOnInit(): void {
        this.start();
        this.innerHeight = (window.innerHeight) + 'px';
        // this.loadGoogleMapScript();

        this.subscription = this.lastActiveDate$.subscribe(res => {
            this.resetActivity();
            // console.log('last ',res);
            this.timeoutId = setTimeout(() => {
                // console.log('Inactivity detected');
                this.subscription.unsubscribe();
                this.showInactiveDialog();
                this.logoutTimerId = setTimeout(() => {
                    // console.log('Logout detected');
                    this.showSessionOutDialog();
                }, (5 * 60 * 1000));
            }, (25 * 60 * 1000));
        });
    }

    resetActivity() {
        this.inActiveDialog?.close();
        this.sessionOutDialog?.close();
        clearTimeout(this.timeoutId);
        clearTimeout(this.logoutTimerId);
    }

    showInactiveDialog() {
        this.inActiveDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            autoFocus: true,
            width: '500px',
            data: {
                title: 'Attention!',
                message: 'Your session is about to expire in 5 Minutes. Click "Continue Session" to stay logged in',
                okButtonText: 'Continue Session',
                isCancel: false
            },
        });
        this.inActiveDialog.afterClosed().subscribe(result => {
            clearTimeout(this.logoutTimerId);
            // On Continue Session, Do nothing
        });
    };

    showSessionOutDialog() {
        this.inActiveDialog.close();
        this.sessionOutDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            autoFocus: true,
            width: '500px',
            data: {
                title: 'Attention!',
                message: 'For security reasons, your session has timed out. Please click "Login" to continue',
                okButtonText: 'Login',
                isCancel: false
            },
        });
        this.sessionOutDialog.afterClosed().subscribe(result => {
            this.onLogout();
        });
    };

    onLogout(): void {
        this.iPredictService.logout().subscribe(data => {
            const URI = data.response;
            const allowlist = 'ionecloud.lntebg.com';
            const allowProd = 'smartcommcloud.lntebg.com';
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('REFRESH_TOKEN');
            localStorage.clear();
            if (URI.indexOf(allowlist) > -1 || URI.indexOf(allowProd) > -1) {
                window.location.href = (data?.response).toString();
            }
        });
    }



    start() {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');
        const storedCode = window.localStorage.getItem('AUTHORIZATION_CODE');
        let operation = Operation.None;
        let param: string = '';
        if (window.location.href.includes('register')) {
            operation = Operation.Register;
        } else {
            if (window.location.href.includes('localhost')) {
                operation = Operation.Local;
            } else {
                if (code) {
                    operation = Operation.StoreCode;
                    param = code;
                } else if (storedCode) {
                    operation = Operation.ExchangeCode;
                    param = storedCode;
                } else if (!this.auth.checkAuthentication()) {
                    let url = window.location.href;
                    window.localStorage.setItem('myurl', url);
                    operation = Operation.ShowLoginPage;
                }
            }
        }

        this.handleOperation(operation, param);
    }

    private handleOperation(operation: Operation, code?: string): void {

        switch (operation) {
            case Operation.StoreCode:
                //storing code in localstorage & reloading
                console.log("got code from url");
                this.storeCode(code);
                break;
            case Operation.ShowLoginPage:
                //displaying login page
                console.log("got nothing and calling login");
                this.login();
                break;
            case Operation.ExchangeCode:
                //continue login process by sending code
                console.log("got stored code and calling token API with it");
                this.getToken(code);
                break;
            case Operation.None:
                console.log("user already logged in");
                let url: any = window.localStorage.getItem('myurl');
                window.localStorage.removeItem('myurl');
                if (url)
                    window.location.href = url;
                // this.router.navigateByUrl('/layout/home');
                break;
            // case Operation.Local:
            //   console.log("localhost app");
            //   this.getLocalLogin("test.customer@ipredict.com", "qwerty");
            //   break;
            case Operation.Register:
                console.log("Register Page");
                this.getRegisterPage();
                break;
        }
    }

    login() {
        this.auth.login().subscribe({
            next: data => {
                console.log('Data from login path');
                console.log(data);
                window.location.href = data.toString();
            },
            error: err => {
                this.errorMessage = err?.error?.message;
            }
        });
    }

    getToken(code: any) {
        this.auth.token(code, 'CODE').subscribe({
            next: data => {
                console.log("came to home route");
                window.location.reload();
            },
            error: err => {
                this.errorMessage = err?.error?.message;
            }
        });
    }

    private storeCode(code: any) {
        console.log("refreshing url");
        window.localStorage.setItem('AUTHORIZATION_CODE', code);
        let url = window.location.origin;
        if (window.location.pathname) {
            url += window.location.pathname;
        }
        console.log("new url is " + url);
        window.location.replace(url);
    }

    getLocalLogin(u: string, p: string) {
        if (localStorage.getItem('ACCESS_TOKEN') && localStorage.getItem('ACCESS_TOKEN') != 'undefined') {
            console.log("Already logged in");
            // this.router.navigateByUrl('/layout/home');
        } else {
            this.auth.localLogin(u, p).subscribe({
                next: data => {
                    console.log(data);
                    window.location.reload();
                },
                error: err => {
                    this.errorMessage = err?.error?.message;
                }
            });
        }
    }

    getRegisterPage() {
        if (this.auth.checkAuthentication()) {
            this.router.navigate(['/layout/dashboard']);
        } else {
            this.router.navigate(['/register']);
        }
    }

    //   loadGoogleMapScript() {
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapKey}`;
    //     document.getElementsByTagName('head')[0].appendChild(script);
    //   }
}
