import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Asset} from '../_models/asset';
import { SharedValueService } from '../_services/shared-value.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-sites-dashboard',
  templateUrl: './sites-dashboard.component.html',
  styleUrls: ['./sites-dashboard.component.css']
})
export class SitesDashboardComponent implements OnInit {

  siteId: string = '';
  innerHeight!: number;
  constructor(private _router: Router, private _route: ActivatedRoute, private sharedValueService: SharedValueService, public sharedService: SharedService) { 
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
      this._route.params.subscribe((params: Params) => {
        this.siteId = params['id'] || this.sharedValueService.getSiteId();
        this.sharedValueService.setSiteId(this.siteId);
      });
  }

  closeSubMenu(tab?:string) {
    this.sharedService.toggleSitesSubMenu(tab);
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }


  
}
