<div class="header-tabs">
    <div class="row mx-2 my-3">
        <div class="col-9 col-lg-4  px-lg-2 px-0">
            <!-- Tabs navs -->
            <ul class="nav nav-tabs mb-3 menu" id="ex1" role="tablist">
                <li class="nav-item" role="presentation" *ngIf="module?.IBData?.canRead">
                    <a class="active button-bottom-triangle" id="ex1-asset-tab-1" data-mdb-toggle="tab" href="#ex1-asset-tabs-1"
                        role="tab" aria-controls="ex1-asset-tabs-1" aria-selected="true" (click)="selectedTab='ibData'">IB
                        Data</a>
                </li>
                <li class="nav-item" role="presentation" *ngIf="module?.HealthKpi?.canRead">
                    <a class="button-bottom-triangle" id="ex1-asset-tab-2" data-mdb-toggle="tab" href="#ex1-asset-tabs-2" role="tab"
                        aria-controls="ex1-asset-tabs-2" aria-selected="false" (click)="selectedTab='healthKpi'">Health
                        KPI</a>
                </li>
            </ul>
            <!-- Tabs navs -->
        </div>
        <div class="col-lg-5 col-4 desktop-only" *ngIf="selectedTab=='healthKpi' && analyticsData">
            <span>Analytics Status:&nbsp;</span><span class="fw-bold me-3">{{analyticsData?.analyticStatus}}</span>
            <span class="ms-5">Last Calculation &nbsp;</span><span class="fw-bold" style="color: #024D87;">{{ ' '+
                analyticsData?.endTime ? (analyticsData?.endTime + 'Z' | date:
                'dd/MM/yyy h:mm a': offsetDate) : ''}}</span>
        </div>
        <div
            [ngClass]="selectedTab=='healthKpi' && analyticsData?'col-lg-3 col-3 pe-lg-2 pe-0':'col-lg-8 col-3 pe-lg-2 pe-0'">
            <div class="d-flex" style="float:right;">
                <!-- <span class="ms-1"><img src="../../assets/images/alarm_events.svg" alt="" height="17"></span>
                <span class="ms-1">
                    <img src="../../assets/images/download.svg" alt="" height="21">
                </span>
                <span class="ms-1">
                    <img src="../../assets/images/print.svg" alt="" height="21">
                </span> -->
                <app-icon class="me-2" title="" iconFor="bell" action="layout"></app-icon>
                <app-icon class="me-2" title="Print" iconFor="print" action="layout"></app-icon>
                <app-icon class="" title="Download" iconFor="download" action="layout"></app-icon>
            </div>
        </div>
    </div>
</div>
<div class="row non-desktop-only container" *ngIf="selectedTab=='healthKpi' && analyticsData">
    <div class="col-6">
        <span>Analytics Status:&nbsp;</span><span class="fw-bold me-3">{{analyticsData?.analyticStatus}}</span>

    </div>
    <div class="col-6">
        <span class="ms-2">Last Calculation &nbsp;</span><span class="fw-bold" style="color: #024D87;">{{' '+
            analyticsData?.endTime ? (analyticsData?.endTime + 'Z' | date:
            'dd/MM/yyy h:mm a': offsetDate) : ''}}</span>
    </div>
</div>


<!-- Tabs content -->
<div class="tab-content" id="ex1-content">
    <div class="tab-pane fade show active" id="ex1-asset-tabs-1" role="tabpanel" aria-labelledby="ex1-asset-tab-1">
        <div class="asset-progress pt-4 px-3 mx-3">
            <div class="progressbar">
                <div class="progress" style="background: transparent;height: 120px;">
                    <ng-container *ngFor="let step of steps;let i =index" style="position: relative;">
                        <div class="step" id="step-no-{{i}}" style="position: relative;">
                            <div class="inner-circle mb-1">
                                <span class="step-icon">
                                    <img [src]="stepImages[step]" alt="">
                                </span>
                            </div>
                            <div class="step-caption" style="position: absolute;">
                                <div class="">{{step| camelToSpace | titlecase}}
                                </div>
                                <div class="">
                                    <span
                                        *ngIf="!((step == 'warrantyEnd' && editWarranty) || (step == 'activeContractEnd' && editContract) )">
                                        {{StepsData[step] | date: 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="progress-bar" role="progressbar" *ngIf="steps.indexOf(step) < steps.length-1"
                            [ngClass]="stepsWithCurrent.indexOf(step) < stepsWithCurrent.indexOf('currentDate') ? 'solid-border': 'dashed-border'"
                            [ngStyle]="{ 'width': '7.14%' }" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        </div>
                        <div class="drop" id="drop-no-{{i}}" *ngIf="stepsWithCurrent.indexOf('currentDate') == i+1">
                            <div class="current-date-caption" style="color: #389921;font-size:12px">
                                <div class="step-caption">Current Date</div>
                                <div class="step-caption">{{currentDate}}</div>
                            </div>
                            <svg width="40%" height="64px" viewBox="0 0 8 8">
                                <path stroke-width="0.4" stroke="#fff" fill="#389921" d="M4 2L2 4A1.9 2 0 1 0 5 4">
                                </path>
                            </svg>
                        </div>
                        <div class="progress-bar" role="progressbar" *ngIf="steps.indexOf(step) < steps.length-1"
                            [ngClass]="(stepsWithCurrent.indexOf(step) < stepsWithCurrent.indexOf('currentDate') && stepsWithCurrent.indexOf('currentDate') != i+1) ? 'solid-border': 'dashed-border'"
                            [ngStyle]="{ 'width': '7.14%' }" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        </div>
                        <div class="progress-bar" role="progressbar" *ngIf="steps.indexOf(step) == steps.length-1"
                            [ngClass]="'red-border'" [ngStyle]="{ 'width': '16.14%' }" aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class=" w-100 ">
            <div class="alarm-data-parent w-100 mt-3 p-2">
                <div class="alarm-data h-100 container-fluid">
                    <div class="row ps-2 pe-2">
                        <ng-container *ngFor="let section of assetDetailsKeys">
                            <ng-container *ngFor="let item of getKeys(section); let i = index" [ngSwitch]="item">
                                <ng-container class="col-xl-2 col-6 px-3 col-lg-4"
                                    *ngIf="allDetails[section][item].displayName && !((item.toLowerCase()).includes('id'))">
                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'AssetCategories'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value?.assetCategoryName}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item, allDetails[section][item].value?.assetCategoryName)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>

                                            <input id="input-{{item}}" type="text"
                                                (change)="updateTempValue(section, item, $event)"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                (keydown)="$event.stopPropagation()"
                                                [ngModel]="allDetails[section][item].value.assetCategoryName"
                                                class="edit-asset col">
                                            <div class="col" (click)="selectedField = item;selectedSection = section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable && selectedSection == section)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value.assetCategoryName=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'AssetTypes'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value?.typeName}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item, allDetails[section][item].value?.typeName)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <input id="input-{{item}}" type="text"
                                                (change)="updateTempValue(section, item, $event)"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item &&  allDetails[section][item].isWritable"
                                                [ngModel]="allDetails[section][item].value.typeName"
                                                (keydown)="$event.stopPropagation()" class="edit-asset col">
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable && selectedSection == section)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value.typeName=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'IsOutdoor'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value == 'true' || allDetails[section][item].value == true ? 'Outdoor': 'Indoor'}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <select name="isoutdoor" id="isoutdoor" class="edit-asset col"
                                                (change)="updateTempValue(section, item, $event)"
                                                [ngModel]="allDetails[section][item].value"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                (keydown)="$event.stopPropagation()">
                                                <option value="true">Outdoor</option>
                                                <option value="false">Indoor</option>
                                            </select>
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'LocalMonitoringControl'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value == 'true' ? 'Yes': 'No' }}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <select name="localMonitoringControl" id="localMonitoringControl"
                                                (change)="updateTempValue(section, item, $event)" class="edit-asset col"
                                                [ngModel]="allDetails[section][item].value"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                (keydown)="$event.stopPropagation()">
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'CentralMonitoringControl'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value == 'true' ? 'Yes': 'No'}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <select name="centralMonitoringControl" id="centralMonitoringControl"
                                                (change)="updateTempValue(section, item, $event)" class="edit-asset col"
                                                [ngModel]="allDetails[section][item].value"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                (keydown)="$event.stopPropagation()">
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'BusbarMaterial'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{busbar[allDetails[section][item].value]}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <select name="busbarMaterial" id="usbarMaterial"
                                                (change)="updateTempValue(section, item, $event)" class="edit-asset col"
                                                [ngModel]="allDetails[section][item].value"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                (keydown)="$event.stopPropagation()">
                                                <option value="Al">ALUMINIUM</option>
                                                <option value="Tinned Cu">TINNED COPPER</option>
                                                <option value="Cu">COPPER</option>
                                            </select>
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4"
                                        *ngSwitchCase="allDetails[section][item].dataType == 'DateTime'?item:''">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value | date: 'dd/MM/yyyy'}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <ng-container [ngSwitch]="item">
                                                <ng-container *ngSwitchCase="'WarrantyEndDate'">
                                                    <input id="input-{{item}}" type="date"
                                                    (change)="updateTempValue(section, item, $event)"
                                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                     value="{{allDetails[section][item].value | date: 'yyyy-MM-dd'}}"
                                                     class="edit-asset col" [min]="(allDetails[section]['WarrantyStartDate']?.value | date: 'yyyy-MM-dd') ?? null">
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'WarrantyStartDate'">
                                                    <input id="input-{{item}}" type="date"
                                                    (change)="updateTempValue(section, item, $event)"
                                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                     value="{{allDetails[section][item].value | date: 'yyyy-MM-dd'}}"
                                                    (keydown)="$event.stopPropagation()" class="edit-asset col" [max]="(allDetails[section]['WarrantyEndDate'].value | date: 'yyyy-MM-dd') ?? null">
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'ContractEndDate'">
                                                    <input id="input-{{item}}" type="date"
                                                    (change)="updateTempValue(section, item, $event)"
                                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                     value="{{allDetails[section][item].value | date: 'yyyy-MM-dd'}}"
                                                     class="edit-asset col" [min]="(allDetails[section]['ContractStartDate']?.value | date: 'yyyy-MM-dd') ?? null">
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'ContractStartDate'">
                                                    <input id="input-{{item}}" type="date"
                                                    (change)="updateTempValue(section, item, $event)"
                                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                     value="{{allDetails[section][item].value | date: 'yyyy-MM-dd'}}"
                                                    (keydown)="$event.stopPropagation()" class="edit-asset col" [max]="(allDetails[section]['ContractEndDate']?.value | date: 'yyyy-MM-dd') ?? null">
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <input id="input-{{item}}" type="date"
                                                    (change)="updateTempValue(section, item, $event)"
                                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable"
                                                     value="{{allDetails[section][item].value | date: 'yyyy-MM-dd'}}"
                                                    (keydown)="$event.stopPropagation()" class="edit-asset col">
                                                </ng-container>
                                            </ng-container>
                                            
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && allDetails[section][item].isWritable && selectedSection == section)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchCase="'DeletionFlag'">
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <input type="checkbox" *ngIf="allDetails[section][item].value" [checked]="allDetails[section][item].value" 
                                                 readonly>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <div class="col d-flex justify-content-end" *ngIf="isEditableForUser && assetEdit && selectedField == item &&  allDetails[section][item].isWritable"
                                            >
                                                <input type="checkbox" value="" id="input-{{item}}" [checked]="allDetails[section][item].value"
                                            (click)="updateTempValue(section, item, $event)"
                                            #check>
                                            </div>
                                            <div class="col d-flex justify-content-start" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && assetEdit && selectedField == item && allDetails[section][item].isWritable && selectedSection == section)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-2 col-6 px-3 col-lg-4" *ngSwitchDefault>
                                        <div class="field-header mt-2">{{allDetails[section][item].displayName|
                                            camelToSpace |
                                            titlecase}}
                                            <span *ngIf="isEditableForUser && assetEdit && selectedField == item &&  allDetails[section][item].isWritable">{{'(Max len: ' + allDetails[section][item].maxLength+')'}}</span>
                                        </div>
                                        <div class="row">
                                            <div [id]="item"
                                                *ngIf="!(assetEdit && selectedField == item && selectedSection == section)"
                                                class="field-value col">
                                                <span>{{allDetails[section][item].value}}</span>
                                                <button class="edit-icon" [disabled]="pendingRequest" (click)="setEditProperties(section, item)"
                                                *ngIf="isEditableForUser && allDetails[section][item].isWritable">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                            <input id="input-{{item}}" type="text"
                                                (change)="updateTempValue(section, item, $event)"
                                                *ngIf="isEditableForUser && assetEdit && selectedField == item &&  allDetails[section][item].isWritable"
                                                [maxlength]="allDetails[section][item].maxLength"
                                                [ngModel]="allDetails[section][item].value"
                                                (keydown)="$event.stopPropagation()" class="edit-asset col">
                                            <div class="col" (click)="selectedField = item;selectedSection=section"
                                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && assetEdit && selectedField == item && allDetails[section][item].isWritable && selectedSection == section)">
                                                <i class="fa fa-check healthy p-1" title="Save"
                                                    (click)="sendValues(section, item)"></i>
                                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                                    (click)="assetEdit = !assetEdit;allDetails[section][item].value=oldVal"></i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <hr *ngIf="getKeys(section).length" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="ex1-asset-tabs-2" role="tabpanel" aria-labelledby="ex1-asset-tab-2">
        <app-healthkpi [target]="'assets'" [data]="{assetId:selectedAssetId , feeder: '', siteId: siteId }"
            *ngIf="selectedTab=='healthKpi'"></app-healthkpi>
    </div>
</div>
<!-- Tabs content -->