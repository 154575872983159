<ng-container *ngIf="!details">
  <!-- Desktop Header -->

  <div class="container-fluid row gx-4 pt-3 pb-3 m-0 asset-overview desktop-only main-header">
    <div class="row col-4 site-detials">
      <div class="col-2 ps-2">
        <p class="mb-0" style="color: var(--card-normal-text);font-size: 12px;">Site Name</p>
      </div>
      <div class="search col-5 ps-0 pe-0">
<!-- 
        <select name="sites" id="sites" class="form-control select-input" [(ngModel)]="selectedSite"
          (change)="changeSite($event.target)">
          <option *ngFor="let site of siteList; let i = index;" [value]="site?.siteId" [title]="site.siteName">
            {{ (site?.siteName.length>20)? (site?.siteName | slice:0:20)+'...':(site?.siteName) }}</option>
        </select> -->
        <ng-select [items]="siteList" [(ngModel)]="selectedSite" bindValue="siteId" [dropdownPosition]="'bottom'" bindLabel="siteName" id="select-site" placeholder="Select site" (change)="changeSite($event.siteId)">
          <ng-template ng-option-tmp let-item="item">
              <div title="{{item.siteName}}">{{item.siteName}}</div>
          </ng-template>
      </ng-select>
        <!-- <i class="fa fa-angle-down"></i> -->
      </div>
      <!-- <div class="search col-5">
          <select name="sites" id="sites" class="form-control select-input sub-station" [(ngModel)]="selectedSubStation" (change)="changeSubstation($event.target)" [disabled]="!subStations?.length" placeholder="Select Substation">
            <option  *ngFor="let subStation of subStations" [ngValue]="subStation?.id">{{ subStation?.subStationName }}</option>
        </select>
        <i class="fa fa-angle-down"></i>
      </div> -->
      <div class=" col-5">
        <!-- <ng-select [items]="subStations" (change)="changeSubstation($event.target)" bindValue="id" [multiple]="true" [selectableGroup]="true"  [closeOnSelect]="false"
        [dropdownPosition]="'bottom'" bindLabel="subStationName" placeholder="Select Substation" [(ngModel)]="selectedSubStation">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
          </ng-template>
        </ng-select> -->
        <ng-select [items]="subStations" [(ngModel)]="selectedSubstations" bindValue="id" [dropdownPosition]="'bottom'"
          bindLabel="subStationName" placeholder="Select SubStation" (change)="changeSubstation($event)"
          [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.subStationName | titlecase}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.subStationName}}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-5 p-1 asset-counts" style="background-color: var(--card-bg); color: var(--card-label-text);">
      <div class="assest-status d-flex">
        <div class="">
          <p class="mb-0 asset-subAsset-heading">Assets <span class="asset-subAsset-count">{{ totalAsset }}</span></p>
        </div>
        <div class="assest-status-btn">
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 veryGood" 
                [ngClass]="statusFilterText === 'veryGood' ? 'filtered' : ''" 
                (click)="applyStatusFilter('veryGood')">
                Very Good {{ veryGood }}
            </button>
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 good" 
                [ngClass]="statusFilterText === 'good' ? 'filtered' : ''" 
                (click)="applyStatusFilter('good')">
                Good {{ good }}
            </button>
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 fair" 
                [ngClass]="statusFilterText === 'fair' ? 'filtered' : ''" 
                (click)="applyStatusFilter('fair')">
                Fair {{ fair }}
            </button>
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 poor" 
                [ngClass]="statusFilterText === 'poor' ? 'filtered' : ''" 
                (click)="applyStatusFilter('poor')">
                Poor {{ poor }}
            </button>
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 veryPoor" 
                [ngClass]="statusFilterText === 'veryPoor' ? 'filtered' : ''" 
                (click)="applyStatusFilter('veryPoor')">
                Very Poor {{ veryPoor }}
            </button>
            <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 unknown" 
                [ngClass]="statusFilterText === 'unknown' ? 'filtered' : ''" 
                (click)="applyStatusFilter('unknown')">
                Unknown {{ unknown }}
            </button>
            <!-- <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 healthy" 
                [ngClass]="statusFilterText === 'Healthy' ? 'filtered' : ''" 
                (click)="applyStatusFilter('Healthy')">
                Healthy {{ totalHealty }}
            </button>
          <button type="button" class="btn btn-rounded btn-outline-light p-0 ms-2 me-2 col-4 at-risk"
            [ngClass]="statusFilterText === 'AtRisk' ? 'filtered' : ''" (click)="applyStatusFilter('AtRisk')">At risk {{
            totalAtRisk }}</button>
          <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 critical"
            [ngClass]="statusFilterText === 'Critical' ? 'filtered' : ''"
            (click)="applyStatusFilter('Critical')">Critical
            {{ totalCritical }}</button> -->
        </div>
        <!-- <div class="col-3 asset-subAsset">
          <p class="mb-0 asset-subAsset-heading">Sub Assets <span class="asset-subAsset-count">{{ totalSubAsset
              }}</span>
          </p>
        </div> -->
      </div>
    </div>
    <div class="col-3">
      <div class="row gx-3 asset-actions">
        <div class="search col-4">
          <input type="text" class="form-control sub-station select-input" placeholder="Search"
            [(ngModel)]="searchKeyword" 
            (keyup.enter)="getAssets.next(false)"
            (keyup)="searchKeyword?.length > 2 || searchKeyword?.length === 0 ? getAssets.next(false) : ''">
          <i class="fa fa-search" (click)="getAssets.next(false)"></i>
        </div>
        <div class="search col-4">
          <mat-select name="filters" id="filters" [formControl]="filterControl" placeholder="Filters" #assetFilters class="form-control select-input assetFilter"
            disableOptionCentering multiple>
            <mat-select-trigger>
              {{ filterControl.value?.[0] }}
              <span *ngIf="filterControl.value?.length > 1">(+ {{ filterControl.value?.length -1 }})</span>
            </mat-select-trigger>

            <mat-optgroup label="Asset Status">
              <mat-option
                *ngFor="let assetStatus of assetFilterData.assetStatus.list | slice:0:assetFilterData.assetStatus.countLimit"
                [value]="assetStatus" (onSelectionChange)="filterSelection($event, 'assetStatus')"
                title="{{assetStatus}}">{{ assetStatus }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.assetStatus.list.length > 3"
              (click)="toggleFilterList('assetStatus', assetFilterData.assetStatus.hidden)">{{
              assetFilterData.assetStatus.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="SubAsset/Component Status" title="SubAsset/Component Status">
              <mat-option
                *ngFor="let subAssetStatus of assetFilterData.subAssetStatus.list | slice:0:assetFilterData.subAssetStatus.countLimit"
                [value]="subAssetStatus" (onSelectionChange)="filterSelection($event, 'subAssetStatus')"
                title="{{subAssetStatus}}">{{ subAssetStatus }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.subAssetStatus.list.length > 3"
              (click)="toggleFilterList('subAssetStatus', assetFilterData.subAssetStatus.hidden)">{{
              assetFilterData.subAssetStatus.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="Asset Category">
              <mat-option
                *ngFor="let assetCategory of assetFilterData.assetCategory.list | slice:0:assetFilterData.assetCategory.countLimit"
                [value]="assetCategory.name" (onSelectionChange)="filterSelection($event, 'assetCategory')"
                title="{{assetCategory.name}}">{{ assetCategory.name }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.assetCategory.list.length > 3"
              (click)="toggleFilterList('assetCategory', assetFilterData.assetCategory.hidden)">{{
              assetFilterData.assetCategory.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="SubAsset/Component Category" title="SubAsset/Component Category">
              <mat-option
                *ngFor="let subAssetCategory of assetFilterData.subAssetCategory.list | slice:0:assetFilterData.subAssetCategory.countLimit"
                [value]="subAssetCategory" (onSelectionChange)="filterSelection($event, 'subAssetCategory')"
                title="{{subAssetCategory}}">{{ subAssetCategory }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.subAssetCategory.list.length > 3"
              (click)="toggleFilterList('subAssetCategory', assetFilterData.subAssetCategory.hidden)">{{
              assetFilterData.subAssetCategory.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="Component Make">
              <mat-option
                *ngFor="let assetMake of assetFilterData.assetMake.list | slice:0:assetFilterData.assetMake.countLimit"
                [value]="assetMake" (onSelectionChange)="filterSelection($event, 'componentMake')" title="{{assetMake}}">{{
                assetMake }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.assetMake.list.length > 3"
              (click)="toggleFilterList('assetMake', assetFilterData.assetMake.hidden)">{{
              assetFilterData.assetMake.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="Design Type">
              <mat-option
                *ngFor="let designType of assetFilterData.designType.list | slice:0:assetFilterData.designType.countLimit"
                [value]="designType" (onSelectionChange)="filterSelection($event, 'designType')" title="{{designType}}">
                {{ designType }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.designType.list.length > 3"
              (click)="toggleFilterList('designType', assetFilterData.designType.hidden)">{{
              assetFilterData.designType.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="System Voltage">
              <mat-option
                *ngFor="let systemVoltage of assetFilterData.systemVoltage.list | slice:0:assetFilterData.systemVoltage.countLimit"
                [value]="systemVoltage" (onSelectionChange)="filterSelection($event, 'systemVoltage')"
                title="{{systemVoltage}}">{{ systemVoltage }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.systemVoltage.list.length > 3"
              (click)="toggleFilterList('systemVoltage', assetFilterData.systemVoltage.hidden)">{{
              assetFilterData.systemVoltage.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup label="Balance Life">
              <mat-option
                *ngFor="let balanceLife of assetFilterData.balanceLife.list | slice:0:assetFilterData.balanceLife.countLimit"
                [value]="balanceLife" (onSelectionChange)="filterSelection($event, 'balanceLife')"
                title="{{balanceLife}}">{{ balanceLife }}</mat-option>
            </mat-optgroup>
            <a class="filterShowMore" *ngIf="assetFilterData.balanceLife.list.length > 3"
              (click)="toggleFilterList('balanceLife', assetFilterData.balanceLife.hidden)">{{
              assetFilterData.balanceLife.hidden ? 'show more' : 'show less' }}</a>

            <mat-optgroup class="clearAll" role="button">
                <span role="button" (click)="clearAllFilters()">Clear All</span>
            </mat-optgroup>

          </mat-select>
          <!-- <i class="fa fa-filter"></i> -->
        </div>
        <!-- <div class="col-1" style="margin-bottom: 15px;"><i class="fa fa-print"
            style="position:absolute; right :10px;"></i></div>
        <div class="col-1" style="margin-bottom: 15px;"><i class="fa fa-cloud-download-alt"
            style="position:absolute; right :35px;"></i></div> -->
        <div class="col-2 d-flex">
          <app-icon class="me-2" title="Print" iconFor="print" action="group"></app-icon>
          <app-icon class="" title="Download" iconFor="download" action="group"></app-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile Header -->
  <div class="container-fluid gx-3 row mt-2 asset-overview-mobile non-desktop-only px-0">
    <div class="row" style="position: relative;">
      <div class="search col-5">
        <select name="sites" id="sites" class="form-control select-input pe-4" [(ngModel)]="selectedSite"
          (change)="changeSite($event.target)">
          <option *ngFor="let site of siteList; let i = index;" [value]="site?.siteId" [title]="site.siteName">
            {{ (site?.siteName.length>20)? (site?.siteName | slice:0:20)+'...':(site?.siteName) }}</option>
        </select>
        <i class="fa fa-angle-down"></i>
      </div>
      <div class="search col-5 px-0">
        <ng-select [items]="subStations" [(ngModel)]="selectedSubstations" bindValue="id" [dropdownPosition]="'bottom'"
          bindLabel="subStationName" placeholder="Select SubStation" (change)="changeSubstation($event)"
          [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.subStationName | titlecase}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.subStationName}}
          </ng-template>
        </ng-select>
      </div>
      <span class="mobile-filter">
        <!-- <span data-mdb-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false"
          aria-controls="collapseExample2" class="px-1"><i class="fa fa-filter"></i></span>
        <span class="px-1">
          <i class="fa fa-print"></i>
        </span>
        <span class="ps-1">
          <i class="fa fa-cloud-download-alt"></i>
        </span> -->
        <span data-mdb-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false"
        aria-controls="collapseExample2" class="px-1"><app-icon class="me-2" title="Filter" iconFor="filter" action="group"></app-icon></span>
        <app-icon class="me-2" title="Print" iconFor="print" action="group"></app-icon>
        <app-icon class="" title="Download" iconFor="download" action="group"></app-icon>
      </span>

    </div>
    <div class="row asset-actions collapse mt-2" id="collapseExample2">
      <div class="search col-5">
        <input type="text" class="form-control sub-station select-input" placeholder="Search"
          [(ngModel)]="searchKeyword" 
          (keyup.enter)="getAssets.next(false)"
            (keyup)="searchKeyword?.length > 2 || searchKeyword?.length === 0 ? getAssets.next(false) : ''">
        <i class="fa fa-search" (click)="getAssets.next(false)"></i>
      </div>
      <div class="search col-5 ps-0 pe-3">
        <mat-select name="filters" id="filters" [formControl]="filterControl" placeholder="Filters" #assetFiltersMobile class="form-control select-input assetFilter"
        disableOptionCentering multiple>
        <mat-select-trigger>
          {{ filterControl.value?.[0] }}
          <span *ngIf="filterControl.value?.length > 1">(+ {{ filterControl.value?.length -1 }})</span>
        </mat-select-trigger>

        <mat-optgroup label="Asset Status">
          <mat-option
            *ngFor="let assetStatus of assetFilterData.assetStatus.list | slice:0:assetFilterData.assetStatus.countLimit"
            [value]="assetStatus" (onSelectionChange)="filterSelection($event, 'assetStatus')"
            title="{{assetStatus}}">{{ assetStatus }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.assetStatus.list.length > 3"
          (click)="toggleFilterList('assetStatus', assetFilterData.assetStatus.hidden)">{{
          assetFilterData.assetStatus.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="SubAsset/Component Status" title="SubAsset/Component Status">
          <mat-option
            *ngFor="let subAssetStatus of assetFilterData.subAssetStatus.list | slice:0:assetFilterData.subAssetStatus.countLimit"
            [value]="subAssetStatus" (onSelectionChange)="filterSelection($event, 'subAssetStatus')"
            title="{{subAssetStatus}}">{{ subAssetStatus }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.subAssetStatus.list.length > 3"
          (click)="toggleFilterList('subAssetStatus', assetFilterData.subAssetStatus.hidden)">{{
          assetFilterData.subAssetStatus.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="Asset Category">
          <mat-option
            *ngFor="let assetCategory of assetFilterData.assetCategory.list | slice:0:assetFilterData.assetCategory.countLimit"
            [value]="assetCategory.name" (onSelectionChange)="filterSelection($event, 'assetCategory')"
            title="{{assetCategory.name}}">{{ assetCategory.name }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.assetCategory.list.length > 3"
          (click)="toggleFilterList('assetCategory', assetFilterData.assetCategory.hidden)">{{
          assetFilterData.assetCategory.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="SubAsset/Component Category" title="SubAsset/Component Category">
          <mat-option
            *ngFor="let subAssetCategory of assetFilterData.subAssetCategory.list | slice:0:assetFilterData.subAssetCategory.countLimit"
            [value]="subAssetCategory" (onSelectionChange)="filterSelection($event, 'subAssetCategory')"
            title="{{subAssetCategory}}">{{ subAssetCategory }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.subAssetCategory.list.length > 3"
          (click)="toggleFilterList('subAssetCategory', assetFilterData.subAssetCategory.hidden)">{{
          assetFilterData.subAssetCategory.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="Component Make">
          <mat-option
            *ngFor="let assetMake of assetFilterData.assetMake.list | slice:0:assetFilterData.assetMake.countLimit"
            [value]="assetMake" (onSelectionChange)="filterSelection($event, 'componentMake')" title="{{assetMake}}">{{
            assetMake }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.assetMake.list.length > 3"
          (click)="toggleFilterList('assetMake', assetFilterData.assetMake.hidden)">{{
          assetFilterData.assetMake.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="Design Type">
          <mat-option
            *ngFor="let designType of assetFilterData.designType.list | slice:0:assetFilterData.designType.countLimit"
            [value]="designType" (onSelectionChange)="filterSelection($event, 'designType')" title="{{designType}}">
            {{ designType }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.designType.list.length > 3"
          (click)="toggleFilterList('designType', assetFilterData.designType.hidden)">{{
          assetFilterData.designType.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="System Voltage">
          <mat-option
            *ngFor="let systemVoltage of assetFilterData.systemVoltage.list | slice:0:assetFilterData.systemVoltage.countLimit"
            [value]="systemVoltage" (onSelectionChange)="filterSelection($event, 'systemVoltage')"
            title="{{systemVoltage}}">{{ systemVoltage }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.systemVoltage.list.length > 3"
          (click)="toggleFilterList('systemVoltage', assetFilterData.systemVoltage.hidden)">{{
          assetFilterData.systemVoltage.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup label="Balance Life">
          <mat-option
            *ngFor="let balanceLife of assetFilterData.balanceLife.list | slice:0:assetFilterData.balanceLife.countLimit"
            [value]="balanceLife" (onSelectionChange)="filterSelection($event, 'balanceLife')"
            title="{{balanceLife}}">{{ balanceLife }}</mat-option>
        </mat-optgroup>
        <a class="filterShowMore" *ngIf="assetFilterData.balanceLife.list.length > 3"
          (click)="toggleFilterList('balanceLife', assetFilterData.balanceLife.hidden)">{{
          assetFilterData.balanceLife.hidden ? 'show more' : 'show less' }}</a>

        <mat-optgroup class="clearAll" role="button">
            <span role="button" (click)="clearAllFilters()">Clear All</span>
        </mat-optgroup>

      </mat-select>
        <!-- <i class="fa fa-filter"></i> -->
      </div>
    </div>
    <div class="container-fluid gx-3 mt-2 mx-2 asset-counts-mobile">
      <div class="assest-status py-2 row">
        <div class="col-2">
          <p class="mb-0 asset-subAsset-heading-mobile">Assets <span class="asset-subAsset-count-mobile">{{ totalAsset
              }}</span></p>
        </div>
        <div class="assest-status-btn col-7">
          <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 healthy"
            [ngClass]="statusFilterText === 'Healthy' ? 'filtered' : ''" (click)="applyStatusFilter('Healthy')">Healthy
            {{
            totalHealty }}</button>
          <button type="button" class="btn btn-rounded btn-outline-light p-0 ms-2 me-2 col-4 at-risk"
            [ngClass]="statusFilterText === 'AtRisk' ? 'filtered' : ''" (click)="applyStatusFilter('AtRisk')">At risk {{
            totalAtRisk }}</button>
          <button type="button" class="btn btn-rounded btn-outline-light p-0 col-4 critical"
            [ngClass]="statusFilterText === 'Critical' ? 'filtered' : ''"
            (click)="applyStatusFilter('Critical')">Critical
            {{ totalCritical }}</button>
        </div>
        <div class="col-3 asset-subAsset">
          <p class="mb-0 asset-subAsset-heading-mobile">Sub Assets <span class="asset-subAsset-count-mobile">{{
              totalSubAsset }}</span></p>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <div class="container text-center mt-5 loader">
      <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <!-- <div class="text-center m-4 critical"
      *ngIf="showNoDataFilter || ((newAssets | assetFilter: statusFilterText: '': statusFilterCategory | assetSearch: searchKeyword)?.length <= 0)">
      No Data found for the Applied Filter</div> -->
      <div class="text-center m-4 critical"
      *ngIf="showNoDataFilter || newAssets?.length <= 0">
      No Data found for the Applied Filter</div>
  </ng-container>
  <!-- <div class="text-center m-4 critical" *ngIf="showNoDataFilter">No Data found for the Applied Filter</div> -->
<div>
  <div class="container-fluid gx-3 gx-lg-4 py-2">
    <div class="row pb-5"
     infiniteScroll
[infiniteScrollDistance]="2"
[infiniteScrollThrottle]="50"
(scrolled)="onScroll()"
[scrollWindow]="false" 
style="overflow-y:auto;"
[ngStyle]="{'opacity':isLoading?0.2:1,'height':isMobile ? '':(innerHeight-254)+'px'}">
      <!-- <div class="col-xl-3 col-lg-4 col-sm-6 col-12 py-2"
        *ngFor="let asset of newAssets | assetFilter: statusFilterText: '': statusFilterCategory | assetSearch: searchKeyword; let i = index"> -->
        <div class="col-xl-3 col-lg-4 col-sm-6 col-12 py-2"
        *ngFor="let asset of newAssets; let i = index">
        <div class="card assest-health-status" [ngClass]="asset?.AssetHealth?.value" [style.height]="isMobile ? '' : '320px'">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <div type="button" class="dashboard-card-header desktop-only" data-mdb-toggle="modal"
                  attr.data-mdb-target="#assetModal{{i}}" (click)="selectedLink = i; isAsset = true">{{
                  asset?.SerialNo?.value }}
                </div>
                <div type="button" class="dashboard-card-header non-desktop-only">
                  {{ asset?.SerialNo?.value }}</div>
                <div class="modal fade w-100 h-90" id="assetModal{{i}}" tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-xl h-100 modal-sm">
                    <div class="modal-content h-100">
                      <div class="modal-body pb-4 scroll-adjust">
                        <!-- Tabs navs -->
                        <div class="row pt-0">
                          <div class="col-11 px-0">
                            <ul class="nav nav-tabs mb-3 px-0 pt-0" id="ex1" role="tablist">
                              <li class="nav-item px-0 me-2" role="presentation">
                                <a class="nav-link px-3 py-3" id="ex1-tab-asset-1" data-mdb-toggle="tab"
                                  [ngClass]="isAsset ? 'active': ''" href="#ex1-tab-asset-s-1-{{i}}" role="tab"
                                  attr.aria-controls="ex1-tab-asset-s-1-{{i}}"
                                  aria-selected="true">{{asset?.SerialNo?.value}}</a>
                              </li>
                              <li class="nav-item mx-4" role="presentation">
                                <a class="nav-link px-3 py-3" id="ex1-tab-asset-2-{{i}}"
                                  [ngClass]="!isAsset ? 'active': ''" data-mdb-toggle="tab"
                                  href="#ex1-tab-asset-s-2-{{i}}" role="tab"
                                  attr.aria-controls="ex1-tab-asset-s-2-{{i}}" aria-selected="false"
                                  style="text-transform: none!important;">Sub Assets ({{
                                  asset?.SubAssetsCount?.value }})</a>
                              </li>
                            </ul>
                          </div>
                          <div class="col-1 px-0">
                            <ul class="nav nav-tabs mb-3 px-0 pt-0" id="ex1" role="tablist">
                              <li class="nav-item px-0 me-2" role="presentation">
                                <a class="nav-link px-5 py-3" data-mdb-dismiss="modal" aria-selected="true">
                                  <i class="fa fa-times" data-mdb-dismiss="modal" aria-label="Close" role="button"></i>
                                  <!-- <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button> -->
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!-- Tabs navs -->

                        <!-- Tabs content -->
                        <div class="tab-content px-3" id="ex1-content">
                          <div class="tab-pane fade" id="ex1-tab-asset-s-1-{{i}}" role="tabpanel"
                            attr.aria-labelledby="ex1-tab-asset-1-{{i}}" [ngClass]="isAsset ? 'active show': ''">
                            <app-asset-details [selectedAssetId]="asset?.Id.value" *ngIf="selectedLink == i" [isEditableForUser]="false" [siteId]="selectedSite" [customerId]="customerId" (subAssetSelection)="subAssetSelection($event)">
                            </app-asset-details>
                          </div>
                          <div class="tab-pane fade" id="ex1-tab-asset-s-2-{{i}}" role="tabpanel"
                            attr.aria-labelledby="ex1-tab-asset-2-{{i}}" [ngClass]="!isAsset ? 'active show': ''"
                            style="position: relative;">
                            <app-sub-asset-details [selectedAssetId]="asset?.Id.value" *ngIf="selectedLink == i"
                              [siteId]="selectedSite" [isEditableForUser]="false"
                              [health]="[asset?.SubMenuDto?.value?.healthy, asset?.SubMenuDto?.value?.risk, asset?.SubMenuDto?.value?.critical]"
                              [selectedFilter]="selectedSubAssetStatus">
                            </app-sub-asset-details>
                          </div>
                        </div>
                        <!-- Tabs content -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 col-lg-3" *ngIf="module?.AlarmOrEvents?.canRead">
                <i *ngIf="asset?.GatewayStatus?.value && asset?.GatewayStatus?.value != 'NotConnected' && asset?.GatewayStatus?.value != 'DisConnected'" class="fa fa-wifi me-2" style="font-size: 16px;" [ngClass]="asset?.GatewayStatus?.value"></i>
                <span *ngIf="asset?.GatewayStatus?.value && (asset?.GatewayStatus?.value == 'NotConnected' || asset?.GatewayStatus?.value == 'DisConnected')"><svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                data-icon="wifi-slash" role="img" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 640 712"
                                                class="svg-inline--fa fa-wifi-slash fa-w-20 critical">
                                                <path fill="currentColor"
                                                    d="M5.09 154.87c-6.66 6.16-6.79 16.59-.35 22.97l34.24 33.96c6.14 6.09 16.02 6.23 22.4.38 6.99-6.4 14.31-12.22 21.65-18.01l-64.96-50.21c-4.3 3.71-8.79 7.04-12.98 10.91zm471.75 181.9l45.42-45.21c6.52-6.46 6.29-17.06-.57-23.17-64.94-57.74-148.91-82.66-230.34-74.98l-83.16-64.27c125.94-38.36 267.75-11.01 370.43 83.05 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.96c6.44-6.39 6.3-16.82-.35-22.97C496.46 26.82 298.08-.76 133.42 71.35L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.03-2.81-22.45L476.84 336.77zm-358.53-68.38c-6.86 6.1-7.08 16.7-.57 23.17l34.28 34.01c5.97 5.93 15.59 6.32 21.94.8 13.35-11.6 28.01-20.66 43.15-28.55l-68.36-52.83c-10.48 7.15-20.74 14.78-30.44 23.4zM256 416c0 35.35 28.65 64 64 64 31.91 0 58.15-23.42 62.99-53.98l-88.7-68.56C271.77 367.37 256 389.82 256 416z"
                                                    class=""></path>
                                            </svg></span>
                <div class="card-notification">
                  <a target="_blank" [routerLink]="['/layout/alarms']"
                    [queryParams]="{siteId: selectedSite, assetId:asset?.Id.value}">
                    <i class="fas fa-bell fa-md"></i>
                    <span style="color: #FF1A1A; border: 2px solid #024D87; background-color: #FFFFFF;"
                      class="badge rounded-pill badge-notification" *ngIf="asset?.AlarmCount?.value">
                      {{ asset?.AlarmCount?.value > 99 ? '99+': asset?.AlarmCount?.value}}</span>
                  </a>
                </div>
              </div>
              <div class="col-1 non-desktop-only">
                <div class="card-notification">
                  <a data-mdb-toggle="collapse" attr.href="#asset-card{{i}}" role="button" aria-expanded="false"
                    aria-controls="collapseExample">
                    <i class="fas fa-angle-down fa-md"></i>
                  </a>
                </div>
              </div>
              <div class="col-1">
                <div class="parent dropstart" style="position: absolute;top: 0; right: 0;">
                  <a class="card-menu" id="dropdownMenuLink1" role="button" data-mdb-toggle="dropdown"
                    aria-expanded="false">
                    <i class="fa fa-ellipsis-v"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1"
                    style="box-shadow: 0px 0px 15px #00000066;border-radius: 5px;">
                    <li *ngIf="module?.AssetAndSubAsset?.canRead">
                      <a class="dropdown-item desktop-only" data-mdb-toggle="modal" attr.data-mdb-target="#assetModal{{i}}"
                        (click)="selectedLink = i;isAsset = true">View asset details</a>
                      <a class="dropdown-item non-desktop-only"
                      (click)="$event.preventDefault();$event.stopPropagation();selectedLink = i;isMobile=true; isAsset = true;details=true;selectedAsset=asset">View asset details</a>
                    </li>
                    <li *ngIf="module?.AssetAndSubAsset?.canRead">
                      <a class="dropdown-item desktop-only" data-mdb-toggle="modal" attr.data-mdb-target="#assetModal{{i}}"
                        (click)="selectedLink = i;isAsset = false">View subasset details</a>
                      <a class="dropdown-item non-desktop-only"
                      (click)="$event.preventDefault();$event.stopPropagation();selectedLink = i;isMobile=true; isAsset = false;details=true;selectedAsset=asset">View subasset details</a>
                    </li>
                    <!-- <li><a class="dropdown-item">View alarm / Events</a></li> -->
                    <li *ngIf="module?.AlarmOrEvents?.canRead">
                      <a class="dropdown-item" target="_blank" [routerLink]="['/layout/alarms']"
                        [queryParams]="{siteId: selectedSite, assetId:asset?.Id.value}">View alarm / Events</a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="row">
              <!-- Collapsed content -->
              <div class="mt-0" id="asset-card{{i}}" [ngClass]="isMobile ? 'collapse':''">
                <div class="row mt-0 asset-desc asset-gray">
                  <div class="col-4 name pe-0">{{ asset?.SerialNo?.value }}</div>
                  <div class="col-1 px-0"><div class="vr"></div></div>
                  <div class="col-4 name px-0">{{asset?.AssetCategoryName?.value}}</div>
                  <!-- <div class="col-5" [ngClass]="asset?.WarrantyStatus?.value?.statusHealth">{{
                    asset?.WarrantyStatus?.value?.statusName }}</div> -->
                </div>
                <!-- <div class="row asset-desc asset-gray" style="height: 36px;">
                  <div class="col-7 name">{{ asset?.AssetCategoryName?.value }}</div>
                  <div class="col-5" [ngClass]="asset?.ContractStatus?.value?.statusHealth">{{
                    asset?.ContractStatus?.value?.statusName }}</div>
                </div> -->
                <div class="card-body-upperDeck pb-2"></div>
                <div class="row asset-desc pt-2">
                    <div class="col-5 risk-stauts pe-0">Risk :
                        <span [ngClass]="asset?.RiskStatus?.value">{{ asset?.RiskStatus?.value }}</span>
                    </div>
                  <div class="col-1 px-0"><div class="vr"></div></div>
                  <div class="col-5 maintenance-stauts px-0">Maintenance :
                    <span [ngClass]="asset?.MaintenanceStatus?.value">{{ asset?.MaintenanceStatus?.value }}</span>
                  </div>
                  <div class="col-5" [ngClass]="asset?.ObsolensceneStatus?.value?.statusHealth"></div>
                </div>
                <div class="card-body-upperDeck pb-2"></div>
                <div class="card-body-keel pt-2">
                  <div class="dashboard-card-header col-sm-10 desktop-only" data-mdb-toggle="modal" role="button"
                    attr.data-mdb-target="#assetModal{{i}}" (click)="selectedLink = i;isAsset = false">Sub Asset ({{
                    asset?.SubAssetsCount?.value }})</div>
                    <div class="dashboard-card-header col-sm-10 non-desktop-only">Sub Asset ({{
                    asset?.SubAssetsCount?.value }})</div>
                  <div class="asset-status-keel mt-1 d-flex">
                    <div class="asset-status-keel-head " style="width:85px;">Health Status</div>
                    <div class="keel-button-group">
                      <!-- <button type="button" class="btn btn-rounded me-2 btn-outline-light healthy">
                        <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.healthy}}">
                          {{ asset?.SubMenuDto?.value?.healthy > 99 ? '99+':asset?.SubMenuDto?.value?.healthy}}</span></button>
                      <button type="button" class="btn btn-rounded me-2 btn-outline-light warning">
                        <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.critical}}">
                          {{ asset?.SubMenuDto?.value?.critical > 99 ? '99+':asset?.SubMenuDto?.value?.critical}}</span></button>
                      <button type="button" class="btn btn-rounded btn-outline-light risk">
                        <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.risk}}">
                          {{ asset?.SubMenuDto?.value?.risk > 99 ? '99+':asset?.SubMenuDto?.value?.risk}}</span></button> -->
                        <button type="button" class="btn btn-rounded btn-outline-light veryGood">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.veryGood}}">
                              {{ asset?.SubMenuDto?.value?.veryGood > 99 ? '99+':asset?.SubMenuDto?.value?.veryGood}}
                            </span>
                        </button>
                        <button type="button" class="btn btn-rounded btn-outline-light good">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.good}}">
                              {{ asset?.SubMenuDto?.value?.good > 99 ? '99+':asset?.SubMenuDto?.value?.good}}
                            </span>
                        </button>
                        <button type="button" class="btn btn-rounded btn-outline-light fair">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.fair}}">
                              {{ asset?.SubMenuDto?.value?.fair > 99 ? '99+':asset?.SubMenuDto?.value?.fair}}
                            </span>
                        </button>
                        <button type="button" class="btn btn-rounded btn-outline-light poor">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.poor}}">
                              {{ asset?.SubMenuDto?.value?.poor > 99 ? '99+':asset?.SubMenuDto?.value?.poor}}
                            </span>
                        </button>
                        <button type="button" class="btn btn-rounded btn-outline-light veryPoor">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.veryPoor}}">
                              {{ asset?.SubMenuDto?.value?.veryPoor > 99 ? '99+':asset?.SubMenuDto?.value?.veryPoor}}
                            </span>
                        </button>
                        <button type="button" class="btn btn-rounded btn-outline-light unknown">
                            <span *ngIf="asset?.SubMenuDto?.value" matTooltip="{{asset?.SubMenuDto?.value?.unknown}}">
                              {{ asset?.SubMenuDto?.value?.unknown > 99 ? '99+':asset?.SubMenuDto?.value?.unknown}}
                            </span>
                        </button>
                    </div>
                  </div>
                  <div class="card-body-upperDeck pb-3"></div>
                  <div class="dashboard-card-header col-sm-10 mt-2">Components ({{
                  asset?.ComponentCount?.value }})</div>
                  <div class="asset-status-keel row mt-1">
                    <div class="asset-status-keel-head col-lg-6 col-xl-5 pe-0">Obsolescence Profile</div>
                    <div class="keel-button-group col-lg-6 col-xl-7">
                      <button type="button" class="btn btn-rounded me-2 btn-outline-light healthy">
                        <span *ngIf="asset?.ObsolenscenceStatusCount?.value" matTooltip="{{asset?.ObsolenscenceStatusCount?.value?.underActiveLifecycle}}">
                          {{ asset?.ObsolenscenceStatusCount?.value?.underActiveLifecycle > 99 ? '99+':asset?.ObsolenscenceStatusCount?.value?.underActiveLifecycle}}</span></button>
                      <button type="button" class="btn btn-rounded me-2 btn-outline-light warning">
                        <span *ngIf="asset?.ObsolenscenceStatusCount?.value" matTooltip="{{asset?.ObsolenscenceStatusCount?.value?.underNotice}}">
                          {{ asset?.ObsolenscenceStatusCount?.value?.underNotice > 99 ? '99+':asset?.ObsolenscenceStatusCount?.value?.underNotice}}</span></button>
                      <button type="button" class="btn btn-rounded btn-outline-light risk">
                        <span *ngIf="asset?.ObsolenscenceStatusCount?.value" matTooltip="{{asset?.ObsolenscenceStatusCount?.value?.obsoleted}}">
                          {{ asset?.ObsolenscenceStatusCount?.value?.obsoleted > 99 ? '99+':asset?.ObsolenscenceStatusCount?.value?.obsoleted}}</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>

<ng-container *ngIf="isMobile && details">
  <div class="container-fluid mobile-nav">
    <span class="mobile-back" (click)="details=false"><i class="fa fa-angle-left ms-3"></i></span>

    <ul class="nav nav-tabs mb-3 ms-4 w-100" id="asset-details-list" role="tablist">
      <li class="nav-item w-25" role="presentation">
        <a class="nav-link" [ngClass]="isAsset ? 'active': ''" id="asset-tab-1" data-mdb-toggle="tab" href="#asset-tabs-1" role="tab"
          aria-controls="asset-tabs-1" aria-selected="true">{{selectedAsset?.SerialNo?.value}}</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="!isAsset ? 'active': ''" id="asset-tab-2" data-mdb-toggle="tab" href="#asset-tabs-2" role="tab"
          aria-controls="asset-tabs-2" aria-selected="false" style="text-transform: unset;">Sub Assets ({{
          selectedAsset?.SubAssetsCount?.value }})</a>
      </li>

    </ul>

  </div>
  <!-- Tabs content -->
  <div class="tab-content mobile-tab-content" id="ex1-content">
    <div class="tab-pane fade mobile-content" id="asset-tabs-1" role="tabpanel"
      aria-labelledby="asset-tab-1" [ngClass]="isAsset ? 'active show': ''">
      <app-asset-details [selectedAssetId]="selectedAsset?.Id.value" [isEditableForUser]="false" [siteId]="selectedSite" [customerId]="customerId" (subAssetSelection)="subAssetSelection($event)">
      </app-asset-details>
    </div>
    <div class="tab-pane fade" id="asset-tabs-2" role="tabpanel" aria-labelledby="asset-tab-2"
    [ngClass]="!isAsset ? 'active show': ''">
      <app-sub-asset-details [selectedAssetId]="selectedAsset?.Id.value" [siteId]="selectedSite" [isEditableForUser]="false"
        [health]="[selectedAsset?.SubMenuDto?.value?.healthy, selectedAsset?.SubMenuDto?.value?.risk, selectedAsset?.SubMenuDto?.value?.critical]" [selectedFilter]="selectedSubAssetStatus">
      </app-sub-asset-details>
    </div>
  </div>
  <!-- Tabs content -->
</ng-container>
