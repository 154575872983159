import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusBasedFilter'
})
export class StatusBasedFilterPipe implements PipeTransform {

  transform(value: any, searchText?: any, colName?: any): any {
    if (!value) return null;
    if (!searchText) return value;
    if (searchText.toLowerCase() != 'all') {
      searchText = searchText.toLowerCase();
      return value.filter((value: any) => {
        return value[colName].toLowerCase() == searchText;
      });
    }
    else {
      return value;
    }
  }
}
