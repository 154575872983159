import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPredictService } from '../../../_services/i-predict.service';
import { DatePipe } from '@angular/common';
import { AssetLife } from '../../../_models/asset';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../../../modal/modal.component';
import { SharedService } from '../../../_services/shared.service'
import { RbacService } from '../../../_helpers/rbac.service';
import { AssetService } from 'src/app/_services/assets.service';

@Component({
  selector: 'app-asset-details-admin',
  templateUrl: './asset-details-admin.component.html',
  styleUrls: ['./asset-details-admin.component.scss']
})
export class AssetDetailsAdminComponent implements OnInit, AfterViewInit {

  selectedTab: string = 'ibData';
  @Input() selectedAssetId: any;
  @Input() siteId: number = 0;
  assetDetails: any;
  offsetDate: string = '';
  StepsData: any = {
    "installation": "",
    "commissioning": "",
    "inService": "",
    "warrantyEnd": "",
    "activeContractStart": "",
    "activeContractEnd": "",
    "endOfLife": ""
  };
  stepImages: any = {
    "installation": "../../assets/images/asset/installation.png",
    "commissioning": "../../assets/images/asset/commissioning.png",
    "inService": "../../assets/images/asset/commissioning.png",
    "warrantyEnd": "../../assets/images/asset/warranty.png",
    "activeContractStart": "../../assets/images/asset/active_contract.png",
    "activeContractEnd": "../../assets/images/asset/active_contract.png",
    "endOfLife": "../../assets/images/asset/endlife.png"
  }
  steps: any;
  currentDateIndex: number = 0;
  currentDate: string | null;
  editWarranty: boolean = false;
  editContract: boolean = false;
  invalid = true;
  endOfLife = false;
  warrantyEnd!: Date;
  activeContractEnd!: Date;
  saved: boolean = false;
  today = new Date().toISOString().split("T")[0];
  minDate!: string;
  stepsWithCurrent: any;

  assetEdit: boolean = false;
  selectedField: string = '';
  selectedFieldValue: any;
  oldVal: any;
  @Input() isEditableForUser!: boolean;

  assetDetailsKeys!: string[];
  allDetails: any;
  excludedFields: string[] = [];
  module: any;
  selectedSection: string = '';
  analyticsData: any;
  @Input() customerId: any;
  @Input() pendingRequest!: boolean;
  @Output() dataUpdate = new EventEmitter<string>();

  busbar: any = {'Cu': 'COPPER', 'Al': 'ALUMINIUM', 'Tinned Cu': 'TINNED COPPER'};

  constructor(private iPredictService: IPredictService,
    private datePipe: DatePipe,
    public matDialog: MatDialog,
    private sharedService: SharedService,
    private rbacService: RbacService,
    private assetService: AssetService
  ) {
    this.currentDate = datePipe.transform(Date.now(), 'dd-MM-yyyy');
  }

  ngOnInit(): void {
    if (this.sharedService.user.id) {
      this.module = this.sharedService.modules;
    } else {
      this.rbacService.getUserInformation().subscribe((value: any) => {
        this.module = this.sharedService.modules;
        if (!this.module.IBData.canUpdate) {
          this.isEditableForUser = false;
        }
      })
    }
    this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
    this.getAssetDetails();
    this.getAssetLifeCycle();
    this.getAlgoStatus();
  }

  getAssetDetails(): void {
    if (this.isEditableForUser) {
      this.iPredictService.getAssetABSByIBId(this.selectedAssetId)
        .subscribe((data: any) => {
        //   console.log(data);
          this.allDetails = data;
          this.assetDetailsKeys = Object.keys(data);
        });
    } else {
      this.iPredictService.getAssetABSById(this.selectedAssetId)
        .subscribe((data: any) => {
        //   console.log(data);
          this.allDetails = data;
          this.assetDetailsKeys = Object.keys(data);
        });
    }
  }

  getKeys(key: string) {
    if (key !== 'LogDetails') {
      this.excludedFields = ['ModifiedBy', 'CreatedBy', 'IsMapped', 'IsFromInstallBase']
    } else this.excludedFields = [];
    let keys = Object.keys(this.allDetails[key]).filter((item: string)=> this.excludedFields.indexOf(item) == -1 )
    return keys;
  }

  getAssetLifeCycle(): void {
    if (this.isEditableForUser) {
      this.iPredictService.getAssetLifeCycleIB(this.selectedAssetId)
        .subscribe((data: any) => {
          this.StepsData = data;
          this.warrantyEnd = new Date(this.StepsData['warrantyEnd']);
          this.activeContractEnd = new Date(this.StepsData['warrantyEnd']);
          let minDate = new Date(this.StepsData['installation']);
          minDate.setDate(minDate.getDate() + 2);
          this.minDate = minDate.toISOString().split("T")[0];
          this.addCurrentDateAndSort();
        });
    } else {
      this.iPredictService.getAssetLifeCycle(this.selectedAssetId)
        .subscribe((data: any) => {
          this.StepsData = data;
          this.warrantyEnd = new Date(this.StepsData['warrantyEnd']);
          this.activeContractEnd = new Date(this.StepsData['warrantyEnd']);
          let minDate = new Date(this.StepsData['installation']);
          minDate.setDate(minDate.getDate() + 2);
          this.minDate = minDate.toISOString().split("T")[0];
          this.addCurrentDateAndSort();
        });
    }
  }

  addCurrentDateAndSort() {
    let self = this;
    if (!this.StepsData['endOfLife']) {
      this.StepsData['endOfLife'] = new Date('2050-01-01');
    } else {
      this.endOfLife = true;
    }
    this.steps = Object.keys(this.StepsData);
    this.steps = Object.keys(this.StepsData).sort(function (a, b) {
      let c: any = new Date(self.StepsData[a]);
      let d: any = new Date(self.StepsData[b]);
      return c - d;
    })
    this.StepsData['currentDate'] = new Date();

    let keysSorted = Object.keys(this.StepsData).sort(function (a, b) {
      let c: any = new Date(self.StepsData[a]);
      let d: any = new Date(self.StepsData[b]);
      return c - d;
    })
    this.currentDateIndex = keysSorted.indexOf('currentDate');
    this.stepsWithCurrent = keysSorted;
    if (!this.endOfLife) {
      this.StepsData['endOfLife'] = null;
    }
  }

  ngAfterViewInit(): void {

  }

  onDateChange(e: any, step: string): void {
    let date = e?.target?.value;
    if (!isNaN(new Date(date).getTime())) {
      if (new Date(date).getTime() < new Date(this.minDate).getTime()) {
        this.invalid = true;
      } else {
        if (step == 'warrantyEnd') {
          this.warrantyEnd = date;
        } else if (step == 'activeContractEnd') {
          this.activeContractEnd = date;
        }
        this.invalid = false;
      }
    } else {
      this.invalid = true;
    }

  }

  saveDateChange(step: string): void {
    let payload = this.StepsData;
    delete payload['currentDate'];
    this.StepsData['warrantyEnd'] = new Date(this.warrantyEnd);
    this.StepsData['activeContractEnd'] = new Date(this.activeContractEnd);
    this.iPredictService.saveDateForAsset(this.StepsData, this.selectedAssetId)
      .subscribe((data: any) => {
        // TODO: message on updation
        this.saved = true;
        this.getAssetLifeCycle();
        if (step == 'warrantyEnd') {
          this.editWarranty = !this.editWarranty;
        } else {
          this.editContract = !this.editContract;
        }
      });
  }

  openSaveModal(step: string) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      name: "Update",
      title: "Are you sure you want to update the date?",
      description: "Save?",
      actionButtonText: "Update",
      step: step
    }
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(data => {
      if (data) {
        this.saveDateChange(step);
      }
    })
  }

  setEditProperties(section: string, item: string, value?: any) {
    this.assetEdit = true;
    this.selectedField = item;
    this.selectedSection = section;
    if (this.selectedField === 'AssetCategories') {
      this.oldVal = value || this.allDetails[section][item].value.assetCategoryName;
    } else if (this.selectedField === 'AssetTypes') {
      this.oldVal = value || this.allDetails[section][item].value.typeName;
    } else this.oldVal = value || this.allDetails[section][item].value;

    if(!this.allDetails[section][item].previousValue)
      this.allDetails[section][item].previousValue = this.oldVal;
  }

  updateAsset(section: string, item: any, value?: any) {
    // console.log(item);
    // console.log(this.allDetails[section][item].value);
    // console.log(this.allDetails[section][item].displayName);
    let payload = {
      "assetId": this.selectedAssetId,
      "modifiedColumn": item,
      "modifiedDisplayName": this.allDetails[section][item].displayName,
      "modifiedValue": value || this.allDetails[section][item].value,
      "entity": section,
      "dataSource": this.allDetails[section][item].dataSource
    }
    // console.log(payload);
    this.iPredictService.updateAssetDetails(payload)
      .subscribe({
        next: (data: any) => {
          this.assetEdit = !this.assetEdit;
          this.selectedField = '';
          this.selectedSection = '';
        },
        error: (e: Error) => {

          console.log("Save failed");
        }
      })
  }

  getAlgoStatus() {
    if (this.customerId) {
      this.assetService.getAlgoStatus(this.customerId).subscribe((data: any) => {
        this.analyticsData = data;
      })
    }
  }

  updateTempValue(section: string, item: any, e: any) {
    if(item === 'DeletionFlag'){
      this.allDetails[section][item].value = e?.target?.checked ? 'X' : '';
      return;
    }
    if(item === 'IsOutdoor'){
      this.allDetails[section][item].value = e.target.value == 'true' ? true : false;
      return;
    }
    if (this.selectedField === 'AssetCategories') {
      this.allDetails['Assets']['AssetCategories'].value.assetCategoryName = e.target.value;
    } else if (this.selectedField === 'AssetTypes') {
      this.allDetails['Assets']['AssetTypes'].value.typeName = e.target.value;
    } else this.allDetails[section][this.selectedField].value = e.target.value;
  }

  sendValues(section: string, item: any, value?: any) {
    let modifiedValue; 
    if (this.selectedField === 'AssetCategories') {
      modifiedValue = this.allDetails['Assets']['AssetCategories'].value.assetCategoryName;
    } else if (this.selectedField === 'AssetTypes') {
      modifiedValue = this.allDetails['Assets']['AssetTypes'].value.typeName;
    } else modifiedValue = this.allDetails[section][this.selectedField].value;
    let obj = {
      "subAssetId": 0,
      "previousValue": this.allDetails[section][item].previousValue,
      "assetId": this.selectedAssetId,
      "modifiedColumn": item,
      "modifiedDisplayName": this.allDetails[section][item].displayName,
      "modifiedValue": modifiedValue,
      "entity": section,
      "dataSource": this.allDetails[section][item].dataSource
    }
    let obj2 = { asset: obj };

    this.dataUpdate.emit(obj2 as any);
    this.assetEdit = !this.assetEdit;
    this.selectedField = '';
    this.selectedSection = '';
  }
}
