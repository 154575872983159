export class AlarmQuery {
    alarmClass: string[] = [];
    eventClass: string[] = [];
    status: string[] = [];
    alarmClassVal: number[] = [];
    eventClassVal: number[] = [];
    statusVal: number[] = [];
    assetCategories: string[] = [];
    assetMake: string[] = [];
    dateRange: DateRange = {};
    searchString: string = '';
    siteId: number = 0;
    customerId: number = 0;
    userId: number = 0;
    assetId: number = 0;
    feederNo: string = '';
    group: string = '';
    isCommon: boolean = false;
}

interface DateRange {
    from?: Date;
    to?: Date;
}