<!-- Modal -->
<!-- Suggestions -->
<ng-container *ngIf="popupData=='Suggestions'">
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" [ngClass]="isMobile?'h-100':''">
      <div class="modal-content" style="width:650px">
        <div class="modal-header">
          <h5 class="fontdesign" id="exampleModalLabel">{{popupData}}</h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <!-- suggestionList -->
            <div *ngIf="isDesktopDevice" class="statusSearchFlex">
              <div class="space-evenly m-1">
                <div class="space-evenly me-2">Status</div>
                <div class="select">
                  <ng-select [items]="suggestionItems" [(ngModel)]="searchCode" bindLabel="name" bindValue="code">
                    <ng-template ng-option-tmp let-item="item">
                      <div>{{item.name}}</div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
           
              <div class="search ">
                <input type="text" class="form-control select-input" placeholder="Search" [(ngModel)]="searchText">
                <i class="fa fa-search icon-color"></i>
              </div>

              <div style="display:flex;flex-direction: row;">
                <!-- <div class="icon-color m-2"><i class="fa fa-print" matTooltip="Print"></i></div>
                <div class="icon-color m-2"><i class="fa fa-cloud-download-alt" matTooltip="Download"></i>
                </div> -->
                <app-icon class="me-2" title="Print" iconFor="print" action="layout"></app-icon>
                <app-icon class="" title="Download" iconFor="download" action="layout"></app-icon>
              </div>
            </div>
            <!--mobile header-->
            <div *ngIf="isMobile">
              <div class="row mb-2">
                <div class="col-9 select">
                  <ng-select [items]="suggestionItems" placeholder="Status" [(ngModel)]="searchCode" bindLabel="name"
                    bindValue="code">
                    <ng-template ng-option-tmp let-item="item">
                      <div>{{item.name}}</div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-1">
                  <div><i class="fa fa-search" (click)="searchOpen=true;" role="button"></i></div>
                </div>
                <div class="filter-search p-2" *ngIf="searchOpen">
                  <div class="row">
                    <div class="col-6">Search</div>
                    <div class="col-6"><i class="fa fa-times float-end" (click)="searchOpen=false"></i></div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input type="text" class="form-control filter-input" placeholder="Search" [(ngModel)]="searchText">
                    </div>
                  </div>
                </div>
            
                <!-- <div class="col-1 icon-color"><i class="fa fa-print" matTooltip="Print"></i></div>
                <div class="col-1 icon-color"><i class="fa fa-cloud-download-alt" matTooltip="Download"></i></div> -->
                <app-icon class="me-2" title="Print" iconFor="print" action="layout"></app-icon>
                <app-icon class="" title="Download" iconFor="download" action="layout"></app-icon>
            
              </div>
            </div>

          <!-- table section -->
          <table class="table-height" [ngStyle]="{'height':isMobile?(innerHeight-160)+'px':''}">
            <thead>
              <th width="150px"> Asset/SubAsset </th>
              <th width="350px"> Description </th>
              <th width="150px"> Suggestion </th>
            </thead>
            <tbody>
              <ng-container
                *ngIf="(suggestionsAssetList | modalFilter: searchText | statusBasedFilter : searchCode : 'suggestion') as filteredsuggestions">
                <tr *ngFor="let row of filteredsuggestions">
                  <td width="150px"> {{row.asset}}</td>
                  <td width="350px"> {{row.description}}</td>
                  <td width="150px"> {{row.suggestion | splitCamelCase | titlecase}}</td>
                </tr>
                <p *ngIf="filteredsuggestions.length === 0" class="critical my-5 text-center text-message"> No data found for the
                  applied filter</p>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Site Name & Details -->
<ng-container *ngIf="popupData=='Site Name & Details'">
  <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="fontdesign" id="exampleModalLabel1">{{popupData}}</h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Tabs navs -->
          <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab"
                aria-controls="ex1-tabs-1" aria-selected="true">Site Details</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab"
                aria-controls="ex1-tabs-2" aria-selected="false">Environment Data</a>
            </li>
          </ul>
          <!-- Tabs navs -->

          <!-- Tabs content -->
          <div class="tab-content" id="ex1-content">
            <div class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
              <div class="card site-popup">
                <div class="row p-1">
                  <div class="col-lg-6 col-12">Customer Code: {{general.customerCode}}</div>
                  <div class="col-lg-6 col-12">Site Display Name: {{general.siteDisplayName}}</div>
                </div>
                <div class="w-100" style="height: 1px;background:#d7d6d6">&nbsp;</div>
                <div class="row">
                  <div class="sd-data col-lg-4 col-6 p-3">City: {{general.city}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">PinCode: {{general.pinCode}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">State Code: {{general.stateCode}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">State Description: {{general.stateDescription}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">Country Code: {{general.countryCode}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">Country Description: {{general.countryDescription}}</div>
                  <div class="sd-data col-lg-4 col-6 p-3">Contact Details: {{general.contactDetails}}</div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
              <div class="card site-popup">
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Maximum Ambient Temperature</div>
                    <div class="col-6">{{(environmentData.maxAmbientTemperature ? environmentData.maxAmbientTemperature : "-")}}</div>
                  </div>
                </div>
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Minimum Ambient Temperature</div>
                    <div class="col-6">{{(environmentData.minAmbientTemperature ? environmentData.minAmbientTemperature : "-")}}</div>
                  </div>
                </div>
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Design Ambient Temperature</div>
                    <div class="col-6">{{(environmentData.designAmbientTemperature ? environmentData.designAmbientTemperature : "-")}}</div>
                  </div>
                </div>
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Relatve Humidity</div>
                    <div class="col-6">{{(environmentData.relativeHumidity ?
                      environmentData.relativeHumidity : "-")}}</div>
                  </div>
                </div>
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Altitude</div>
                    <div class="col-6">{{(environmentData.altitude ?
                      environmentData.altitude : "-")}}</div>
                  </div>
                </div>
                <div class="env-data">
                  <div class="row">
                    <div class="col-6">Environment</div>
                    <div class="col-6">{{(environmentData.environment ?
                      environmentData.environment : "-")}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tabs content -->

        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Obsolescence Profile -->
<ng-container *ngIf="popupData=='Obsolescence Profile'">
  <!-- Modal -->
  <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
    <div class="modal-dialog" [ngClass]="isMobile?'h-100':''">
      <div class="modal-content" style="width:650px">
        <div class="modal-header">
          <h5 class="fontdesign" id="exampleModalLabel2">{{popupData}}</h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <!-- search section -->
          <div *ngIf="isDesktopDevice" class="statusSearchFlex">
            <div class="space-evenly m-1">
              <div class="space-evenly me-2">Status</div>
              <div class="select">
                <ng-select [items]="obsolenscenceItems" [(ngModel)]="searchCode" bindLabel="name" bindValue="name"
                [disabled]="obsolenscenceProfileList.length  === 0">
                <ng-template ng-option-tmp let-item="item">
                  <div><span style="box-shadow: none;" class="ptw-list-indicator-variant me-3"
                      [ngClass]="(item.code).toLowerCase()+'-bg'"></span>
                    {{item.name}}</div>
                </ng-template>
              </ng-select>
              </div>
            </div>
         
            <div class="search ">
              <input type="text" class="form-control select-input" placeholder="Search" [(ngModel)]="searchText"
              [disabled]="obsolenscenceProfileList.length  === 0">
            <i class="fa fa-search icon-color"></i>
            </div>

            <div style="display:flex;flex-direction: row;">
              <!-- <div class="icon-color m-2" (click)="printData('O')" matTooltip="Print"><i class="fa fa-print"></i></div>
              <div class="icon-color m-2" (click)="download()" matTooltip="Download"><i class="fa fa-cloud-download-alt"></i></div> -->
              <app-icon class="me-2" title="Print" iconFor="print" action="layout" (clickEvent)="printData('O')"></app-icon>
              <app-icon class="" title="Download" iconFor="download" action="layout" (clickEvent)="download()"></app-icon>          
            </div>            
          </div>
          <!--mobile header-->
          <div *ngIf="isMobile">
            <div class="row mb-2">
              <div class="col-9 select">
                <ng-select [items]="obsolenscenceItems" [(ngModel)]="searchCode" bindLabel="name" bindValue="name"
                  [disabled]="obsolenscenceProfileList.length  === 0">
                  <ng-template ng-option-tmp let-item="item">
                    <div><span style="box-shadow: none;" class="ptw-list-indicator-variant me-3"
                        [ngClass]="(item.code).toLowerCase()+'-bg'"></span>
                      {{item.name}}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-1">
                <div class="icon-color"><i class="fa fa-search" (click)="searchOpen=true;" role="button"></i></div>
              </div>
              <div class="filter-search p-2" *ngIf="searchOpen">
                <div class="row">
                  <div class="col-6">Search</div>
                  <div class="col-6"><i class="fa fa-times float-end" (click)="searchOpen=false"></i></div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <input type="text" class="form-control filter-input" placeholder="Search" [(ngModel)]="searchText"
                      [disabled]="obsolenscenceProfileList.length  === 0">
                  </div>
                </div>
              </div>
              <!-- <div class="col-1 icon-color" (click)="printData('O')" matTooltip="Print"><i class="fa fa-print"></i></div>
              <div class="col-1 icon-color" (click)="download()" matTooltip="Download"><i class="fa fa-cloud-download-alt"></i></div>   -->
              <app-icon class="me-2" title="Print" iconFor="print" action="layout" (clickEvent)="printData('O')"></app-icon>
              <app-icon class="" title="Download" iconFor="download" action="layout" (clickEvent)="download()"></app-icon>   
            </div>
          </div>

          <!-- table section -->
          <div id="forPrintO" class="table-height" [ngStyle]="{'height':isMobile?(innerHeight-145)+'px':''}">
            <h5 class="only-print" style="color: #013359;">{{popupData}}</h5>
            <table *ngIf="obsolenscenceProfileList.length > 0;">
              <thead>
                <th width="50px"> </th>
                <th width="150px"> Type </th>
                <th width="400px"> Class Decription </th>
                <th width="150px"> Cat No. </th>
                <th width="100px"> Make </th>
              </thead>
              <tbody id="table-body-O">
                <ng-container
                  *ngIf="(obsolenscenceProfileList | modalFilter : searchText | statusBasedFilter : searchCode : 'assetStatus') as filteredData">
                  <tr *ngFor="let row of filteredData">
                    <td width="50px" class="ptw-list-indicator-variant me-3"
                      [ngClass]="(row.assetStatus).toLowerCase()+'-bg'">
                    </td>
                    <td width="150px"> {{row.assetType}}</td>
                    <td width="400px"> {{row.assetDescription}}</td>
                    <td width="150px"> {{row.catNo}}</td>
                    <td width="100px"> {{row.make}}</td>
                  </tr>
                  <p *ngIf="filteredData.length === 0" class="critical my-5 text-center text-message"> No data found for the applied
                    filter</p>
                </ng-container>
              </tbody>
  
            </table>
          </div>
          <div *ngIf="obsolenscenceProfileList.length  === 0" class="critical my-5 text-center text-message">No details found for
            this site</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Maintenance Summary -->
<ng-container *ngIf="popupData=='Maintenance Summary'">
  <!-- Modal -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
    <div class="modal-dialog" [ngClass]="isMobile?'h-100':''">
      <div class="modal-content" style="width:750px">
        <div class="modal-header">
          <h5 class="fontdesign" id="exampleModalLabel3">{{popupData}}</h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <!-- search section -->
          <div *ngIf="isDesktopDevice" class="statusSearchFlex">
            <div class="space-evenly m-1">
              <div class="space-evenly me-2">Status</div>
              <div class="select">
                <ng-select [items]="maintenanceItems" [(ngModel)]="searchCode" bindLabel="name" bindValue="code"
                  [disabled]="maintenanceSummaryList.length  === 0">
                  <ng-template ng-option-tmp let-item="item">
                    <div><span style="box-shadow: none;" class="ptw-list-indicator-variant me-3"
                        [ngClass]="(item.code).toLowerCase()+'-bg'"></span>
                      {{item.name}}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
         
            <div class="search ">
              <input type="text" class="form-control select-input" placeholder="Search" [(ngModel)]="searchText"
              [disabled]="maintenanceSummaryList.length  === 0">
            <i class="fa fa-search icon-color"></i>
            </div>

            <div style="display:flex;flex-direction: row;">
              <!-- <div class="icon-color m-2" (click)="printData('M')"><i class="fa fa-print" matTooltip="Print"></i></div>
              <div class="icon-color m-2" (click)="download()" matTooltip="Download"><i class="fa fa-cloud-download-alt"></i></div> -->
              <app-icon class="me-2" title="Print" iconFor="print" action="layout" (clickEvent)="printData('M')"></app-icon>
              <app-icon class="" title="Download" iconFor="download" action="layout" (clickEvent)="download()"></app-icon> 
            </div>
          </div>

          <!--mobile header-->
          <div *ngIf="isMobile">
            <div class="row mb-2">
              <div class="col-9 select">
                <ng-select [items]="maintenanceItems" [(ngModel)]="searchCode" bindLabel="name" bindValue="code"
                  [disabled]="maintenanceSummaryList.length  === 0">
                  <ng-template ng-option-tmp let-item="item">
                    <div><span style="box-shadow: none;" class="ptw-list-indicator-variant me-3"
                        [ngClass]="(item.code).toLowerCase()+'-bg'"></span>
                      {{item.name}}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-1">
                <div class="icon-color"><i class="fa fa-search" (click)="searchOpen=true;" role="button"></i></div>
              </div>
              <div class="filter-search p-2" *ngIf="searchOpen">
                <div class="row">
                  <div class="col-6">Search</div>
                  <div class="col-6"><i class="fa fa-times float-end" (click)="searchOpen=false"></i></div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <input type="text" class="form-control filter-input" placeholder="Search" [(ngModel)]="searchText"
                      [disabled]="maintenanceSummaryList.length  === 0">
                  </div>
                </div>
              </div>
              <!-- <div class="col-1 icon-color" (click)="printData('M')"><i class="fa fa-print" matTooltip="Print"></i></div>
              <div class="col-1 icon-color" (click)="download()" matTooltip="Download"><i class="fa fa-cloud-download-alt"></i></div> -->
              <app-icon class="me-2" title="Print" iconFor="print" action="layout" (clickEvent)="printData('M')"></app-icon>
              <app-icon class="" title="Download" iconFor="download" action="layout" (clickEvent)="download()"></app-icon> 
            </div>
          </div>
          <div id="forPrintM" class="table-height" [ngStyle]="{'height':isMobile?(innerHeight-145)+'px':''}">
            <h5 class="only-print" style="color: #013359;">{{popupData}}</h5>

            <!-- table section -->
            <table *ngIf="maintenanceSummaryList.length > 0;">
              <thead>
                <th width="30px"> </th>
                <th width="120px"> Asset / Subasset </th>
                <th width="120px"> Asset Category </th>
                <th width="290px"> Descriptions </th>
                <th width="110px"> Next Maintenance Date </th>
                <th width="40px"> Plan </th>
              </thead>
              <tbody id="table-body-M">
                <ng-container
                  *ngIf="(maintenanceSummaryList | modalFilter : searchText | statusBasedFilter : searchCode : 'maintenanceStatus') as filteredSummary">
                  <tr *ngFor="let row of filteredSummary">
                    <td width="30px" class="ptw-list-indicator-variant me-3"
                      [ngClass]="(row.maintenanceStatus).toLowerCase() == 'over due'?'overdue-bg':(row.maintenanceStatus).toLowerCase()+'-bg'">
                    </td>
                    <td width="120px"> {{row.assetSubAssetDetails}}</td>
                    <td width="120px"> {{row.assetCategory}}</td>
                    <td width="290px"> {{row.description}}</td>
                    <td width="110px"> {{row.maintenanceDate| date: 'dd/MM/yyyy'}}</td>
                    <td width="40px"> {{row.planStatus}}</td>
                  </tr>
                  <p *ngIf="filteredSummary.length === 0" class="critical my-5 text-center text-message"> No data found for the applied
                    filter</p>
                </ng-container>
              </tbody>
          
            </table>
          </div>
          <div *ngIf="maintenanceSummaryList.length  === 0" class="critical my-5 text-center text-message">No details found for this
            site</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
