import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteManagementService } from '../_services/site-management.service';

@Component({
  selector: 'app-site-overview-assets',
  templateUrl: './site-overview-assets.component.html',
  styleUrls: ['./site-overview-assets.component.css']
})
export class SiteOverviewAssetsComponent implements OnInit {
  @Input() assets: any;

  constructor(private _router: Router, private siteManagementService: SiteManagementService) { }

  ngOnInit(): void {
  }

  sortData(asset:any, type: string, e:any) {
    switch(type) {
      case 'healthy':
        asset.assetDetails.sort((a:any, b:any) => b.assetHealth - a.assetHealth);
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'critical':
        asset.assetDetails.sort((a: any, b: any) => a.assetHealth - b.assetHealth);
        break; 
      case 'risk':
        let critical:any=[], healthy:any=[], risk:any=[];
        asset.assetDetails.forEach((item: any) => {
          item.assetHealth <= 40 ? (critical.push(item)) :(item.assetHealth > 70 ? (healthy.push(item)) : (risk.push(item)))
        });
        asset.assetDetails = risk.concat(healthy, critical);
        break;

    }
  }

}
