import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetSearch'
})
export class AssetSearchPipe implements PipeTransform {

  transform(value: any, searchText: string,): any {
    if (!value) return null;
    if (!searchText) return value;

    return value.filter((value: any) => {
      return value.AssetName.value.toLowerCase().includes(searchText.toLowerCase());
    });
  }

}
