import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;
  baseUrl = environment.apiURL;

  constructor(private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    // this.currentUser = this.currentUserSubject.asObservable();
  }


  checkAuthentication(): any {
    let token = window.localStorage.getItem('ACCESS_TOKEN');
    return token ? true : false;
  }

  // public get currentUserValue(): any {
  //   return this.currentUserSubject.value;
  // }


  login() {
    //For IDMS
    return this.http.post(`${this.baseUrl}/accesscontrol/Token`, {}, { headers: { skip: "true" } })
      .pipe(map(response => {
        return response;
      }));
  }

  token(token: string, type: string) {
    return this.http.post(`${this.baseUrl}/accesscontrol/Token`, { token, type }, { headers: { skip: "true" } })
      .pipe(map(token => {
        console.log(token);
        this.saveToken(token);
        return token;
      }));
  }

  refreshToken(token: any, type: string) {
    return this.http.post(`${this.baseUrl}/accesscontrol/Token`, { token, type }, { headers: { skip: "true" } })
      .pipe(map(token => {
        console.log(token);
        if(token === 'token_expired') {
          console.log("token expired, so clearing the localstorage and refreshing th page")
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN'); 
        } else {
          this.saveToken(token);
        }
      }));
  }

  private saveToken(token: any): void {
    if (token) {
      localStorage.removeItem('AUTHORIZATION_CODE');
      localStorage.setItem('ACCESS_TOKEN', token.accessToken);
      if(token.refreshToken){
        localStorage.setItem('REFRESH_TOKEN', token.refreshToken);
      }
    }
  }

  localLogin(u: string, p: string) {
    console.log("came to local log in");
    return this.http.post(`${this.baseUrl}/auth/token`, { "username": u, "password": p }, {responseType: 'text' } )
      .pipe(map(response => {
        console.log(response);
        localStorage.setItem('ACCESS_TOKEN', response.toString());
        return response;
      }));
  }
}
