import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() totalRecords = 0;  
  @Input() recordsPerPage = 0;  

  activeSection: number = 0;
  startPage: number = 0;
  endPage: number = 0;
  totalSec: number = 0;
  isDesktopDevice: boolean = true;
  isMobile: boolean = false;

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();  

  public pages: number [] = [];  
  activePage!: number;  

  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit(): void {
    this.epicFunction();
    this.onClickButton(0);
    this.totalSec = 1;
  }
  
  
  epicFunction() {
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  onClickButton(section: number): void {
    this.totalSec = Math.round(this.totalRecords / this.recordsPerPage);
    this.activeSection = section;
    this.startPage = Math.max(section * this.recordsPerPage, 0);
    this.endPage = Math.min(section * this.recordsPerPage + this.recordsPerPage, this.totalRecords);
    this.onPageChange.emit({ 'start': this.startPage, 'end': this.endPage });

  }
}
