<div class="register-container">
  <div class="row">
      <div class="col-sm-12 pb-4 pb-lg-0 col-lg-5 bg-green">
          <div class="row mb-4 mb-lg-5">
              <div class="col-sm-12">
                  <p class="logo"><img class="logo-image img-responsive" src="../assets/images/lntlogo.png" alt="L&T Electrical Automation"/></p>
              </div>
          </div>

          <div class="row form-container">
              <div class="col-sm-10 col-10 col-lg-8">
                  <div class="card bg-transparent" style="box-shadow:unset!important;margin: unset!important;">
                    <p [ngClass]="accountMsgColor ? 'account-success-msg' : 'account-error-msg'">{{ accountMessage }}</p>
                    <h6 class="form-heading">Sign Up</h6>
                    <p class="form-link-label">Already have an account ? <a class="form-link-text" (click)="goToLogin()">Sign in</a></p>
                      <div class="card-body" style="margin: unset!important;">
                          <form autocomplete="off" name="registerForm" [formGroup]="registerForm" (ngSubmit)="sendFormData()">
                              <div class="form-outline mb-3">
                                  <input type="text" class="form-control form-control-lg bg-white" formControlName="firstName" placeholder="First Name*" name="firstName"
                                  [ngClass]="{'is-invalid': (registerFormControls['firstName'].touched && registerFormControls['firstName'].errors) ||
                                  registerForm['errors']?.['displayName']}"/>
                                  <label class="form-label" for="typeEmailX-2">First Name*</label>
                                  <div class="error-text ps-2" *ngIf="registerFormControls['firstName'].touched && registerFormControls['firstName'].errors">
                                    <p *ngIf="registerFormControls['firstName']['errors']['required']">FirstName is required</p>
                                    <p *ngIf="registerFormControls['firstName']['errors']['pattern']">Only Alphabets are allowed</p>
                                  </div>
                              </div>

                              <div class="form-outline mb-3">
                                  <input type="text" class="form-control form-control-lg bg-white" formControlName="lastName" placeholder="Last Name*" name="lastName"
                                  [ngClass]="{'is-invalid': (registerFormControls['lastName'].touched && registerFormControls['lastName'].errors) ||
                                  registerForm['errors']?.['displayName']}"/>
                                  <label class="form-label" for="typePasswordX-2">Last Name*</label>
                                  <div class="error-text ps-2" *ngIf="registerFormControls['lastName'].touched && (registerFormControls['lastName'].errors)">
                                    <p *ngIf="registerFormControls['lastName']['errors']['required']">Lastname is required</p>
                                    <p *ngIf="registerFormControls['lastName']['errors']['pattern']">Only Alphabets are allowed</p>
                                  </div>
                                  <div class="error-text ps-2" *ngIf="registerForm['errors']?.['displayName']">
                                    <p>FirstName and LastName shouldn't match</p>
                                  </div>
                              </div>

                              <div class="form-outline mb-3">
                                <div class="input-group mb-3">
                                  <input type="text" class="form-control form-control-lg bg-white otp-field mobile-number-verified" formControlName="mobileNumber" placeholder="Mobile Number*"
                                  id="mobilenumber" maxlength="10" name="mobileNumber"
                                  [ngClass]="{'is-invalid': (registerFormControls['mobileNumber'].touched && registerFormControls['mobileNumber'].errors || (errorGenerateOTP))}" />
                                  <label class="form-label" for="mobilenumber">Mobile Number*</label>
                                  <button *ngIf="registerFormControls['mobileNumber'].errors === null && !verifiedMobileOTP && !otpInProgress" class="btn bg-white otp-button"
                                  type="button" id="button-addon2" (click)="generateMobileOTP()"> Get OTP </button>
                                  <button *ngIf="registerFormControls['mobileNumber'].errors === null && !verifiedMobileOTP && otpInProgress" class="btn bg-white otp-button"
                                  type="button" id="button-addon2" disabled> {{display}} </button>
                                  <button *ngIf="registerFormControls['mobileNumberOTP'].errors === null && verifiedMobileOTP" class="btn bg-white otp-button"
                                  type="button" id="mobileVerfiyBtn">Verified <i class="fas fa-check ps-1"></i></button>
                                </div>
                                <div class="error-text ps-2" *ngIf="registerFormControls['mobileNumber'].touched && registerFormControls['mobileNumber'].errors">
                                  <p *ngIf="registerFormControls['mobileNumber']['errors']['required']">Mobile Number is required</p>
                                  <p *ngIf="registerFormControls['mobileNumber'].hasError('pattern')">enter an valid mobile number</p>
                                </div>
                                <div class="error-text ps-2" *ngIf="errorGenerateOTP">
                                  <p>{{ errorGenerateOTP }}</p>
                                </div>
                              </div>

                              <div class="form-outline mb-3" *ngIf="showMobileOTP">
                                <div class="input-group mb-3">
                                  <input type="text" class="form-control form-control-lg bg-white otp-field" formControlName="mobileNumberOTP" placeholder="Mobile Number - OTP*"
                                  id="mobilenumberOTP"  maxlength="6" name="mobileNumberOTP"
                                  [ngClass]="{'is-invalid': (registerFormControls['mobileNumberOTP'].touched && registerFormControls['mobileNumberOTP'].errors || (errorVerifyOTP))}"/>
                                  <label class="form-label" for="mobilenumberOTP">Mobile Number - OTP*</label>
                                  <button *ngIf="registerFormControls['mobileNumberOTP'].errors === null" class="btn bg-white otp-button"
                                  type="button" id="mobileVerfiyBtn" (click)="verfiyMobileOTP()">Verify
                                  </button>
                                </div>
                                <div class="error-text ps-2" *ngIf="registerFormControls['mobileNumberOTP'].touched && registerFormControls['mobileNumberOTP'].errors">
                                  <p *ngIf="registerFormControls['mobileNumberOTP']['errors']['required']">Mobile Number - OTP is required</p>
                                  <p *ngIf="registerFormControls['mobileNumberOTP'].hasError('pattern')">enter an valid OTP number</p>
                                </div>
                                <div class="error-text ps-2" *ngIf="errorVerifyOTP">
                                  <p>{{ errorVerifyOTP }}</p>
                                </div>
                              </div>

                            <div class="form-outline mb-3">
                              <div class="input-group mb-3">
                                <input type="email" class="form-control form-control-lg bg-white otp-field" formControlName="emailId" placeholder="Email ID*" name="emailId"
                                id="emailid" (blur)="checkMailtoUse()"
                                [ngClass]="{'is-invalid': (registerFormControls['emailId'].touched && registerFormControls['emailId'].errors || (errorEmail))}" />
                                <label class="form-label" for="emailid">Email ID*</label>
                                <button type="button" disabled class="btn bg-white otp-button" *ngIf="emailLoader ? emailLoader : (verifyEmail ? verifyEmail : errorEmail)"
                                [ngClass]="{'is-invalid': (registerFormControls['emailId'].touched && registerFormControls['emailId'].errors || (errorEmail))}">
                                  <span *ngIf="emailLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                  <i *ngIf="emailLoader ? false : verifyEmail" class="fas fa-check ps-1"></i>
                                  <i *ngIf="emailLoader ? false : !verifyEmail" class="fas fa-times ps-1 error-text"></i>
                                </button>
                              </div>
                                <!-- <button *ngIf="registerFormControls['emailId'].errors === null" class="btn bg-white otp-button"
                                  type="button" id="button-addon2" (click)="generateEmailOTP()">Get OTP</button> -->
                              <div class="error-text ps-2" *ngIf="registerFormControls['emailId'].touched && registerFormControls['emailId'].errors">
                                <p *ngIf="registerFormControls['emailId']['errors']['required']">Email ID is required</p>
                                <p *ngIf="registerFormControls['emailId']['errors']['email']">enter an valid email validation</p>
                              </div>
                              <div class="error-text ps-2" *ngIf="registerFormControls['emailId'].errors === null && errorEmail">
                                <p>{{ errorEmail }}</p>
                              </div>
                            </div>

                            <!-- <div class="form-outline mb-3" *ngIf="showEmailOTP">
                              <div class="input-group mb-3">
                                <input type="text" class="form-control form-control-lg bg-white otp-field" formControlName="emailIdOTP" placeholder="Email ID - OTP*" name="emailIdOTP"
                                id="emailIdOTP" [ngClass]="{'is-invalid': registerFormControls['emailIdOTP'].touched && registerFormControls['emailId'].errors}" maxlength="6"/>
                                <label class="form-label" for="emailIdOTP">Email ID - OTP*</label>
                                <button *ngIf="registerFormControls['emailIdOTP'].errors === null" class="btn bg-white otp-button"
                                 type="button" id="emailVerifyBtn" (click)="verfiyEmailOTP()">Verify</button>
                              </div>
                              <div class="error-text ps-2" *ngIf="registerFormControls['emailIdOTP'].touched && registerFormControls['emailIdOTP'].errors">
                                <p *ngIf="registerFormControls['emailIdOTP']['errors']['required']">Email ID - OTP is required</p>
                                <p *ngIf="registerFormControls['emailIdOTP'].hasError('pattern')">enter an valid OTP number</p>
                              </div>
                            </div> -->

                            <div class="form-outline mb-3">
                              <select class="form-select mb-3" name="country" formControlName="country"
                              [ngClass]="{'is-invalid': registerFormControls['country'].touched && registerFormControls['country'].errors}">
                                <option selected value="">Select</option>

                                <option [value]="country.code" *ngFor="let country of countries">
                                  {{ country.name}}
                              </option>
                              </select>
                              <div class="error-text ps-2" *ngIf="registerFormControls['country'].touched && registerFormControls['country'].errors">
                                <p *ngIf="registerFormControls['country']['errors']['required']">Country is required</p>
                                <p></p>
                              </div>
                            </div>

                            <div class="form-check terms mb-3">
                              <input class="form-check-input" type="checkbox" id="hasAgreementSigned" formControlName="hasAgreementSigned"/>
                              <label class="form-check-label ps-2" for="flexCheckDefault">I have read and agree to SmartComm
                                <a (click)="openTermsOfUse(privacyPolicy)" role="button">Privacy Policy</a>,
                                <a (click)="openTermsOfUse(privacyNotice)" role="button">Privacy Notice</a> and
                                <a  (click)="openTermsOfUse(termsURL)" role="button">Terms of Use</a></label>
                            </div>

                            <div class="form-check terms mb-3">
                              <input class="form-check-input" type="checkbox" id="hasNewsLetterSubscribed" formControlName="hasNewsLetterSubscribed"/>
                              <label class="form-check-label ps-2" for="flexCheckDefault">I consent to receive Notifications on Product, Solutions & Service from SmartComm in accordance with SmartComm's
                                <a (click)="openTermsOfUse(privacyPolicy)" role="button">Privacy Policy</a>
                              </label>
                            </div>

                            <!-- <div class="form-outline mb-3">
                              <input type="text" class="form-control form-control-lg bg-white" formControlName="companyName" placeholder="Company name*" name="companyName"
                              [ngClass]="{'is-invalid': registerFormControls['companyName'].touched && registerFormControls['companyName'].errors}"/>
                              <label class="form-label" for="typePasswordX-2">Company name*</label>
                              <div class="error-text ps-2" *ngIf="registerFormControls['companyName'].touched && registerFormControls['companyName'].errors">
                                <p *ngIf="registerFormControls['companyName']['errors']['required']">Company Name is required</p>
                                <p></p>
                              </div>
                            </div> -->

                              <button *ngIf="!showInlineLoader" class="btn btn-sm btn-block text-white" type="submit" style="background: #0A68AD;"
                              [disabled]="(registerForm.invalid || errorGenerateOTP !== '' || errorVerifyOTP !== '' || !verifyEmail || submitted || !verifiedMobileOTP)">
                                SignUp
                              </button>
                              <button *ngIf="showInlineLoader" class="btn btn-sm btn-block text-white" type="button" disabled style="background: #0A68AD;">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </button>
                          </form>
                      </div>
                  </div>
              </div>
          </div>

      </div>
      <div class="col-lg-7 d-none d-lg-block register-image">
      </div>
  </div>
</div>
<div class="col-sm-12" class="register-footer">
  <p class="text-center small" style="padding-top:5px">L&T Electrical and Automation <i class="far fa-copyright"></i> 2021</p>
</div>
