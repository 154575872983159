<!-- <div class="container-fluid h-100" style="padding:0">
    <div class="row h-100 m-0">
        <div class="col-sm-12 sites-page h-100" >
            <div class="row h-100">
                <div class="col-sm-12 col-lg-4 col-xl-2 mb-4 h-100" id="sites-overview-left-panel">
                    <app-sites-left-panel></app-sites-left-panel>
                </div>
                <div class="dashboard col-sm-12 col-lg-8 col-xl-10 no-padding mb-4 h-100 p-0">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="settings-page row h-100  gx-0">
    <div id="sites-overview-left-panel" class="settings-sub-menu-section col-lg-3 col" *ngIf="checkIfAssets() && (sharedService.getToggleSitesSubMenu() | async)">
      <div class="settings-sub-menu">
        <app-sites-left-panel></app-sites-left-panel>
      </div>
    </div>
    <div class="settings-section col" [ngClass]="(checkIfAssets() && (sharedService.getToggleSitesSubMenu() | async))? 'col-lg-10':'col-lg-12'">
        <router-outlet></router-outlet>
    </div>
  </div>
