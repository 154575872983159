import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-azure-maps',
    templateUrl: './azure-maps.component.html',
    styleUrls: ['./azure-maps.component.scss']
})
export class AzureMapsComponent implements OnInit, OnChanges {

    private AzureSubKey: string = environment.AzureSubKey;
    private baseUrl = environment.apiURL;
    map = atlas.Map.prototype;
    @Input() height: string;
    @Input() width: string;
    @Input() sites = [];

    constructor() { }

    ngOnInit(): void {
        this.InitMap();
    }

    InitMap(): void {
        this.map = new atlas.Map('mapContainer', {
            view: 'Auto',
            zoom: 4,
            showLogo: false,
            showFeedbackLink: false,
            language: 'en-us',
            authOptions: {
                authType: atlas.AuthenticationType.subscriptionKey,
                subscriptionKey: this.AzureSubKey
            }
        });

        // this.map.events.add('ready', () => {
        // });

        const zoomControl = new atlas.control.ZoomControl();
        const styleControl = new atlas.control.StyleControl();
        const compassControl = new atlas.control.CompassControl()
        const fullScreenButton = document.getElementById('fullScreenButton');
        const mapContainer = document.getElementById('mapContainer');

        // show zoomcontrol only on fullscreen view
        mapContainer.addEventListener("fullscreenchange", (event) => {
            if (document.fullscreenElement) {
                this.map.controls.add(zoomControl, {
                    position: atlas.ControlPosition.BottomRight
                });
                this.map.controls.add(styleControl, {
                    position: atlas.ControlPosition.BottomRight
                });
                this.map.controls.add(compassControl, {
                    position: atlas.ControlPosition.BottomRight
                });
            } else {
                this.map.controls.remove(zoomControl);
                this.map.controls.remove(styleControl);
                this.map.controls.remove(compassControl);
            }
        });

        // add listener to show fullscrenn view
        fullScreenButton.addEventListener('click', () => {
            if (mapContainer.requestFullscreen) {
                mapContainer.requestFullscreen();
            }
            //  else if(mapContainer?.msRequestFullscreen){
            //     mapContainer?.msRequestFullscreen()
            // } else if(mapContainer?.mozRequestFullscreen){
            //     mapContainer?.mozRequestFullscreen()
            // } else if(mapContainer?.webkitRequestFullscreen){
            //     mapContainer?.webkitRequestFullscreen()
            // }
        });
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['sites'] && changes['sites'].currentValue) {
            this.setMarkerData();
        }
    }

    setMarkerData(): void {

        const positions = this.sites.map(site => [site.location.longitude, site.location.latitude]);

        // Create a bounding box that covers all marker positions
        const bounds = atlas.data.BoundingBox.fromLatLngs(positions);

        // Fit the map view to the bounding box
        this.map.setCamera({
            bounds: bounds,
            maxZoom: 8,
            // padding: 40 // Optional padding around the bounds
        });

        this.sites.forEach(site => {
            const position = [site.location.longitude, site.location.latitude];
            const url = this.baseUrl + "/layout/sites/site/" + site['siteId'] + "/overview";

            // Create popup for mouseover
            const mouseoverPopup = new atlas.Popup({
                position: position,
                content: `<div style="padding: 5px">${site.siteName}</div>`,
                className: 'mouseoverPopup',
                closeButton: false,
                pixelOffset: [30, 30],
                maxWidth: '200px',
                maxHeight: '150px',
                showPointer: true,
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: 'blcak',
                borderRadius: 5,
                fontSize: '14px',
                testAlign: 'center'
            });

            // Create popup for click
            const clickPopup = new atlas.Popup({
                position: position,
                content: `
                    <div class="clickPopup" style="padding: 5px;font-weight:bold;font-size:12px">
                        <div style="color:#024D87"><a href="${url}" target="_blank">${site.siteName}</a></div>
                        <div>${site.address}</div>
                    </div>`,
                className: 'clickPopup',
                closeButton: true,
                pixelOffset: [0, -20],
                maxWidth: '200px',
                maxHeight: '150px',
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: 'blcak',
                borderRadius: 5,
                fontSize: '14px',
                testAlign: 'center'
            });

            const htmlMarker = new atlas.HtmlMarker({
                position: position,
                color: 'red',
            })

            this.map.markers.add(htmlMarker);

            // Show mouseover popup
            htmlMarker.getElement().addEventListener('mouseover', () => {
                mouseoverPopup.open(this.map);
            });

            // Close mouseover popup on mouseout
            htmlMarker.getElement().addEventListener('mouseout', () => {
                mouseoverPopup.close();
            });

            // Show click popup on marker click
            htmlMarker.getElement().addEventListener('click', () => {
                mouseoverPopup.close();
                clickPopup.open(this.map);
            });

            //Add custom class for mouseover popup to remove popup-arrow
            mouseoverPopup.getPopupContainer().setAttribute('class', 'mouseoverPopup')
        });
    }

}