<div class="accordion-item mb-2 p-1" *ngFor="let asset of assets;let j=index" style="margin: 0.2rem 0.5rem;">

    <h2>
        <div class="row">
            <div class="col-10 ps-4">
                <div class="row w-100">
                    <div class="asset-name col-sm-12">{{ (asset.assetCategoryName && asset.assetCategoryName.length>15)? (asset.assetCategoryName |
                        slice:0:15)+'..':(asset.assetCategoryName)}}</div>
                    <div class="col-sm-9 col-9" style="margin-top: -7px;">
                        <div class="heat" style="width:100%" role="button"
                            (click)="$event.preventDefault();$event.stopPropagation();$event.stopImmediatePropagation()">
                            <!-- <span [style.width.%]="(asset.healthy/(asset.healthy+asset.critical+asset.risk))*95"
                                            class="badge bg-success"
                                            style="border-radius:3px 0px 0px 3px!important" *ngIf="asset.healthy">{{asset.healthy}}</span>
                                        <span [style.width.%]="(asset.risk/(asset.healthy+asset.critical+asset.risk))*95"
                                            class="badge bg-warning" *ngIf="asset.risk">{{asset.risk}}</span>
                                        <span [style.width.%]="(asset.critical/(asset.healthy+asset.critical+asset.risk))*95"
                                            class="badge bg-danger"
                                            style="border-radius:0px 3px 3px 0px!important" *ngIf="asset.critical">{{asset.critical}}</span> -->
                            <span class="badge bg-success" style="border-radius:3px 0px 0px 3px!important;width:33%"
                                (click)="sortData(asset,'healthy', $event)">{{asset.healthy}}</span>
                            <span class="badge bg-warning" style="width:33%"
                                (click)="sortData(asset,'risk', $event)">{{asset.risk}}</span>
                            <span class="badge bg-danger" style="border-radius:0px 3px 3px 0px!important;width:33%"
                                (click)="sortData(asset,'critical',$event)">{{asset.critical}}</span>
                        </div>
                    </div>
                    <div class="col-sm-2 col-2" style="margin-top: -7px;">
                        <div class="heat" style="">
                            <span class="badge bg-transparent" style="color: #075DA0;font-size:medium;">{{asset.healthy +
                                asset.risk + asset.critical }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2" style="margin-left: -11px;">
                <div class="accordion-button blue_active sub-accordion"  data-mdb-toggle="collapse"
                [ngClass]="j ? 'collapsed': ''" attr.data-mdb-target="#asset{{j}}" aria-expanded="true"
                aria-controls="collapseOne" role="button">

                </div>
            </div>
        </div>
    </h2>
    <div id="asset{{j}}" class="accordion-collapse collapse" aria-labelledby="headingOne" [ngClass]="j ? '': 'show'"
        data-mdb-parent="#accordionExample2">
        <div class="accordion-body mt-2">
            <div class="row" style="box-shadow: 0px 0px 4px #0000001A;margin-bottom: 0.02rem;padding: 0.1rem 0rem 0.1rem 00.5rem;"
                *ngFor="let item of asset.assetDetails">
                <div class="col-sm-9 col-9 p-1">
                    <div class="assetHeading">{{item.assetName | uppercase}}</div>
                    <div class="assetSubheading">Sub Assets {{item.subAssetsCount}}</div>
                </div>
                <div class="col-sm-3 col-3 pe-0 ps-0" [ngClass]="item.assetHealth <= 40 ? 'criticalBorder' :(item.assetHealth > 70 ? 'healthyBorder' : 'riskBorder')">
                    <!-- <div class="heat mt-2" style="border-radius: 3px;">
                        <span class="badge" style="color: #fff;background: #0370C0;width:40px; border-radius: 3px!important;box-shadow: 0px 3px 6px #0000004F;">{{item.assetHealth +
                            '%'}}</span>
                    </div> -->
                </div>
            </div>
            <!-- <ul class="list-group list-group-flush">
                <li class="list-group-item m-1 p-1" style="font-size: smaller;padding: 0.5rem 0;border:1px solid #eee"
                    *ngFor="let item of asset.assetDetails">

                    <div class="row"
                        [ngClass]="item.assetHealth < 30 ? 'criticalBorder' :(item.assetHealth > 70 ? 'healthyBorder' : 'riskBorder')">
                        <div class="col-sm-8 col-8">
                            <span class="assetHeading">{{item.assetName}}</span>
                            <div class="assetSubheading">Sub Assets {{item.subAssetsCount}}</div>
                        </div>
                        <div class="col-sm-3 col-3">
                            <div class="heat" style="border-radius: 3px;">
                                <span class="badge"
                                    style="color: #fff;background: #0370C0;">{{item.assetHealth +
                                    '%'}}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul> -->
        </div>
    </div>
</div>
<div *ngIf="assets.length === 0" class="critical my-5 text-center"> No data available </div>
