<div class="position-relative">
    <mat-select multiple name="filters" id="filters" placeholder="Filters" class="" disableOptionCentering #filterField [formControl]="filterControl">
        <mat-select-trigger>
            <mat-chip-list class="d-inline-block me-1" aria-label="select value" *ngIf="selectTriggerValue"><mat-chip>{{selectTriggerValue[0]}}</mat-chip></mat-chip-list>
            <span *ngIf="selectTriggerValue?.length > 1">(+ 1)</span>
        </mat-select-trigger>
        
        <div class="group" *ngFor="let group of filterGroups,let i = index">
            <ng-container *ngIf="group.type !== 'dateRange'">
                <a class="filterShowMore" *ngIf="group?.list?.length > 3" (click)="group.hidden = !group.hidden">
                    {{ group.hidden ? 'See All' : 'See Less' }}
                </a>
                <mat-optgroup [label]="group.name" [title]="group.name">
                    <mat-option *ngFor="let item of group.list | slice:0:3" [value]="item.value" (onSelectionChange)="filterSelection($event,i,item.value)" [title]="item.name">
                        {{ item.name | titlecase }}
                    </mat-option>
                </mat-optgroup>
            </ng-container>
            <ng-container *ngIf="group.type === 'dateRange'">
                <mat-optgroup label="Date Range" title="Date Range">
                    <ng-container *ngIf="group.list?.length">
                        <mat-option *ngFor="let range of group.list" [value]="range.value" class="date-range-options">
                            <mat-radio-button class="date-range-radio-button" [value]="range.value" (change)="setRangeOption($event, i);rangeOption = range.value">
                                {{range.name | titlecase}}
                            </mat-radio-button>
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
                <div class="date-selection">
                    <div class="date-field mb-2">
                        <label class="col-3">From</label>
                        <input class="col-9" type="date" id="fromDate" name="fromDate" placeholder="00/00/0000" [(ngModel)]="filterFromDate" (ngModelChange)="setCustomRange($event,i,'from')" [max]="filterToDate?filterToDate:null" [disabled]="rangeOption !== 'custom'">
                    </div>
                    <div class="date-field mb-2">
                        <label class="col-3">To</label>
                        <input class="col-9" type="date" id="toDate" name="toDate" placeholder="00/00/0000" [(ngModel)]="filterToDate" (ngModelChange)="setCustomRange($event,i,'to')" [min]="filterFromDate?filterFromDate:null" [disabled]="rangeOption !== 'custom'">
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <ng-container *ngIf="isDateRange">
            <mat-optgroup label="Date Range" title="Date Range">
                <mat-option *ngFor="let range of rangeList" [value]="range" class="date-range-options">
                    <mat-radio-button class="date-range-radio-button" [value]="range.name" (change)="setRangeOption($event);rangeOption = range.name">
                        {{range.title | titlecase}}
                    </mat-radio-button>
                </mat-option>
            </mat-optgroup>
            <div class="date-selection">
                <div class="date-field mb-2">
                    <label class="col-3">From</label>
                    <input class="col-9" type="date" id="fromDate" name="fromDate" placeholder="00/00/0000" [(ngModel)]="filterFromDate" (ngModelChange)="filterSelection($event,'','')" [max]="filterToDate?filterToDate:null" [disabled]="rangeOption !== 'Custom'">
                </div>
                <div class="date-field mb-2">
                    <label class="col-3">To</label>
                    <input class="col-9" type="date" id="toDate" name="toDate" placeholder="00/00/0000" [(ngModel)]="filterToDate" (ngModelChange)="filterSelection($event,'','')" [min]="filterFromDate?filterFromDate:null" [disabled]="rangeOption !== 'Custom'">
                </div>
            </div>
        </ng-container> -->
        <mat-optgroup class="clearAll" role="button">
            <span role="button" (click)="clearAllFilters()">Clear All</span>
        </mat-optgroup>
    </mat-select>
</div>