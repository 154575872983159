import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { } from 'googlemaps';
import { cloneDeep, find } from 'lodash';
import { Subscription } from 'rxjs';
import { FilterPipe } from '../_pipes/filter.pipe';
import { SiteManagementService } from '../_services/site-management.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-sites-left-panel',
  templateUrl: './sites-left-panel.component.html',
  styleUrls: ['./sites-left-panel.component.css']
})
export class SitesLeftPanelComponent implements OnInit {


  @ViewChild('mapElement') mapElement: any;

  map: any;
  mapView: Boolean = true;
  executeOnce: any;
  searchText: any;
  filteredSites: { [key: string]: any }[] = [];
  ids : any[]=[];
  siteOverviewHeader: any;
  siteOverviewData: any;
  remainingHeight: any;
  copyData:any;
  subMenu:any;
  filteredSiteId:any;
  innerHeight: any;
  private getSiteLeftPanel: Subscription = new Subscription();
  private subscription: Subscription = new Subscription();
  isLoading: boolean = true;
  showMsg!: string;
  showError: boolean = false;
  createStatus!: boolean;
  pageSize = 5;
  pageNumber = 1;
  totalRecords: number;
  isScrollLoading = false;

  constructor(private _router: Router, private siteManagementService: SiteManagementService) {
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.filteredSites = this.siteOverviewData;
    this.getSiteOverviewPanelHeader();
    this.getSiteOverviewPanelData();
  }

  getSiteOverviewPanelHeader() {
    this.subscription.add(
      this.siteManagementService.getSiteOverviewPanelHeader()
      .subscribe((data: any) => this.siteOverviewHeader = data)
    );
  }

  onScroll(){
    if(this.totalRecords > (this.pageNumber * this.pageSize)){
      this.pageNumber++;
      this.getSiteOverviewPanelData()
    }
  }

  getSiteOverviewPanelData(isSearch = false) {
    this.pageNumber = isSearch ? 1 : this.pageNumber;
    this.pageNumber === 1 ? this.isLoading = true : this.isScrollLoading = true;
    this.pageSize = this.pageNumber === 1 ? 10 : 5;
    this.subscription.add(
      this.siteManagementService.getSiteOverviewData(this.pageNumber,this.pageSize, this.searchText)
        .subscribe((data: any) => {
          if (data instanceof HttpErrorResponse) {
            this.isLoading = false;
            this.isScrollLoading = false;
            this.showUIMsg("some error occured");
          } else {
            this.isLoading = false;
            this.isScrollLoading = false;
            this.siteOverviewData = this.siteOverviewData && this.siteOverviewData.length && !isSearch ? this.siteOverviewData.concat(data.siteOverview) : data.siteOverview;
            this.copyData = cloneDeep(data.siteOverview);
            this.totalRecords = data.totalRecords;
            if (data.siteOverview && data.siteOverview.length) {
              // this._router.navigate(['/layout/sites/site/' + data[0].siteId]);
            }
          }
        })
    );

  }

  toggleMapView(view: string): void {
    if (view === 'map') {
      this.mapView = true;
    } else {
      this.mapView = false;
    }
  }

  goToSiteDashboard(id: string): void {
    this._router.navigate(['/layout/sites/site/' + id]);
  }

  goToSiteDashboardMobile(id: string): void {
    this._router.navigate(['/layout/sites/site/' + id]);
    let el = document.getElementById('sites-overview-left-panel') as HTMLElement;
    el.classList.add("hide-left-panel");
  }

  onChange(subStationId: number, siteId: number, isChecked: any) {
    this.searchText = '';
    if (this.filteredSiteId != siteId && this.ids.length != 0) {
      this.ids = [];

      //uncheck the checked items
      this.siteOverviewData.forEach((value: any) => {
        if (value.subStations?.length > 0) {
          const siteListArray: Array<any> = [];
          value.subStations.forEach((subStationsValue: any) => {
            if (subStationsValue.siteId == this.filteredSiteId) {
              let el = document.getElementById(subStationsValue.id) as any;
              if (el && el.checked) {
                el.checked = false;
              }
            }
          });
        }
      });

      //replace the filtered submenu with original
      this.siteOverviewData.forEach((value: any) => {
        if (value.siteId == this.filteredSiteId) {
          value.subMenu = find(this.copyData, ['siteId', this.filteredSiteId]).subMenu;
        }
      });
      this.filteredSiteId = siteId;
    }
    else {
      this.filteredSiteId = siteId
    }
    const checked = isChecked.target.checked;
    if (checked) {
      this.ids.push(subStationId);
      this.apply();
    } else {
      const index = this.ids.findIndex(x => x === subStationId);
      this.ids.splice(index, 1);
      this.apply();
    }
  }

  apply() {
    if (this.ids.length > 0) {
      this.subscription.add(
        this.siteManagementService.getSiteOverviewFilteredPanelData(this.filteredSiteId, this.ids)
        .subscribe((data: any) => {
          if(data instanceof HttpErrorResponse){
            this.showError = true;
            this.showUIMsg("some error occured");
          }else{
            this.subMenu = data[0].subMenu;
            if (this.subMenu) {
              this.siteOverviewData.forEach((value: any) => {
                if (value.siteId == this.filteredSiteId) {
                  value.subMenu = this.subMenu;
                }
              });
            }
          }
        })
      );
    }
    else {
      this.siteOverviewData.forEach((value: any) => {
        if (value.siteId == this.filteredSiteId) {
          value.subMenu = find(this.copyData, ['siteId', this.filteredSiteId]).subMenu;
        }
      });
    }
  }

  search() {
    this.isLoading = true;
    this.getSiteLeftPanel.unsubscribe();
    this.getSiteOverviewPanelData(true);
  }

  showUIMsg(msg: string) {
    this.createStatus = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.showMsg = msg;
    setTimeout(() => { this.createStatus = false; }, 6000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.getSiteLeftPanel.unsubscribe();
  }
}
