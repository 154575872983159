<div class="form-outline mt-3" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
    <input type="file" class="file-input" (change)="addFile($event)" #fileUpload style="display: none;" [(ngModel)]="myFiles">
    <div class="file-upload m-4" (click)="fileUpload.click()">
        <ng-container *ngIf="status =='none' || status =='selected'">
            <div class="row">
                <div class="col-12 text-center p-4">
                    <!-- <i class="fa fa-cloud-upload-alt fa-4x"></i> -->
                    <img src="../../../../assets/images/upload.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div *ngIf="status =='none'" class="pb-4 drop-text px-1" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;
                  color: #242424;">
                        Drop files here or click to upload
                    </div>
                    <div *ngIf="status =='selected' && files && files[0]" class="pb-4 px-1" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;
                  color: #242424;">
                        {{files[0].name|titlecase}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="status =='validating' && files && files[0]">
            <div class="row">
                <div class="col-12 text-center p-4" style="color:#dad6d6;font-size:32px">
                    <div class="p-5">
                        Validating...
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div class="pb-2" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;
                  color: #242424;">
                        {{files[0].name|titlecase}}

                        <div class="demo-container p-2">
                            <div class="progress-bar">
                                <div class="progress-bar-value"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="status =='failed'">
            <div class="row">
                <div class="col-12 text-center p-2" style="color:#dad6d6;font-size:32px">
                    <div class="p-5">
                        <img src="../../../../assets/images/dmm_icons/rejected.png" alt="" style="height: 6rem;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div class="pb-2 critical" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;">
                        {{message? message :"Failed to upload"}}

                        <div class="demo-container p-2">
                            <div class="progress-bar" value="100%" style="background: #f56b10;">
                                <div class="progress-bar-value" style="background: #f56b10;"></div>
                            </div>
                            <div class="label mt-1" style="color: black;">100%</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="status =='error'">
            <div class="row">
                <div class="col-12 text-center p-2" style="color:#dad6d6;font-size:32px">
                    <div class="p-5">
                        <img src="../../../../assets/images/dmm_icons/rejected.png" alt="" style="height: 6rem;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div class="pb-2 critical" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;">
                        {{message? message :"Failed to upload"}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="status =='uploaded' && files && files[0]">
            <div class="row">
                <div class="col-12 text-center p-2" style="color:#dad6d6;font-size:32px">
                    <div class="p-5">
                        <img src="../../../../assets/images/dmm_icons/approved.png" alt="" style="height: 6rem;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <div class="pb-2" style="border-bottom:2px solid #EDEDED;font: normal normal normal 19px/29px Source Sans Pro;
                  letter-spacing: 0px;
                  color: #242424;">
                        {{files[0].name|titlecase}}

                        <div class="demo-container p-2">
                            <div class="progress-bar" value="100%" style="background: #4dd509;">
                                <div class="progress-bar-value" style="background: #4dd509;"></div>
                            </div>
                            <div class="label mt-1" style="color: black;">100%</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row py-1">
            <div class="col text-center" style="font: normal normal normal 13px/29px Source Sans Pro;
              letter-spacing: 0px;
              color: #757779;">
                Please upload only suggested format
            </div>
        </div>
    </div>
    <div class="row py-2">
        <div class="col-12 py-2 button-group pe-5" style="text-align: center;">
            <button class="px-4 me-3" style="border: none;background: transparent;"
                (click)="cancel()">Cancel</button>
            <button class="btn-primary btn" [disabled]="!files || !files.length || status == 'uploaded' || status == 'failed' || status == 'validating'"  (click)="uploadFile()">Upload</button>
        </div>
    </div>
</div>