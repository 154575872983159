import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { SiteDetails, WorkPermit, Alarm, HealthProfile, RecentAlarm, UpcomingMaintenance, HorizontalChart } from '../_models/dashboard.type';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl: string = environment.apiURL;
   reqHeaders = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded'
 });

  constructor(
    private http: HttpClient
  ) { }

  getSiteDetails(): Observable<SiteDetails> {
    const url = `${this.baseUrl}/sitemanagement/dashboard`;
    return this.http.get<SiteDetails>(url, {headers:this.reqHeaders});
  }

  getSitesDropdown(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/sitemanagement/Site/siteDropdown`);
  }

  getSitelocations(): Observable<any> {
    const url = `${this.baseUrl}/sitemanagement/dashboard/sitelocations`
    return this.http.get(url);
  }

  getWorkPermit(): Observable<WorkPermit> {
    const url = `${this.baseUrl}/maintenanceworkflow/Dashboard/workpermit`
    return this.http.get<WorkPermit>(url).pipe(catchError(error => of(error)));
  }

  getHorizontalCharts(type: string, category: string, option: number): Observable<HorizontalChart> {
    let url = '';
    switch(type) {
      case 'assetmanagement':
        url =`${this.baseUrl}/assetmanagement/dashboard/${category}/${option}`;
        break;
      case 'alarm':
        url = `${this.baseUrl}/alarm/dashboard/alarmcause/${option}`;
        break;
    }
    return this.http.get<HorizontalChart>(url);
  }

  //Alaram / Events
  getAlarmOrEvents(all: number): Observable<Alarm> {
    const url =`${this.baseUrl}/alarm/dashboard/0/${all}`;
    return this.http.get<Alarm>(url);
  }

  //Recent Alaram
  getRecentAlarams(all: boolean): Observable<Array<RecentAlarm>> {
    const url =`${this.baseUrl}/alarm/dashboard/recentalarm/${all}`;
    return this.http.get<Array<RecentAlarm>>(url);
  }

  //Recent Alaram
  getUpcomingMaintenance(days: number): Observable<UpcomingMaintenance> {
    const url =`${this.baseUrl}/maintenanceworkflow/dashboard/upcoming/${days}`;
    return this.http.get<UpcomingMaintenance>(url);
  }

  //Health Profile
  getHealthProfile(): Observable<any> {
    // const url =`${this.baseUrl}/alarm/dashboard/healthprofile`;
    const url = `${this.baseUrl}/maintenanceworkflow/Dashboard/healthProfile`;
    return this.http.get<any>(url);
  }

  getMaintenanceProfile(): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Dashboard/maintenanceProfile`;
    return this.http.get<any>(url);
  }

  getObsolesceneceProfile(): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Dashboard/obsolesceneceProfile`;
    return this.http.get<any>(url);
  }

//   getDashboardKPICards(): Observable<HealthProfile>{
//     const url =`${this.baseUrl}/maintenanceworkflow/Dashboard`;
//     return this.http.get<any>(url);
//   }
}