import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { filter } from 'rxjs/operators';
import { IPredictService } from '../_services/i-predict.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../_services/shared.service';
import { SharedValueService } from '../_services/shared-value.service';
import { RbacService } from '../_helpers/rbac.service';
import { AdminPanelService } from '../modules/admin-panel/services/admin-panel.service';



@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
  slim: boolean = true;
  sidenavslim: Boolean = true;
  user!: User;
  executeOnce: any;
  panelOpen: Boolean = false;
  showHome: boolean = false;
  currentNavigation: string = '';
  private subscription: Subscription = new Subscription();
  showDMM: boolean = true;
  isOandM: boolean = false;
  innerHeight: any;
  scrollTop = 0;
  hideNav = false;
  count:number = 0;
  customerId:number = 0;
  module:any;
  isCustomerUser = false;
  isAdminOrEAUser = false;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private iPredictService: IPredictService,
    private sharedService: SharedService,
    public sharedValueService: SharedValueService,
    private rbacService: RbacService,
    private adminPanelService: AdminPanelService
    ) {
    let self = this;
    if (this.authenticationService.checkAuthentication()) {
      // this.user = this.authenticationService.currentUserValue;
    }

    
    if(window.location.href.includes('home')){
      this.showHome = false;
    } else{
      this.showHome = true;
    }

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentNavigation = event.url;
    });
  }

  ngOnInit(): void {
    // console.log("layout init");
    this.innerHeight = window.innerHeight;
    this.subscription.add(
      this.adminPanelService.getAccountTypeData().subscribe(data => {
       this.isAdminOrEAUser = data?.isSuperAdmin || data?.isEAUser;
      })
    );
    this.subscription.add(this.rbacService.getUserInformation().subscribe(userData => {
      this.user = userData;
      this.isCustomerUser = this.user.customerId ? true : false;
      this.isOandM = this.user?.role?.name == 'EA.OandM'? true: false;
      this.customerId = this.user.customerId?this.user.customerId:0;
      this.module = this.sharedService.modules;
      if(this.module?.AlarmOrEvents?.canRead)
        this.getTotalAlarms();
    }));
  }

  getModuleList(customerId: number): void {
    this.subscription.add(
      this.iPredictService.getModuleList(customerId).subscribe(moduleData => {
        this.showDMM = moduleData.some((value: any) => value.moduleMaster.name === 'DMM');
      })
    )
  }

  onLogout(): void {
    this.subscription.add(this.iPredictService.logout().subscribe(data => {
      const URI = data.response;
      const allowlist ='ionecloud.lntebg.com';
      const allowProd = 'smartcommcloud.lntebg.com';
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('REFRESH_TOKEN');
      localStorage.clear();
      if (URI.indexOf(allowlist) > -1 || URI.indexOf(allowProd) > -1) {
        window.location.href = (data?.response).toString();
      }
    }));
  }

  toggleSlimFull(): void {
    this.slim = !this.slim;
    this.sidenavslim = !this.sidenavslim;
  }

  closeSlimFull() {
    this.slim = true;
  }

  navigateToDMM() {
    if (this.currentNavigation.includes('DMM')) {
      this.sharedService.toggleDMMSubMenu();
    } else {
      this._router.navigate(['/layout/DMM/overview']);
    }
  }

  navigateToAdminPanel() {
    if (this.currentNavigation.includes('adminpanel')) {
    this.sharedService.toggleAdminPanelSubMenu();
    }
    else {
      if (this.module?.AssetManagement?.canRead)
        this._router.navigate(['/layout/adminpanel/assetmanagement/customer']);
      else if (this.module?.UserManagement?.canRead)
        this._router.navigate(['/layout/adminpanel/usermanagement']);
      else if (this.module?.SubscriptionManagement?.canRead)
        this._router.navigate(['/layout/adminpanel/subscription']);
      else if (this.module?.TemplateManagement?.canRead)
        this._router.navigate(['/layout/adminpanel/templatemanagement']);
    }
  }

  navigateToSites() {
    if (this.currentNavigation.includes('sites')) {
      if (this.currentNavigation.includes('assets')) {
        this.sharedService.toggleSitesSubMenu('assets');
      }
      else {
        this.sharedService.toggleSitesSubMenu();
      }
    }
    else {
      this._router.navigate(['/layout/sites']);
    }
  }

  navigateToSettings() {
    if (this.currentNavigation.includes('settings')) {
      this.sharedService.toggleSettingsSubMenu();
    }
    else{
      this._router.navigate(['/layout/settings/general']);
    }
  }

  navigateToSitePanel() {
    this.sharedService.toggleSitePanel();
  }

  navigateToDLB()
  {
    if (this.currentNavigation.includes('DLB')) {
      this.sharedService.toggleDLBSubMenu();
    }
    else{
      this._router.navigate(['/layout/DLB/documents']);
    }
  }

  navigateToInsights(){
    this._router.navigate(['/layout/insights']);
  }

  navigateToWhatsNew(){
    this._router.navigate(['/layout/whatsnew']);
  }

  navigateToContactUs(){
    this._router.navigate(['/layout/contact']);
  }

  navigateToHelp(){
    this._router.navigate(['/layout/help']);
  }

  onScroll(event: any) {
    this.hideNav = this.scrollTop < event.target.scrollTop;
    this.scrollTop = event.target.scrollTop;
  }

  getTotalAlarms() {
    this.subscription.add(
      this.iPredictService.getTotalAlarmCount(this.customerId).subscribe(data => {
        this.count = data;
      })
    );
  }

  checkAccess(module: string, access: string) {
    // this.subscription.add(
    //   this.rbacService.canAccess(module, access).subscribe((access: Boolean) => {
    //     return access;
    //   })
    // );
    console.log("i'm called with ", module, access);
    // let modules = this.sharedService.modules;
    // console.log(modules);
    // let moduleObj = modules?.filter((item: any)=> item.moduleName == module);
    // console.log(moduleObj);
    // let entityObj =  moduleObj[0]?.entityAccessDetails.filter((item:any) => item.entityName == module)
    // console.log(entityObj);
    // return entityObj[0][access];
  }

  goToProfile() {
    this._router.navigate([`/layout/profile`], { queryParams: {userId: this.user?.id, page: this.getCurrentPage()}});
  }

  getCurrentPage() {
    return window.location.href;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
