import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, catchError, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeederResponse } from 'src/app/_models/feeder.type';
import { ProgressResponse } from 'src/app/_models/progress.type';
import { PtwSummary, PtwJobList, WorkPermitType, WorkPriority, CheckList } from '../../../_models/dmm.type';

@Injectable({
  providedIn: 'root'
})
export class WorkPermitService {
  private baseUrl: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  getWorkPermitData(status: string, customerId: number, siteId: number, isSuperEAUser: boolean) {
    //status = status || 'null';
    if (customerId && siteId) {
      const assetTypes = this.http.get(`${this.baseUrl}/assetmanagement/Asset/assetTypes?seAsset=${isSuperEAUser}`).pipe(catchError(error => of(error)));     
      // const jobNos = (status?this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/true/${status}/${customerId}?siteId=${siteId}`):this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/true/${customerId}?siteId=${siteId}`)).pipe(catchError(error => of(error)));
      // const workHistory = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory/true/${customerId}?siteId=${siteId}`).pipe(catchError(error => of(error)));
      const workType = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workTypes/${customerId}`).pipe(catchError(error => of(error)));
      const workPermitType = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workPermitTypes/${customerId}`).pipe(catchError(error => of(error)));
      const workPriority = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workPriorities/${customerId}`).pipe(catchError(error => of(error))); 
      return forkJoin({
        assetTypes: assetTypes,
        // jobNos: jobNos,
        // workHistory: workHistory,
        workType: workType,
        workPermitType: workPermitType,
        workPriority: workPriority
      });
    }
    else {
      const assetTypes = this.http.get(`${this.baseUrl}/assetmanagement/Asset/assetTypes?seAsset=${isSuperEAUser}`).pipe(catchError(error => of(error)));
      // const jobNos = (status?this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/true/${status}/${customerId}`):this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/true/${customerId}`)).pipe(catchError(error => of(error)));
      // const workHistory = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory/true/${customerId}`).pipe(catchError(error => of(error)));
      const workType = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workTypes/${customerId}`).pipe(catchError(error => of(error)));
      const workPermitType = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workPermitTypes/${customerId}`).pipe(catchError(error => of(error)));
      const workPriority = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workPriorities/${customerId}`).pipe(catchError(error => of(error)));
      return forkJoin({
        assetTypes: assetTypes,
        // jobNos: jobNos,
        // workHistory: workHistory,
        workType: workType,
        workPermitType: workPermitType,
        workPriority: workPriority
      });
    }

  }

  getTeamAndApproverDetails(customerId: number) {
    const checkLists = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermitCheckLists/checkLists/${customerId}`).pipe(catchError(error => of(error)));
    const teamDetails = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermitTeam/teamDetails/${customerId}`).pipe(catchError(error => of(error)));
    const approvalDetails = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermitTeam/ApprovalDetails/${customerId}`).pipe(catchError(error => of(error)));

    return forkJoin({
      checkLists: checkLists,
      teamDetails: teamDetails,
      approvalDetails: approvalDetails
    })
  }

  getAssetFromAssetType(assetTypeId: number, customerId:number, siteId:number) {
    if(customerId && siteId){
      const url = `${this.baseUrl}/assetmanagement/Asset/Assets/${assetTypeId}?customerId=${customerId}&siteId=${siteId}`;
      return this.http.get<FeederResponse>(url);
    }
    else{
      const url = `${this.baseUrl}/assetmanagement/Asset/Assets/${assetTypeId}?customerId=${customerId}`;
      return this.http.get<FeederResponse>(url);
    }
  }

  getSubAssetFromAssetId(assetId: number) {
    const url = `${this.baseUrl}/assetmanagement/SubAsset/assetByFeeder/${assetId}`;
    return this.http.get<FeederResponse>(url);
  }

  getInspectionTemplates(workTypeName: string) {
    let url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/all/templatesGroupByWorkType`;
    if(workTypeName) url += `?name=${workTypeName}`
    return this.http.get<any>(url);
  }

  getSubAssetDetails(assetId: string, subassets: any): Observable<FeederResponse> {
    let param = '';
    if (assetId && subassets && subassets.length) {
      let ids = subassets.join();
      param = assetId + '?feederNo=' + ids;
    } else if (assetId && (!subassets || !subassets.length)) {
      param = assetId;
    }
    const url = `${this.baseUrl}/assetmanagement/SubAsset/AssetInfo/${param}`;
    return this.http.get<FeederResponse>(url);
  }

  getProgressData(permitId: string): Observable<ProgressResponse> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/ptwProgress/${permitId}`;
    return this.http.get<ProgressResponse>(url);
  }

  getPTWDataFromJobNo(jobNo: number): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitDetails/${jobNo}`;
    return this.http.get<any>(url);
  }

  // getWorkHistory(): Observable<Array<PtwSummary>> {
  //   const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory`;
  //   return this.http.get<Array<PtwSummary>>(url);
  // }

  getPTWJobList(): Observable<Array<PtwJobList>> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos`;
    return this.http.get<Array<PtwJobList>>(url);
  }

  getWorkPermitType(): Observable<Array<WorkPermitType>> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/workPermitTypes`;
    return this.http.get<Array<WorkPermitType>>(url);
  }

  getWorkPriorities(): Observable<Array<WorkPriority>> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/workPriorities`;
    return this.http.get<Array<WorkPriority>>(url);
  }

  getCheckList(): Observable<Array<CheckList>> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitCheckLists/checkLists`;
    return this.http.get<Array<CheckList>>(url);
  }

  sendApproverAction(postData: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Task/ApprovalWorkFlow`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  sendWorkPermitDetails(postData: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitDetails`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  uploadFiles(files: any, privacy: string, customerId?:number): Observable<any> {
    const formData = new FormData();
    files.forEach((f: any) => formData.append('file', f));
    const url = customerId?`${this.baseUrl}/contentmanagement/document/${privacy}?customerId=${customerId}`:`${this.baseUrl}/contentmanagement/document/${privacy}`;
    return this.http.post<any>(url, formData).pipe(catchError(error => of(error)));;

  }

  deleteFile(fileUrl: string, privacy: string): Observable<any> {
    const url = `${this.baseUrl}/contentmanagement/document/${privacy}?link=${fileUrl}`;
    return this.http.delete<any>(url);

  }

  downloadFile(fileUrl: string): Observable<any> {
    const url = `${this.baseUrl}/contentmanagement/document?link=${fileUrl}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  removeFileFromDb(fileUrl: string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Task?url=${fileUrl}`;
    return this.http.delete<any>(url);
  }
  removePTW(ptw: string, flag: boolean): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitDetails?Id=${ptw}&isPTW=${flag}`;
    return this.http.delete<any>(url);
  }

  sendReminder(ptwId: string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/sendReminder/${ptwId}`;
    return this.http.post<any>(url, ptwId).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getForwardApprovers(jobId: string | undefined) {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitTeam/ForwardApprovalDetails/${jobId}`;
    return this.http.get<any>(url);
  }

  /* Task API's */

  getTaskForApprover(ptwId: number) {
    const url = `${this.baseUrl}/maintenanceworkflow/Task/ByPtw/${ptwId}`;
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  getTaskData(customerId: number, siteId: number, filterState?: string) {
    let taskNos;
    if (filterState) {
      taskNos = siteId?this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/false/${filterState}/${customerId}?siteId=${siteId}`).pipe(catchError(error => of(error))): this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/false/${filterState}/${customerId}`).pipe(catchError(error => of(error)));
    } else {
      taskNos = siteId?this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/false/${customerId}?siteId=${siteId}`).pipe(catchError(error => of(error))):this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/false/${customerId}`).pipe(catchError(error => of(error)));
    }
    // const taskNos = this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/false`).pipe(catchError(error => of(error)));
    const taskHistory = siteId?this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory/false/${customerId}?siteId=${siteId}`).pipe(catchError(error => of(error))):this.http.get(`${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory/false/${customerId}`).pipe(catchError(error => of(error)));
    return forkJoin({
      taskNos: taskNos,
      taskHistory: taskHistory
    });
  }

  sendDelegation(userId: string, taskId: string, payload: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Task/Delegation/${userId}/${taskId}`;
    return this.http.post<any>(url, payload);
  }

  getTaskFormData(taskId: number): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Task/${taskId}`;
    return this.http.get<any>(url);
  }

  getBreakDownCause() {
    const url = `${this.baseUrl}/maintenanceworkflow/Task/breakdownCauses`;
    return this.http.get<any>(url);
  }

  submitTaskFormData(postTask: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/Task`;
    return this.http.post<any>(url, postTask).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getFilteredSummaryList(isWorkPermit: boolean, status: string, customerId: number, siteId: number): Observable<Array<PtwSummary>> {
    let url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/${isWorkPermit}/${status}/${customerId}`;
    if(siteId){
      url += `?siteId=${siteId}`;
    }
    return this.http.get<Array<PtwSummary>>(url);
  }

  getCanCreatePTW() {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/canCreatePTW`;
    return this.http.get<boolean>(url);
  }

  canExtendPTW(id: string) {
    const url  = `${this.baseUrl}/maintenanceworkflow/WorkPermit/canExtend/${id}`;
    return this.http.get<boolean>(url);
  }

  extendReschedulePTW(payload: any, action: string): Observable<any> {
    let endpoint;
    if (action == 'extend') endpoint = 'ExtendPTW';
    else endpoint = 'ReschedulePTW'
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/${endpoint}`;
    return this.http.post<any>(url, payload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  cancelPTW(id: string) {
    const url  = `${this.baseUrl}/maintenanceworkflow/WorkPermit/CancelPtw/${id}`;
    return this.http.post<any>(url, {});
  }

  getEAUserCustomers() {
    const url = `${this.baseUrl}/maintenanceworkflow/AdminSettings/getEAUserCustomers`;
    return this.http.get<boolean>(url).pipe(catchError(error => of(error)));
  }

  getEAUserSites(customerId: number) {
    const url = `${this.baseUrl}/maintenanceworkflow/AdminSettings/getEAUserSites/${customerId}`;
    return this.http.get<boolean>(url).pipe(catchError(error => of(error)));
  }

  getswitchboardOptions(){
    const observedMaintenance = this.http.get(`${this.baseUrl}/assetmanagement/Asset/observedMaintenance`).pipe(catchError(error => of(error)));  
    // const measuredMaintenance = this.http.get(`${this.baseUrl}/assetmanagement/Asset/measuredMaintenance`).pipe(catchError(error => of(error)));    
    return forkJoin({
      observedMaintenance: observedMaintenance,
      // measuredMaintenance: measuredMaintenance
    });
  }

  getWorkPermitJobs(customerId: number, siteId: number, isPtw: boolean) {
    let url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/jobNos/${isPtw}`;
    if(customerId) url += `/${customerId}`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getWorkPermitHistory(customerId: number, siteId: number, isPtw: boolean) {
    let url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/workhistory/${isPtw}`;
    if(customerId) url += `/${customerId}`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url).pipe(catchError(error => of(error)));
  }

  getAssetSubAssetList(requestPayload: any, assetId = null): Observable<any> {
    let url = `${this.baseUrl}/maintenanceworkflow/WorkPermit/maintenanceOverivew`;
    if(assetId){
      url += `/${assetId}`
    }
    return this.http.post<any>(url, requestPayload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getAssetTemplates(requestParams): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/all/pagination/templates?PageNumber=${requestParams.pageNumber}&PageSize=${requestParams.pageSize}&customerId=${requestParams.customerId}&assetId=${requestParams.assetId}&feederNo=${requestParams.feederNo}`
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  getAssetTemplate(templateId: number): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/generateTemplateJson/${templateId}`
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  saveAssetInput(requestpayload: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/maintenanceOverview`;
    return this.http.post<any>(url, requestpayload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getUserProfileDate(userId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/UserProfileView/${userId}`);
  }
}
