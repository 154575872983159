<div class="row matrix-container" style="height:84%">
    <div class="col px-0 pt-3 text-center" style="width:6%;flex:unset;" *ngIf="showY">
        <div class="yAxis">Criticality Index</div>
    </div>
    <div class="col ps-0 pe-1 col-lg-11" style="width:94%">
        <div class="row px-0 mx-0">
            <div class="col-12 text-center p-0 m-0">
                <div class="xAxis">Health Index</div>
            </div>
        </div>
        <div class="row matrix-row p-0" style="margin: 0!important;height: unset!important;">
            <div class="col matrix-column-header p-0"></div>
            <div class="col matrix-column-header p-0" *ngFor="let item of healthIndex">
                <div class="cell w-100 h-100 text-center">
                    <div class="item-description">{{item.description}}</div>
                    <div class="item-label">{{item.label}}</div>
                </div>
            </div>
        </div>
        <div class="row matrix-row p-0" *ngFor="let item of criticalIndex;let i=index">
            <div class="col matrix-column-header p-0">
                <div class="cell w-100 h-100 text-center" style="width:56px!important">
                    <div class="item-description">{{item.description}}</div>
                    <div class="item-label">{{item.label}}</div>
                </div>
            </div>
            <div class="col matrix-column p-0" *ngFor="let column of healthIndex;let j=index" id="{{(j+1)+''+(i+1)}}"
                [style.background]="colors[(j+1)+''+(i+1)]">
                <div class="cell w-100 h-100 text-center matrix-value" *ngIf="subAssetRiskMatrix">
                    <span style="cursor: pointer;" (click)="subAssetSelection.emit([column.code,item.code])">{{getData(j+1,i+1)}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 legend pe-2">
        <span class="ms-2"><i class="fa fa-square" [style.color]="colors['11']"></i> <span class="ms-1">Low</span></span>
        <span class="ms-2"><i class="fa fa-square" [style.color]="colors['41']"></i><span class="ms-1">Medium</span></span>
        <span class="ms-2"><i class="fa fa-square" [style.color]="colors['54']"></i><span class="ms-1">High</span></span>
    </div>
</div>