import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { IPredictService } from '../_services/i-predict.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  @Input('termsandConditions') termsandConditions: any;
  @Output() updateTermsAndConditions = new EventEmitter();
  termsAndConditionsURI: any;
  agree: boolean = false;
  private subscription: Subscription = new Subscription();
  privacyPolicy!: string;
  privacyNotice!: string;

  constructor(
    private sanitizer: DomSanitizer,
    private iPredictService: IPredictService,
  ) { }

  ngOnInit(): void {
  }

  getTermsOfUse() {
    this.subscription.add(
      this.iPredictService.getTermsAndConditions().subscribe((data) => {
        this.termsAndConditionsURI = (data['termsAndConditions'].uri).toString();
        let el = document.getElementById('termsUse') as HTMLElement;
        el.setAttribute("src", this.termsAndConditionsURI);
      })
    )

  }

  getPrivacyPolicy() {
    this.subscription.add(
      this.iPredictService.getPrivacyPolicy().subscribe((data) => {
        this.privacyPolicy = data;
        if (this.privacyPolicy !== '') {
          window.open(this.privacyPolicy, '_blank');
        }
      })
    )
  }

  getPrivacyNotice() {
    this.subscription.add(
      this.iPredictService.getPrivacyNotice().subscribe((data) => {
        this.privacyNotice = data;
        if (this.privacyNotice !== '') {
          window.open(this.privacyNotice, '_blank');
        }
      })
    )
  }

  openTermsOfUse(url: string) {
    if (url == "privacyPolicy") {
      this.getPrivacyPolicy();
    }
    else if (url == "privacyNotice") {
      this.getPrivacyNotice();
    }

  }

  updateValue(agree: boolean) {
    this.updateTermsAndConditions.emit(agree);
  }

}
