import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-manager',
  templateUrl: './maintenance-manager.component.html',
  styleUrls: ['./maintenance-manager.component.css']
})
export class MaintenanceManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
