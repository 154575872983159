import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlarmQuery } from '../_models/alarm.query';
import { Alarms } from '../_models/alarm.type';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  baseUrl = environment.apiURL;


  constructor(private http: HttpClient) { }

  getAlarms(id: number): any {
    return this.http.get<Alarms>(`${this.baseUrl}/alarm/AlarmDetails/alarms/${id}`);
  }

  getAlarmsWithPagination(pageNumber:number, pageSize:number, payload: AlarmQuery): any {
    return this.http.post<Alarms>(`${this.baseUrl}/alarm/AlarmDetails/alarms/${pageNumber}/${pageSize}`, payload).pipe(catchError(error => of(error)));
  }

  getAlarmStatus():any{
    return this.http.get<string>(`${this.baseUrl}/alarm/AlarmDetails/AlarmStatus`);
  }

  getAlarmEventClass():any{
    return this.http.get<string>(`${this.baseUrl}/alarm/AlarmDetails/AlarmEventClass`);
  }

  getAlarmClass():any{
    return this.http.get<string>(`${this.baseUrl}/alarm/AlarmDetails/AlarmClass`);
  }

  getAssetCategory(id:number):any{
    return this.http.get<string>(`${this.baseUrl}/alarm/AlarmDetails/AssetCategories/${id}`);
  }

  updateAlarms(payload: any, action:string): any {
    let actionType = (action == ('comment') || action == 'edit-comment' )? '/comment' : '';
    const url = `${this.baseUrl}/alarm/AlarmDetails/alarmActions${actionType}`;
    return this.http.put<any>(url, payload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  deleteAlarms(alarmIds: any): any {
    let options = {
      body: alarmIds,
    };
    const url = `${this.baseUrl}/alarm/AlarmDetails/delete`;
    return this.http.delete<any>(url,options).pipe(catchError(error => of(error)));
  }

  getPtwDetails(alarmId: any): any{
    return this.http.get<any>(`${this.baseUrl}/alarm/AlarmDetails/alarms/createptw/${alarmId}`).pipe(catchError(error => of(error)));
  }

  acknowledge(payload: any): any {
    const url = `${this.baseUrl}/alarm/AlarmDetails/acknowledge`;
    return this.http.put<any>(url, payload).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }
}
