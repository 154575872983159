import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedValueService {
  siteId: string = '';
  customerIdforDLB: number = 0;
  _message: string = '';
  constructor() { }

  setSiteId(id: string): any {
    this.siteId = id;
  }

  getSiteId(): any {
    return this.siteId;
  }
  setCustomerIdDLB(customerid: number): any {
    this.customerIdforDLB = customerid;
  }
  getCustomerIdDLB(): any {
    return this.customerIdforDLB;
  }

  set message(val: string) {
    this._message = val;
  }
  get message(): string {
    const returnedMessage = this._message;
    return returnedMessage;
  }
  clear() {
    this.message = '';
  }
}
