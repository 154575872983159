import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import { Index } from 'src/app/_models/health.type';


@Component({
  selector: 'app-index-chart',
  templateUrl: './index-chart.component.html',
  styleUrls: ['./index-chart.component.scss']
})
export class IndexChartComponent implements OnInit {
  chart: any;
  riskChart: any;
  Highcharts: any = Highcharts;
  chartOptions: Options = {};
  riskChartOptions: Options = {};
  @Input() data: any;
  @Input() chartName: string = '';
  @Input() target: string = '';
  @Input() chartData!: Index;
  selectedValue!: number;
  selectedRiskValue!: number;
  seriesColor: any;
  colors: string[] = ['#08aa17', '#08aa17', '#79b96e','#9dd594', '#f3e52c','#f3c42c', '#f89843','#fa7600', '#ea2f40', '#ea2f40'];
  constructor() { }

  ngOnInit(): void {
    this.getData();
  }


  getData() {
    // console.log(this.chartData);
    if (this.chartData && this.chartData.indexRange) {
      let keys = Object.keys(this.chartData?.indexRange);
      let arr: any = [];
      keys.forEach((key: string) => {
        arr.push({ name: key, value: this.chartData?.indexRange[key] });
      })
      this.data = arr;
      if (this.chartName.includes('contact'))
        this.selectedValue = parseInt(this.chartData?.indexValue);
      else this.selectedValue = this.chartData.indexRange[this.chartData.indexValue];
      if (this.chartName.includes('risk'))
        this.createRiskChart();
      else this.createChart();
    }
  }



  createChart() {
    let self1 = this;
    (this.chartOptions as any) = {
      chart: {
        renderTo: 'container',
        marginTop: 23,
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      series: [{
        data: [],
        marker: {
          radius: 0
        },
        type: 'line'
      }, {
        dataLabels: {
          enabled: true,
          formatter: function (): any {
            let self1 = this as any;
            let min = self1.series.xAxis.min;
            let max = self1.series.xAxis.max;
            // if (self1.x !== min && self1.x !== max ) {
            if (self1.x == self.selectedValue ) {
              let el = self.data.filter((item: any) => item.value == self1.x);
              return el[0]?.name;
            } else {
              return ''
            }

          },
          style: {
            fontSize: '9px',
            visibility: 'visible'
          },
          overflow: 'allow',
          verticalAlign: 'top',
          y:-30,
          x:0
        },
        type: 'scatter',
        marker: {
          symbol: 'triangle',
          radius: 7,
          lineColor: 'white',
          lineWidth: 1
        },
        data: [{ x: this.selectedValue, y: 0 }],
        color: {
          linearGradient: [0, 0, 350, 0],
          stops: [
            [0, 'rgb(8 170 23)'],
            [0.5, 'rgb(255 255 0)'],
            [1, 'rgb(248 15 11)']
          ]
        }
      }],
      xAxis: {
        type: 'linear',
        max: Math.max(...this.data.map((o: any) => o.value)),
        min: Math.min(...this.data.map((o: any) => o.value)),
        lineColor: {
          linearGradient: [0, 0, 350, 0],
          stops: [
            [0, 'rgb(8 170 23)'],
            [0.5, 'rgb(255 255 0)'],
            [1, 'rgb(248 15 11)']
          ]
        },
        lineWidth: 12,
        offset: -2,
        labels: {
          distance: '15px',
          endOnTick: true,
          formatter: function (): any {
            let self1 = this as any;
            if ((self1.isFirst || self1.isLast)) {
              let el = self.data.filter((item: any) => item.value == self1.value);
              return el[0]?.name;
            } else {
              return ''
            }

          },
          showLastLabel: true,
          startOnTick: false,
          step:1,
          style: {
            fontSize: '10px'
          },
          value: undefined,
          y: 19,
        }
      },
      yAxis: {
        visible: false
      }
    }
    let el = this.chartName + this.target;
    let self = this;
    setTimeout(() => {
      self.chart = Highcharts.chart(el, self.chartOptions);

      
      if (this.chartName.includes('contact')) {
        let index = this.selectedValue/5;
        index = parseInt(index as any);
        index = index ? index-1: index;
        this.seriesColor =  this.colors[index];
      } else {
        let index = this.selectedValue*2;
        index = parseInt(index as any);
        index = index ? index-1: index;
        this.seriesColor =  this.colors[index];
      }
     
    }, 2000)
  }

  createRiskChart() {
    let arr = this.data.map((o: any) => o.name);
    this.selectedRiskValue = arr.indexOf(this.chartData.indexValue);
    let self = this;
    (this.riskChartOptions as any) = {
      chart: {
        ignoreHiddenSeries: false,
        marginTop: 23
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      series: [{
        data: [],
        marker: {
          radius: 0
        },
        type: 'line'
      }, {
        dataLabels: {
          enabled: true,
          formatter: function (): any {
            let self1 = this as any;
            // if (self1.x !== 'R11' && self1.x !== 'R54') {
              let el = self.data.filter((item: any) => item.name == self1.x);
              return el[0]?.name;
            // } else {
            //   return ''
            // }

          },
          style: {
            fontSize: '9px',
            visibility: 'visible'
          },
          overflow: 'allow',
          verticalAlign: 'top',
          y:-30,
          x:0
        },
        type: 'scatter',
        marker: {
          symbol: 'triangle',
          radius: 7,
          lineColor: 'white',
          lineWidth: 1
        },
        data: [{ x: this.selectedRiskValue, y: 0 }],
        color: {
          linearGradient: [0, 0, 350, 0],
          stops: [
            [0, 'rgb(8 170 23)'],
            [0.5, 'rgb(255 255 0)'],
            [1, 'rgb(248 15 11)']
          ]
        }
      }],
      xAxis: {
        type: 'category',
        categories: arr,
        max: 19,
        min: 0,
        lineColor: {
          linearGradient: [0, 0, 350, 0],
          stops: [
            [0, 'rgb(8 170 23)'],
            [0.5, 'rgb(255 255 0)'],
            [1, 'rgb(248 15 11)']
          ]
        },
        lineWidth: 12,
        offset: -2,
        labels: {
          distance: '15px',
          formatter: function (): any {
            let self1 = this as any;
            if ((self1.isFirst || self1.isLast)) {
              return self1.value;
            } else {
              return ''
            }



            // // if ((self1.isFirst || self1.isLast) && self1.value !== self.selectedValue) {
            //   if(!self1.isFirst && !self1.isLast && !self1.value.includes(self.selectedValue) ) {
            // // if (!self1.isFirst && !self1.isLast) {
            //   return ''
            // } else return self1.value;

          },
          endOnTick: true,
          showLastLabel: true,
          startOnTick: true,
          step:1,
          y: 19,
          style: {
            fontSize: '9px'
          }
        }
      },
      yAxis: {
        visible: false
      }
    }
    let el = this.chartName + this.target;
    setTimeout(() => {
      self.riskChart = Highcharts.chart(el, self.riskChartOptions);
      let index = (this.selectedRiskValue / 2);
      index = parseInt(index as any);
      index = index ? index-1: index;
      this.seriesColor =  this.colors[index];
    }, 2000)
  }

}
