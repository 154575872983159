<ng-container *ngIf="isLoading">
    <div class="container text-center mt-5">
      <i class="fas fa-spinner fa-pulse fa-3x healthy"></i>
    </div>
  </ng-container>
<ng-container *ngIf="!isLoading">
<div class="row mb-2">
    <div class="col-lg-4 col-12 health-col col-md-6">
        <div class="card health-kpi-card chart-card">
            <div class="card-body mb-0 healthIndex" *ngIf="chartData" [ngClass]="target.includes('subasset')? '':'px-5'">
                <div class="card-title health-kpi-header row py-0 my-0 mt-1">
                    <div class="col">Health Index</div>
                    <div class="critical col-1 mt-1" (click)="setSelectedId('expand-'+target+'health');getForecast()"><i
                            class="fa fa-arrow-trend-up float-end"></i></div>
                </div>
                <div class="card-text pe-2">
                    <!-- <h5 class="ps-2">{{chartData?.healthIndex?.indexStatus || 'NA'}}</h5> -->
                    <app-index-chart [target]="target" [chartName]="'health'"
                        [chartData]="chartData?.healthIndex"></app-index-chart>
                </div>
                <div class="card-title health-kpi-header row py-0 my-0 mt-1">
                    <div class="col">Criticality Index</div>
                    <div class="critical col-1 mt-1" (click)="setSelectedId('expand-'+target+'critical')"><i
                            class="fa fa-arrow-trend-up float-end"></i></div>
                </div>
                <div class="card-text pe-2">
                    <!-- <h5 class="ps-2">{{chartData?.crticalIndex?.indexStatus || 'NA'}}</h5> -->
                    <app-index-chart [target]="target" [chartName]="'critical'"
                        [chartData]="chartData?.crticalIndex"></app-index-chart>
                </div>
                <div class="card-title health-kpi-header row py-0 my-0 mt-1">
                    <div class="col">Risk Index</div>
                    <div class="critical col-1 mt-1" (click)="setSelectedId('expand-'+target+'risk')"><i
                            class="fa fa-arrow-trend-up float-end"></i></div>
                </div>
                <div class="card-text pe-2">
                    <!-- <h5 class="ps-2">{{chartData?.riskIndex?.indexStatus || 'NA'}}</h5> -->
                    <app-index-chart [target]="target" [chartName]="'risk'"
                        [chartData]="chartData?.riskIndex"></app-index-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-12 health-col col-md-6" *ngIf="target=='assets'">
        <div class="card health-kpi-card chart-card">
            <div class="card-body px-3">
                <div class="card-title health-kpi-header" style="height:10%">Subasset Risk Matrix</div>
                <div class="card-text" style="height:96%">
                    <app-subasset-matrix [subAssetRiskMatrix]="subAssetRiskMatrix" (subAssetSelection)="subAssetSelection.emit($event)"></app-subasset-matrix>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-12 health-col col-md-6">
        <div class="card health-kpi-card chart-card">
            <div class="card-body">
                <div class="card-title health-kpi-header row">
                    <div class="col">Maintenance Index</div>
                    <div class="critical col-1" (click)="setSelectedId('expand-'+target+'maintenance');scroll()"><i
                            class="fa fa-arrow-trend-up float-end"></i></div>
                </div>
                <div class="card-text pe-2" style="min-height: 40px;">
                    <app-index-chart [target]="target" [chartName]="'maintenance'"
                        [chartData]="maintenanceIndex.maintenanceIndex" *ngIf="maintenanceIndex"></app-index-chart>
                </div>
                <div class="card-text mt-3" *ngIf="maintenanceIndex">
                    <div class="row">
                        <div class="col-lg-6 col-7 maintenance-label">Last Maintenance </div>
                        <div  style="display:inline !important" *ngIf="!maintenanceIndex.lastMaintenance" class="col-lg-6 col-5 maintenance-value px-0" >   : Not Available </div>
                        <div class="col-lg-6 col-5 maintenance-value px-0" *ngIf="maintenanceIndex.lastMaintenance">:
                            {{(lastMaintenanceDate
                            || maintenanceIndex.lastMaintenance) + 'Z' | date:
                            'dd/MM/yyy': offsetDate}}</div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-6 col-7 maintenance-label">Maintenance Schedule</div>
                        <div style="display:inline !important" *ngIf="!maintenanceIndex.lastMaintenance" class="col-lg-6 col-5 maintenance-value px-0" >  : Not Available </div>
                        <div class="col-lg-6 col-5 maintenance-value px-0" *ngIf="maintenanceIndex.maintenanceSchedule">:
                            {{(maintenanceSchedule? (maintenanceSchedule ) : maintenanceIndex.maintenanceSchedule) }} months</div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-lg-6 col-7 maintenance-label pe-0">Suggested Next Maintenance</div>
                        <div class="col-lg-6 col-5 maintenance-value px-0" *ngIf="maintenanceIndex.sugestedNextMaintenance">: {{maintenanceIndex.sugestedNextMaintenance + 'Z' |
                            date:
                            'dd/MM/yyy': offsetDate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <span *ngIf="savedDateMsg.length" [ngClass]="savedDateError?'critical':'healthy'">{{savedDateMsg}}</span>
                        </div>
                    </div>
                    <hr [ngClass]="target=='assets'?'mt-lg-4 mt-4':'mt-lg-3 mt-2'">
                    <div class="row">
                        <div class="col-12">
                            <div class="health-kpi-header">Remaining Useful Life</div>
                            <h5 style="color:var(--card-label-text)">{{maintenanceIndex.remainingLife}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-12 health-col col-md-6" *ngIf="target.includes('subassets')">
        <div class="card health-kpi-card chart-card">
            <div class="card-body">
                <div class="card-title health-kpi-header row mb-5" [ngClass]="contactWear?.isApplicable ?'pb-5' : ''">
                    <div class="col">Contact Wear</div>
                    <div class="critical col-1" *ngIf="contactWear?.isApplicable" (click)="setSelectedId('expand-'+target+'contact')"><i
                            class="fa fa-arrow-trend-up float-end"></i></div>
                </div>
                <div class="ps-4 contact-card" *ngIf="contactWear && !contactWear.isApplicable">Applicable only for ACBs and VCBs</div>
                <!-- <h5 class="mt-5 ps-2">{{contactWear?.indexStatus || 'NA'}}</h5> -->
                <div class="card-text pe-2" *ngIf="contactWear" [ngClass]="!(contactWear?.isApplicable)?'disable-btn':''">
                    <app-index-chart [target]="target" [chartName]="'contact'"
                        [chartData]="contactWear"></app-index-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12 health-col">
        <div class="card health-kpi-card">
            <div class="card-body py-2 px-4" style="position: relative;">
                <!-- Tabs navs -->
                <ul class="nav nav-tabs mb-3" id="'kpi'+{{index}}" role="tablist">
                    <li class="nav-item pe-4 firstTab" role="presentation">
                        <a class="nav-link active p-1" id="kpi-tab-1" data-mdb-toggle="tab" href="#kpi-tabs-1-{{index}}"
                            role="tab" aria-controls="kpi-tabs-1" aria-selected="true"
                            (click)="selectedTab='observation';openMeasuredCriteria=false;openCriteria=false;resetOptions()">Last Observed condition</a>
                    </li>
                    <li class="nav-item px-4 secondTab" role="presentation">
                        <a class="nav-link p-1" id="kpi-tab-2" data-mdb-toggle="tab" href="#kpi-tabs-2-{{index}}"
                            role="tab" aria-controls="kpi-tabs-2" aria-selected="false"
                            (click)="selectedTab='measured';openCriteria=false;openMeasuredCriteria=false;resetOptions()">Measured Condition</a>
                    </li>
                    <li class="col py-2" *ngIf="selectedTab=='observation'">
                        <!-- <i class="fa fa-cog float-end" role="button" style="font-size: 16px;"
                            (click)="openCriteria=true;openMeasuredCriteria=false;resetOptions()"></i> -->
                        <app-icon class="float-end" title="settings" iconFor="gear" action="layout" (clickEvent)="openCriteria=true;openMeasuredCriteria=false;resetOptions()"></app-icon>
                    </li>
                    <li class="col py-2" *ngIf="selectedTab=='measured'">
                        <!-- <i class="fa fa-cog float-end" role="button" style="font-size: 16px;"
                            (click)="openMeasuredCriteria=true;openCriteria=false;resetOptions()"></i> -->
                        <app-icon class="float-end" title="settings" iconFor="gear" action="layout" (clickEvent)="openMeasuredCriteria=true;openCriteria=false;resetOptions()"></app-icon>
                    </li>
                </ul>
                <!-- Tabs navs -->

                <!-- Tabs content -->
                <div class="tab-content" id="kpi-content+{{index}}">
                    <div class="tab-pane fade show active table-parent" id="kpi-tabs-1-{{index}}" role="tabpanel"
                        aria-labelledby="kpi-tab-1">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Conditions</th>
                                    <th scope="col">Criteria</th>
                                    <th scope="col">Observed By</th>
                                    <th scope="col">Updated On</th>
                                </tr>
                            </thead>
                            <tbody style="color: var(--inner-table-text)">
                                <tr *ngFor="let item of observedCondition">
                                    <th>{{item.condition}}</th>
                                    <td>{{item.criteria}}</td>
                                    <td>{{item.modifiedBy}}</td>
                                    <td>{{item.modifiedOn + 'Z' | date:
                                        'dd/MM/yyy h:mm a': offsetDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="kpi-tabs-2-{{index}}" role="tabpanel" aria-labelledby="kpi-tab-2">
                        <div class="tab-pane fade show active table-parent" id="kpi-tabs-1" role="tabpanel"
                            aria-labelledby="kpi-tab-1">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Conditions</th>
                                        <th scope="col">Criteria</th>
                                        <th scope="col">Observed By</th>
                                        <th scope="col">Updated On</th>
                                    </tr>
                                </thead>
                                <tbody [ngClass]="target" style="color: var(--inner-table-text)">
                                    <tr *ngFor="let item of measuredCondition">
                                        <th>{{item.condition}}</th>
                                        <td>{{item.criteria}}</td>
                                        <td>{{item.modifiedBy}}</td>
                                        <td>{{item.modifiedOn + 'Z' | date:
                                            'dd/MM/yyy h:mm a': offsetDate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Tabs content -->

                <!-- last observed pop up -->
                <div class="condition-criteria-container position-absolute" *ngIf="openCriteria" id="for-observed-condition">
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body">
                            <div class="card-title health-kpi-header row">
                                <div class="col" style="font-size: 15px;">Conditions & Criteria</div>
                                <div class="col-1"><i class="fa fa-times float-end" style="font-size: 17px;"
                                        (click)="openCriteria=false"></i></div>
                            </div>
                            <div class="card-text">
                                <div class="row my-2" *ngFor="let elelment of [1,2,3,4,5];let i=index">
                                    <div class="col-6">
                                        <div class="select" *ngIf="observedConditionList">
                                            <select name="conditions" id="conditions+{{i}}" class="form-control select-input pe-4"
                                                (change)="onConditionChange($event, i)" role="button"
                                                [(ngModel)]="selectedObservedList[i].condition">
                                                <option [value]='{ condition: {}, criteria: {} }' style="color:gray" disabled selected>Select Condition</option>
                                                <option [ngValue]="condition" *ngFor="let condition of observedConditionList"
                                                    [title]="condition.observedCondition" [disabled]="condition.disabled">
                                                    {{ (condition.observedCondition.length>20)? (condition.observedCondition |
                                                    slice:0:20)+'..':(condition.observedCondition)}}
                                                </option>
                                            </select>
                                            <i class="fa fa-angle-down"></i>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="select">
                                            <select name="criteria" id="criteria+{{i}}" class="form-control select-input pe-4"
                                                role="button" [(ngModel)]="selectedObservedList[i].criteria" (change)="onCriteriaChange($event, i)">
                                                <option [value]='{}' style="color:gray"  disabled selected>Select Criteria</option>
                                                <option [ngValue]="item" *ngFor="let item of allObservedCriteria[i]"
                                                    [title]="item.observedCondition">
                                                    {{ (item.observedCondition.length>20)? (item.observedCondition |
                                                    slice:0:20)+'..':(item.observedCondition)}}
                                                </option>
                                            </select>
                                            <i class="fa fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button (click)="submitConditionsCriteria()" class="btn-primary"
                                            style="width: fit-content;" [disabled]="disabled">Submit</button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 text-center">
                                        <div *ngIf="observedConditonMessage" [ngClass]="observedConditonError?'critical':'healthy'">{{observedConditonMessage}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- measured condition -->
                <div class="condition-criteria-container position-absolute" *ngIf="openMeasuredCriteria" id="for-measured-condition">
                    <div class="card health-kpi-card chart-card">
                        <div class="card-body">
                            <div class="card-title health-kpi-header row">
                                <div class="col" style="font-size: 15px;">Conditions & Criteria</div>
                                <div class="col-1"><i class="fa fa-times float-end" style="font-size: 17px;"
                                        (click)="openMeasuredCriteria=false"></i></div>
                            </div>
                            <div class="card-text">
                                <div class="row my-2" *ngFor="let elelment of [1,2,3,4,5];let i=index">
                                    <div class="col-6">
                                        <div class="select">
                                            <select name="conditions" id="conditions+{{i}}" class="form-control select-input pe-4"
                                                (change)="onMeasuredConditionChange($event, i)" role="button"
                                                [(ngModel)]="selectedMeasuredList[i].condition">
                                                <option [value]='{ condition: {}, criteria: {} }' style="color:gray"  disabled selected>Select Condition</option>
                                                <option [ngValue]="condition" *ngFor="let condition of measuredConditionList"
                                                    [title]="condition.measuredCondition" [disabled]="condition.disabled">
                                                    {{ (condition.measuredCondition.length>20)? (condition.measuredCondition |
                                                    slice:0:20)+'..':(condition.measuredCondition)}}
                                                </option>
                                            </select>
                                            <i class="fa fa-angle-down"></i>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="select">
                                            <select name="criteria" id="criteria+{{i}}" class="form-control select-input pe-4" *ngIf="!selectedMeasuredList[i].isNumeric"
                                                role="button" [(ngModel)]="selectedMeasuredList[i].criteria" (change)="onMeasuredCriteriaChange($event, i)">
                                                <option [value]='{}' style="color:gray"  disabled selected>Select Criteria</option>
                                                <option [ngValue]="item" *ngFor="let item of allMeasuredCriteria[i]"
                                                    [title]="item.measuredCondition">
                                                    {{ (item.measuredCondition.length>20)? (item.measuredCondition |
                                                    slice:0:20)+'..':(item.measuredCondition)}}
                                                </option>
                                            </select>
                                            <i class="fa fa-angle-down" *ngIf="!selectedMeasuredList[i].isNumeric"></i>
                                            <input type="number" max="99999" class="w-100" id="" *ngIf="selectedMeasuredList[i].isNumeric" (change)="onMeasuredCriteriaChange($event, i, 'numeric')">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button (click)="submitMeasuredConditionsCriteria()" class="btn-primary"
                                            style="width: fit-content;" [disabled]="disabled">Submit</button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 text-center">
                                        <div *ngIf="measuredConditonMessage" [ngClass]="measuredConditonError?'critical':'healthy'">{{measuredConditonMessage}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12 health-col">
        <div class="card health-kpi-card">
            <div class="card-body">
                <div class="card-title health-kpi-header">Recommendation</div>
                <div class="card-text recommendation">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col" class="col-9">Recommendation</th>
                                <th scope="col" class="col-3">Updated On</th>
                            </tr>
                        </thead>
                        <tbody [ngClass]="target+'_recommendation'" style="color: var(--inner-table-text)">
                            <tr *ngFor="let recommendation of recommendations">
                                <td>{{recommendation.actionDescription}}</td>
                                <td>{{recommendation.modifiedOn + 'Z' | date:
                                    'dd/MM/yyy &nbsp; h:mm:ss': offsetDate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="expanded" class="position-absolute expanded-chart">
    <div class="row">
        <div class="col">
            <i class="fa fa-times float-end" (click)="expanded=false" role="button" style="font-size: 18px;"></i>
        </div>
    </div>
    <div class="row">
        <ng-container>
            <div class="col-12" *ngIf="selectedId.includes('health') && chartData && chartData.healthIndex && forecastData">
                <app-linear-gradient-chart [target]="selectedId" [data]="chartData?.healthIndex" [forecastData]="forecastData" [params]="{assetId: data?.assetId, feeder: data?.feeder, name: 'healthIndex'}" [isHealthIndex]="true"></app-linear-gradient-chart>
            </div>
            <div class="col-12" *ngIf="selectedId.includes('risk') && chartData && chartData.riskIndex">
                <app-linear-gradient-chart [target]="selectedId" [data]="chartData.riskIndex" [params]="{assetId: data?.assetId, feeder: data?.feeder, name: 'riskIndex'}"></app-linear-gradient-chart>
            </div>
            <div class="col-12" *ngIf="selectedId.includes('critical') && chartData && chartData.crticalIndex">
                <app-linear-gradient-chart [target]="selectedId" [data]="chartData.crticalIndex" [params]="{assetId: data?.assetId, feeder: data?.feeder, name: 'criticalIndex'}"></app-linear-gradient-chart>
            </div>
            <div class="col-12" *ngIf="selectedId.includes('maintenance') && maintenanceIndex?.maintenanceIndex">
                <app-linear-gradient-chart [target]="selectedId" [data]="maintenanceIndex.maintenanceIndex" [params]="{assetId: data?.assetId, feeder: data?.feeder, name: 'maintenanceIndex'}"></app-linear-gradient-chart>
            </div>
            <div class="col-12" *ngIf="selectedId.includes('contact') && contactWear">
                <app-linear-gradient-chart [target]="selectedId" [data]="contactWear" [params]="{assetId: data?.assetId, feeder: data?.feeder, name: 'contactWear'}"></app-linear-gradient-chart>
            </div>
        </ng-container>
    </div>

</div>
</ng-container>