import { IfStmt, ReturnStatement } from '@angular/compiler';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { SiteManagementService } from 'src/app/_services/site-management.service';
import { EnvironmentData, General, ObsolenscenceProfileList, SiteMaintenanceList, SuggestionsAssetList } from '../../_models/overview/site-maintenance-list.type';

@Component({
    selector: 'app-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit {
    obsolenscenceItems = [
        {
            id: 1,
            name: 'All',
            code: 'all'
        },
        {
            id: 2,
            name: 'UnderActiveLifecycle',
            code: 'UnderActiveLifecycle'
        },
        {
            id: 3,
            name: 'UnderNotice',
            code: 'UnderNotice'
        },
        {
            id: 4,
            name: 'Obsoleted',
            code: 'Obsoleted'
        },
    ];
    suggestionItems = [
        {
            id: 1,
            name: 'All',
            code: 'all'
        },
        {
            id: 2,
            name: 'Modernize of Assets',
            code: 'ModernizeofAssets'
        },
        {
            id: 3,
            name: 'Renewal of AMC',
            code: 'RenewalofAMC'

        },
        {
            id: 4,
            name: 'Request for new AMC',
            code: 'RequestfornewAMC'
        },
    ];
    maintenanceItems = [
        {
            id: 1,
            name: 'All',
            code: 'all'
        },
        {
            id: 2,
            name: 'Done',
            code: 'done'
        },
        {
            id: 3,
            name: 'Due',
            code: 'due'
        },
        {
            id: 4,
            name: 'Over Due',
            code: 'overdue'
        },
    ];
    private _searchText!: string;
    searchCode: string = 'all';
    filteredSuggestions!: any;
    private subscription: Subscription = new Subscription();


    get searchText(): string {
        return this._searchText;
    }
    set searchText(value: string) {
        this._searchText = value;
    }
    isMobile: boolean = false;
    isDesktopDevice: boolean = true;
    searchOpen: Boolean = false;
    innerHeight!: number;
    constructor(private siteManagementService: SiteManagementService, private deviceService: DeviceDetectorService,) { }

    @Input('suggestionsAssetList') suggestionsAssetList!: SuggestionsAssetList[];
    @Input('maintenanceSummaryList') maintenanceSummaryList!: SiteMaintenanceList[];
    @Input('obsolenscenceProfileList') obsolenscenceProfileList!: ObsolenscenceProfileList[];
    @Input('environmentData') environmentData !: EnvironmentData;
    @Input('general') general !: General;
    @Input('popupData') popupData!: string;
    @Input() siteId!: string;

    ngOnInit(): void {
        this.epicFunction();
        this.innerHeight = window.innerHeight;
    }

    epicFunction() {
        this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }
    download() {
        if (this.popupData == 'Maintenance Summary') {
            let filters = {
                status: this.searchCode,
                search: this.searchText
            }
            this.subscription.add(
                this.siteManagementService.downloadSummaryData(this.siteId, filters).subscribe({
                    next: (data) => {
                        if (!this.isMobile && data.type.includes('application/pdf')) {
                            const fileURL = window.URL.createObjectURL(data);
                            window.open(fileURL, '_blank');
                        }
                        else {
                            const fileURL = window.URL.createObjectURL(data)
                            const anchor = document.createElement("a");
                            anchor.href = fileURL;
                            anchor.download = fileURL.substring(fileURL.lastIndexOf('/') + 1);
                            document.body.appendChild(anchor);
                            anchor.click();
                            document.body.removeChild(anchor);
                        }
                    },
                    error: (e) => {
                        console.log(e);
                    }
                })
            );
        } else if (this.popupData == 'Obsolescence Profile') {
            let filters = {
                status: this.searchCode,
                search: this.searchText
            }
            this.subscription.add(
                this.siteManagementService.downloadObsolensceneDetails(this.siteId, filters).subscribe({
                    next: (data) => {
                        const fileURL = URL.createObjectURL(data);
                        window.open(fileURL, '_blank');
                    },
                    error: (e) => {
                        console.log(e);
                    }
                })
            );
        }
    }

    printData(id: string) {

        // window.onbeforeprint = (event) => {
        //     console.log("Before print");
        // };

        let el = document.getElementById('forPrint' + id) as HTMLElement;
        let el2 = document.getElementById('print') as HTMLElement;
        let el3 = document.getElementById('isPrinting') as HTMLElement;
        let el4 = document.getElementById('table-body-' + id) as any;
        let backdrop = document.getElementsByClassName('modal-backdrop') as HTMLCollectionOf<Element>;

        //add appropriate classes for print document style
        el4.classList.add('while-printing');
        let printContents = el.innerHTML;
        el2.innerHTML = printContents;
        el2.classList.remove('hide');
        el3.classList.remove('show');
        el2.classList.add('show');
        el3.classList.add('hide');
        document.body.classList.add('overflow');
        backdrop[0].classList.remove('show');

        window.print();

        window.addEventListener("afterprint", (event) => {
            el4.classList.remove('while-printing');
            el2.classList.add('hide');
            el2.innerHTML = '';
            el3.classList.remove('hide');
            document.body.classList.remove('overflow');
            document.body.classList.add('no-overflow');
            backdrop[0].classList.add('show');
            console.log("After print");
        });
        setTimeout(() => {
            el4.classList.remove('while-printing');
            el2.classList.add('hide');
            el2.innerHTML = '';
            el3.classList.remove('hide');
            document.body.classList.remove('overflow');
            document.body.classList.add('no-overflow');
            backdrop[0].classList.add('show');
        }, 500);

    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
