import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { graphData } from 'src/app/_models/dlb/reportsDocument.type';

@Component({
    selector: 'app-stacked-bar-chart',
    templateUrl: './stacked-bar-chart.component.html',
    styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements OnInit {
    @Input() reportData: any;
    @Input() target: string = '';
    myOptions: any;
    series: any = [];
    isOneCat: boolean = false;
    @ViewChild('charts') public chartEl!: ElementRef;
    //colors: string[] = ['pink', 'rgb(124,181,236)', 'rgb(238 215 125)'];
    colors = {
        Critical: 'rgb(234, 47, 64)', High: 'rgb(248, 152, 67)', Low: 'rgb(255 193 7)',
        Warning: 'rgb(168 73 189)', Notification: 'rgb(52 122 242)', Current: 'pink', Past: 'rgb(124,181,236)',
        Closed: 'rgb(82 218 79)', Approved: 'rgb(52 122 242)', Open: 'rgb(124,181,236)', Cancelled: 'rgb(234, 47, 64)'
    }
    constructor() { }

    ngOnInit(): void {
        type ObjectKey = keyof typeof this.colors;
        this.isOneCat = this.reportData?.data?.length === 1;
        this.getData();
        //console.log(this.reportData, this.reportData?.data)
        this.reportData?.data.forEach((item: graphData, index: number) => {
            const myVar = item.name as ObjectKey;
            this.series.push({
                name: item.name,
                data: item.count,
                value: item.value,
                color: this.colors[myVar],
                maxPointWidth: 50,
            });
        });

    }

    ngOnchanges(changes: SimpleChanges) {
        // if (changes['reportData']&& !changes['reportData'].firstChange) {
        //   this.getData();
        //   console.log(this.reportData, this.reportData?.data)

        // }
    }

    getData() {
        const self = this;
        this.myOptions = {
            lang: {
                noData: 'Nichts zu anzeigen'
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            },
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: this.reportData?.labels,
                lineColor: 'black',
                lineWidth: 1,
                showEmpty: false,
                title: {
                    // text: 'AssetName'
                },
            },
            yAxis: {
                min: 0,
                //max: 100,
                lineColor: 'black',
                lineWidth: 1,
                title: {
                    text: ''
                },
                showEmpty: false,
                stackLabels: {
                    enabled: true,
                    formatter: function (): any {
                        let self1 = this as any;
                        let total = 0;
                        self.series.forEach(element => {
                            if (element.value) {
                                total = total + element.value[self1.x];
                            }
                        });
                        return total ? total : self1.total ? self1.total : '';
                    },
                    style: {
                        fontWeight: 'bold',
                        //color: 'gray'
                    }
                }
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                x: 0,
                y: 100,
                symbolRadius: 0
            },
            exporting: { enabled: false },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        // enabled: !this.isOneCat,
                        // formatter: function (): any {
                        //     if ((this as any).y) {
                        //         return (this as any).y;
                        //     }
                        // }
                    }
                },
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: !this.isOneCat,
                        formatter: function (): any {
                            const self2 = this as any;
                            const category = self.series?.findIndex(item => item.color === self2.color);
                            if (self2.y === self2.total) {
                                return '';
                            } else if (self.series[category]?.value) {
                                // const index = self.series[category]?.data?.findIndex(item => item === self2.y);
                                const index = self.reportData?.labels?.findIndex(item => item == self2.x);
                                if (self.series[category]?.value[index]) {
                                    return self.series[category]?.value[index]
                                } else if (self.series[category]?.data[index]) {
                                    return self.series[category]?.data[index];
                                }
                            } else {
                                return self2.y;
                            }
                        },
                        color: 'black',
                        style: {
                            //textShadow: '0 0 3px black'
                        }
                    },
                    borderWidth: 0
                }
            },
            series: this.series
        };

        let el = this.target;
        setTimeout(() => {
            this.createChart(el, this.myOptions);
        }, 1000)

    }


    createChart(id: string, cfg: any) {
        Highcharts.chart(id, cfg);
    }
}
