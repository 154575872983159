import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ConditionMonitoringCard } from '../_models/conditionmonitoring-cards.type';
import { ObservedCondition, Index, IndexData, MeasuredCondition, MaintenanceIndex, Observation, SubAssetRisk, Recommendation, PresentFuture } from '../_models/health.type';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  baseUrl = environment.apiURL;


  constructor(private http: HttpClient) { }

  //get Asset Status
  getAssetStatus(): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/assetStatus`;
    return this.http.get(url);
  }

  //get SubAsset Status
  getSubAssetStatus(): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/subAssetStatus`;
    return this.http.get(url);
  }

  //get Asset Categories
  getAssetCategories(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/assetCategories`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }

  //get SubAsset Categories
  getSubAssetCategories(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/asset/componentTypes`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }
  //get Asset make details
  getAssetmakeDetails(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/assetMakeDetails`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }

  //get Design types
  getDesignTypes(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/designTypes`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }

  //get System voltage
  getVoltageLevel(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/voltageLevels`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }

  //get Balance life
  getBalanceLife(): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/balanceLife`;
    return this.http.get(url);
  }
  //get Component Types
  getComponentTypes(siteId = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/asset/componentTypes`;
    if(siteId) url += `?siteId=${siteId}`;
    return this.http.get(url);
  }

  getFeederType(id: number): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/SubAsset/assetByFeeder/${id}`;
    return this.http.get(url);
  }

  //condition monitoring cards
  getconditionMonitoringCards(assetId: number, feederNo: string, common: boolean): Observable<Array<ConditionMonitoringCard>> {
    const url = `${this.baseUrl}/conditionmonitoring/ConditionMonitoring/${assetId}/${feederNo}/${common}`;
    return this.http.get<Array<ConditionMonitoringCard>>(url).pipe(catchError(error => of(error)));
  }

  getRecommendedSpares(assetId: number, feederNo: string): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/SubAsset/recommendedSpares/subassetIno/${assetId}/${feederNo}`;
    return this.http.get(url);
  }


  //Health KPI 

  getHealthCriticalRiskIndex(assetId: string, subasset?: string): Observable<IndexData> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/HRCCard?assetId=${assetId}&feederNo=${subasset}`;
    return this.http.get<IndexData>(url);
  }

  getHealthMeasuredData(assetId: string, subasset?: string): Observable<Observation[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/measuredConditionTable?assetId=${assetId}&feederNo=${subasset}`;
    return this.http.get<Observation[]>(url);
  }

  getHealthObservationData(assetId: string, subasset?: string): Observable<Observation[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/observationTable?assetId=${assetId}&feederNo=${subasset}`;
    return this.http.get<Observation[]>(url);
  }

  getMaintenanceIndexData(assetId: string, subasset?: string): Observable<MaintenanceIndex> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/maintenanceIndex?assetId=${assetId}&feederNo=${subasset}`;
    return this.http.get<MaintenanceIndex>(url);
  }

  getObservationList(subasset?: string): Observable<ObservedCondition[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/observationList?feederNo=${subasset||''}`;
    return this.http.get<ObservedCondition[]>(url);
  }

  getCriteriaList(id: number): Observable<ObservedCondition[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/observationCriteriaList?conditionId=${id}`;
    return this.http.get<ObservedCondition[]>(url);
  }

  getMeasuredList(subasset?: string): Observable<MeasuredCondition[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/measuredList?feederNo=${subasset||''}`;
    return this.http.get<MeasuredCondition[]>(url);
  }

  getMeasuredCriteriaList(id: number): Observable<MeasuredCondition[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/measuredCriteriaList?conditionId=${id}`;
    return this.http.get<MeasuredCondition[]>(url);
  }

  getHealthContactWear(assetId: string, subasset?: string): Observable<Index> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/contactWear?assetId=${assetId}&feederNo=${subasset}`;
    return this.http.get<Index>(url);
  }

  getSubAssetRiskMatrix(assetId: string): Observable<SubAssetRisk[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/subassetRiskMatrixIndexSummary?assetId=${assetId}`;
    return this.http.get<SubAssetRisk[]>(url);
  }

  getSubAssetRiskMatrixForUser(): Observable<any> {
    // const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/subassetRiskMatrixIndexSummaryByUser`;
    const url = `${this.baseUrl}/maintenanceworkflow/Dashboard/assetRiskProfile`;
    return this.http.get<any>(url);
  }

  getSubAssetRiskMatrixForSite(siteId: string): Observable<SubAssetRisk[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/subassetRiskMatrixIndexSummaryBySiteId?siteId=${siteId}`;
    return this.http.get<SubAssetRisk[]>(url);
  }

  getHealthRecommendation(assetId: string, subasset?: string): Observable<Recommendation[]> {
    let url = `${this.baseUrl}/assetmanagement/HealthKpiCard/recommendations?assetId=${assetId}`;
    if(subasset){
      url += `&feederNo=${subasset}`;
    }
    return this.http.get<Recommendation[]>(url);
  }

  saveObservedConditions(siteId: string, data: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/upsert/observedConditions/${siteId}`;
    return this.http.post<any>(url, data);
  }

  saveMeasuredConditions(siteId: string, data: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/upsert/measuredConditions/${siteId}`;
    return this.http.post<any>(url, data);
  }

  getChartData(assetId: string, feeder: any, duration: number): Observable<any> {
    let data = {
      "assetId": assetId,
      "feederNumber": feeder || '',
      "duration": duration
  }
    const url = `${this.baseUrl}/assetmanagement/HealthKpiGraph/healthKpiHistoryGraph`;
    return this.http.post<any>(url, data);
  }

  getStaticDynamicChartData(requestData: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiGraph/healthKpiHistoryGraphNew/`;
    return this.http.post<any>(url, requestData);
  }

//   getForecastChartData(payload:any, assetId: string, feeder: any): Observable<any> {
//     const url = `${this.baseUrl}/assetmanagement/HealthKpiGraph/healthForecast/${assetId}?feederNo=${feeder}`;
//     return this.http.post<any>(url, payload);
//   }

    getForecastChartData(payload:any, assetId: string, feeder: any): Observable<any> {
        const url = `${this.baseUrl}/assetmanagement/HealthKpiGraph/healthForecastPred/${assetId}?feederNo=${feeder}`;
        return this.http.post<any>(url, payload);
    }

  getChartDurations(): Observable<any[]> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiGraph/healthKpiDropdown`;
    return this.http.get<any>(url);
  }

  saveLastMaintenanceDate(assetId: string, feeder: any, date: string): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/lastMaintenanceDate?assetId=${assetId}&lastMaintenanceDate=${date}&feederNo=${feeder}`;
    return this.http.put<any>(url, {});
  }

  saveMaintenanceScheduleDate(assetId: string, feeder: any, date: number): Observable<any> {
    // let suffix = date > 1 ? ' months': ' month';
    const url = `${this.baseUrl}/assetmanagement/HealthKpiCard/maintenanceSchedule?assetId=${assetId}&maintenanceSchedule=${date}&feederNo=${feeder}`;
    return this.http.put<any>(url, {});
  }

  getAlgoStatus(id:string, siteId? : number, assetId? : number, feeder? : string) {
    let url = `${this.baseUrl}/assetmanagement/HealthKpiSettings/getAlgorithmStatus/${id}`;
    if(siteId){
        url += `?siteId=${siteId}`;
        if(assetId) url += `&assetId=${assetId}`;
        if(feeder) url += `&feeder=${feeder}`;
    }
    return this.http.get<any>(url);
  }

  getHealthKpiCalculatePresent(payload: any): Observable<PresentFuture> {
    const url = `${this.baseUrl}/algorithmorchestration/HealthKpiCalculatePresent/healthKpiCalculate`;
    return this.http.post<PresentFuture>(url, payload);
  }

  downloadCalculated(payload:any): Observable<any> {
    const url = `${this.baseUrl}/algorithmorchestration/HealthKpiCalculatePresent/healthKpiDownload`;
    return this.http.post<any>(url, payload, { responseType: 'blob' as 'json'});
  }

  sendFile(files: any): Observable<any> {
    // const url = `${this.baseUrl}/report/Report/mockReport`;

    // const url = `${this.baseUrl}/contentmanagement/Logbook/version?url=https://ipredictstoragedev.blob.core.windows.net/f23f6f9f28aa4403895ba0746e1648f2-private/1af73ca5c542461ca7b268347c10e19e.pdf`
    // return this.http.post<any>(url, file);
    var formData = new FormData();
    formData.append('file', files);
    const url  = `${this.baseUrl}/report/Report/upload`;
    // console.log(formData.getAll('file'));
    return this.http.post<any>(url, formData);
  }

  downloadFile(fileUrl: string): Observable<any> {
    const url  = `${this.baseUrl}/contentmanagement/document?link=${fileUrl}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json'});
  }
}