import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldBasedFilter'
})
export class FieldBasedFilterPipe implements PipeTransform {

  transform(value: any, q?: any, field?: string): any {
    if (!value) return null;
    if (!q) return value;
    q = q.toLowerCase();
    if (field) {
      return value.filter((item: any) => {
        if(item[field])
        return item[field].toLowerCase().includes(q);
        return false;
      });
    }
    return value.filter((item: any) => {
      return JSON.stringify(item).toLowerCase().includes(q);
    });
  }

}
