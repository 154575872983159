import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Country, AccessControl, CanUse } from '../_models/register.type';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private baseUrl: string = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }

  // getCountries() {
  //   const url = `${this.baseUrl}/`;
  //   return this.http.get(url);
  // }

  generateOTP(mobileNumber: number, UUID: string, userId?: number): Observable<AccessControl> {
    let url: any;
    if(typeof userId !== 'undefined'){
      url = `${this.baseUrl}/accesscontrol/Otp/generate?userId=${userId}`;
    }
    else{
      url = `${this.baseUrl}/accesscontrol/Otp/generate`;
    }
    const payload = {
      mobileNumber: mobileNumber,
      traceId: UUID,
      otpValue: ''
    }
    const httpOptions = {
      observe: 'response' as const
    }
    return this.http.post<AccessControl>(url, payload);
  }

  verifyMobileOTP(otp: number, mobileNumber: number, UUID: string): Observable<AccessControl> {
    const url = `${this.baseUrl}/accesscontrol/Otp/verify`;
    const payload = {
      mobileNumber: mobileNumber,
      traceId: UUID,
      otpValue: otp
    }
    return this.http.post<AccessControl>(url, payload);
  }

  checkMailtoUse(email: string): Observable<CanUse> {
    const url = `${this.baseUrl}/accesscontrol/Account/${email}/canuse`;
    return this.http.post<CanUse>(url, {});
  }

  registerAccount(formData: any): Observable<AccessControl> {
    const url = `${this.baseUrl}/accesscontrol/Account`;
    return this.http.post<AccessControl>(url, formData).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    );
  }

  getCountries(): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(`${this.baseUrl}/accesscontrol/Country/all/`);
  }
}
