import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

	@Input() title: string;
	@Input() iconFor: string;
	@Input() imgFor: string;
	@Input() action: string;
	@Input() disabled = false;
	@Output() clickEvent = new EventEmitter<boolean>();

	className: string;

	constructor() { }

	ngOnInit(): void {
		
	}

	getClassName(): string{
		switch (this.iconFor) {
		case 'print':
			return 'fa-print'
			break;
		case 'calendar':
			return 'fa-calendar'
		break;
		case 'download':
			return 'fa-download'
		break;
		case 'envelope':
			return 'fa-envelope'
		default:
			return '';
			break;
		}
	}

	getImgSrc(): string{
		switch (this.imgFor) {
		case 'automation':
			return '../@app/assets/images/automation.svg'
			break;
		case 'calendar':
			return 'fa-calendar'
		break;
		case 'download':
			return 'fa-download'
		break;
		case 'envelope':
			return 'fa-envelope'
		default:
			return '';
			break;
		}
	}

}
