<div id="modal-content-wrapper">
    <header id="modal-header" class="mt-5">
        <h1 id="modal-title">{{modalData.title}}</h1>
    </header>

    <footer id="modal-footer">
        <button  id="modal-cancel-button" (click)="closeModal()">
            {{ modalData.cancelActionText ? modalData.cancelActionText : 'No' }}
        </button>
        <button mat-raised-button id="modal-action-button" [mat-dialog-close]="true">
            {{  modalData.confirmActionText ? modalData.confirmActionText : 'Yes' }}
        </button>
    </footer>
</div>
