import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IPredictService } from 'src/app/_services/i-predict.service';
import { RegisterService } from 'src/app/register/register.service';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public guid!: Guid;
  baseUrl = environment.apiURL;
  reactiveForm!: FormGroup;
  businessTypeList!: any[];
  countryList!: any[];
  isUpdate: boolean = false;
  picUpdate: boolean = false;
  private subscribe: Subscription = new Subscription();
  userId: any;
  userDetails: any;
  createStatus: boolean = false;
  showMsg: string = '';
  showError: boolean = false;
  uuid: string = '';
  showMobileOTP: boolean = false;
  errorGenerateOTP: string = '';
  verifiedMobileOTP: boolean = false;
  errorVerifyOTP: string = '';
  isDisabled: boolean = false;
  showOtptext: boolean = false;
  file: any;
  isLoading: boolean = true;
  errorMessage:boolean = false;
  backUrl! : string;

  constructor(private iPredictService: IPredictService, private registerService: RegisterService, private route: ActivatedRoute, private router: Router, private cd: ChangeDetectorRef) {
    this.route.queryParams.subscribe(param => {
      if (this.userId != param['userId']) {
        this.userId = param['userId'];
      }
      this.backUrl = param['page'];
    })
  }
  @ViewChild('fileInput') el!: ElementRef;
  profilePic: any;
  defaultUrl: any = `${this.baseUrl}/assets/images/default_profile_pic.PNG`;
  imageUrl: any = this.defaultUrl;
  editFile: boolean = true;
  removeUpload: boolean = false;

  ngOnChanges(changes: any): void {
    this.onChanges();
  }

  ngOnInit(): void {
    this.reactiveForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z]+$')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z]+$')]),
      role:new FormControl(''),
      mobNum: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      mobileNumOTP: new FormControl(['']),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
      companyName: new FormControl(''),
      streetAddr: new FormControl(''),
      city: new FormControl('', Validators.pattern('^[a-zA-Z][a-zA-Z\\s]+$')),
      zipCode: new FormControl(''),
      state: new FormControl(''),
      businessType: new FormControl(''),
    });
    this.getCountryList();
    this.getBusinessType();
    this.getUserDetails();
    this.reactiveForm.disable();
  }

  get firstName() {
    return this.reactiveForm.get('firstName')!;
  }

  get lastName() {
    return this.reactiveForm.get('lastName')!;
  }

  get role() {
    return this.reactiveForm.get('role')!;
  }

  get mobNum() {
    return this.reactiveForm.get('mobNum')!;
  }

  get mobileNumOTP() {
    return this.reactiveForm.get('mobileNumberOTP')!;
  }

  get emailId() {
    return this.reactiveForm.get('emailId')!;
  }

  get country() {
    return this.reactiveForm.get('country')!;
  }

  get companyName() {
    return this.reactiveForm.get('companyName')!;
  }

  get streetAddr() {
    return this.reactiveForm.get('streetAddr')!;
  }

  get city() {
    return this.reactiveForm.get('city')!;
  }

  get zipCode() {
    return this.reactiveForm.get('zipCode')!;
  }

  get state() {
    return this.reactiveForm.get('state')!;
  }

  get businessType() {
    return this.reactiveForm.get('businessType')!;
  }

  get reactiveFormControls() {
    return this.reactiveForm.controls;
  }

  changeCountry(e: any) {
    this.country?.setValue(e.id, {
      onlySelf: true,
    });
  }

  changeBusinessType(e: any) {
    this.businessType?.setValue(e, {
      onlySelf: true,
    });
  }

  Cancel() {
    this.getUserDetails();
    this.reactiveForm.patchValue({
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      role:this.userDetails.roleDescription,
      mobNum: this.userDetails.mobileNumber,
      mobileNumOTP: '',
      emailId: this.userDetails.emailAddress,
      country: this.userDetails.country,
      companyName: this.userDetails.companyName,
      streetAddr: this.userDetails.streetAddress,
      city: this.userDetails.city,
      zipCode: this.userDetails.zipCode,
      businessType: this.userDetails.businessType
    });
    this.isUpdate = false;
    this.isDisabled = false;
    this.showOtptext = false;
    this.verifiedMobileOTP = false;
    this.reactiveForm.disable();
  }

  getCountryList() {
    this.subscribe.add(
      this.iPredictService.getCountry().subscribe((country: any) => {
        if (country instanceof HttpErrorResponse) {
          //handle the error here
        } else {
          this.countryList = country;
        }
      })
    )
  }

  getBusinessType() {
    this.subscribe.add(
      this.iPredictService.getBusinessType().subscribe((type: any) => {
        if (type instanceof HttpErrorResponse) {
          //handle the error here
        } else {
          this.businessTypeList = type;
        }
      })
    )
  }

  getUserDetails() {
    this.isLoading = true;
    this.subscribe.add(
      this.iPredictService.getUserProfileDetails(this.userId).subscribe((details: any) => {
        this.isLoading = false;
        if (details instanceof HttpErrorResponse) {
          this.showError = true;
          this.showUIMsg(details.error.message ? details.error.message : 'Failed to get User Details');
        } else {
          this.userDetails = details;
          if (details.profileUrl) {
            this.profilePic = details.profileUrl;
            this.imageUrl = this.profilePic;
          }
          this.reactiveForm.patchValue({
            firstName: details.firstName,
            lastName: details.lastName,
            role: details.roleDescription,
            mobNum: details.mobileNumber,
            emailId: details.emailAddress,
            country: parseInt(details.country),
            companyName: details.companyName,
            streetAddr: details.streetAddress,
            city: details.city,
            zipCode: details.zipcode1,
            state: details.state,
            businessType: details.businessType
          });
        }
      })
    )
  }

  showUIMsg(msg: string, callback?: () => void) {
    this.createStatus = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.showMsg = msg;
    setTimeout(() => {
      this.createStatus = false;
      callback ? callback() : '';
    }, 3000);
  }

  Edit() {
    this.isUpdate = true;
    this.reactiveForm.enable();
    this.emailId.disable();
    this.isDisabled = true;
    this.showOtptext = false;
    this.verifiedMobileOTP = false;
    this.isDisabled = false;
    //this.getUserDetails();
  }

  onChanges(): void {
    // this.reactiveForm.controls['mobNum'].valueChanges.subscribe(change => {
    //   console.log(change); 

    // });  
    this.reactiveForm.valueChanges.subscribe((value) => {
      this.reactiveForm.controls['mobNum'].enable();
    });
  }

  generateMobileOTP() {
    this.showOtptext = true;
    this.uuid = this.uuidv4();
    this.subscribe.add(this.registerService.generateOTP(this.reactiveForm.value.mobNum, this.uuid, this.userId).subscribe((data: any) => {
      if (data.response && data.success) {
        this.showMobileOTP = true;
        this.errorGenerateOTP = '';
        this.showError = false;
        this.showUIMsg('OTP sent to your Mobile');
      } else {
        this.showMobileOTP = false;
        this.errorGenerateOTP = data.message
        this.showError = true;
        this.showUIMsg(data.message);
      }
    }));
  }

  verfiyMobileOTP() {
    this.showError = false;
    this.showUIMsg('Verifying OTP');
    const mobileNumberOTP = this.reactiveForm.value.mobileNumOTP;
    const mobileNumber = this.reactiveForm.value.mobNum;
    this.subscribe.add(this.registerService.verifyMobileOTP(mobileNumberOTP, mobileNumber, this.uuid).subscribe((result: any) => {
      if (result.response && result.success) {
        this.verifiedMobileOTP = true;
        this.showMobileOTP = false;
        //this.errorVerifyOTP = '';
        this.reactiveForm.get('mobNum')?.disable();
      } else {
        this.isDisabled = true;
        this.verifiedMobileOTP = false;
        this.showError = true;
        this.showUIMsg(result.message);
        //this.errorVerifyOTP = result.message;
      }
    }));
  }

  uuidv4() {
    this.guid = Guid.create();
    return this.guid.toJSON().value;
  }

  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    this.file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(this.file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.reactiveForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
      this.picUpdate = true;
    }
  }

  //function to call upload profile pic api
  updateUserProfilePicture() {
    this.errorMessage= false;
    this.iPredictService.updateUserProfilePicture(this.file, this.userId).subscribe((data: any) => {
      if (data instanceof HttpErrorResponse) {       
        this.errorMessage= true;
        this.showMsg= data.error;
        this.picUpdate = false;
      } else {
        this.imageUrl = data;
        this.showUIMsg("Picture added successfully")
        this.picUpdate = false;
        this.getUserDetails();
        this.close();
      }
    })
  }

  // Function to remove uploaded file from preview
  removeUploadedFile(type?: string) {
    this.errorMessage = false;
    this.showMsg = "";
    let newFileList = Array.from(this.el.nativeElement.files);
    if (type && type == 'delete') {
      this.imageUrl = this.defaultUrl;
    }
    else {
      if (this.profilePic) {
        this.imageUrl = this.profilePic;
      }
      else {
        this.imageUrl = this.defaultUrl;
      }
    }
    this.picUpdate = false;
    this.editFile = true;
    this.removeUpload = false;
    this.reactiveForm.patchValue({
      file: [null]
    });
  }

  //function to call delete profile pic api
  deleteProfilePicture() {
    this.iPredictService.deleteUserProfilePicture(this.userId).subscribe((data: any) => {
      if (data == true) {
        this.removeUploadedFile('delete');
        this.close();
        this.getUserDetails();
        this.showUIMsg("Picture removed successfully");
      }
      else {
        this.showUIMsg("some error occured")
      }
    })
  }

  close() {
    let el = document.getElementById('closeBtnClick') as HTMLElement;
    el.click();
  }

  public validate(): void {
    if (this.reactiveForm.invalid) {
      for (const control of Object.keys(this.reactiveForm.controls)) {
        this.reactiveForm.controls[control].markAsTouched();
      }
      this.showError = true;
      this.showUIMsg("Please fill the mandatory details")
      return;
    }
    const userDetailsFormValue = this.reactiveForm.getRawValue();
    const userDetailForm: any = {
      id: this.userId,
      firstName: userDetailsFormValue.firstName,
      lastName: userDetailsFormValue.lastName,
      mobileNumber: userDetailsFormValue.mobNum,
      emailAddress: userDetailsFormValue.emailId,
      country: userDetailsFormValue.country,
      companyName: userDetailsFormValue.companyName,
      streetAddress: userDetailsFormValue.streetAddr,
      city: userDetailsFormValue.city,
      zipcode1: userDetailsFormValue.zipCode,
      state: userDetailsFormValue.state,
      businessType: userDetailsFormValue.businessType,
      profileUrl: (this.imageUrl == this.defaultUrl) ? null : this.profilePic
    }
    this.iPredictService.addUserProfileDetails(userDetailForm).subscribe((val: any) => {
      if (val instanceof HttpErrorResponse || val instanceof TypeError ) {
        this.showError = true;
        this.showUIMsg('Update Failed');
      }
      else {
        if (val) {
          this.showError = false;
          this.showUIMsg("Successfully submitted details");

          this.reactiveForm.reset();
          this.getUserDetails();
          this.reactiveForm.disable();
          this.isUpdate = false;
        } else {
          this.showError = true;
          this.showUIMsg('Unable to submit Form.Please try again');
        }
      }
    });
  }

  exit() {
    if(this.backUrl)
      window.location.href = this.backUrl;
    else this.router.navigate([`/layout/dashboard`]);  
  }
}
