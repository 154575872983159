import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, switchMap, throwError, of, BehaviorSubject } from 'rxjs';
import { AssetsList } from 'src/app/_models/admin-panel/assets-list';
import { EntityMaster, EntityAccessRole, EntityRole } from 'src/app/_models/admin-panel/entity-roles.type';
import { TemplateList } from 'src/app/_models/admin-panel/maintenance-template';
import { RequestAssets } from 'src/app/_models/admin-panel/request-list.type';
import { EnvironmentVariable, General, MapSiteToCustomer, Site, SubStation } from 'src/app/_models/admin-panel/sites-list';
import {
  CurrentUsage, CurrentUsageQuery, SubscriptionQuery, Subscriptions, SubscriptionPlan,
  SubscriptionModule, PlanModule, NewPlan, postSubscribedModules, AllPlanModules
} from 'src/app/_models/admin-panel/subscription.type';
import { UsersList } from 'src/app/_models/admin-panel/users-list.type';
import { AssetType } from 'src/app/_models/asset';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelService {
  private baseUrl: string = environment.apiURL;

  pendingCount = new BehaviorSubject({
    customerCount: 0,
    siteCount: 0,
    assetCount: 0,
    RequestCount: 0
  });
  // pendingCount = this.pendingCountVal.value;

  constructor(private http: HttpClient) { }

  // updatePendingCount(val: number) {
  //   this.pendingCountVal.next(val)
  // }

  //asset-management
  // By Site DD Site
  getAssetList(siteId: number): Observable<any> {
    return this.http.get<AssetsList>(`${this.baseUrl}/assetmanagement/Asset/assetBySiteId/${siteId}`);
  }

  // By Substation from Substation DD
  getAssetListBySubStationId(subStationId: number): Observable<Array<AssetsList>> {
    return this.http.get<Array<AssetsList>>(`${this.baseUrl}/assetmanagement/Asset/site/${subStationId}`).pipe(catchError(error => of(error)));
  }

  /* By Cutomer Id Cusotmer DD */
  getAssetListByCustomerId(customerId: number, filterId: number): Observable<any> {
    return this.http.get<AssetsList>(`${this.baseUrl}/assetmanagement/Asset/sitesByCustomer/${customerId}/${filterId}/true`);
  }

  getAssetListByAllCustomer(pageNo: number, pageSize: number, searchString = null): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/sitesByCustomer/selectedAll/${pageNo}/${pageSize}`;
    if(searchString)  url += `?searchString=${searchString}`
    return this.http.get<any>(url);
  }

  getAssetsByMappingFilter(filterId: number, subStationId: number): Observable<Array<AssetsList>> {
    return this.http.get<Array<AssetsList>>(`${this.baseUrl}/assetmanagement/Asset/site/${filterId}/${subStationId}`).pipe(catchError(error => of(error)));
  }

  // Asset Mapping and UnMapping
  assetMapping(mapping: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/assetMapping`;
    return this.http.post<Site>(url, mapping).pipe(catchError(error => of(error)));
  }

  //asset-management-sites

  getAllDBSites(): Observable<any> {
    return this.http.get<AssetsList>(`${this.baseUrl}/sitemanagement/Site/allDbSites`);
  }

  getGeneralSites(siteId: number): Observable<any> {
    return this.http.get<General>(`${this.baseUrl}/sitemanagement/Site/site/general/${siteId}`);
  }

  getSiteEnvironmentVariables(customerId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/sitemanagement/Site/envParams/${customerId}`);
  }

  getSiteEnvironmentVariableValues(siteId: number): Observable<Array<EnvironmentVariable>> {
    return this.http.get<Array<EnvironmentVariable>>(`${this.baseUrl}/sitemanagement/Site/site/environment/${siteId}`);
  }

  getSiteEnvironments(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/sitemanagement/Site/site/environments`);
  }

  getSiteSubStationsForSite(siteId: number): Observable<any> {
    return this.http.get<SubStation>(`${this.baseUrl}/sitemanagement/Site/substations/${siteId}`);
  }

  postSite(postData: any): Observable<Site> {
    const url = `${this.baseUrl}/sitemanagement/Site/site`;
    return this.http.post<Site>(url, postData).pipe(catchError(error => of(error)));
  }

  patchMapSiteToCustomer(postData: MapSiteToCustomer[]): Observable<Site> {
    const url = `${this.baseUrl}/sitemanagement/Site/mapSiteToCustomer`;
    return this.http.patch<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  patchRemoveSiteMap(siteId: number): Observable<any> {
    const url = `${this.baseUrl}//sitemanagement/Site/removeSiteMap/${siteId}`;
    return this.http.patch<any>(url, '').pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  getRequest(pageNo: number, customerId?: number, siteId?: number, substationId?: number): Observable<any> {
    if (customerId && siteId && substationId) {
      return this.http.get<any>(`${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequestFilter/${pageNo}?customerId=${customerId}&siteId=${siteId}&subStationId=${substationId}`).pipe(catchError(error => of(error)));
    }
    else if (customerId && siteId) {
      return this.http.get<any>(`${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequestFilter/${pageNo}?customerId=${customerId}&siteId=${siteId}`).pipe(catchError(error => of(error)));
    }
    else if (customerId) {
      return this.http.get<any>(`${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequestFilter/${pageNo}?customerId=${customerId}`).pipe(catchError(error => of(error)));

    }
    else {
      return this.http.get<any>(`${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequestFilter/${pageNo}`).pipe(catchError(error => of(error)));
    }
  }

  getRequestByRequestId(requestId: number): Observable<Array<RequestAssets>> {
    return this.http.get<any>(`${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequest/${requestId}`).pipe(catchError(error => of(error)));
  }

  getPendingCount(customerId?: number, siteId?: number, substationId?: number): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/SAPModification/getSapModificationRequestPendingCount`;
    if (customerId && siteId && substationId) {
      url += `?customerId=${customerId}&siteId=${siteId}&subStationId=${substationId}`;
    }
    else if (customerId && siteId) {
      url += `?customerId=${customerId}&siteId=${siteId}`;
    }
    else if (customerId) {
      url += `?customerId=${customerId}`;
    }
    return this.http.get<any>(url).pipe(
      map((successResponse) => {
        this.pendingCount.next(successResponse)
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )


  }

  updateSapModificationRequest(postData: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/SAPModification/updateSapModificationRequest`;
    return this.http.post<Site>(url, postData).pipe(catchError(error => of(error)));
  }

  updateView(type: string, id:number): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/update`;
    switch (type) {
      case 'customer':
        url += `/customerView/${id}`
        break;
      case 'site':
        url += `/siteView/${id}`
        break;
      case 'asset':
        url += `/assetView/${id}`
          break;
      default:
        break;
    }
    return this.http.post<Site>(url, id).pipe(catchError(error => of(error)));
  }

  saveSubmitted(requestId: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/assetSubasset/submit/${requestId}`;
    return this.http.put<Site>(url, null).pipe(catchError(error => of(error)));
  }

  checkFinalSubmission(requestId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/assetmanagement/Asset/finalSubmission/${requestId}`).pipe(catchError(error => of(error)));
  }

  //user-management
  getUserListEAndA(): Observable<any> {
    return this.http.get<UsersList>(`${this.baseUrl}/accesscontrol/Account/Users`);
  }

  getUserList(customerId: number): Observable<any> {
    return this.http.get<UsersList>(`${this.baseUrl}/accesscontrol/Account/${customerId}`);
  }

  getContactPersonList(customerId: number): Observable<any> {
    return this.http.get<UsersList>(`${this.baseUrl}/accesscontrol/Account/ContactPerson/${customerId}`);
  }

  getUserProfile(userId: string): Observable<any> {
    return this.http.get<UsersList>(`${this.baseUrl}/accesscontrol/Account/UserProfile/${userId}`);
  }

  getEAUserProfile(userId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/EACustomerAssociationUserProfiles/${userId}`);
  }

  getAccountTypeData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`);
  }

  deleteUser(userId: string): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/${userId}`;
    return this.http.delete<any>(url);
  }

  updateStatus(userId: string, status: boolean): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/UserProfileStatus/${userId}/${status}`;
    return this.http.put<any>(url, null);
  }

  updateUserProfile(putData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/userProfile`;
    return this.http.put<any>(url, putData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  updateEAUserProfile(putData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/EAUserUpdate`;
    return this.http.put<any>(url, putData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  postUserProfile(postData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/userAccountCreation`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  //Roles list
  getRolesForCustomerId(customerId: number): Observable<Array<EntityRole>> {
    return this.http.get<Array<EntityRole>>(`${this.baseUrl}/accesscontrol/EntityAccess/roles/${customerId}`).pipe(catchError(error => of(error)));
  }

  getRolesForSuperAdmin(): Observable<Array<EntityRole>> {
    return this.http.get<Array<EntityRole>>(`${this.baseUrl}/accesscontrol/Role/LnTRoles`).pipe(catchError(error => of(error)));
  }

  // list of functions
  getModules(): Observable<Array<EntityMaster>> {
    return this.http.get<Array<EntityMaster>>(`${this.baseUrl}/accesscontrol/EntityAccess/entityMaster`);
  }

  getModulesForCustomer(id: number | null): Observable<Array<EntityMaster>> {
    return this.http.get<Array<EntityMaster>>(`${this.baseUrl}/accesscontrol/EntityAccess/entityMaster/${id}`);
  }

  //get value for role
  getEntityRoleFunctions(roleId: number): Observable<Array<EntityAccessRole>> {
    return this.http.get<Array<EntityAccessRole>>(`${this.baseUrl}/accesscontrol/EntityAccess/entityRoles/${roleId}`);
  }

  deleteEntityRole(roleId: number, customerId: number): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/EntityAccess/roles/${roleId}`;
    return this.http.delete<any>(url).pipe(
      map(response => {
        return response;
      }),
      switchMap(response => {
        if (response) {
          if (customerId) {
            return this.getRolesForCustomerId(customerId);
          } else {
            return this.getRolesForSuperAdmin();
          }
        } else {
          return response;
        }
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  // for add and edit the role
  editAddRole(postData: any, customerId?: number | null): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/EntityAccess/roles`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      switchMap((successResponse) => {
        if (successResponse && successResponse.id === 0) {
          if (customerId === null) {
            return this.getRolesForSuperAdmin();
          } else {
            return this.getRolesForCustomerId(customerId as number);
          }
        } else {
          return of(successResponse);
        }
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  //post role access
  editEntityRoleFunctions(postData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/EntityAccess/entityRole`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  downloadUserSheet(id: number): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/generate/customerAdminUser/${id}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  uploadUserSheet(files: any, id: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', files[0])
    // files.forEach((f: any) => formData.append('file', f));
    const url = `${this.baseUrl}/accesscontrol/Account/upload/customerAdminUser/${id}`;
    return this.http.post<any>(url, formData);
  }

  downloadAssetsSheet(type: string, category: string, seAsset: string): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/generate/assetSheet/${type}/${category}/${seAsset}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  uploadAssetsSheet(files: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', files[0]);
    const url = `${this.baseUrl}/assetmanagement/Asset/upsert/assetSheet`;
    return this.http.post<any>(url, formData);
  }

  getAssetTypes(seAsset: string): Observable<AssetType[]> {
    return this.http.get<AssetType>(`${this.baseUrl}/assetmanagement/Asset/assetTypes?seAsset=${seAsset}`).pipe(catchError(error => of(error)));
  }

  getAssetCategories(typeId?:any): Observable<any> {
    let url = `${this.baseUrl}/assetmanagement/Asset/assetCategories`;
    if(typeId){
      url += `?typeId=${typeId}`;
    }
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  getDesignTypes(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/assetmanagement/Asset/designTypes`).pipe(catchError(error => of(error)));
  }

  downloadSuperAdminUserSheet(): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/generate/internalInvitedUsers`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  uploadSuperAdminUserSheet(files: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', files[0])
    // files.forEach((f: any) => formData.append('file', f));
    const url = `${this.baseUrl}/accesscontrol/Account/upload/InvitedInternalUsers`;
    return this.http.post<any>(url, formData);
  }

  inviteUser(postData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/superAdminUser`;
    return this.http.post<any>(url, postData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  updateUser(putData: any): Observable<any> {
    const url = `${this.baseUrl}/accesscontrol/Account/superAdminUserUpdate`;
    return this.http.put<any>(url, putData).pipe(
      map((successResponse) => {
        return successResponse;
      }),
      catchError(error => {
        return throwError(() => error.error);
      })
    )
  }

  /* Asset Management */

  getCustomerInfo(customerId: number): Observable<any> {
    const url = `${this.baseUrl}/sitemanagement/Customer/${customerId}`
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  postCustomerInfo(customerRecord: any, contactPersonId: number): Observable<any> {
    const url = `${this.baseUrl}/sitemanagement/Customer/contactUserId?contactUserId=${contactPersonId}`;
    return this.http.post<any>(url, customerRecord).pipe(catchError(error => of(error)));
  }

  removeSiteMapping(id: string, payload: any): Observable<any> {
    const url = `${this.baseUrl}/sitemanagement/Site/removeSiteMap/${id}`;
    return this.http.patch<any>(url, payload).pipe(catchError(error => of(error)));
  }

  //template management
  //get all templates
  getAllTemplates(): Observable<Array<TemplateList>> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/all/templates`
    return this.http.get<Array<TemplateList>>(url).pipe(catchError(error => of(error)));
  }

  getAllTemplatesByGroups(): Observable<Array<any>> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/all/templatesGroupByWorkType`
    return this.http.get<Array<TemplateList>>(url).pipe(catchError(error => of(error)));
  }

  deleteTemplate(templateId: number): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/delete/Template/${templateId}`;
    return this.http.delete<any>(url);
  }

  getWorkTypes(): Observable<Array<any>> {
    const url = `${this.baseUrl}/maintenanceworkflow/WorkPermitTeam/workTypeMaster`
    return this.http.get<Array<any>>(url).pipe(catchError(error => of(error)));
  }

  getCompTypes(): Observable<Array<any>> {
    const url = `${this.baseUrl}/assetmanagement/Asset/asset/componentTypes`
    return this.http.get<Array<any>>(url).pipe(catchError(error => of(error)));
  }

  getCompTypesTemplate(): Observable<Array<any>> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/asset/componentTypes`
    return this.http.get<Array<any>>(url).pipe(catchError(error => of(error)));
  }

  getClassesTemplate(compType: string): Observable<Array<string>> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/asset/descriptions/${compType}`;
    return this.http.get<Array<string>>(url);
  }

  getClasses(compType: string): Observable<Array<string>> {
    const url = `${this.baseUrl}/assetmanagement/Asset/asset/descriptions/${compType}`;
    return this.http.get<Array<string>>(url);
  }

  downloadTemplate(id: string) {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/get/template/${id}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }


  downloadSubbasetIbTemplate(assetId: number) {
    const url = `${this.baseUrl}/assetmanagement/Asset/SubassetIb/generateSheet/${assetId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  downloadGenericTemplate(): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/generate/maintenanceTemplate`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  uploadTemplateData(isReplacement: boolean, isFromList: boolean, payload: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/upsert/MaintenanceTemplate/${isReplacement}/${isFromList}`;
    return this.http.post<any>(url, payload);
  }

  uploadTemplate(files: any): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/upsert/MaintenanceTemplate`;
    const formData = new FormData();
    formData.append('file', files[0]);

    return this.http.post<any>(url, formData);
  }

  deleteTemplateFile(fileUrl: string): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/delete/templateUrl?templateUrl=${fileUrl}`;
    return this.http.delete<any>(url)
  }

  getMaintenanceTemplate(templateId: number): Observable<any> {
    const url = `${this.baseUrl}/maintenanceworkflow/MaintenanceTemplate/generateTemplateJson/${templateId}`
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  //Subscription
  getSubscriptionsWithPagination(pageNumber: number, pageSize: number, payload: string): any {
    return this.http.get<Subscriptions>(`${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionNew/${pageNumber}/${pageSize}?searchString=${payload}`).pipe(catchError(error => of(error)));;
  }
  getCurrentUsageWithPagination(pageNumber: number, pageSize: number, payload: CurrentUsageQuery): any {
    return this.http.get<Subscriptions>(`${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionNew/${pageNumber}/${pageSize}?searchString=${payload}`).pipe(catchError(error => of(error)));;
  }

  // Subscription - plan
  getSubscriptionPlans(): Observable<Array<SubscriptionPlan>> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionPlans`
    return this.http.get<Array<SubscriptionPlan>>(url).pipe(catchError(error => of(error)));
  }

  getModulesList(): Observable<Array<SubscriptionModule>> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionModuleAndSubModules`;
    return this.http.get<Array<SubscriptionModule>>(url).pipe(catchError(error => of(error)));
  }

  getsubscriptionPlanModule(planId: number): Observable<Array<PlanModule>> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionPlansModule/${planId}`;
    return this.http.get<Array<PlanModule>>(url).pipe(catchError(error => of(error)));
  }

  postSubscribedPlanModules(subcribedPlanModules: postSubscribedModules): Observable<boolean> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionModules`;
    return this.http.post<boolean>(url, subcribedPlanModules).pipe(catchError(error => of(error)));
  }

  subscriptionPlanName(PlanName: NewPlan): Observable<boolean | HttpErrorResponse> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionPlan`;
    return this.http.post<boolean | HttpErrorResponse>(url, PlanName).pipe(catchError(error => of(error)));
  }

  deletePlan(planId: number): Observable<boolean | HttpErrorResponse> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionPlan/delete/${planId}`;
    return this.http.delete<boolean | HttpErrorResponse>(url).pipe(catchError(error => of(error)));
  }

  disablePlan(planId: number, isDisabled: boolean): Observable<boolean | HttpErrorResponse> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/disable/${planId}/${isDisabled}`;
    return this.http.patch<boolean | HttpErrorResponse>(url, null).pipe(catchError(error => of(error)));
  }

  // Add Subscription - plan
  addSubscriptionPlans(body: Subscriptions): Observable<Subscriptions> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionNew`
    return this.http.put<Subscriptions>(url, body).pipe(catchError(error => of(error)));
  }

  // Get Subscription for Id
  getSubscriptionForId(id: number): Observable<Subscriptions> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionNew/${id}`
    return this.http.get<Subscriptions>(url).pipe(catchError(error => of(error)));
  }

  // Get Subscription for Id
  renewSubscription(id: number): Observable<Subscriptions> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscription/renew/${id}`
    return this.http.post<Subscriptions>(url, id).pipe(catchError(error => of(error)));
  }

  // Get Subscription for Id
  switchSubscription(id: number): Observable<Subscriptions> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscription/upgrade/${id}`
    return this.http.post<Subscriptions>(url, id).pipe(catchError(error => of(error)));
  }

  //Manage plan
  //get plan and modules available for plan
  getmoduleAndPlans(subscriptionId: number): Observable<Array<AllPlanModules>> {
    const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscription/managePlans/${subscriptionId}`
    return this.http.get<Subscriptions>(url).pipe(catchError(error => of(error)));
  }
  //send plan change request
  changePlanRequest(subscriptionId: number, planId: number, flag: boolean, otherRequest: string): Observable<any> {
    if (otherRequest) {
      const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionCheck/${subscriptionId}/${planId}/IsRenew/${flag}?otherrequest=${otherRequest}`
      return this.http.post<Subscriptions>(url, {}).pipe(catchError(error => of(error)));
    }
    else {
      const url = `${this.baseUrl}/subscriptionmanagement/SubscriptionPlan/subscriptionCheck/${subscriptionId}/${planId}/IsRenew/${flag}`
      return this.http.post<Subscriptions>(url, {}).pipe(catchError(error => of(error)));
    }
  }

  saveIBData(payload: any): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/assetSubassetABS`;
    return this.http.put<any>(url, payload).pipe(catchError(error => of(error)));
  }

  getRequestStatus(id: string): Observable<boolean> {
    const url = `${this.baseUrl}/assetmanagement/Asset/requestDone/${id}`
    return this.http.get<Subscriptions>(url).pipe(catchError(error => of(error)));
  }

  //gateway
  getGateway(customerId: number, pageNo: number, pageSize: number, searchString?: string, siteId?: number): Observable<boolean> {
    let url = `${this.baseUrl}/conditionmonitoring/DeviceGateway/gateways/${customerId}/${pageNo}/${pageSize}`;

    if (siteId && searchString) {
      url += `?searchString=${searchString}&siteId=${siteId}`;
    }
    else if (siteId && !searchString) {
      url += `?siteId=${siteId}`;
    }
    else if (!siteId && searchString) {
      url += `?searchString=${searchString}`
    }
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  deleteGateway(gatewayId: number): Observable<boolean | HttpErrorResponse> {
    const url = `${this.baseUrl}/conditionmonitoring/DeviceGateway/delete/${gatewayId}`;
    return this.http.delete<boolean | HttpErrorResponse>(url).pipe(catchError(error => of(error)));
  }

  saveUpdateGateWay(payload: any,isReplace = false): Observable<any> {
    let url = `${this.baseUrl}/conditionmonitoring/DeviceGateway`
    if(isReplace) url += '/replace';
    return this.http.post<any>(url, payload).pipe(map((res) => res),
    catchError((error) => throwError(error)));
  }

  getGateWayDetails(gatewayId): Observable<any> {
    const url = `${this.baseUrl}/conditionmonitoring/DeviceGateway/${gatewayId}`
    return this.http.get<any>(url).pipe(catchError(error => of(error)));
  }

  postAssetVisibility(assetId: number, isVisible: boolean): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/upsert/visibility/asset/${assetId}/${isVisible}`
    return this.http.post<Subscriptions>(url, {}).pipe(catchError(error => of(error)));
  }

  postSubAssetVisibility(componentIds: any, isVisible: boolean): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/SubAsset/upsert/visibility/subAsset/${isVisible}`
    return this.http.post<Subscriptions>(url, componentIds).pipe(catchError(error => of(error)));
  }

  getIngestedData(payload: any): Observable<Subscriptions> {
    const url = `${this.baseUrl}/assetmanagement/Asset/dataIngestion`
    return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
  }

  ackIngestedData(payload: {date:string}): Observable<Subscriptions> {
    const url = `${this.baseUrl}/assetmanagement/Asset/dataIngestion/acknowledgement`
    return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
  }

  getHeaderDetailsSheet(assetIds: Array<number>): Observable<any> {
    const url = `${this.baseUrl}/assetmanagement/Asset/Header/generateSheet`
    return this.http.post<any>(url, assetIds, { responseType: 'blob' as 'json' });
  }

}
