import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-calender',
  templateUrl: './report-calender.component.html',
  styleUrls: ['./report-calender.component.css']
})
export class ReportCalenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
