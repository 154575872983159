import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbModule } from "xng-breadcrumb";
import { BreadcrumbService } from 'xng-breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { LayoutComponent } from './layout/layout.component';
import { SitesComponent } from './sites/sites.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { OverviewComponent } from './overview/overview.component';
import { AlarmComponent } from './alarm/alarm.component';
import { MaintenanceManagerComponent } from './maintenance-manager/maintenance-manager.component';
import { CompareAssetsComponent } from './compare-assets/compare-assets.component';
import { FilterPipe } from './_pipes/filter.pipe';
import { AssetsComponent } from './assets/assets.component';
import { ChartModule } from './charts/chart.module';
import { SiteOverviewAssetsComponent } from './site-overview-assets/site-overview-assets.component';
import { SitesDashboardComponent } from './sites-dashboard/sites-dashboard.component';
import { SitesLeftPanelComponent } from './sites-left-panel/sites-left-panel.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component'
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { SharedValueService } from './_services/shared-value.service';
import { LandingComponent } from './landing/landing.component';
import { AssetFilterPipe } from './_pipes/asset-filter.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalComponent } from './modal/modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AlarmFilterPipe } from './_pipes/alarm-filter.pipe';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FieldBasedFilterPipe } from './_pipes/field-based-filter.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { AssetSearchPipe } from './_pipes/asset-search.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConditionmonitoringCardsComponent } from './conditionmonitoring-cards/conditionmonitoring-cards.component';
import { SharedModule } from './shared/shared.module';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { SubAssetDetailsComponent } from './subasset-details/subasset-details.component';
import { HealthCalculateComponent } from './health-calculate/health-calculate.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatCardModule } from '@angular/material/card';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);
import { IdleCheckService } from './_services/idle-check.service';

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        LayoutComponent,
        SitesComponent,
        DashboardComponent,
        TermsConditionsComponent,
        OverviewComponent,
        AlarmComponent,
        MaintenanceManagerComponent,
        CompareAssetsComponent,
        FilterPipe,
        AssetsComponent,
        SiteOverviewAssetsComponent,
        SitesDashboardComponent,
        SitesLeftPanelComponent,
        BreadcrumbComponent,
        LandingComponent,
        AssetFilterPipe,
        ModalComponent,
        SubAssetDetailsComponent,
        ModalComponent,
        FieldBasedFilterPipe,
        AlarmFilterPipe,
        AssetSearchPipe,
        ConditionmonitoringCardsComponent,
        UserProfileComponent,
        HealthCalculateComponent,
        ContactUsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartModule,
        BreadcrumbModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatFormFieldModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatPaginatorModule,
        SharedModule,
        MatIconModule,
        MatSnackBarModule,
        InfiniteScrollModule,
        MatCardModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        // //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the application is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // })
    ],
    providers: [
        BreadcrumbService,
        SharedValueService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DatePipe,
        AssetFilterPipe,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [IdleCheckService],
            useFactory: (idleCheckService: IdleCheckService) => () =>
                idleCheckService.setUp(),
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [ModalComponent]
})
export class AppModule { }
