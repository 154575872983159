<nav aria-label="page navigation example">
    <ul class="pagination">
        <li class="page-item" ><button class="page-link btn"  [disabled]="activeSection <= 0" (click)="onClickButton(activeSection - 1)">
            <span *ngIf="isMobile"><i class="fa fa-angle-left"></i></span>
            <span *ngIf="isDesktopDevice">«
                Previous</span>
            </button></li>

        <li class="page-item"><button class="page-link btn"  [disabled]="activeSection > (totalSec-1) || endPage >=totalRecords" (click)="onClickButton(activeSection + 1)">
            <span *ngIf="isMobile"><i class="fa fa-angle-right"></i></span>
            <span *ngIf="isDesktopDevice">Next
                »</span>
            </button></li>
    </ul>
</nav>