import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartParams } from 'src/app/_models/conditionmonitoring-cards.type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartApiService {

  baseUrl = environment.apiURL;


  constructor(private http: HttpClient) { }

  getChartData(params:ChartParams):any {
    return this.http.post<any>(`${this.baseUrl}/conditionmonitoring/TimeBasedDataMonitoring/timebaseddata`, params);
  }
}
