import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import { ObsolenscenceProfileList, SiteMaintenanceList } from 'src/app/_models/overview/site-maintenance-list.type';
import { HttpErrorResponse } from '@angular/common/http';
import { SiteManagementService } from 'src/app/_services/site-management.service';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-stacked-column-chart',
  templateUrl: './stacked-column-chart.component.html',
  styleUrls: ['./stacked-column-chart.component.scss']
})
export class StackedColumnChartComponent implements OnInit {
  @Input('chartTitle') chartTitle!: string;
  @Input('apiEndPoint') apiEndPoint!: string;
  @Input('siteId') siteId!: string;
  @Input('obsolenscenceProfileList') obsolenscenceProfileList!: ObsolenscenceProfileList[];
  @Input('maintenanceSummaryList') maintenanceSummaryList!: SiteMaintenanceList[];
  @Input('licenseCheck') licenseCheck!: boolean;

  maintenanceSummary!: any[];
  deepCopyData!: any[];
  customLegend: any;
  noDataForChart: string = '';

  colors: string[] = ['#389921', '#F9A432', '#FF0000'];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options = {};
  myChart: any;
  series: any;
  yAxis: any;
  chartData: any;
  chart!: any;

  private subscription: Subscription = new Subscription();
  xAxis: any;

  constructor(private siteManagementService: SiteManagementService) { }

  ngOnInit(): void {
    this.getStackedVerticalChartData(this.siteId);
  }

  createChart() {
    let self = this;
    this.chartOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 180
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '{point.x}<span style="font-size:8px"> ● </span>{series.name}</br>',
        pointFormat: '<div style="text-align:center;ps-3">{point.y}</div>',
        backgroundColor: 'black',
        style: {
          color: 'white',
          textAlign: 'center'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      xAxis: {
        categories: this.xAxis.categories,
        labels: {
          rotation: -90,
          style: {
            'font-family': "Source Sans Pro"
          }
        }

      },
      yAxis: {
        className: 'stacked-chart-y-axis',
        endOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: undefined
        },
        stackLabels: {
          enabled: false
        },
        tickAmount: 5
      },
      series: this.series,
    };
    this.chart = Highcharts.chart(this.apiEndPoint, this.chartOptions);
  }

  customLegendFilter(legend: any) {
    if (!legend.chartBtnSelected) {
      this.resetLegendFilter();
      let series = this.series.filter((item:any) => item.name == legend.name);
      // this.series = series;
      (this.chartOptions as any).series = series;
      this.chart = Highcharts.chart(this.apiEndPoint, this.chartOptions);
      // Needs Object Mutation to Update the graph
      legend.chartBtnSelected = true;
    } else {
      (this.chartOptions as any).series = this.series;
      this.chart = Highcharts.chart(this.apiEndPoint, this.chartOptions);
      legend.chartBtnSelected = false;
    }
  }

  resetLegendFilter() {
    this.customLegend.Count.forEach((value: any) => {
      value.chartBtnSelected = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteId'] && !changes['siteId'].firstChange) {
      this.getStackedVerticalChartData(changes['siteId'].currentValue);
    }
  }

  getStackedVerticalChartData(siteId: string) {
    this.subscription.add(
      this.siteManagementService.getStackedVerticalCharts(siteId, this.apiEndPoint).subscribe(chartData => {
        if (chartData instanceof HttpErrorResponse) {
          this.noDataForChart = chartData.error ? chartData.error : 'No details found for this site';
          this.chartOptions = {
            chart: {
              height: 1
            },
            title: {
              text: ''
          },
          series: [{
              type: 'column',
              name: 'Random data',
              data: []
          }]
          }
          this.chart = Highcharts.chart(this.apiEndPoint, this.chartOptions);
        } else {
          this.noDataForChart = '';
          this.maintenanceSummary = chartData.item2;
          let series1: any = [], series2: any = [], series3: any = [];

          this.xAxis = {
            categories: [],
            labels: {
              rotation:-90,
            }
            
          }
          let legends = chartData.item1.Count.map((item: any) => item.name);
          chartData.item2.forEach((item: any, i: number) => {

            this.xAxis.categories.push(item.name);
            let obj: any = {};
            item?.series?.forEach((el: any) => {
              obj[el.name] = el.value;
            })
            series1.push(obj[legends[0]]);
            series2.push(obj[legends[1]]);
            series3.push(obj[legends[2]]);

          });
          this.series = [
            {
              name: legends[0],
              type: 'column',
              maxPointWidth: 10,
              label: {
                enabled: false
              },
              data: series1,
              color: this.colors[0]
            },
            {
              name: legends[1],
              type: 'column',
              maxPointWidth: 10,
              label: {
                enabled: false
              },
              data: series2,
              color: this.colors[1]
            },
            {
              name: legends[2],
              type: 'column',
              maxPointWidth: 10,
              label: {
                enabled: false
              },
              data: series3,
              color: this.colors[2]
            }
          ];
          this.createChart();

          this.customLegend = chartData.item1;
          if (this.customLegend && this.maintenanceSummary) {
            this.customLegend.Count.map((value: any, index: number) => {
              return Object.assign(value, {
                chartBtnSelected: false,
                legendColor: this.colors[index]
              });
            });
          }
        }
      }));
  }
}