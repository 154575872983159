<div class="row">
    <div class="col-8 md-offset-4 offset-4">
        <div class="row  text-center" style="flex-flow: row;">
            <span *ngFor="let day of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" style="font-size: 9px;
            width: 13px;
            border-right: 0.5px solid #3f3c3c;" class="m-0 p-0">{{day}}</span>
        </div>
    </div>
</div>
<div class="row" *ngFor="let item of [1,2,3,4,5,6,7]">
    <div class="col-2">Grant Marshal</div>
    <div class="col-2">SWGAI|INC1</div>
    <div class="col-8 text-center">
        <div class="row" style="flex-flow: row;">
            <span *ngFor="let day of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]" style="font-size: 7px;
            width: 13px;height: 33px;
            border-right: 0.5px solid #000;" class="m-0 p-0">
        <div class="m-0 p-0" style="background: yellow;width: 13px;height: 11px;"></div>
        <div class="m-0 p-0" style="background: red;width: 13px;height: 11px;"></div>
        <div class="m-0 p-0" style="background: green;width: 13px;height: 11px;"></div>   
        </span>
        </div>
    </div>
</div>