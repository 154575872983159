import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {

    transform(value: any, args: any,type?:string): any {
        if(!args) return value;
        const re = new RegExp("\\b("+args+"\\b)", 'igm');
        value= value.replace(re, '<span class="highlighted-text">$1</span>');
        return value;
    }

}
