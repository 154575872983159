import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-list-table-widget',
  templateUrl: './list-table-widget.component.html',
  styleUrls: ['./list-table-widget.component.scss']
})
export class ListTableWidgetComponent implements OnChanges, OnInit {

  constructor() { }

  public dataSource = new MatTableDataSource();
  public selection = new SelectionModel<any>(true, []);

  public displayedColumns: string[] = [];
  public listDataPageLimit = 25;
  
  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  @ViewChild(MatPaginator, {read: true}) paginator!: MatPaginator;

  @Input() header: Array<{title: string, field: string}>;
  @Input() listData: Array<any>;
  @Input() isDownload: Boolean;
  @Input() isAction: Boolean;
  @Input() actions: any;
  @Input() isStatus: Boolean;
  @Input() loading: Boolean;
  @Input() totalRecords: number;
  @Input() isSelect: Boolean;
  @Input() isLinkItem = false;
  @Input() linkColumn: string;
  @Input() tableHeight: number;
  @Output() pageChange = new EventEmitter<any>();
  @Output() download = new EventEmitter<any>();
  @Output() action = new EventEmitter<any>();
  @Output() selectedRows = new EventEmitter<any>();
  @Output() selectedColumn = new EventEmitter<any>();
  
  ngOnInit(): void {
    this.displayedColumns = this.header.map(item => item.field);
    if(this.isSelect){
      this.displayedColumns.unshift('select');
    }
    if(this.isDownload){
      this.displayedColumns.push('download');
    }
    if(this.isStatus){
      this.displayedColumns.push('status')
    }
    if(this.isAction){
      this.displayedColumns.push('action')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.listData);
    this.dataSource.sort = this.sort
    // this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onPageChange(event: any){
    this.pageChange.emit({
      limit: event.pageSize,
      pageNo: event.pageIndex+1
    })
    // if(this.totalRecords > this.listDataPageLimit && Math.floor(this.listDataPageLimit/event.pageSize) === event.pageIndex){
    //   this.pageChange.emit(event);
    // }
  }

  fileDownload(element: any){
    this.download.emit(element)
  }

  actionCall(id:any, type?: string){
    this.action.emit({id, type});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getSelected(event){
    this.selectedRows.emit(this.selection.selected);
  }

  columnClick(element){
    this.selectedColumn.emit(element)
  }
}
