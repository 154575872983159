<div class="contact-container">
    <form [formGroup]="contactUsFormGroup">
    <div class="row mb-4">
        <div class="col">
            <label for="select-request" class="required">Request Type</label>
            <ng-select [items]="requestTypes" (change)="changeRequest($event)" bindValue="name" [dropdownPosition]="'bottom'" bindLabel="name" id="select-request" placeholder="Select request" formControlName="supportType">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{item}}">{{item}}</div>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col">
            <label for="subject" class="required">Subject</label>
            <input type="text" id="subject" name="subject" placeholder="Enter Subject" formControlName="subject">
        </div>
    </div>
    <div class="row mb-4">
        <div class="col">
            <label for="message" class="required pb-1">Message</label>
            <textarea class="form-control" id="message" name="message" rows="5" placeholder="Enter Message" formControlName="message"></textarea>
        </div>
    </div>
    <div>
        <button type="button" class="btn btn-primary mt-4"
        [disabled]="contactUsFormGroup.invalid"
        (click)="submitForm()" id="submit">Submit</button>
    </div>
    </form>
</div>