<form class="work-Performed-container" *ngIf="assetTemplate.report" [formGroup]="parentFormGroup" autocomplete="off">
  <div class="my-2 small-full-width" [formGroupName]="feederNo">
    <div [formGroupName]="formId">
      <div class="group p-3" *ngIf="assetTemplate.report.header">
        <div class="group-controls" (click)="accordian('header')">
          <div class="group-header accordian">{{assetTemplate.report.header.label }}</div>
          <div class="form-arrows">
            <i *ngIf="assetTemplate.report.header.openAccordian" class="fa fa-angle-up"></i>
            <i *ngIf="!assetTemplate.report.header.openAccordian" class="fa fa-angle-down"></i>
          </div>
        </div>

        <div class="panel" id="header" formArrayName="header" [ngClass]="{' mt-2 open': assetTemplate.report.header.openAccordian}">
          <div class="field-group">
            <div class="text-field mb-3" *ngFor="let input of assetTemplate.report.header.children.textField; let formIndex = index;">
              <div class="label">{{ (input.label) }} <span *ngIf="(input.label).indexOf(':') === -1">:</span></div>
              <div class="form-outline">
                <input type="text" class="form-control" [id]="input.label.split(' ').join('-') + formIndex" [placeholder]="input.label" [name]="input.label" maxlength="32" [formControlName]="formIndex"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="assetTemplate.report.group" formArrayName="group">
        <div class="group p-3 mt-2" *ngFor="let group of assetTemplate.report.group; let groupIndex = index" [formGroupName]="groupIndex">
          <div class="group-controls">
            <div class="group-header accordian" (click)="accordian('group', groupIndex)">{{ group.label }}</div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" formControlName="enable" (change)="groupEnableDisable($event, groupIndex)"/>
            </div>
            <div class="form-arrows" (click)="accordian('group', groupIndex)">
              <i *ngIf="group.openAccordian" class="fa fa-angle-up"></i>
              <i *ngIf="!group.openAccordian" class="fa fa-angle-down"></i>
            </div>
          </div>

          <div class="panel"  [ngClass]="{'open': group.openAccordian}" [id]="'group'+groupIndex" *ngIf="!isMobile">

            <div class="mt-2">
              <ng-container *ngIf="group.children.checkPoint" formArrayName="checkPointFormArray">
                <div *ngFor="let field of group.children.checkPoint; let fieldIndex = index;">
                  <div class="checkpoint-header" *ngIf="fieldIndex === 0">
                    <div class="very-small-column">Sl. No</div>
                    <div class="description">Description</div>
                    <div class="small-column">Ok</div>
                    <div class="small-column">Not ok</div>
                    <div class="small-column">Made Ok</div>
                    <div class="small-column">NA</div>
                    <div class="remarks">Remarks</div>
                  </div>

                  <div class="checkpoint-field-group" [formGroupName]="fieldIndex">
                    <div class="very-small-column">{{ fieldIndex + 1 }}</div>
                    <div class="description">{{ field.label }}</div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="ok"/>
                    </div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="notok"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="madeok"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="na"/>
                    </div>
                    <div class="remarks form-outline">
                      <input type="text" class="form-control" placeholder="Remarks" [name]="'checkpointRemarks'+fieldIndex" maxlength="32"
                        formControlName="remarks" [id]="'checkpointRemarks'+fieldIndex"/>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="group.children.measurement" formArrayName="measurementFormArray">
                <div *ngFor="let field of group.children.measurement; let fieldIndex = index;">
                  <div class="measurement-header mb-3" *ngIf="fieldIndex === 0">
                    <div class="very-small-column">Sl. No</div>
                    <div class="description">Description</div>
                    <div class="medium-column">Test Method</div>
                    <div class="medium-column">Value Measured</div>
                    <div class="small-column">NA</div>
                    <div class="remarks">Remarks</div>
                  </div>

                  <ng-container [formGroupName]="fieldIndex">
                    <div class="measurement-field-group">
                      <div class="very-small-column">{{ fieldIndex + 1 }}</div>
                      <div class="description">{{ field.label }}</div>
                      <div class="medium-column titleCase">{{ field.testMethod }}</div>
                      <div class="medium-column form-outline">
                        <input class="form-control valueMeasurementInput" type="number"  [placeholder]="isMobile ? 'Value Measured' : 'Value'" [name]="'measurementValue'+fieldIndex" [id]="'measurementValue'+fieldIndex"
                          formControlName="valueMeasured"/>
                      </div>
                      <div class="small-column">
                        <input class="measurementNA" type="checkbox" [name]="'measurementNA'+fieldIndex" [id]="'measurementNA'+fieldIndex" (change)="measurementNA($event, fieldIndex, groupIndex)"
                           formControlName="measurementNA"/>
                      </div>
                      <div class="remarksMeasurement form-outline">
                        <input type="text" class="form-control" placeholder="Remarks" [name]="'measurementRemarks'+fieldIndex" maxlength="32"
                          formControlName="remarks" [id]="'measurementRemarks'+fieldIndex"/>
                      </div>
                    </div>
                    <div class="critical measurement-error" *ngIf="measurementValueChecker(fieldIndex, groupIndex)">
                      <span *ngIf="measurementValueChecker(fieldIndex, groupIndex) === 'min'">{{ field.lowerThresholdWarning }}</span>
                      <span *ngIf="measurementValueChecker(fieldIndex, groupIndex) === 'max'">{{ field.upperThresholdWarning }}</span>
                    </div>
                  </ng-container>

                </div>
              </ng-container>
              <ng-container *ngIf=" group.children.textField" formArrayName="textFieldFormAray">
                <div class="mt-2" *ngFor="let field of group.children.textField; let fieldIndex = index;">
                  <div class="text-field-header mb-3" *ngIf="fieldIndex === 0">
                    <div class="very-small-column">Sl. No</div>
                    <div class="description">Description</div>
                    <div class="remarks">Remarks</div>
                  </div>
                  <div class="text-field-group">
                    <div class="very-small-column">{{ fieldIndex + 1 }}</div>
                    <div class="description">{{ field.label }}</div>
                    <div class="large-column form-outline">
                      <input type="text" class="form-control" placeholder="Remarks" [name]="field.label" maxlength="32" [formControlName]="fieldIndex"
                        [id]="field.label.split(' ').join('-') + fieldIndex"/>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="group.children.measuredCondition" formArrayName="measuredConditionFormArray">
                <div *ngFor="let field of group.children.measuredCondition; let fieldIndex = index;">
                  <div class="checkpoint-header mb-3" *ngIf="fieldIndex === 0">
                    <div class="very-small-column">Sl. No</div>
                    <div class="description">Description</div>
                    <div class="small-column">Minor</div>
                    <div class="small-column">Major</div>
                    <div class="small-column">Significant</div>
                    <div class="small-column">Replacement</div>
                    <div class="small-column">NA</div>
                  </div>

                  <div class="checkpoint-field-group" [formGroupName]="fieldIndex">
                    <div class="very-small-column">{{ fieldIndex + 1 }}</div>
                    <div class="description">{{ field.label }}</div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Minor"/>
                    </div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Major"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Substantial"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Replacecment"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="NA"/>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="group.children.observedCondition" formArrayName="observedConditionFormArray">
                <div *ngFor="let field of group.children.observedCondition; let fieldIndex = index;">
                  <div class="checkpoint-header mb-3" *ngIf="fieldIndex === 0">
                    <div class="very-small-column">Sl. No</div>
                    <div class="description">Description</div>
                    <div class="small-column">Operable</div>
                    <div class="small-column">In-Operable</div>
                    <div class="small-column">NA</div>
                  </div>

                  <div class="checkpoint-field-group" [formGroupName]="fieldIndex">
                    <div class="very-small-column">{{ fieldIndex + 1 }}</div>
                    <div class="description">{{ field.label }}</div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="Operable"/>
                    </div>
                    <div class="small-column">
                      <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="In-Operable"/>
                    </div>
                    <div class="small-column ">
                      <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="NA"/>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>

          </div>

          <div class="panel"  [ngClass]="{'open': group.openAccordian}" [id]="'group'+groupIndex" *ngIf="isMobile">

            <div class="mt-2">
              <ng-container *ngIf="group.children.checkPoint" formArrayName="checkPointFormArray">
                <div *ngFor="let field of group.children.checkPoint; let fieldIndex = index;" [formGroupName]="fieldIndex">
                  <hr class="hr" *ngIf="fieldIndex > 0"/>
                  <div><span>{{ fieldIndex + 1 }}</span>.&nbsp;<span>{{ field.label }}</span></div>
                  <div class="mt-1 d-flex align-items-center">
                    <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="ok"/><span class="ms-1 me-2">Ok</span>
                    <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="notok"/><span class="ms-1 me-2">Not ok</span>
                    <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="madeok"/><span class="ms-1 me-2">Made Ok</span>
                    <input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="na"/><span class="ms-1 me-2">NA</span>
                  </div>
                  <div class="mt-1">
                    <span class=""><input type="text" class="form-control" placeholder="Remarks" [name]="'checkpointRemarks'+fieldIndex" maxlength="32" formControlName="remarks" [id]="'checkpointRemarks'+fieldIndex"/></span> 
                  </div>
                  <!-- <div class="row mb-1"><span class="col">Sl. No</span> <span class="col">{{ fieldIndex + 1 }}</span> </div>
                  <div class="row mb-1"><span class="col">Description</span> <span class="col">{{ field.label }}</span> </div>
                  <div class="row mb-1"><span class="col">Ok</span> <span class="col"><input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="ok"/></span> </div>
                  <div class="row mb-1"><span class="col">Not ok</span> <span class="col"><input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="notok"/></span> </div>
                  <div class="row mb-1"><span class="col">Made Ok</span> <span class="col"><input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="madeok"/></span> </div>
                  <div class="row mb-1"><span class="col">NA</span> <span class="col"><input class="" type="radio" [id]="'checkpoint'+fieldIndex" formControlName="radio" value="na"/></span> </div>
                  <div class="row mb-1"><span class="col">Remarks</span> <span class="col"><input type="text" class="form-control" placeholder="Remarks" [name]="'checkpointRemarks'+fieldIndex" maxlength="32"
                    formControlName="remarks" [id]="'checkpointRemarks'+fieldIndex"/></span> </div> -->
                </div>
                <hr class="hr"/>
              </ng-container>
              

              <ng-container *ngIf="group.children.measurement" formArrayName="measurementFormArray">
                <div *ngFor="let field of group.children.measurement; let fieldIndex = index;" [formGroupName]="fieldIndex">
                  <hr class="hr" *ngIf="fieldIndex > 0"/>
                  <div><span>{{ fieldIndex + 1 }}</span>.&nbsp;<span>{{ field.label }}</span></div>
                  <div class="mt-1 d-flex align-items-center">
                    <span class="me-2"><input class="form-control valueMeasurementInput" type="number"  [placeholder]="isMobile ? 'Value Measured' : 'Value'" [name]="'measurementValue'+fieldIndex" [id]="'measurementValue'+fieldIndex"
                      formControlName="valueMeasured"/></span>
                      <span class="me-1">NA</span> <input class="measurementNA" type="checkbox" [name]="'measurementNA'+fieldIndex" [id]="'measurementNA'+fieldIndex" (change)="measurementNA($event, fieldIndex, groupIndex)" formControlName="measurementNA"/> 
                  </div>
                  <div class="mt-1">
                    <span class=""><input type="text" class="form-control" placeholder="Remarks" [name]="'measurementRemarks'+fieldIndex" maxlength="32"
                      formControlName="remarks" [id]="'measurementRemarks'+fieldIndex"/></span>
                  </div>
                  <!-- <div class="row mb-1"><span class="col">Sl. No</span> <span class="col">{{ fieldIndex + 1 }}</span> </div>
                  <div class="row mb-1"><span class="col">Description</span> <span class="col">{{ field.label }}</span> </div>
                  <div class="row mb-1"><span class="col">Test Method</span> <span class="col">{{ field.testMethod }}</span> </div>
                  <div class="row mb-1"><span class="col">Value Measured</span> <span class="col"><input class="form-control valueMeasurementInput" type="number"  placeholder="Value Measured" [name]="'measurementValue'+fieldIndex" [id]="'measurementValue'+fieldIndex"
                    formControlName="valueMeasured"/></span> </div>
                  <div class="row mb-1"><span class="col">NA</span> <span class="col"><input class="measurementNA" type="checkbox" [name]="'measurementNA'+fieldIndex" [id]="'measurementNA'+fieldIndex" (change)="measurementNA($event, fieldIndex, groupIndex)"
                    formControlName="measurementNA"/></span> </div>
                  <div class="row mb-1"><span class="col">Remarks</span> <span class="col"><input type="text" class="form-control" placeholder="Remarks" [name]="'measurementRemarks'+fieldIndex" maxlength="32"
                    formControlName="remarks" [id]="'measurementRemarks'+fieldIndex"/></span> </div>
                    <div class="critical measurement-error" *ngIf="measurementValueChecker(fieldIndex, groupIndex)">
                      <span *ngIf="measurementValueChecker(fieldIndex, groupIndex) === 'min'">{{ field.lowerThresholdWarning }}</span>
                      <span *ngIf="measurementValueChecker(fieldIndex, groupIndex) === 'max'">{{ field.upperThresholdWarning }}</span>
                    </div> -->
                  </div>
                  <hr class="hr"/>
                </ng-container>
            </div>
            <ng-container *ngIf=" group.children.textField" formArrayName="textFieldFormAray">
              <div class="mt-2" *ngFor="let field of group.children.textField; let fieldIndex = index;">
                <hr class="hr" *ngIf="fieldIndex > 0"/>
                <div><span>{{ fieldIndex + 1 }}</span>.&nbsp;<span>{{ field.label }}</span></div>
                <!-- <div class="row mb-1"><span class="col">Sl. No</span> <span class="col">{{ fieldIndex + 1 }}</span> </div>
                <div class="row mb-1"><span class="col">Text Field Description</span> <span class="col">{{ field.label }}</span> </div> -->
                <div class="mb-1">
                  <span class=""><input type="text" class="form-control" placeholder="Remarks" [name]="field.label" maxlength="32" [formControlName]="fieldIndex" [id]="field.label.split(' ').join('-') + fieldIndex"/></span>
                </div>
              </div>
              <hr class="hr"/>
            </ng-container>

            <ng-container *ngIf="group.children.measuredCondition" formArrayName="measuredConditionFormArray">
              <div *ngFor="let field of group.children.measuredCondition; let fieldIndex = index;" [formGroupName]="fieldIndex">
                <hr class="hr" *ngIf="fieldIndex > 0"/>
                <div><span>{{ fieldIndex + 1 }}</span>.&nbsp;<span>{{ field.label }}</span></div>
                <div class="mt-1 d-flex align-items-center">
                  <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Minor"/><span class="ms-1 me-2">Minor</span>
                  <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Major"/><span class="ms-1 me-2">Major</span>
                  <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Substantial"/><span class="ms-1 me-2">Significant</span>
                  <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Replacecment"/><span class="ms-1 me-2">Replacement</span>
                  <input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="na"/><span class="ms-1 me-2">NA</span>
                </div>
                <!-- <div class="row mb-1"><span class="col">Sl. No</span> <span class="col">{{ fieldIndex + 1 }}</span> </div>
                <div class="row mb-1"><span class="col">Description</span> <span class="col">{{ field.label }}</span> </div>
                <div class="row mb-1"><span class="col">Minor</span> <span class="col"><input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Minor"/></span> </div>
                <div class="row mb-1"><span class="col">Major</span> <span class="col"><input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Major"/></span> </div>
                <div class="row mb-1"><span class="col">Significant</span> <span class="col"><input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Substantial"/></span> </div>
                <div class="row mb-1"><span class="col">Replacement</span> <span class="col"><input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="Replacecment"/></span> </div>
                <div class="row mb-1"><span class="col">NA</span> <span class="col"><input class="" type="radio" [id]="'measuredCondition'+fieldIndex" formControlName="radio" value="NA"/></span> </div> -->
              </div>
              <hr class="hr"/>
            </ng-container>

            <ng-container *ngIf="group.children.observedCondition" formArrayName="observedConditionFormArray">
              <div *ngFor="let field of group.children.observedCondition; let fieldIndex = index;" [formGroupName]="fieldIndex">
                <hr class="hr" *ngIf="fieldIndex > 0"/>
                <div><span>{{ fieldIndex + 1 }}</span>.&nbsp;<span>{{ field.label }}</span></div>
                <div class="mt-1 d-flex align-items-center">
                  <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="Operable"/><span class="ms-1 me-2">Operable</span>
                  <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="In-Operable"/><span class="ms-1 me-2">In-Operable</span>
                  <input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="NA"/><span class="ms-1 me-2">NA</span>
                </div>
                <!-- <div class="row mb-1"><span class="col">Sl. No</span> <span class="col">{{ fieldIndex + 1 }}</span> </div>
                <div class="row mb-1"><span class="col">Description</span> <span class="col">{{ field.label }}</span> </div>
                <div class="row mb-1"><span class="col">Operable</span> <span class="col"><input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="Operable"/></span> </div>
                <div class="row mb-1"><span class="col">In-Operable</span> <span class="col"><input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="In-Operable"/></span> </div>
                <div class="row mb-1"><span class="col">NA</span> <span class="col"><input class="" type="radio" [id]="'observedCondition'+fieldIndex" formControlName="radio" value="NA"/></span> </div> -->
              </div>
            </ng-container>

          </div>
        </div>
      </ng-container>
    </div>
  </div>

</form>
