<div class="dropdown-section">
<h5 class="chart-title col-sm-10">{{ chartTitle }}</h5>
<div class="dropdown-maintenance col-sm-2">
  <a class="value-text-grey me-1" href="#" role="button" id="dropdownMenuLink8-{{apiEndPointName}}"
    data-mdb-toggle="dropdown" aria-expanded="false">
    
    <span class="card-dropDown">{{ accessOptionValue }}</span>
    <i class="arrow down"></i>
  </a>
  <ul class="dropdown-menu" attr.aria-labelledby="dropdownMenuLink8-{{apiEndPointName}}"
    style="box-shadow: 0px 0px 15px #00000066;border-radius: 5px;">
    <li *ngFor="let option of accessOptions"><a class="dropdown-item" (click)="getMoreData(option, 'Top '+option)">{{
        'Top
        ' + option }}</a></li>
  </ul>
</div>
</div>

<div *ngIf="noChartData === ''" class="chart-section" [ngClass]="apiEndPointName">
  <ngx-charts-bar-horizontal class="roundEdges" [view]="view" [scheme]="colorScheme" [results]="single"
    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [xAxisTicks]="xAxisTicks"
    [maxYAxisTickLength]="maxYAxisTickLength" [xAxisTickFormatting]="xAxisTickFormatting"
    [yAxisTickFormatting]="yAxisTickFormatting" [yAxisLabel]="yAxisLabel" style="fill: #868686;">
  </ngx-charts-bar-horizontal>
</div>
<div class="critical text-center mt-5" *ngIf="noChartData !== ''">{{ noChartData }}</div>
<!-- </div> -->