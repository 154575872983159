<div class="container-fluid trends-card">
    <div class="row">
        <div class="trends-header">
            <span class="mobile-back non-desktop-only me-2" (click)="closeModal()"><i class="fa fa-angle-left ms-2"></i></span>
            <span class="non-desktop-only ms-3 mobile-header">
                <span class="me-1">
                    <img [src]="icons[data.group.split(' ')[0].toLowerCase()]" alt="" height="22">
                </span>
                {{name[data.group.split(' ')[0].toLowerCase()]}}</span>
            <div class="mobile-back non-desktop-only trends-header-menu" style="background: unset;">
                        <div class="trends-menu-btn-strip check-1">
                            <span (click)="download()" id="downloadChart" class="trends-header-menu-btn ms-3 me-1 px-1"><img
                                src="../../../../assets/images/download.png" alt="" height="21"></span>
                        <span (click)="print()" id="downloadChart" class="trends-header-menu-btn mx-1 px-2 py-1"><i
                                class="fa fa-print"></i></span>
                                <span style="display: none;">
                                    <input type="checkbox" (change)="toggleChartGrid($event)" id="toggleGrid" checked>
                                    <label for="toggleGrid" class="ms-1 me-2 my-1">Grid</label>
                                    <input type="checkbox" (change)="toggleMarkers($event)" id="toggleMarkers" checked>
                                    <label for="toggleMarkers" class="ms-1 me-2 my-1">Markers</label>
                                </span>
                        </div>
            </div>
            <div class="trends-header-text desktop-only">
                <span *ngIf="isCommon">
                    {{name[data.group.split(' ')[0].toLowerCase()]}}
                </span>
                <span *ngIf="!isCommon">
                    {{name[data.group.split(' ')[0].toLowerCase()]}}
                </span>
            </div>
            <div class="trends-close desktop-only" (click)="closeModal()"><span><i class="fa fa-times"></i></span></div>
        </div>
    </div>
    <div class="row trends-header-menu mt-3 p-2">
        <div class="col-2 desktop-only">
            <span class="ps-4 d-flex align-items-center">
                <img [src]="icons[data.group.split(' ')[0].toLowerCase()]" alt="">
            </span>
        </div>
        <div class="col px-0">
            <div class="trends-menu-btn-strip">
                <input type="checkbox" (change)="toggleTooltip($event)" id="toggleTooltip" checked class="">
                <label for="toggleTooltip" class="ms-1 me-2 ">Tooltip</label>
                <input type="checkbox" (change)="toggleChartGrid($event)" id="toggleGrid" checked class="">
                <label for="toggleGrid" class="ms-1 me-2 ">Grid</label>
                <input type="checkbox" (change)="toggleMarkers($event)" id="toggleMarkers" checked class="">
                <label for="toggleMarkers" class="ms-1 me-2 ">Markers</label>
                <label for="refresh" class="me-1 ">Refresh</label>
                <div class="select" for="slct" title="Refresh Rate">
                    <select id="refresh" #refresh [disabled]="!play" (change)="refreshChartData($event)">
                        <option value="60000">1 Min</option>
                        <option value="300000">5 Mins</option>
                        <option value="600000">10 Mins</option>
                    </select>
                    <span (click)="refresh.click()"><i class="fa fa-angle-down"></i></span>
                </div>
                <span (click)="download()" id="downloadChart" class="trends-header-menu-btn ms-3 me-1 px-1 desktop-only" title="Download Chart as png"><img
                        src="../../../../assets/images/download.png" alt="" height="21"></span>
                <span (click)="print()" id="downloadChart" class="trends-header-menu-btn mx-1 px-2 py-1 desktop-only" title="Download Chart as pdf"><i
                        class="fa fa-print"></i></span>
                <div class="trends-header-menu-btn-grp p-0 mx-1">
                    <div class="p-0 trends-header-menu-btn" [ngClass]="selectedOption=='zoom'?'selectedOption':''">
                        <span (click)="disablePan();" class="p-1 mx-1" title="Enable Zoom"><i
                                class="fa fa-search"></i></span>
                    </div>
                    <div class="p-0 trends-header-menu-btn" [ngClass]="selectedOption=='pan'?'selectedOption':''">
                        <span (click)="enablePan();" class="p-1 mx-1" title="Enable Panning">
                            <img src="../../../../assets/images/swipe.png" alt="" height="19" *ngIf="selectedOption !='pan'">
                            <img src="../../../../assets/images/swipe_active.png" alt="" height="19" *ngIf="selectedOption =='pan'">
                        </span>
                    </div>
                    <div class="p-0 trends-header-menu-btn" [ngClass]="selectedOption=='reset'?'selectedOption':''">
                        <span (click)="resetZoom();" class="p-1 mx-1" title="Toggle Auto-scale"><i
                                class="fas fa-expand"></i></span>
                    </div>
                    <div class="p-0 trends-header-menu-btn last" [ngClass]="selectedOption=='play'?'selectedOption':''">
                        <span class="p-1 mx-1" (click)="play=!play;refreshChartData()" *ngIf="!play" title="Play"><i class="fa fa-play"></i></span>
                        <span class="p-1 mx-1" (click)="play=!play;stopRefresh()" *ngIf="play" title="Pause"><i class="fa fa-pause"></i></span>
                    </div>
                </div>
                <label for="view" class="mx-1">View</label>
                <div class="select" for="slct">
                    <select id="view" required="required" #view (change)="updateChartData($event)"  [(ngModel)]="params.duration">
                        <option [value]="1">5 Mins</option>
                        <option [value]="2">10 Mins</option>
                        <option [value]="3">30 Mins</option>
                        <option [value]="4">1 Hour</option>
                        <option [value]="5">1 Day</option>
                        <option [value]="6">7 Days</option>
                        <option [value]="7">1 Month</option>
                        <option [value]="8">3 Month</option>
                        <option [value]="9">6 Month</option>
                    </select>
                    <span (click)="view.click()"><i class="fa fa-angle-down"></i></span>
                </div>
            </div>
        </div>
        <div class="no-show col-1 desktop-only"></div>
    </div>
    <div class="row mt-2">
        <div class="col-12 trends-left-section col-xl-2 col-lg-2 desktop-only">
            <div class="trends-left-section-panel">
                <div class="row">
                    <div class="col col-lg-12" *ngFor="let item of data.values;let i=index">
                        <div class="trends-left-section-header">
                            <span><i class="fa fa-square me-1" [style.color]="colors[i]"></i></span>
                            <span>{{item.tag}}</span>
                        </div>
                        <div class="trends-left-section-value mx-3">{{item.value | number:'0.0-2'}}{{item.unit}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10 col-xl-10 trends-right-section my-2" style="position:relative ;">
            <div *ngIf="isLoading"  class="position-absolute text-center pt-5 mt-5 w-100 h-100 mb-5 px-4 py-4" style="background: transparent;border:none"><i class="fas fa-spinner fa-pulse fa-3x healthy"></i></div>
            <div id="chart-line" class="px-2 py-4 h-100"></div>
            <div class="zoom-button" *ngIf="false && selectedOption == 'zoom'">
                <div (click)="zoomout()" class="zoomout-btn"><i class="fa fa-search-plus"></i></div>
                <div (click)="zoomin()"><i class="fa fa-search-minus"></i></div>
            </div>
            <!-- <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart> -->
        </div>
    </div>
    <div class="row non-desktop-only">
        <div class="col at-risk">
            Select only upto 2 trends for better visibility.
        </div>
    </div>
    <div class="row trends-footer mt-3 mx-1">
        <div class="trends-box col-lg-3 col-xl-3 col-12 me-2 my-1" *ngFor="let item of data.values;let i=index">
            <div class="row me-2 ms-2">
                <div class="form-check col-7">
                    <input class="form-check-input" type="checkbox" value="" [id]="'id-' + item.tag"
                        [style.background]='colors[i]' (change)="changeSeriesSelection(item.tag, $event)" [checked]="selectedSeries.includes(item.tag)"/>
                    <label class="form-check-label" [for]="'id-' + item.tag">{{item.tag}}</label>
                </div>
                <div class="form-check col-5">
                    <input class="form-check-input" type="checkbox" value="" [id]="'id-' + item.tag + '-limit'"
                        (change)="showHideLimits(item.tag, $event, i)" [checked]="!reset"/>
                    <label class="form-check-label" [for]="'id-' + item.tag + '-limit'">Limit</label>
                </div>
            </div>
        </div>
    </div>
</div>