import { Component, Input, OnInit } from '@angular/core';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-plain-table',
  templateUrl: './plain-table.component.html',
  styleUrls: ['./plain-table.component.scss']
})
export class PlainTableComponent implements OnInit {
  @Input() data: any=[];
  tableCellWidth: number;
  constructor() { }

  ngOnInit(): void {
    if(this.data.length){
      const keys = Object.keys(this.data[0]);
      this.tableCellWidth = 100 / keys.length;
    }
  }

  isDate(val: any) {
    if(isNumber(val)) return false;
    let timestamp = Date.parse(val);
    if (isNaN(timestamp)) {
      return false;
    } else {
      return true;
    }
  }

}
