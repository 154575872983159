import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'alarmFilter'
})
export class AlarmFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe){}

  transform(value: any, searchList?: any, fromDate?: any, toDate?: any): any {
    searchList = searchList.map((element:any) => {
      return element.toLowerCase();
    });
    if (!value) return null;
    if (searchList === undefined||(searchList.length === 0 && (fromDate===""&& toDate==="")) ) {
      return value;
    }
    return value.filter((item:any) =>{
      let newdate=item.receivedAt;
      newdate=this.datePipe.transform(item.receivedAt,'yyyy-MM-dd')
      if(newdate >= fromDate && newdate <= toDate)
      {
        return item;
      }
      for(var i=0;i<searchList.length;i++)
      {
        if(item.status?.toLowerCase().includes(searchList[i]) || item.alarmClass?.toLowerCase().includes(searchList[i]) || item.eventType?.toLowerCase().includes(searchList[i])
        || item.assetCategoryName?.toLowerCase().includes(searchList[i])|| item.Make?.toLowerCase().includes(searchList[i])
         )
        {
         return item;
        }
      }
    })  
  }
    }


