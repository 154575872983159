import { Component, Input, OnInit } from '@angular/core';
import { IPredictService } from 'src/app/_services/i-predict.service';
import { SharedService } from '../../../_services/shared.service'

@Component({
  selector: 'app-feeder-info',
  templateUrl: './feeder-info.component.html',
  styleUrls: ['./feeder-info.component.css']
})
export class FeederInfoComponent implements OnInit {

  @Input() feedersData: any;
  @Input() tabno!: string;
  offsetDate: string = '';
  assetEdit: boolean = false;
  selectedField: string = '';
  selectedFieldValue: any;
  oldVal: any;
  @Input() isEditableForUser!: boolean;
  @Input() selectedAssetId!: string;

  constructor(
    private sharedService: SharedService,
    private iPredictService: IPredictService
  ) { }

  ngOnInit(): void {
    this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
  }

  toggleHeight(id: any, action: string) {
    let elementId = 'element' + id;
    let el = document.getElementById(elementId) as HTMLElement;
    let expandIcon = document.getElementById('expand' + id) as HTMLElement;
    let collapseIcon = document.getElementById('collapse' + id) as HTMLElement;
    if (action == 'expand') {
      el.classList.add("expandedView");
      el.classList.remove("collapsedView");
      expandIcon.classList.remove('show');
      expandIcon.classList.add('hide');
      collapseIcon.classList.remove('hide');
      collapseIcon.classList.add('show');
    } else if (action == 'collapse') {
      el.classList.remove("expandedView");
      el.classList.add("collapsedView");
      expandIcon.classList.add('show');
      expandIcon.classList.remove('hide');
      collapseIcon.classList.add('hide');
      collapseIcon.classList.remove('show');
    }

  }

  setEditProperties(element: any, item: string, value?: any) {
    this.assetEdit = true;
    if (!this.selectedField) {
      this.selectedField = item;
      this.oldVal = value || element[item].value;
    }
    if (this.selectedField && item !== this.selectedField) {
      element[this.selectedField].value = this.oldVal;
      this.oldVal = value || element[item].value;
      this.selectedField = item;
    }
  }

  updateAsset(element: any, item: any, value?: any) {
    //will be used in admin panel
    this.assetEdit = !this.assetEdit;
    this.selectedField = '';
    let payload = {
      "assetId": this.selectedAssetId,
      "modifiedColumn": item,
      "modifiedDisplayName": item,
      "modifiedValue": value || element[item].value,
      "entity": 'SubAssets',
      "subAssetId":  element['Id'].value
    }
    this.iPredictService.updateAssetDetails(payload)
      .subscribe({
        next: (data: any) => {
          this.assetEdit = !this.assetEdit;
          this.selectedField = '';
        },
        error: (e: Error) => {

          console.log("Save failed");
        }
      })
  }

  getKeys(item: any) {
    return Object.keys(item);
  }
}
