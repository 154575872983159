import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPredictService } from '../_services/i-predict.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public contactUsFormGroup: FormGroup;
  private subscription: Subscription = new Subscription();
  public requestTypes: any;

  constructor(
    private formBuilder: FormBuilder,
    private iPredictService: IPredictService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.contactUsFormGroup = this.formBuilder.group({
      supportType: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      isServiceReq: [false]
    });
    this.getRequestTypes();
  }

  getRequestTypes(){
    this.subscription.add(
      this.iPredictService.getRequestTypes().subscribe(res => {
        // console.log(res);
        this.requestTypes = res;
      })
    )
  }

  changeRequest(event){
    this.contactUsFormGroup.get('isServiceReq').setValue(event === 'Engineer Visit Related' ? true : false)
  }

  submitForm(){
    const payload = this.contactUsFormGroup.value;
    this.subscription.add(
      this.iPredictService.submitContactRequest(payload).subscribe(res => {
        this.openSnackBar('Support request submited successfully');
        this.contactUsFormGroup.reset();
      })
    )
  }

  openSnackBar(message: string) {
    this._snackBar.open(message,'',{ 
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 1000
    });
  }

}
