import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HorizontalChartComponent } from './horizontal-chart/horizontal-chart.component';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { ModalFilterPipe } from '../_pipes/modalfilter.pipe';
import { FormsModule } from '@angular/forms';
import { SplitCamelCasePipe } from '../_pipes/split-camel-case.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { StatusBasedFilterPipe } from '../_pipes/status-filter.pipe';
import { StackedColumnChartComponent } from '../charts/stacked-column-chart/stacked-column-chart.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    HorizontalChartComponent,
    PopupModalComponent,
    ModalFilterPipe,
    SplitCamelCasePipe,
    StatusBasedFilterPipe,
    StackedColumnChartComponent
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    FormsModule,
    NgSelectModule,
    SharedModule
  ],
  exports: [
    HorizontalChartComponent,
    PopupModalComponent,
    StackedColumnChartComponent
  ]
})
export class ChartModule { }
