import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Asset } from '../_models/asset';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators'
import { result } from 'lodash';
import { SharedService } from './shared.service';
import { User } from '../_models/user';
import { TermsAndConditions } from '../_models/register.type';

@Injectable({
    providedIn: 'root'
})
export class IPredictService {
    asset: Asset = new Asset;
    assets: Asset[] = [new Asset];
    baseUrl = environment.apiURL;

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) { }

    getAssetById(id: string): any {
        return this.http.get<Asset>(`${this.baseUrl}/assetmanagement/Asset/` + id);
    }

    getAssetABSById(id: string): any {
        return this.http.get<Asset>(`${this.baseUrl}/assetmanagement/Asset/AssetABS/` + id);
    }

    getAssetABSByIBId(id: string): any {
        return this.http.get<Asset>(`${this.baseUrl}/assetmanagement/Asset/AssetABSIB/` + id);
    }

    getAssetsBySiteId(id: string): Observable<Asset[]> {
        return this.http.get<Asset[]>(`${this.baseUrl}/assetmanagement/Asset/site/${id}`);
    }

    getSubAssetsByAssetId(id: string): Observable<Asset[]> {
        return this.http.get<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/asset/${id}`);
    }

    getSubAssetsBySubAssetId(id: string): Observable<Asset[]> {
        return this.http.get<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/` + id);
    }

    getApplications(): Observable<any> {
        return this.http.get(`${this.baseUrl}/accesscontrol/apps`).pipe(
            map(data => {
                return { applist: data };
            })
        );
    }

    getModuleList(customerId: number): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/GeneralSettings/module/${customerId}`;
        return this.http.get<any>(url);
    }

    getAdminSettings(): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/GeneralSettings/adminSettings`;
        return this.http.get<any>(url).pipe(
            map(response => {
                this.sharedService.addAdminSettings(response);
                return response;
            })
        )
    }

    getPrivacyNotice(): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/Account/privacyNoticeUrl`
        return this.http.get<any>(url).pipe(
            map(data => {
                return data['response'];
            })
        );
    }

    getPrivacyPolicy(): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/Account/privacyPolicyUrl`
        return this.http.get<any>(url).pipe(
            map(data => {
                return data['response'];
            })
        );
    }

    getTermsAndConditions(): Observable<{ [key: string]: TermsAndConditions }> {
        const url = `${this.baseUrl}/accesscontrol/Account/GetTermsAndCondition`
        return this.http.get<{ [key: string]: TermsAndConditions }>(url).pipe(
            map(data => {
                return { termsAndConditions: data['response'] };
            })
        );
    }
    getAssetMake(siteId?: number): any {
        return this.http.get<string>(`${this.baseUrl}/assetmanagement/Asset/assetMakeDetails/${siteId}`);
    }
    updateTermsAgreement(agreeTerms: boolean) {
        const url = `${this.baseUrl}/accesscontrol/Account/UpdateAgreement`
        return this.http.patch<any>(url, agreeTerms);
    }

    getUserStatus(): Observable<any> {
        const userAccounturl = `${this.baseUrl}/accesscontrol/Account`
        return this.http.get<any>(userAccounturl).pipe(
            map(data => {
                this.sharedService.addUser(data.response);
                return data.response.hasAgreementSigned;
            }),
            switchMap((hasAgreementSigned: boolean) => {
                if (hasAgreementSigned) {
                    return this.getApplications();
                } else {
                    return this.getTermsAndConditions();
                }
            })
        );
    }

    updateAgreement(agreeTerms: boolean): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/Account/UpdateAgreement`
        return this.http.patch<any>(url, agreeTerms).pipe(
            map(data => data.response && data.success),
            switchMap((result) => {
                if (result) {
                    return this.getUserStatus();
                } else {
                    return of('error in UpdateAgrrement API');
                }
            })
        );
    }

    getSubAssetsForAssetId(id: string): Observable<Asset[]> {
        return this.http.get<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/AssetInfo/` + id);
    }

    getSubAssetsForAssetIdABS(id: string, statuspayload = {}): Observable<Asset[]> {
        return this.http.post<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/SubAsset/vertical/${id}/false`, statuspayload);
    }

    getSubAssetsForAssetIdIBABS(id: string): Observable<Asset[]> {
        return this.http.post<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/SubAsset/vertical/${id}/true`, {});
    }

    getAllSubAssetsForFeeder(assetId: number, feeder: string): Observable<any> {
        return this.http.post<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/SubAssetABSIBInfo/${assetId}/true?feederNo=${feeder}`, {});
    }

    getAllSubAssetsForFeederABS(assetId: number, feeder: string, statuspayload = {}): Observable<any> {
        return this.http.post<Asset[]>(`${this.baseUrl}/assetmanagement/SubAsset/SubAssetABSInfo/${assetId}/false?feederNo=${feeder}`, statuspayload);
    }

    getAssetLifeCycle(id: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/assetmanagement/Asset/assetHeaderDetails/` + id);
    }

    getAssetLifeCycleIB(id: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/assetmanagement/Asset/assetHeaderIBDetails/` + id);
    }

    saveDateForAsset(postData: any, id: string): Observable<any> {
        const url = `${this.baseUrl}/assetmanagement/Asset/${id}`;
        return this.http.put<any>(url, postData).pipe(
            map((successResponse) => {
                return successResponse;
            }),
            catchError(error => {
                return throwError(() => error.error);
            })
        )
    }

    //   getAssetForSiteId(id: string): Observable<any> {
    //     return this.http.get<any>(`${this.baseUrl}/assetmanagement/Overview/assetDetails/${id}/0`).pipe(catchError(error => of(error)));
    //   }

    logout() {
        return this.http.get<any>(`${this.baseUrl}/accesscontrol/Account/logoutUrl/`).pipe(catchError(error => {
            console.log(error);
            return throwError(() => error);
        }));
    }

    validateLoogbook(data: any): Observable<any> {
        const url = `${this.baseUrl}/contentmanagement/Logbook/Validate`;
        return this.http.post<any>(url, data).pipe(catchError(error => of(error)));;
    }

    updateAssetDetails(payload: any): Observable<any> {
        const url = `${this.baseUrl}/assetmanagement/Asset/AssetABS`;
        return this.http.put<any>(url, payload).pipe(
            map((successResponse) => {
                return successResponse;
            }),
            catchError(error => {
                return throwError(() => error.error);
            })
        )
    }

    getTotalAlarmCount(customerId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/alarm/AlarmDetails/AlarmCount/${customerId}`);
    }

    getCountry(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/accesscontrol/Country/all`);
    }

    getBusinessType(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/BussinessTypes`);
    }

    getUserProfileDetails(userId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/UserProfileView/${userId}`);
    }

    // Add User Details
    addUserProfileDetails(body: any): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/UserProfile/update`
        return this.http.put<any>(url, body).pipe(catchError(error => of(error)));
    }

    //add User profile picture
    updateUserProfilePicture(file: any, userId: number): Observable<any> {
        const formData = new FormData();
        formData.append('image', file);
        const url = `${this.baseUrl}/accesscontrol/UserProfile/profilePicUpdate/${userId}`
        return this.http.post<any>(url, formData).pipe(catchError(error => of(error)));
    }

    //delete User profile picture
    deleteUserProfilePicture(userId: number): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/UserProfile/deleteProfilePic/${userId}`
        return this.http.delete<any>(url).pipe(catchError(error => of(error)));
    }

    //download Bulk QR template 
    downloadBulkQRCode(): Observable<any> {
        const url = `${this.baseUrl}/contentmanagement/BulkQrCode/generate`;
        return this.http.get<any>(url, { responseType: 'blob' as 'json' });
    }

    uploadBulkQRCode(formData: any): Observable<any> {
        const url = `${this.baseUrl}/report/Report/bulkUploadQRCode`;
        return this.http.post<any>(url, formData, { responseType: 'blob' as 'json' }).pipe(catchError(error => of(error)));
    }

    getRequestTypes(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/accesscontrol/UserProfile/contactReqTypes`);
    }

    submitContactRequest(payload: any): Observable<any> {
        const url = `${this.baseUrl}/accesscontrol/UserProfile/SupportRequest`
        return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
    }

}
