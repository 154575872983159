import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetFilter'
})
export class AssetFilterPipe implements PipeTransform {

  transform(value: any, searchText: string, searchKey?: string, category?: string): any {
    if (!value) return null;
    if (!searchText) return value;

    if (category === 'HealthStatus') {
      // button click filter
      return value.filter((value: any) => {
        return value.AssetHealth.value.toLowerCase() === searchText.toLowerCase();
      });
    } else if (searchKey && !category){
      // url withquery Params
      return value.filter((value: any) => {
        return value[searchKey].value.statusName.toLowerCase() === searchText.toLowerCase();
      });
    }
  }

}
