import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    private baseUrl = environment.apiURL;

    constructor(
        private http: HttpClient,
    ) {
    }

    savePushSubscription(payload: any) {
        const url = `${this.baseUrl}/alarm/AlarmDetails/pushSubscription`;
        return this.http.post<any>(url, payload).pipe(catchError(error => of(error)));
    }

    checkForPushSubscription(pushSubscription) {
        const url = `${this.baseUrl}/accesscontrol/Account/pushSubscription`;
        return this.http.post<any>(url, pushSubscription).pipe(catchError(error => of(error)));
    }

    requestForNotification(userid?: any) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Permission granted');
                this.requestPermission(userid);
            } else {
                console.log('Permission not granted');
            }
        });
    }

    getAvailableSubscription(userid?: any) {
        const currentToken = localStorage.getItem('endpointToken');
        const payload = currentToken ? { subscriptionEndpoint: currentToken } : { subscriptionEndpoint: '' };
        this.checkForPushSubscription(payload).subscribe({
            next: (response) => {
                if (response.response) {
                    this.requestPermission(userid);
                }
                if (!response.response && currentToken) {
                    this.listen();
                }
            },
            error: (error) => console.log('error :', error)
        })
    }

    unsubscribeNotifiactions() {
        const messaging = getMessaging();
        deleteToken(messaging);
        localStorage.removeItem('endpointToken');
    }

    requestPermission(userid?: any) {
        console.log('checkinggggggggg');
        const messaging = getMessaging();
        getToken(messaging,
            { vapidKey: environment.firebase.vapidKey }).then(
                (currentToken) => {
                    if (currentToken) {
                        console.log('Token received ', currentToken);
                        localStorage.setItem('endpointToken', currentToken);
                        const payload = { subscriptionEndpoint: currentToken, userId: userid };
                        this.savePushSubscription(payload).subscribe({
                            next: (res) => {
                                this.listen();
                            },
                            error: (error) => console.log('error :', error)
                        })
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
    }
    listen() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // this.message = payload;

            navigator.serviceWorker.register('/firebase-messaging-sw.js').then(registration => {
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                    body: payload.notification.body,
                };
                registration.showNotification(notificationTitle,
                    notificationOptions);
            });
        })

    }

    //         curl -X POST \
    //   https://fcm.googleapis.com/fcm/send \
    //   -H 'Authorization: key=AAAA7yu_8Lc:APA91bHuzTsArnzdnXrjHFWGJfrXKBBb2HaCchcByb4oz9p07VmZD30okNIwzTP2qIyI4Vg6yXRLse2iB6LP35sa2TCjQF6O6asqZPa8K2v8Lrkvra6QQbtG4anKKXKx-Vdg3WgsRSl6' \
    //   -H 'Content-Type: application/json' \
    //   -d '{ 
    //  "notification": {
    //   "title": "First Notification", 
    //   "body": "Hello from Jishnu!!"
    //  },
    //  "to" : "dDh4JDZcEcV_ctyWZatLcD:APA91bEvZkyHFIYO-MU4Kkrif2RKjVGV8mgq7IBJigpa2HwQk7ZjRbY8f8Bp5N01hNvdzSM0s2qRWffMxSe41XT3R2ARsamzw-_vaYyFxxkCpgHj_MMonARRz-hNMpheaCQLwHkNrqR6"
    // }'

    // {
    //     "type": "service_account",
    //     "project_id": "ionecloud-push",
    //     "private_key_id": "7e30259e8c71641e5e218d81f0fca11fa23cdec6",
    //     "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC/t3Dv8uVQqb0s\nwFWXore5InxK545umZUjI790IjK3qHwtI2A7+xURTTXfiyLTiNXTG7+t2+/QO5+O\nbi2OdPjzbir/flOVEW9W/ARHf+oEbShfj1flAivpzv5fJntZVY/uCcBG8OUodNVX\njEemh9tHfhDssYpHJEhxG3Eyx51ULHOBU56d++uH3MKIl3gsxwLci+nRmV5P3qqd\n7W//Er78IfJ0WvFMRYV2lGTnu+lH/eMhEKh1RWfxyDtLwmZ9U57AEs7ncyxlUUL1\nlUTx8KlJC1zgLbrlVNdXwlC5OSCfxOr2qtoo1AIBAuuTHCmG0s0mpz4MD8GnPHiT\nFOYBy5MZAgMBAAECggEAE+INqJcNfRSDigH/BoFR0lWvJBIYkaQkRV8SXrQixC0p\nRsGn3ASl6lPSgecDU0K6YWD7+8cm+5bJbRtEZoU/YfjDpRiICNpkbJYpx/QOVEvx\nX1rFk/rKDN9CvqHGMm1HJnn0qu7WJ2BZlXE3J6Bop6Udkny1wXvT9wYQcwKkkJt2\nwWHGtpRScnVEePNXN7OzDzBXIgdwQ9eW+jWWtDQRyK3BSgLGk1CQFDiLE0l9TRNe\nHgEezLZF0DYHLBn3IyMZCgJy54iEwU3OsJf9LdGEBsFjyY3GayRWCJNMfm61Lydc\n/T3JCSACX/nN/b2RAZOY7ndXgjGmZhmQ34+i1PkywQKBgQD7yso3XgkFs5qe2Pp0\ndaKzBCV4HY4rjfm+5IQ69AJvFg2wgob31JGaIDFr1Vm6H6C7HrWaqSazc+M3NCQP\nt3SjXKkXshVjq+7xtj3+4znkd0ZPy/yBRApynE683ykYUnGPQd65JGcFVvK76154\ngPKHPUvqKacshvKA2P9mBTaLWQKBgQDC66M5LqtBIsaZIlT4ahtyO31O9UfLOyQr\nuAiiYtDsZ1d+kc4Tpnx1Mnp7sYqSOzkBXtFFWWQ8uqc3xbZQEJfNxwa31daZuvy1\nZCuAieW39gBVTDc2/LwcFyWg5zkzLI0WbZi/wdQnha/St3pRybVClKAK5OveGwWf\nOswBtUENwQKBgCcefIhZncE7BDzFrTPGyv5RGJkuzlXJ+xFDY5/dJTupC3tu7BU4\n+14cvlDolsYp/8jSnphmy1J6IMUE3s1qTT7MOKEr09LmdXssOzmMiAQM2+mt4Y8b\nyRvBWSJS8YmL1YTSUQ4mr7LOrWuwx76lGgkU5uR8kDKEzMxj6lopWS4RAoGAPAyY\nBl/IRpLOGBMpljYqKnEFU7Pg4E1iAU366vwCXTIhFE8a65bYtMpOTZkOlapFD5UE\nZ5b4eHUQWvBAED5sZeqwuSXZrRacD83CUSEqchgJVj3arMgDs6u13fId/xP0izpM\nWpb+Iwbeec4oktXPT6m1/GU1/QDaJInam5QZbIECgYAPh1qOSNa5SrSLxsezt1wJ\nLMG/8GNdnPrzcho1ZbYxYLWZ/wuWnBqovwPwifG++b3LmmaPZCB1/fDnbC/+R23I\njzc7Azc801sdaHh8GsCQXixKwxk1iwxieBhuSAVzLqJTgUYOaZPfa57HHo6zNZjv\n7tA6zs+u5OExKzrZms9dLQ==\n-----END PRIVATE KEY-----\n",
    //     "client_email": "firebase-adminsdk-cd5bg@ionecloud-push.iam.gserviceaccount.com",
    //     "client_id": "106916762452024801086",
    //     "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    //     "token_uri": "https://oauth2.googleapis.com/token",
    //     "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    //     "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-cd5bg%40ionecloud-push.iam.gserviceaccount.com",
    //     "universe_domain": "googleapis.com"
    //   }
}