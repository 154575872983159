import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs/internal/Subscription';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { ObservedCondition, Index, IndexData, MeasuredCondition, MaintenanceIndex, Observation, SubAssetRisk, Recommendation } from 'src/app/_models/health.type';
import { AssetService } from 'src/app/_services/assets.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SiteManagementService } from 'src/app/_services/site-management.service';

@Component({
  selector: 'app-healthkpi',
  templateUrl: './healthkpi.component.html',
  styleUrls: ['./healthkpi.component.scss']
})
export class HealthkpiComponent implements OnInit, OnDestroy {
  @Input() target: string = '';
  @Input() data: any;
  @Input() index!: number;
  @Output() subAssetSelection = new EventEmitter<any>();

  openCriteria: Boolean = false;
  openMeasuredCriteria: Boolean = false;

  selectedId!: string;
  chartData: any;
  expanded: Boolean = false;
  selectedTab: string = 'observation';
  measuredCondition!: Observation[];
  observedCondition!: Observation[];
  offsetDate: string = '';
  maintenanceIndex!: MaintenanceIndex;
  contactWear!: Index;
  subAssetRiskMatrix!: SubAssetRisk[];
  recommendations!: Recommendation[];
  lastMaintenanceDateEdit: Boolean = false;
  maintenanceScheduleEdit: Boolean = false;
  lastMaintenanceDate!: any;
  maintenanceSchedule: any;


  selectedObservedList: any = [];
  selectedMeasuredList: any = [];

  observedConditionList!: ObservedCondition[];
  measuredConditionList!: MeasuredCondition[];

  allObservedCriteria: ObservedCondition[][] = [];
  allMeasuredCriteria: MeasuredCondition[][] = [];

  measuredConditonError: Boolean = false;
  measuredConditonMessage: string = '';
  observedConditonMessage: string = '';
  observedConditonError: Boolean = false;
  savedDateError: boolean = false;
  savedDateMsg: string = '';
  lastMaintenanceDateFlag: boolean = false;
  maintenanceScheduleFlag: boolean = false;
  disabled: boolean = true;
  siteCustomerId!: number;
  forecastData!:any;
  isMobile: boolean = false;

  isNumeric: boolean = false;

  private subscription: Subscription = new Subscription();
  isLoading: boolean = false;


  constructor(
    private sharedService: SharedService,
    private assetService: AssetService,
    private siteService: SiteManagementService,
    private settingsService: SettingsService,
    private deviceService: DeviceDetectorService,) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
        this.isLoading=true;
        this.fetchData();
    }
  }

  fetchData(){
    this.disabled = true;
    this.getHealthCriticalRiskIndex();
    this.getObservationData();
    this.getMeasuredData();
    this.getObservationList();
    this.getMaintenanceIndexData();
    this.getMeasuredList();
    this.getSubAssetRiskMatrix();
    this.getHealthRecommendation();
    if (this.data && this.data.feeder)
      this.getHealthContactWear();
    this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
    if(this.data && this.data.siteId){
      this.getSiteCustomerId();
    }
    this.epicFunction();
  }

  epicFunction(){
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  getSiteCustomerId() {
    this.siteService.getOverviewSiteList(this.data.siteId).subscribe((siteDetails: any) => {
      this.siteCustomerId = siteDetails.general.customerId;
    })
  }
  
  //Forecast
  getForecast() {
    if (this.siteCustomerId) {
      this.settingsService.getAlgorithmReferenceSetting(this.siteCustomerId).subscribe((data: any) => {
        this.forecastData = data;
      });
    }
  }

  getHealthCriticalRiskIndex() {
    this.assetService.getHealthCriticalRiskIndex(this.data?.assetId, this.data?.feeder).subscribe((chartData: IndexData) => {
      this.chartData = chartData;
      this.isLoading=false;
    })
  }

  getMeasuredData() {
    this.assetService.getHealthMeasuredData(this.data?.assetId, this.data?.feeder).subscribe((data: Observation[]) => {
      this.measuredCondition = data;
    })
  }

  getObservationData() {
    this.assetService.getHealthObservationData(this.data?.assetId, this.data?.feeder).subscribe((data: Observation[]) => {
      this.observedCondition = data;
    })
  }

  getMaintenanceIndexData() {
    this.assetService.getMaintenanceIndexData(this.data?.assetId, this.data?.feeder).subscribe((data: MaintenanceIndex) => {
      this.maintenanceIndex = data;
      this.lastMaintenanceDate = (this.maintenanceIndex.lastMaintenance)?.toString()?.split("T")[0];
      this.maintenanceSchedule = this.maintenanceIndex.maintenanceSchedule;
    })
  }

  getObservationList() {
    this.assetService.getObservationList(this.data?.feeder).subscribe((data: ObservedCondition[]) => {
      this.observedConditionList = data;
      for (let i = 0; i < 5; i++) {
        this.selectedObservedList.push({ condition: {}, criteria: {}, disabled: false });
        this.allObservedCriteria.push([]);
      }
    })
  }

  getCriteriaList(id: number, index: number) {
    this.assetService.getCriteriaList(id).subscribe((data: ObservedCondition[]) => {
      this.allObservedCriteria[index] = data;
    })
  }

  getMeasuredList() {
    this.assetService.getMeasuredList(this.data?.feeder).subscribe((data: MeasuredCondition[]) => {
      this.measuredConditionList = data;
      for (let i = 0; i < 5; i++) {
        this.selectedMeasuredList.push({ condition: {}, criteria: {}, disabled: false, isNumeric: false });
        this.allMeasuredCriteria.push([]);
      }
    })
  }

  checkIfOperations(condition: string) {
    if(condition.includes('Operations')) return true;
    return false;
  }

  getMeasuredCriteriaList(id: number, index: number) {
    this.selectedMeasuredList[index].isNumeric = this.checkIfOperations(this.selectedMeasuredList[index].condition?.measuredCondition);
    this.assetService.getMeasuredCriteriaList(id).subscribe((data: MeasuredCondition[]) => {
      this.allMeasuredCriteria[index] = data;
    })
  }

  getHealthContactWear() {
    this.assetService.getHealthContactWear(this.data?.assetId, this.data?.feeder).subscribe((data: Index) => {
      this.contactWear = data;
      this.contactWear.indexRange = { 0: 0, 100: 100 };
    })
  }

  getSubAssetRiskMatrix() {
    this.assetService.getSubAssetRiskMatrix(this.data?.assetId).subscribe((data: SubAssetRisk[]) => {
      this.subAssetRiskMatrix = data;
    })
  }

  getHealthRecommendation() {
    this.assetService.getHealthRecommendation(this.data?.assetId, this.data?.feeder).subscribe((data: Recommendation[]) => {
      this.recommendations = data;
    })
  }

  setSelectedId(id: string) {
    this.selectedId = id;
    this.expanded = true;
  }

  onConditionChange(e: any, index: number) {
    this.observedConditionList.forEach((item:any) =>{
      let pos = this.selectedObservedList.map((e:any) => e.condition?.id).indexOf(item.id);
      if(pos == -1)
        item.disabled = false;
    })
    this.getCriteriaList(this.selectedObservedList[index].condition?.id, index);
  }

  onCriteriaChange(e: any, index:number) {
    this.disabled = false;
    let id =this.selectedObservedList[index].condition?.id;
    let pos = this.observedConditionList.map((e:any) => e.id).indexOf(id);
    if(pos !== -1) {
      this.observedConditionList[pos].disabled = true;
    }
  }

  onMeasuredConditionChange(e: any, index: number) {
    this.measuredConditionList.forEach((item:any) =>{
      let pos = this.selectedMeasuredList.map((e:any) => e.condition?.id).indexOf(item.id);
      if(pos == -1)
        item.disabled = false;
    })
    this.getMeasuredCriteriaList(this.selectedMeasuredList[index].condition?.id, index);
  }

  onMeasuredCriteriaChange(e: any, index:number, type?:string) {
    if(type) {
      this.selectedMeasuredList[index].criteria.id = '0';
      this.selectedMeasuredList[index].criteria.measuredCriteria = e?.target?.value;
    }
    this.disabled = false;
    let id =this.selectedMeasuredList[index].condition?.id;
    let pos = this.measuredConditionList.map((e:any) => e.id).indexOf(id);
    if(pos !== -1) {
      this.measuredConditionList[pos].disabled = true;
    }
  }

  submitConditionsCriteria() {
    let observedConditions: any = [];
    for (let i = 0; i < this.selectedObservedList.length; i++) {
      if (this.selectedObservedList[i].criteria && this.selectedObservedList[i].criteria.id)
        observedConditions.push({
          observedCondition: this.selectedObservedList[i].condition.observedCondition,
          id: this.selectedObservedList[i].criteria.id,
          conditionCriteriaId: this.selectedObservedList[i].condition.id,
          referenceIndex: this.selectedObservedList[i].criteria.referenceIndex
        })
    }
    let payload = {
      assetId: this.data?.assetId,
      feederNumber: this.data?.feeder,
      observedConditions: observedConditions
    }

    this.assetService.saveObservedConditions(this.data?.siteId, payload).
      subscribe({
        next: (data: any) => {
          this.observedConditonError = false;
          this.observedConditonMessage = 'Submitted successfully!';
          this.getObservationData();
          setTimeout(() => {
            this.resetOptions();
          }, 5000);
        },
        error: (e: Error) => {
          this.observedConditonError = true;
          this.observedConditonMessage = 'Could not save';
          setTimeout(() => {
            this.resetOptions();
          }, 5000);
        }
      });
  }

  submitMeasuredConditionsCriteria() {
    let measuredConditions: any = [];
    for (let i = 0; i < this.selectedMeasuredList.length; i++) {
      if (this.selectedMeasuredList[i].criteria && this.selectedMeasuredList[i].criteria.id)
        measuredConditions.push({
          measuredCondition: this.selectedMeasuredList[i].condition.measuredCondition,
          id: this.selectedMeasuredList[i].criteria.id,
          conditionId: this.selectedMeasuredList[i].condition.id,
          referenceIndex: this.selectedMeasuredList[i].criteria.referenceIndex,
          criteriaValue: this.selectedMeasuredList[i].criteria.measuredCriteria
        })
    }
    let payload = {
      assetId: this.data?.assetId,
      feederNumber: this.data?.feeder,
      measuredConditions: measuredConditions
    }

    this.assetService.saveMeasuredConditions(this.data?.siteId, payload).
      subscribe({
        next: (data: any) => {
          this.measuredConditonError = false;
          this.measuredConditonMessage = 'Submitted successfully!';
          this.getMeasuredData();
          setTimeout(() => {
            this.resetOptions();
          }, 5000);
        },
        error: (e: Error) => {
          this.measuredConditonError = true;
          this.measuredConditonMessage = 'Could not save';
          setTimeout(() => {
            this.resetOptions();
          }, 5000);
        }
      })
  }

  saveLastMaintenanceDate() {
    this.lastMaintenanceDateFlag = true;
    this.assetService.saveLastMaintenanceDate(this.data?.assetId, this.data?.feeder, this.lastMaintenanceDate).
      subscribe({
        next: (data: any) => {
          this.savedDateError = false;
          this.savedDateMsg = 'Saved Successfully';
          this.getMaintenanceIndexData();
          setTimeout(() => {
            this.resetError();
          }, 4000);
        },
        error: (e: Error) => {
          this.savedDateError = true;
          this.savedDateMsg = 'Could not save!';
          setTimeout(() => {
            this.resetError();
          }, 4000);
        }
      });
  }

  saveMaintenanceScheduleDate() {
    this.maintenanceScheduleFlag = true;
    this.assetService.saveMaintenanceScheduleDate(this.data?.assetId, this.data?.feeder, this.maintenanceSchedule).
      subscribe({
        next: (data: any) => {
          this.savedDateError = false;
          this.savedDateMsg = 'Saved Successfully';
          this.getMaintenanceIndexData();
          setTimeout(() => {
            this.resetError();
          }, 4000);
        },
        error: (e: Error) => {
          this.savedDateError = true;
          this.savedDateMsg = 'Could not save!';
          setTimeout(() => {
            this.resetError();
          }, 4000);
        }
      });
  }

  resetError() {
    this.savedDateError = false;
    this.savedDateMsg = '';
    this.lastMaintenanceDateFlag = false;
    this.maintenanceScheduleFlag = false;
  }

  resetOptions() {
    this.disabled = true;
    this.selectedObservedList = [];
    this.allObservedCriteria = [];
    this.selectedMeasuredList = [];
    this.allMeasuredCriteria = [];
    this.measuredConditionList.forEach((item:any) =>{
        item.disabled = false;
    })
    this.observedConditionList.forEach((item:any) =>{
      item.disabled = false;
  })
    for (let i = 0; i < 5; i++) {
      this.selectedObservedList.push({ condition: {}, criteria: {}, disabled: false });
      this.allObservedCriteria.push([]);
      this.selectedMeasuredList.push({ condition: {}, criteria: {}, disabled: false });
      this.allMeasuredCriteria.push([]);
    }
    this.observedConditonError = false;
    this.observedConditonMessage = '';
    this.measuredConditonError = false;
    this.measuredConditonMessage = '';
  }

  scroll(){
    if(this.isMobile){
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
