<div class="w-100" style="overflow-y:auto">
    <div class="card w-100 mt-3" *ngFor="let element of feedersData; let i = index">
        <div class="alarm-data collapsedView" id="element{{i+tabno}}">
            <div class="row ps-2 pe-2 mx-1">
                <div class="col-12 list-value" style="position: relative;">
                    <span style="position: absolute; top:23px;right: 17px;" role="button">
                        <i class="fa fa-angle-up hide" (click)="toggleHeight(i+tabno, 'collapse')"
                            id="collapse{{i+tabno}}"></i>
                        <i class="fa fa-angle-down show" (click)="toggleHeight(i+tabno, 'expand')"
                            id="expand{{i+tabno}}"></i>
                    </span>
                </div>
                <ng-container *ngFor="let item of getKeys(element)">
                    <ng-container *ngIf="item !== 'Tag'">
                        <div class="col-lg-3 col-6 px-3" *ngIf="!item.includes('Id')">
                            <div class="field-header mt-2">{{element[item]?.displayName | camelToSpace |
                                titlecase}}</div>
                            <div [id]="item" class="field-value col" *ngIf="!(assetEdit && selectedField == item)">
                                <ng-container
                                    *ngIf="element[item].dataType !== 'DateTime' && element[item].dataType !== 'Boolean' && item !== 'DeletionFlag'">
                                    <span>{{(element[item].value)?
                                        element[item].value :
                                        '-'}} </span>
                                </ng-container>
                                <ng-container *ngIf="element[item].dataType == 'DateTime' && item !== 'DeletionFlag'">
                                    <span>{{element[item].value ? (element[item].value | date: 'd/M/yyyy') : '-'}} </span>
                                </ng-container>
                                <ng-container *ngIf="element[item].dataType == 'Boolean' && item !== 'DeletionFlag'">
                                    <span *ngIf="item =='IsDigital'">{{element[item].value ?
                                        'Digital' : 'Non Digital'}} </span>
                                    <span
                                        *ngIf="item !=='IsDigital' && element[item].value.length">{{element[item].value.length
                                        ?
                                        'Yes' : 'No'}} </span>
                                    <span *ngIf="item !=='IsDigital' && !element[item].value.length">-</span>
                                </ng-container>
                                <ng-container *ngIf="item === 'DeletionFlag'">
                                    <span *ngIf="item =='DeletionFlag'"> {{element[item].value ? element[item].value : '-'}}</span>
                                </ng-container>
                                <span class="edit-icon"><i class="fa fa-pencil" (click)="setEditProperties(element, item)"
                                        *ngIf="isEditableForUser && element[item].isWritable"></i></span>
                            </div>
                            <ng-container
                                *ngIf="element[item].dataType !== 'DateTime' && element[item].dataType !== 'Boolean'  && item !== 'DeletionFlag'">
                                <input id="input-{{item}}" type="text" [(ngModel)]="element[item].value"
                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable"
                                    (keydown)="$event.stopPropagation()" class="edit-asset col">
                            </ng-container>
                            <ng-container *ngIf="element[item].dataType == 'DateTime'  && item !== 'DeletionFlag'">
                                <input id="input-{{item}}" type="date" [(ngModel)]="element[item].value"
                                    *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable"
                                    (keydown)="$event.stopPropagation()" class="edit-asset col">
                            </ng-container>
                            <ng-container *ngIf="element[item].dataType == 'Boolean'  && item !== 'DeletionFlag'">
                                <ng-container *ngIf="item =='IsDigital'">
                                    <select name="isdigital" id="isdigital" class="edit-asset col"
                                        [(ngModel)]="element[item].value"
                                        *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable"
                                        (keydown)="$event.stopPropagation()">
                                        <option value="true">Digital</option>
                                        <option value="false">Non Digital</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="item !=='IsDigital'">
                                    <select name="notdigital" id="notdigital" class="edit-asset col"
                                        [(ngModel)]="element[item].value"
                                        *ngIf="isEditableForUser && assetEdit && selectedField == item && element[item].isWritable"
                                        (keydown)="$event.stopPropagation()">
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="item ==='DeletionFlag' && isEditableForUser && assetEdit && selectedField == item && element[item].isWritable">
                                <input type="checkbox" value="" id="input-{{item}}" [(ngModel)]="element[item].value" [checked]="element[item].value" #check>
                            </ng-container>
                            <div class="col" (click)="selectedField = item"
                                *ngIf="(isEditableForUser && assetEdit && selectedField == item && element[item].isWritable)">
                                <i class="fa fa-check healthy p-1" title="Save" (click)="updateAsset(element,item)"></i>
                                <i class="fa fa-times critical p-1 ms-1" title="Cancel"
                                    (click)="assetEdit = !assetEdit;element[item].value=oldVal"></i>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

            </div>
        </div>
    </div>
</div>