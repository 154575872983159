import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatListOption } from '@angular/material/list/selection-list';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from 'rxjs';
import { RbacService } from '../_helpers/rbac.service';
import { AlarmQuery } from '../_models/alarm.query';
import { AlarmDetails, AlarmFilter, AlarmFilterKeys, Alarms, AlarmStatistics } from '../_models/alarm.type';
import { AlarmService } from '../_services/alarm.service';
import { IPredictService } from '../_services/i-predict.service';
import { SharedService } from '../_services/shared.service';
import { SiteManagementService } from '../_services/site-management.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-alarm',
    templateUrl: './alarm.component.html',
    styleUrls: ['./alarm.component.scss'],
})
export class AlarmComponent implements OnInit {

    @ViewChild('paginator') paginator: MatPaginator;

    sites: any;
    isWorkProgress: boolean = false;
    siteId: number = 0;
    isDesktopDevice: boolean = true;
    isMobile: boolean = false;
    alarms!: AlarmDetails[];
    alarmStatistics: AlarmStatistics = { criticalAlarm: 0, all: 0, highAlarm: 0, lowAlarm: 0, warnings: 0, notifications: 0 };
    selectedOptions!: any;
    searchKey: string = '';
    statusFilterText: string = '';
    statusFilterType: string = '';
    selectedStatus: string = '';
    wipno: string = '';
    selectedAlarmId: string = '';
    comment: string = '';
    commentEdit: boolean = false;
    selectedComment: string = '';
    commentUpdated: string = '';
    mouseover: boolean = false;
    filterControl = new FormControl();
    newAlarms!: AlarmDetails[];
    alarmFilterData: AlarmFilter = {
        alarmClass: {
            list: [],
            countLimit: 3,
            hidden: true,
            filteredList: []
        },
        eventType: {
            list: [],
            countLimit: 3,
            hidden: true,
            filteredList: []
        },
        status: {
            list: [],
            countLimit: 3,
            hidden: true,
            filteredList: []
        },
        assetCategoryName: {
            list: [],
            countLimit: 3,
            hidden: true,
            filteredList: []
        },
        Make: {
            list: [],
            countLimit: 3,
            hidden: true,
            filteredList: []
        }
    }
    selectedFilterLabel: Array<string> = [];
    showNoDataFilter: boolean = false;
    filterFromDate: string = '';
    filterToDate: string = '';
    isLoading: boolean = true;
    abc: boolean = false;
    Feedback: string = "";
    Message: string = "";
    feederNo: string = "";
    isCommon!: boolean;
    group: string = "";
    oldVal!: string;
    responseMessage!: string;
    routeParams: boolean = false;

    @ViewChild('alarmFilters', { static: false }) alarmFilters!: MatSelect;


    private subscription: Subscription = new Subscription();
    assetId: any;
    pageEvent!: PageEvent;
    totalRecords: number = 0;
    pageSize: number = 10;
    alarmQuery: AlarmQuery = new AlarmQuery();
    // alarmQuery: {[key: string]: any} = new AlarmQuery();

    pageNumber: any = 1;
    innerHeight!: number;
    offsetDate: string = '';
    searchOpen: Boolean = false;
    module: any;
    isNotification = false;
    isClosedAlarm = false;
    showMsg: string;
    showError: boolean = false;
    createStatus: boolean;
    currentDate = new Date().toISOString().split("T")[0];

    constructor(private siteManagementService: SiteManagementService,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private alarmService: AlarmService,
        private ipredict: IPredictService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private sharedService: SharedService,
        private rbacService: RbacService,
        public dialog: MatDialog
    ) {
        this.epicFunction();
        this.route.queryParams.subscribe(qParams => {
            if (qParams['siteId']) {
                this.routeParams = true;
            }
            else {
                this.routeParams = false;
            }
            this.siteId = qParams['siteId'];
            this.assetId = qParams['assetId'];
            this.feederNo = qParams['feederNo'];
            this.group = qParams['group'];
            this.isCommon = qParams['isCommon'];
            this.alarmQuery.assetId = Number(this.assetId);
            this.alarmQuery.siteId = this.siteId;
            this.alarmQuery.feederNo = this.feederNo;
            this.alarmQuery.group = this.group;
            this.alarmQuery.isCommon = this.isCommon;
        });
    }

    epicFunction() {
        this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
        this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
    }

    ngOnInit(): void {
        if (this.sharedService.user.id) {
            this.module = this.sharedService.modules;
        } else {
            this.rbacService.getUserInformation().subscribe((value: any) => {
                this.module = this.sharedService.modules;
            })
        }
        this.getSites();
        this.innerHeight = window.innerHeight;
    }

    getSites(refresh?: boolean) {
        this.siteManagementService.getSitesDropdown(true)
            .subscribe((data: any) => {
                this.sites = [];
                if (data && data.length) {
                    data.forEach((item: { siteId: any; siteName: any; }) => {
                        this.sites.push({ "siteId": item.siteId, "siteName": item.siteName });
                    });
                    if (refresh) {
                        this.siteId = this.sites[0].siteId
                    }
                    else {
                        this.siteId = this.siteId || this.sites[0].siteId;
                    }
                    this.getAlarmsWithPagination(true);
                    this.setFilterValues(this.siteId);
                }
            });
    }

    toggleHeight(id: string, action: string, e?: any) {
        let elementId = 'alarm' + id;
        let el = document.getElementById(elementId) as HTMLElement;
        let expandIcon = document.getElementById('expand' + id) as HTMLElement;
        let collapseIcon = document.getElementById('collapse' + id) as HTMLElement;
        if (action == 'expand') {
            el.classList.add("expandedView");
            el.classList.remove("collapsedView");
            expandIcon.classList.remove('show');
            expandIcon.classList.add('hide');
            collapseIcon.classList.remove('hide');
            collapseIcon.classList.add('show');
            this.prevent(e);
        } else if (action == 'collapse') {
            el.classList.remove("expandedView");
            el.classList.add("collapsedView");
            expandIcon.classList.add('show');
            expandIcon.classList.remove('hide');
            collapseIcon.classList.add('hide');
            collapseIcon.classList.remove('show');
            this.prevent(e);
        }

    }

    onSiteChange(e: any) {
        this.isLoading = true;
        if (this.alarmFilters) {
            this.alarmFilters.options.forEach((item: MatOption) => item.deselect());
        }
        this.statusFilterText = '';
        this.statusFilterType = '';
        this.searchKey = '';
        let el = document.getElementById('selectAll') as any;
        if (el && el.checked) {
            el.click();
        }
        this.alarmQuery.alarmClass = [];
        this.alarmQuery.eventClass = [];
        this.selectedOptions = [];
        this.getAlarmsWithPagination(true);
        this.setFilterValues(this.siteId);
    }

    getAlarms(siteId?: number) {
        this.subscription.add(
            this.alarmService.getAlarms(this.siteId).subscribe((data: Alarms) => {
                this.alarms = data?.alarmDetailsDto;
                this.alarmStatistics = data?.alarmStatistics;
                this.totalRecords = data.totalRecords;
                this.newAlarms = this.alarms;
                this.isLoading = false;
                if (this.assetId)
                    this.getAlarmsForAsset();
                this.applyFilter();
            })
        );
    }

    getAlarmsWithPagination(isChange = false, from?: string) {
        // this.totalRecords = 0;
        // this.alarmStatistics.all = 0;
        // this.alarmStatistics.criticalAlarm = 0;
        // this.alarmStatistics.highAlarm = 0;
        // this.alarmStatistics.lowAlarm = 0;
        // this.alarmStatistics.notifications = 0;
        // this.alarmStatistics.warnings = 0;
        this.isLoading = true;
        let update = false;
        if (from) {
            if ((this.oldVal || this.searchKey.length) && this.oldVal !== this.searchKey) {
                this.oldVal = this.searchKey;
                update = true;
            } else {
                update = false;
                this.isLoading = false;
            }
        } else update = true;
        if (update) {
            this.alarms = [];
            this.newAlarms = [];
            this.alarmQuery.searchString = this.searchKey;
            this.alarmQuery.siteId = this.siteId;
            if (this.assetId) this.alarmQuery.assetId = this.assetId;
            if (this.pageNumber === 1) this.paginator?.firstPage();
            this.subscription.add(
                this.alarmService.getAlarmsWithPagination(this.pageNumber, this.pageSize, this.alarmQuery).subscribe((data: Alarms) => {
                    if (data instanceof HttpErrorResponse) {
                        this.isLoading = false;
                        this.totalRecords = 0;
                        this.alarmStatistics = isChange ? { criticalAlarm: 0, all: 0, highAlarm: 0, lowAlarm: 0, warnings: 0, notifications: 0 } : this.alarmStatistics;
                    } else {
                        this.alarms = data?.alarmDetailsDto;
                        this.responseMessage = data?.responseMessage;
                        this.alarmStatistics = isChange ? (data?.alarmStatistics ? data?.alarmStatistics : { criticalAlarm: 0, all: 0, highAlarm: 0, lowAlarm: 0, warnings: 0, notifications: 0 }) : this.alarmStatistics;
                        this.totalRecords = data.totalRecords;
                        this.newAlarms = this.alarms;
                        this.isLoading = false;
                        if (this.assetId)
                            this.applyFilter();
                    }
                })
            );
        }
    }

    getData(event) {
        this.pageNumber = event.pageIndex + 1;
        this.pageSize = event.pageSize
        this.alarms = [];
        this.isLoading = true;
        this.getAlarmsWithPagination();
    }

    prevent(e: any) {
        e?.stopPropagation();
        e?.stopImmediatePropagation();
    }

    refetchData() {
        this.isLoading = true;
        this.route.queryParams.subscribe(qParams => {
            if (qParams['siteId'] && (qParams['assetId'] || qParams[this.feederNo])) {
                this.siteId = 0;
                this.assetId = '';
                this.feederNo = '';
                this.group = '';
                this.isCommon = false;
                this.router.navigate(['/layout/alarms']);
            }
        });
        this.getSites(true);
        this.statusFilterText = '';
        this.statusFilterType = '';
        this.searchKey = '';
        let el = document.getElementById('selectAll') as any;
        if (el && el.checked) {
            el.click();
        }
        this.alarmQuery.alarmClass = [];
        this.alarmQuery.eventClass = [];
    }

    onGroupsChange(options: MatListOption[]) {
        this.selectedOptions = options.map(o => o.value);
        if (this.selectedOptions.length == 1 && this.selectedOptions[0].status == "InProgress") {
            this.isWorkProgress = true;
        }
        else {
            this.isWorkProgress = false;
        }
        this.isNotification = this.selectedOptions.findIndex(item => item.eventType === "Notification") !== -1;
        this.isClosedAlarm = this.selectedOptions.findIndex(item => item.eventType === "Alarm" && item.status === "Closed") !== -1;
    }

    applyStatusFilter(value: string, type: string) {
        this.statusFilterType = type;
        this.statusFilterText = value;
        this.selectedOptions = [];
        if (type == 'alarmClass') {
            this.alarmQuery.alarmClass = [value];
            this.alarmQuery.eventClass = [];
        } else if (type == 'eventType') {
            this.alarmQuery.eventClass = [value];
            this.alarmQuery.alarmClass = [];
        } else {
            this.alarmQuery.alarmClass = [];
            this.alarmQuery.eventClass = [];

        }
        let el = document.getElementById('selectAll') as any;
        if (el && el.checked) {
            el.click();
        }
        this.pageNumber = 1;
        this.getAlarmsWithPagination();
    }

    changeStatus() {
        if (this.selectedStatus === 'Delete') {
            this.deleteAlarms();
            return;
        }
        if (this.selectedStatus === 'CreatePTW') {
            this.getPtwData();
            return;
        }
        if (this.selectedStatus === 'ERP') {
            return;
        }
        let payload = {
            "id": 0,
            "alarmId": this.selectedAlarmId ? this.selectedAlarmId : this.selectedOptions[0].Id,
            "actionType": this.selectedStatus,
            "workPermitNo": this.wipno
        };
        this.updateAlarms(payload, 'status');
    }

    addComment(comment: any, alarmId: string, alarm: any) {
        let payload = {
            "id": 0,
            "alarmId": alarmId,
            "comment": comment,
            "commentId": 0,
        };
        if (comment && comment.trim().length)
            this.updateAlarms(payload, 'comment', alarm);
    }

    updateComment(alarmId: string, commentId: string, comment: string, alarm: any, commonId: string) {
        let payload = {
            "id": 0,
            "alarmId": alarmId,
            "comment": comment,
            "commentId": commentId,
            "commonId": commonId
        };
        this.updateAlarms(payload, 'edit-comment', alarm);
        this.commentEdit = !this.commentEdit;
    }

    updateAlarms(payload: any, action: string, alarm?: any) {
        this.subscription.add(
            this.alarmService.updateAlarms(payload, action).
                subscribe({
                    next: (data: any) => {
                        if (action == 'comment') {
                            data.performedOn = data.performedOn.split('Z')[0];
                            alarm.comments ? alarm.comments?.unshift(data) : alarm.comments = [data];
                        } else if (action == 'edit-comment') {
                            data.performedOn = data.performedOn.split('Z')[0];
                            alarm.comments?.forEach((comment: any) => {
                                if (comment.id == payload.commentId)
                                    comment.performedOn = data.performedOn;
                            })
                        } else {
                            this.getAlarmsWithPagination();
                            this.selectedOptions = [];
                            let el = document.getElementById('selectAll') as any;
                            if (this.selectedOptions.length < this.alarms.length && el && el.checked) {
                                el.click();
                            }
                        }
                    },
                    error: (e: Error) => {

                        //   console.log("Comment failed");
                    }
                })
        );
    }

    showUIMsg(msg: string) {
        this.createStatus = true;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.showMsg = msg;
        setTimeout(() => { this.createStatus = false; }, 6000);
    }

    deleteAlarms() {
        const alarmIds = this.selectedOptions.map(item => item.Id);
        this.subscription.add(
            this.alarmService.deleteAlarms(alarmIds).subscribe(response => {
                this.showError = false;
                this.showUIMsg("Deleted Successfully");
                this.selectedOptions = [];
                this.getAlarmsWithPagination();
            }, (error) => {
                this.showError = true;
                this.showUIMsg(error?.error)
            })
        );
    }

    getPtwData() {
        const alarmId = this.selectedOptions[0].Id;
        this.subscription.add(
            this.alarmService.getPtwDetails(alarmId).subscribe(response => {
                const data = {
                    subAssets: [{ subAssetId: response?.subAssetId, feederNo: response?.feederNumber }],
                    assetId: response?.assetId,
                    customerId: response?.customerId,
                    siteId: this.siteId,
                    assetTypeId: response?.typeId,
                    underContract: response?.underContract,
                    location: response?.location,
                    checkLists: response?.checkLists,
                    maintenancePersonnelDetails: response?.maintenancePersonnelDetails,
                    ptwApprovalGroups: response?.ptwApprovalGroups,
                }
                if (!response?.isExisted) {
                    this.router.navigate(['/layout/DMM/workPermit/create'], { state: { data } });
                    return;
                }
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    width: '500px',
                    data: {
                        title: 'Are you sure to proceed?',
                        message: 'Asset' + (response?.assetName ? ' ' + response?.assetName : '') + ' has existing permit to works ' + response?.ptwIds.map(item => 'PW' + item),
                        cancelButtonText: 'Cancel',
                        okButtonText: 'Proceed'
                    },
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.router.navigate(['/layout/DMM/workPermit/create'], { state: { data } });
                    }
                });
            }, (error) => {
                this.showError = true;
                this.showUIMsg(error?.error)
            })
        );
    }

    setCommentId(commentId: string) {
        this.selectedComment = commentId;
        if (!this.commentEdit && this.selectedComment == commentId) {
            this.commentEdit = !this.commentEdit;
        } else {
            // this.commentEdit = !this.commentEdit;
        }
    }

    acknowledge() {
        let arr = this.selectedOptions.map((item: AlarmDetails) => item.Id);
        this.subscription.add(
            this.alarmService.acknowledge(arr).subscribe((data: any) => {
                this.getAlarmsWithPagination();
                this.selectedOptions = [];
                let el = document.getElementById('selectAll') as any;
                if (this.selectedOptions.length < this.alarms.length && el && el.checked) {
                    el.click();
                }
            })
        );
    }

    setFilterValues(siteId: number) {
        this.alarmService.getAlarmClass().subscribe((data: string[]) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i] == 'critical') {
                    data[i] = 'Critical'
                }
            }
            this.alarmFilterData.alarmClass.list = data;
        });
        this.alarmService.getAlarmEventClass().subscribe((data: string[]) => {
            this.alarmFilterData.eventType.list = data;
        });
        this.alarmService.getAlarmStatus().subscribe((data: string[]) => {
            this.alarmFilterData.status.list = data;
        });
        this.alarmService.getAssetCategory(Number(siteId)).subscribe((data: string[]) => {
            this.alarmFilterData.assetCategoryName.list = data.filter((value, index, self) => self.indexOf(value) === index);
        });
        this.ipredict.getAssetMake(this.siteId).subscribe((data: string[]) => {
            this.alarmFilterData.Make.list = data.filter((value, index, self) => self.indexOf(value) === index);
        });
    }

    toggleFilterList(key: string, toggle: boolean) {
        if ((this.alarmFilterData as any)[key] && (this.alarmFilterData as any)[key].countLimit) {
            if (toggle) {
                (this.alarmFilterData as any)[key].countLimit = (this.alarmFilterData as any)[key]?.list.length;
            } else {
                (this.alarmFilterData as any)[key].countLimit = 3;
            }
            (this.alarmFilterData as any)[key].hidden = !toggle;
        }
    }

    filterSelection(event: any, key?: string) {
        let el = document.getElementById('selectAll') as any;
        if (el && el.checked) {
            el.click();
        }
        this.statusFilterType = '';
        this.statusFilterText = '';
        if (key) {
            const filtervalue: string = event.source.value;
            const filterLabel: AlarmFilterKeys = (this.alarmFilterData as any)[key];
            if (event.source.selected) {
                if (!(this.alarmQuery as any)[key].includes(filtervalue))
                    (this.alarmQuery as any)[key]?.push(filtervalue);
                // filterLabel.filteredList.push(filtervalue);
                // if (!this.selectedFilterLabel.includes(key)) {
                //   this.selectedFilterLabel.push(key);
                // }
            } else {
                let index = (this.alarmQuery as any)[key]?.indexOf(filtervalue);
                (this.alarmQuery as any)[key].splice(index, 1);
                // const filterIndex = filterLabel.filteredList.indexOf(filtervalue);
                // filterLabel.filteredList.splice(filterIndex, 1);
                // if (filterLabel.filteredList.length === 0) {
                //   const filterLabelIndex = this.selectedFilterLabel.indexOf(key);
                //   this.selectedFilterLabel.splice(filterLabelIndex, 1);

                // }
            }
        }
        // this.applyFilter();
        if (this.filterFromDate)
            this.alarmQuery.dateRange.from = new Date(this.filterFromDate);
        if (this.filterToDate)
            this.alarmQuery.dateRange.to = new Date(this.filterToDate);
        this.pageNumber = 1;
        this.getAlarmsWithPagination();
    }

    applyFilter() {
        let alarmData = cloneDeep(this.alarms);
        for (let i = 0; i < this.selectedFilterLabel.length; i++) {
            const fiterLabelKey: string = this.selectedFilterLabel[i];
            alarmData = alarmData.filter((value: any) => {
                return (this.alarmFilterData as any)[fiterLabelKey].filteredList.indexOf(value[fiterLabelKey]) > -1;
            });
        }
        if (this.filterFromDate && this.filterToDate) {
            alarmData = alarmData.filter((value: any) => {
                const recordDate: any = this.datePipe.transform(value.receivedAt, 'yyyy-MM-dd');
                return (recordDate >= this.filterFromDate && recordDate <= this.filterToDate);
            });
        }
        this.newAlarms = alarmData;
        if (this.newAlarms.length === 0) {
            this.showNoDataFilter = true;
        }
        if (this.newAlarms.length > 0) {
            this.showNoDataFilter = false;
        }
    }

    getAlarmsForAsset() {
        this.alarms = this.alarms.filter(alarm => alarm.assetId.includes(this.assetId));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    sendMail() {
        var SiteName = this.sites.filter(
            (x: any) => x.siteId == this.siteId);
        this.Feedback = encodeURIComponent(SiteName[0].siteName) + `: Alarm records`;

        this.Message = "";
        this.Message = "Hi," + `%0D%0A` + `%0D%0A`;
        this.selectedOptions.forEach((elem: any) => {
            this.Message += `Asset ID: ` + elem.assetId + `%0D%0A`;
            this.Message += `Asset Details: ` + encodeURIComponent(elem.details) + `%0D%0A`;
            this.Message += `Recieved At: ` + elem.receivedAt + `%0D%0A`;
            if (elem.comments != null) {
                elem.comments.forEach((el: any) => {
                    this.Message += `comment: ` + el.comment + `%0D%0A`;
                    this.Message += `Performed By: ` + el.performedby + `%0D%0A`;
                    this.Message += `Performed On: ` + el.performedOn + `%0D%0A`;
                })
            }
            this.Message += `%0D%0A`;
        })
        this.Message += "Thank You";

    }

    clearAllFilters() {
        this.alarmQuery = new AlarmQuery();
        this.alarmQuery.siteId = this.siteId;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: ''
        });
        this.statusFilterText = '';
        this.searchKey = '';
        if (this.alarmFilters) {
            this.alarmFilters.options.forEach((item: MatOption) => item.deselect());
        }
        this.selectedOptions = [];
        this.filterFromDate = '';
        this.filterToDate = '';
        this.getAlarmsWithPagination(true);
    }

}
